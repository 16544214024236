import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import { Redirect } from 'react-router-dom';
import { forceClientActivation } from '../../services/clientService';
import { getLogos } from '../../utils/getLogos';

import './ClientActivation.scss';

function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(c => {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
}

const ActivationMessage = ({ activation, subsidiary }) => {
  const [redirect, setRedirect] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setRedirect(true);
    }, 3000);
  });
  return (
    <div className={`ActivationMessage ActivationMessage--${subsidiary}`}>
      {activation ? (
        <Fragment>
          <h1>¡Felicidades! </h1>
          <h4>has activado tu cuenta</h4>
          <p>Te redireccionaremos en unos momentos</p>
          <img
            alt="logo"
            className={`ActivationMessage__logo ActivationMessage__logo--${subsidiary}`}
            {...getLogos(subsidiary)}
          />
          {redirect && <Redirect to={`/${subsidiary}`} />}
        </Fragment>
      ) : (
        <h1>La cuenta ya se encuentra activada</h1>
      )}
    </div>
  );
};

ActivationMessage.propTypes = {
  activation: PropTypes.bool,
  subsidiary: PropTypes.string,
};

const ClientActivation = ({
  match: {
    params: { token, subsidiary },
  },
}) => {
  const [activatedFlag, setActivatedFlag] = useState({ requestReady: false, activated: false });
  useEffect(() => {
    try {
      const { rut } = parseJwt(token);
      forceClientActivation(
        rut,
        () => {
          setActivatedFlag({ requestReady: true, activated: true });
        },
        error => {
          console.error(error);
          setActivatedFlag({ requestReady: true, activated: false });
        },
      );
    } catch (err) {
      console.error(err);
    }
  }, []);
  return (
    <div className={`ClientActivation ClientActivation--${subsidiary}`}>
      {activatedFlag.requestReady ? (
        <ActivationMessage activation={activatedFlag.activated} subsidiary={subsidiary} />
      ) : (
        <div />
      )}
    </div>
  );
};

ClientActivation.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string,
      subsidiary: PropTypes.string,
    }),
  }),
};

export default ClientActivation;
