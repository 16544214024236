import { CardContent, Chip, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import Button from "../../../../components/ButtonTypes";
import '../AdditionalList/AdditionalList.scss';

const groupBy = (array, key) => {
  const group = [];
  const agrup = array.reduce((result, currentValue) => {
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    );
    return result;
  }, {});
  for (const [key, value] of Object.entries(agrup)) {
    group.push({ project: key, units: value });
  }
  return group;
}

const AdditionalItemMobile = ({
  additional,
  relationshipArray = [],
  onEditAdditional,
  units = [],
}) => {

  const [unidades, setUnidades] = useState([]);

  useEffect(() => {
    const unitsFiltred = units.filter(unit => additional.unidades.map(unidad => unidad.unidadid_adi).includes(unit.unidadId))
    const unitsGrouped = groupBy(unitsFiltred, 'project');
    setUnidades(unitsGrouped);
  }, [additional, units]);

  return (
    <CardContent className="ClientAdditional__mobile">
      <Grid container>
        <Grid item xs={12}>
          <Typography
            variant="h5"
            component="h2"
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <span style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
              <b>{additional.solicitantenombre_adi}</b>
              <Chip
                label={additional.estadoadiciona_adi === '1' ? 'Activo' : 'Desactivado'}
                style={{
                  color: 'white',
                  marginInline: '0.5rem',
                  backgroundColor:
                  additional.estadoadiciona_adi === '1' ? '#5cb85c' : '#ff3333',
                }}
              />
            </span>
          </Typography>
          <Typography color="textSecondary" style={{display: 'flex', justifyContent: 'center'}}>{additional.solicitanterut_adi}</Typography>
            <Typography
              variant="body2"
              component="p"
              style={{
                paddingTop: '0.5rem',
                fontSize: 'x-small',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              {
                relationshipArray.find(
                  relationship =>
                    relationship.id === +additional.solicitanterelacionPropietarioID_adi,
                ).name
              }{' '}
              - {additional.solicitanteemail_adi} - {additional.solicitantephone_adi}
            </Typography>
            <div className='AdditionalDetail__expansionPanelDetails__mobile__units'>
              {unidades.map(unit => {
                return (
                  <>
                    <div style={{fontSize: 'x-small', textTransform: 'capitalize'}}>
                      {`${unit.project}`}
                    </div>
                      {unit.units.map(unit => <div style={{fontSize: 'x-small', textTransform: 'capitalize'}}>- {unit.numeroProducto}</div>)}
                  </>
                )
              })
              }
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: '0.5rem',
              }}
            >
              <Button size="small" onClick={() => onEditAdditional(additional)}>
                  Editar
              </Button>
            </div>
        </Grid>
      </Grid>
    </CardContent>
  )

};

export default AdditionalItemMobile;
