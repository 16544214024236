 import React, { useState, useEffect, useContext, useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Grid from '@material-ui/core/Grid';
import NoteList from '../../components/NoteList';
import ClientStatus from '../../components/ClientStatus';
import MilestonCalendar from '../../components/MilestonesCalendar/MilestonCalendar';

// Services
import {
  postClientNote,
  listClientNote,
  editClientNote,
  deleteClientNote,
  getClientByRut,
} from '../../services/clientService';

// Provider
import { EnterpriseContext } from '../../providers/EnterpriseProvider';

// utils
import { capitalizeEveryWord } from '../../utils/utils';

import './ClientDetail.scss';
import { useIslogged } from '../../customHooks/useIslogged';
import { useProjectsByClient } from '../../customHooks/fetchProjectsByClient';
import { listClientDocuments, uploadFileClient } from '../../services/documents';

// componentes
import EnterpriseMessageItem from '../EnterpriseMessages/EnterpriseMessageItem';
import ClientProjects from './ClientProjects';
import { useClientByRut } from '../../customHooks/fetClientData';
import { ClientContext } from '../../providers/ClientProvider';
import ProjectDetails from './ClientDetailProjects';


const updateNoteList = (clientId, updateCallback) => {
  listClientNote(
    clientId,
    ({ data: { data } }) => {
      updateCallback([...data]);
    },
    err => {
      console.error(err);
    },
  );
};

const useClientNotes = clientId => {
  const [clientNotes, setClientNotes] = useState([]);
  useEffect(() => {
    if (clientId) {
      updateNoteList(clientId, setClientNotes);
    }
  }, [clientId]);
  return { clientNotes, setClientNotes };
};

const updateClientState = (clientId, setClientStatus) => {
  getClientByRut(
    clientId,
    ({
      data: {
        data: [client],
      },
    }) => {
      setClientStatus(client.estado_sistema);
    },
    err => {
      console.error(err);
    },
  );
};

const useClientState = propState => {
  const [clientStatus, setClientStatus] = useState(propState);
  useEffect(() => {
    setClientStatus(propState);
  }, [propState]);
  return { clientStatus, setClientStatus };
};

const ClientDetail = ({
  match: {
    params: { subsidiary, idProject, idClient: clientId },
  },
  history,
  onReadMessages,
}) => {
  if (!useIslogged()) history.push(`/${subsidiary}`);

  const {
    client: {
      nombres,
      apellidos,
      eMailParticular = '',
      telefonoCelular,
      estado_sistema: propClientStatus,
      _id: mongoId,
    } = {},
  } = useClientByRut(clientId.slice(0, -1));

  const { state: { executiveRut } = {} } = useContext(EnterpriseContext);

  const rutCliente = clientId ? clientId.slice(0, -1) + '-' + clientId.slice(-1) : '';

  const { clientNotes, setClientNotes } = useClientNotes(clientId);
  const { clientStatus, setClientStatus } = useClientState(propClientStatus);
  const { projects, setProjects } = useProjectsByClient(clientId.slice(0, -1), idProject);

  const addNote = (title, text) => {
    postClientNote(
      clientId,
      title,
      text,
      () => {
        updateNoteList(clientId, setClientNotes);
      },
      err => {
        console.error(err);
      },
    );
  };

  const editNote = (noteId, title, text) => {
    editClientNote(
      noteId,
      title,
      text,
      () => {
        updateNoteList(clientId, setClientNotes);
      },
      err => {
        console.error(err);
      },
    );
  };

  const deleteNote = noteId => {
    deleteClientNote(
      noteId,
      () => {
        updateNoteList(clientId, setClientNotes);
      },
      err => {
        console.error(err);
      },
    );
  };
  const selectedFilial = localStorage.getItem('filial');
  const thisSubsidiaryProjects = projects.filter(proj => proj.filial === selectedFilial);
  const otherSubsidiaryProjects = projects.filter(proj => proj.filial !== selectedFilial);
  return (
    <div className="ClientDetail">
      <h2 className="ClientDetail__title">{`${nombres} ${apellidos}`}</h2>
      <h5 className="ClientDetail__subtitle"></h5>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={6}>
          <h5>DATOS DEL CLIENTE</h5>
          <div className="ClientDetail__info">
            <h5 className="ClientDetail__info__title">Datos personales</h5>
            <ul className="ClientDetail__info__list">
              <li className="ClientDetail__info__list__label">Nombre:</li>
              <li className="ClientDetail__info__list__value">
                {capitalizeEveryWord(`${nombres} ${apellidos}`)}
              </li>
              <li className="ClientDetail__info__list__label">Rut:</li>
              <li className="ClientDetail__info__list__value">{rutCliente}</li>
            </ul>
            <h5 className="ClientDetail__info__title">DATOS DE CONTACTO</h5>
            <ul className="ClientDetail__info__list">
              <Fragment>
                <li className="ClientDetail__info__list__label">Correo: </li>
                <li className="ClientDetail__info__list__value">
                  {eMailParticular && eMailParticular !== ''
                    ? eMailParticular.toLowerCase()
                    : 'El cliente no posee correo'}
                </li>
              </Fragment>

              <Fragment>
                <li className="ClientDetail__info__list__label">Celular: </li>
                <li className="ClientDetail__info__list__value">
                  {telefonoCelular && telefonoCelular !== ''
                    ? telefonoCelular
                    : 'El cliente no posee telefono'}
                </li>
              </Fragment>
            </ul>
            <h5 className="ClientDetail__info__title">Estado</h5>
            <ClientStatus
              clientStatus={clientStatus}
              clientId={clientId}
              clientMongoId={mongoId}
              cliente={`${nombres} ${apellidos}`}
              email={eMailParticular}
              disabled={eMailParticular === ''}
              onClientStatusChange={() => updateClientState(clientId, setClientStatus)}
              history={history}
            />
            {eMailParticular === '' && (
              <ul className="ClientDetail__info__list">
                <li>
                  <span className="ClientDetail__info__list__errorMessage">
                    * El cliente no se puede activar ya que no posee correo
                  </span>
                </li>
              </ul>
            )}
          </div>
        </Grid>
        <Grid item sm={6} xs={12}>
          <h5>NOTAS SOBRE EL CLIENTE</h5>
          <NoteList
            onSubmit={addNote}
            editCallback={editNote}
            deleteCallback={deleteNote}
            clientNotes={clientNotes}
          />
        </Grid>
      </Grid>
      <div>
        <h3>PROYECTOS {selectedFilial.toUpperCase()}</h3>
        <ProjectDetails
          subsidiaryProjects={thisSubsidiaryProjects}
          subsidiary={subsidiary}
          nombres={nombres}
          apellidos={apellidos}
          executiveRut={executiveRut}
          rutCliente={rutCliente}
          onReadMessages={onReadMessages}
          clientId={clientId}
        />
      </div>
      {otherSubsidiaryProjects.length > 0 && (
        <Accordion className="ProjectList__otherProjectsAccordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <h3 className={`ProjectList__subsidiaryNames--${subsidiary}`}>
              PROYECTOS EN OTRAS FILIALES
            </h3>
          </AccordionSummary>
          <AccordionDetails>
            <ProjectDetails
              subsidiaryProjects={otherSubsidiaryProjects}
              subsidiary={subsidiary}
              nombres={nombres}
              apellidos={apellidos}
              executiveRut={executiveRut}
              rutCliente={rutCliente}
              onReadMessages={onReadMessages}
              clientId={clientId}
            />
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  );
};

ClientDetail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      subsidiary: PropTypes.string,
    }),
  }),
};

export default ClientDetail;
