import React from 'react';
import PropTypes from 'prop-types';

import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  ExpansionPanelDetails,
} from '@material-ui/core';

import PropertyItem from '../PropertyList/PropertyItem';
import ProjectButton from '../ProjectButton';
import { getLogos } from '../../utils/getLogos';

import './PropertyListAsList.scss';

const PropertyListAsList = ({ nombreProyecto, propiedades, subsidiary, onClick, filial }) => {
  return (
    <ExpansionPanel className="ProjectItemMultiple">
      <ExpansionPanelSummary
        expandIcon={
          <ProjectButton subsidiary={subsidiary} className="ProjectItemMultiple__button" />
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div className="ProjectItemMultiple__summary">
          <img
            alt={`Icono ${nombreProyecto}`}
            className="ProjectItem__header__imageLogo"
            {...getLogos(filial, false, true)}
          />
          <Typography style={{ marginRight: '5px' }}>{`${nombreProyecto}`}</Typography>
          <Typography className="ProjectItem--gallery__number">
            {propiedades.length === 1
              ? `(${propiedades[0].tipo_Unidad} ${propiedades[0].numero_Departamento})`
              : `(${propiedades.length} unidades)`}
          </Typography>
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        {propiedades.map(
          ({
            id_Propiedad: idPropiedad,
            estado_Cliente: estadoCliente,
            numero_Departamento: apartmentNumber,
          }) => (
            <PropertyItem
              numero={apartmentNumber}
              piso={estadoCliente}
              subsidiary={subsidiary}
              key={idPropiedad}
              onClick={() => onClick(idPropiedad)}
            />
          ),
        )}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

PropertyListAsList.propTypes = {
  nombreProyecto: PropTypes.string,
  propiedades: PropTypes.array,
  subsidiary: PropTypes.string,
  onClick: PropTypes.func,
  stageName: PropTypes.string,
};

export default PropertyListAsList;
