import React from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { formatDate } from '../../utils/utils';
import RightArrowIcon from '../Icons/RightArrowIcon';

import './LastMessages.scss';

import { } from '../../services/clientService';

const LastMessages = ({ messages, onClick, subsidiary }) => {
  return (
    <div className="LastMessages">
      {messages && messages.length > 0 ? (
        messages.map(value => {
          const { nombre_emisor: name, asunto } = value;
          const readed = value.leidos;
          const style = readed
            ? `LastMessages__item__readed LastMessages__item__readed--${subsidiary}`
            : `LastMessages__item LastMessages__item--${subsidiary}`;
          const opacity = readed ? 'shadow-readed' : '';
          const arrowColor = subsidiary === 'almagro' ? '#ffffff' : '';

          return (
            <div className={style} key={value._id} onClick={onClick}>
              <Grid container spacing={8} alignItems="center">

                <Grid item xs={1}>
                  <div className={`LastMessages__circle LastMessages__circle--${subsidiary} ${opacity}`}></div>
                </Grid>
                <Grid item xs={3}>
                  <div className={`LastMessages__name LastMessages__name--${subsidiary} ${opacity}`}>
                    {name.toLowerCase()}
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div
                    className={`LastMessages__asunto LastMessages__asunto--${subsidiary} ${opacity}`}
                  >
                    {asunto.toLowerCase()}
                  </div>
                </Grid>
                <Grid item xs={1}>
                  <div className="LastMessages__fecha">
                    {formatDate(new Date(value.fecha_ultimo_mensaje), 'dd MMM')}
                    <RightArrowIcon color={arrowColor} />
                  </div>
                </Grid>
              </Grid>
            </div>
          );
        })
      ) : (
          <div className={`LastMessages__item--${subsidiary}`}>No tiene mensajes</div>
        )}
    </div>
  );
};

LastMessages.propTypes = {
  messages: PropTypes.array,
  onClick: PropTypes.func,
  subsidiary: PropTypes.string,
};

export default LastMessages;
