/* eslint-disable no-confusing-arrow */
import React from 'react';

import IconPropTypes from '../IconPropTypes';

const SightIcon = ({ isOpen = false, color = 'none', onClick }) =>
  isOpen ? (
    <svg
      width="22px"
      height="13px"
      viewBox="0 0 22 13"
      version="1.1"
      onClick={onClick}
      style={{ cursor: onClick ? 'pointer' : 'auto' }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Symbols" stroke="none" strokeWidth="1" fill={color} fillRule="evenodd">
        <g
          id="Icons/24/pass/active"
          transform="translate(-1.000000, -6.000000)"
          fill={color}
          fillRule="nonzero"
        >
          <g id="noun_Eye_506283" transform="translate(1.000000, 6.000000)">
            <path
              d="M11,3.5 C12.7880712,3.5 14.25,4.96192881 14.25,6.75 C14.25,8.53807119 12.7880712,10 11,10 C9.21192881,10 7.75,8.53807119 7.75,6.75 C7.75,4.96192881 9.21192881,3.5 11,3.5 Z M11,9 C12.2369288,9 13.25,7.98692881 13.25,6.75 C13.25,5.51307119 12.2369288,4.5 11,4.5 C9.76307119,4.5 8.75,5.51307119 8.75,6.75 C8.75,7.98692881 9.76307119,9 11,9 Z"
              id="Shape"
            />
            <path
              d="M16,6.75 C16,3.98807119 13.7619288,1.75 11,1.75 C8.23807119,1.75 6,3.98807119 6,6.75 C6,9.51192881 8.23807119,11.75 11,11.75 C13.7619288,11.75 16,9.51192881 16,6.75 Z M11,10.75 C8.78939661,10.75 7,8.96060339 7,6.75 C7,4.53939661 8.78939661,2.75 11,2.75 C13.2106034,2.75 15,4.53939661 15,6.75 C15,8.96060339 13.2106034,10.75 11,10.75 Z"
              id="Shape"
            />
            <path
              d="M11,0.75 C15.0922779,0.75 18.8984785,2.9502386 20.9393732,6.49637606 L21.0915476,6.75 L21.0143732,6.87862394 L20.941676,6.99970563 C18.8984785,10.5497614 15.0922779,12.75 11,12.75 C6.90772206,12.75 3.10152153,10.5497614 1.06062677,7.00362394 L0.908452405,6.75 L0.985626769,6.62137606 L1.05832396,6.50029437 C3.10152153,2.9502386 6.90772206,0.75 11,0.75 Z M11,11.75 C14.6353506,11.75 18.0120196,9.85027306 19.9082122,6.75 C18.0120196,3.64972694 14.6353506,1.75 11,1.75 C7.36464938,1.75 3.98798044,3.64972694 2.09178776,6.75 C3.98798044,9.85027306 7.36464938,11.75 11,11.75 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </svg>
  ) : (
    <svg
      width="22px"
      height="17px"
      viewBox="0 0 22 17"
      version="1.1"
      onClick={onClick}
      style={{ cursor: onClick ? 'pointer' : 'auto' }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Symbols" stroke="none" strokeWidth="1" fill={color} fillRule="evenodd">
        <g
          id="Icons/24/pass/neutro"
          transform="translate(-1.000000, -4.000000)"
          fill={color}
          fillRule="nonzero"
        >
          <g id="noun_hide-password_506282" transform="translate(1.000000, 4.000000)">
            <path
              d="M11,11 C10.7241808,11 10.4569647,10.9535276 10.2428477,10.8678808 L10.095103,10.808783 L9.30947863,11.5453058 L9.64427863,11.7015458 C10.0622237,11.8965868 10.524136,12 11,12 C12.7880712,12 14.25,10.5380712 14.25,8.75 C14.25,8.28392774 14.1517867,7.85178933 13.9540393,7.39979529 L13.8072256,7.06422102 L13.0606408,7.81080585 L13.1150516,7.96043577 C13.2062254,8.21116355 13.25,8.45739577 13.25,8.725 C13.25,9.98343267 12.2403955,11 11,11 Z"
              id="Path"
            />
            <path
              d="M16.5,8.75 C16.5,7.66285796 16.1825979,6.63791362 15.5848925,5.71418718 L15.4168938,5.45455279 L14.6794795,6.19196707 L14.7908847,6.36200656 C15.249727,7.06234478 15.5,7.89251865 15.5,8.75 C15.5,11.2369288 13.4869288,13.25 11,13.25 C10.1523305,13.25 9.31986635,13.0051576 8.60684072,12.56259 L8.43840374,12.4580429 L7.69979207,13.1966545 L7.96862678,13.3626995 C8.903567,13.9401626 9.92537127,14.25 11,14.25 C14.0380712,14.25 16.5,11.7880712 16.5,8.75 Z"
              id="Path"
            />
            <path
              d="M11,13.75 C9.90496041,13.75 8.85648128,13.580304 7.82905694,13.2378292 L7.68247731,13.1889693 L6.88921903,13.9822276 L7.26943405,14.1116625 C8.50408393,14.5319688 9.75455688,14.75 11,14.75 C15.0922779,14.75 18.8984785,12.5497614 20.941676,8.99970563 L21.0143732,8.87862394 L21.0915476,8.75 L20.9393732,8.49637606 C19.9236904,6.76969661 18.4964918,5.33595126 16.8021823,4.33476837 L16.635294,4.23615256 L15.8901502,4.98129638 L16.1774347,5.14289388 C17.7176025,6.0092383 18.9975984,7.24144634 19.9087018,8.7491995 C18.0126008,11.849946 14.6356635,13.75 11,13.75 Z"
              id="Path"
            />
            <path
              d="M17.9732344,1.07168719 C18.1681831,0.878433702 18.4826737,0.879120359 18.6767767,1.0732233 C18.8710576,1.26750421 18.8710576,1.58249579 18.6767767,1.7767767 L4.0267767,16.4267767 C3.83249579,16.6210576 3.51750421,16.6210576 3.3232233,16.4267767 C3.1289424,16.2324958 3.1289424,15.9175042 3.3232233,15.7232233 L5.63055338,13.4158932 C3.73118989,12.4058068 2.14103337,10.8763985 1.06062677,9.00362394 L0.908452405,8.75 L0.985626769,8.62137606 L1.05832396,8.50029437 C3.10152153,4.9502386 6.90772206,2.75 11,2.75 C12.5127095,2.75 14.0161,3.05292945 15.3934892,3.62899983 L17.9732344,1.07168719 Z M12.1973069,6.84913973 C11.8552771,6.62090856 11.4445837,6.5 11,6.5 C9.76307119,6.5 8.75,7.51307119 8.75,8.75 C8.75,9.19458369 8.87090856,9.60527705 9.09913973,9.94730688 L12.1973069,6.84913973 Z M11,4.25 C8.51307119,4.25 6.5,6.26307119 6.5,8.75 C6.5,9.77876658 6.85554609,10.7497382 7.48895352,11.5324931 L8.36923545,10.6522112 C7.97350148,10.0934335 7.75,9.43016319 7.75,8.75 C7.75,6.96192881 9.21192881,5.5 11,5.5 C11.6795664,5.5 12.3429051,5.72263311 12.9243949,6.12205171 L13.8049612,5.24148542 C12.9984724,4.60400459 12.0268309,4.25 11,4.25 Z M2.06777938,8.75001227 C3.12002359,10.4495807 4.60948622,11.8005006 6.37389953,12.6725471 L6.78250172,12.2639449 C5.96438557,11.2908495 5.5,10.0470763 5.5,8.75 C5.5,6.88062839 6.43211843,5.22939053 7.85703113,4.23561327 C5.48662424,4.98558166 3.41790474,6.57012755 2.06777938,8.75001227 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </svg>
  );

SightIcon.propTypes = IconPropTypes;

export default SightIcon;
