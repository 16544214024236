import React from 'react';
import PropTypes from 'prop-types';

// Dialog Material Components
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';

const CustomDialog = ({
  disableBackdropClick = true,
  disableEscapeKeyDown = true,
  dividers = false,
  actions,
  children,
  title,
  onOpen,
  maxWidth = 'xs',
  open,
  stylePaper,
  ...rest
}) => {
  const handleDivider = () => {
    return dividers ? <DialogContent dividers>{children}</DialogContent> : children;
  };
  return (
    <Dialog
      disableBackdropClick={disableBackdropClick}
      disableEscapeKeyDown={disableEscapeKeyDown}
      maxWidth={maxWidth}
      onEntering={onOpen}
      aria-labelledby="confirmation-dialog-title"
      open={open}
      PaperProps={stylePaper}
      {...rest}
    >
      <DialogTitle id="customized-dialog-title">{title}</DialogTitle>
      {handleDivider()}
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  );
};

CustomDialog.propTypes = {
  disableBackdropClick: PropTypes.bool,
  disableEscapeKeyDown: PropTypes.bool,
  dividers: PropTypes.bool,
  actions: PropTypes.node,
  children: PropTypes.node,
  title: PropTypes.string,
  onOpen: PropTypes.func,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  open: PropTypes.bool,
  stylePaper: PropTypes.object,
};

export default CustomDialog;
