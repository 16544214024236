import React, { useState, useContext, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';

import MessageList from '../../components/MessageList';

import {
  getMessagesByProject,
  answerByMessage,
  sendNewMessage,
  markMessageAsRead,
} from '../../services/messageService';

import { received, sended } from '../../constants/messageFilters';

import { GlobalContext } from '../../providers/GlobalStateProvider';
import { useIslogged } from '../../customHooks/useIslogged';
import { useHistory } from 'react-router-dom';

const baseRows = 10;

const useHandlePagination = ({ total }) => {
  const basePage = 1;
  const limit = baseRows;
  const [currentPage, setCurrentPagination] = useState(basePage);
  const [totalPages, setTotalPages] = useState(0);
  useEffect(() => {
    if (total && total > 0) {
      setTotalPages(Math.ceil(total / limit));
    }
  }, [total]);
  const forwardPage = () => {
    if (currentPage < totalPages) {
      setCurrentPagination(currentPage + 1);
    }
  };
  const backwardPage = () => {
    if (currentPage > 0) {
      setCurrentPagination(currentPage - 1);
    }
  };
  return { currentPage, forwardPage, backwardPage, totalPages, limit };
};

// projectId,
//   receptorId,
//   filter = '',
//   page = 1,
//   limit = 10,
//   successCallback,
//   errorCallback,
const updateMessageList = (
  activeProject,
  username,
  setMessageList,
  setMessagesDetail,
  filterValue,
  currentPage = 1,
  limit = baseRows,
) => {
  getMessagesByProject(
    activeProject,
    username,
    filterValue,
    currentPage,
    limit,
    false,
    ({ data: { data, cant_leidos: read, cant_no_leidos: notRead, todos: total } }) => {
      setMessageList([...data]);
      setMessagesDetail({ total, read, notRead });
    },
    err => {
      console.error(err);
    },
  );
};

const useMessageList = (activeProject, username, filterValue) => {
  const [messageList, setMessageList] = useState([]);
  const [messagesDetail, setMessagesDetail] = useState({
    total: undefined,
    read: undefined,
    notRead: undefined,
  });
  const { currentPage, forwardPage, backwardPage, totalPages, limit } = useHandlePagination(
    messagesDetail,
  );
  useMemo(() => {
    if (filterValue !== undefined && filterValue !== null) {
      updateMessageList(
        activeProject,
        username,
        setMessageList,
        setMessagesDetail,
        filterValue,
        currentPage,
        limit,
      );
    }
  }, [activeProject, username, filterValue]);
  return {
    messageList,
    setMessageList,
    messagesDetail,
    setMessagesDetail,
    currentPage,
    limit,
    forwardPage,
    backwardPage,
    totalPages,
  };
};

const useFilterValue = () => {
  const [filterValue, setFilterValue] = useState(received);
  const onFilterChange = (tabValue = 1) => {
    switch (tabValue) {
      case 1:
        setFilterValue(received);
        break;
      case 2:
        setFilterValue(sended);
        break;
      default:
        setFilterValue('leido=false&');
        break;
    }
  };
  return { filterValue, onFilterChange };
};

const ClientMessageList = ({
  projectId,
  projectName,
  subsidiary,
  openAtStart,
  textFilter = '',
  filteredMessages,
  onReadMessages,
}) => {

  const history = useHistory()
  if (!useIslogged()) history.push(`/${subsidiary}`)

  const {
    state: { username, fullName },
  } = useContext(GlobalContext);
  const { filterValue, onFilterChange } = useFilterValue();
  const {
    messageList,
    setMessageList,
    messagesDetail,
    setMessagesDetail,
    currentPage,
    limit,
    forwardPage,
    backwardPage,
    totalPages,
  } = useMessageList(projectId, username, filterValue);

  const onSubmitAnswerMessage = (messageId, message, onSubmitCallback) => {
    answerByMessage(
      messageId,
      message,
      () => {
        onSubmitCallback();
        updateMessageList(
          projectId,
          username,
          setMessageList,
          setMessagesDetail,
          filterValue,
          currentPage,
          limit,
        );
      },
      err => {
        console.error(err);
      },
    );
  };
  const submitNewMessage = (asunto, emisor, receptor, message, file, resetForm) => {
    sendNewMessage(
      { projectId, asunto, emisor, receptor, sendByExecutive: false, message, fullName, file },
      () => {
        updateMessageList(
          projectId,
          username,
          setMessageList,
          setMessagesDetail,
          filterValue,
          currentPage,
          limit,
        );
        resetForm();
      },
      err => {
        console.error(err);
      },
    );
  };

  const markAsRead = messageId => {
    markMessageAsRead(
      messageId,
      () => {
        updateMessageList(
          projectId,
          username,
          setMessageList,
          setMessagesDetail,
          filterValue,
          currentPage,
          limit,
        );
        onReadMessages()
      },
      err => {
        console.error(err);
      },
    );
  };
  return (
    <MessageList
      projectName={projectName}
      messages={textFilter !== '' ? [...filteredMessages] : [...messageList]}
      subsidiary={subsidiary}
      className="ClientMessages__item"
      onSubmitAnswerMessage={onSubmitAnswerMessage}
      onSubmitNewMessage={submitNewMessage}
      messagesDetail={messagesDetail}
      onCollapseItem={markAsRead}
      onTabChange={onFilterChange}
      openAtStart={openAtStart}
      currentPage={currentPage}
      forwardPage={forwardPage}
      backwardPage={backwardPage}
      totalPages={totalPages}
      from={username}
      projectId={projectId}
    />
  );
};

ClientMessageList.propTypes = {
  subsidiary: PropTypes.string,
  projectId: PropTypes.string,
  projectName: PropTypes.string,
  openAtStart: PropTypes.bool,
  textFilter: PropTypes.string,
  filteredMessages: PropTypes.array,
};

export default ClientMessageList;
