/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, memo, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';

import DocumentItem from './DocumentItem';
import DocumentItemPromise from './DocumentItemPromise';
import Button from '../ButtonTypes';
import FileUploadInput from '../FileUploadInput';
import TextFields from '../TextFields';
import { empresas } from '../../constants/filialesNames';
import Dialog from '../Dialog';
import DeleteButton from '../DeleteButton';
import { colorConfig } from '../../constants/colors';

import './DocumentList.scss';

const useSectedFile = uploadCallback => {
  const [selectedFile, setSelectedFile] = useState();
  const [fileName, setFileName] = useState('');
  const handleUploadFile = (files, fileType) => {
    setFileName(files[0].name);
    uploadCallback(files[0], fileType);
  };
  return { selectedFile, setSelectedFile, fileName, handleUploadFile };
};

const DocumentList = ({
  subsidiary,
  fileList,
  uploadCallback,
  deleteCallback,
  onPropertyChecked,
}) => {
  const { handleUploadFile } = useSectedFile(uploadCallback);
  const [openDialog, setOpenDialog] = useState(false);
  const [disableButtonDialog, setDisableButtonDialog] = useState(true);
  const [documentLabel, setDocumentLabel] = useState('');
  const [documentFileEvent, setDocumentFileEvent] = useState([]);
  const [fileKey, setFileKey] = useState(Date.now());

  const toogleDialog = () => {
    setOpenDialog(!openDialog);
    setFileKey(Date.now());
  };

  const openModalDocumentName = event => {
    setDocumentFileEvent(event.target.files);
    toogleDialog();
  };

  const handleChange = text => {
    if (text) {
      setDocumentLabel(text);
      setDisableButtonDialog(false);
    } else {
      setDisableButtonDialog(true);
    }
  };

  const handleUploadDocument = () => {
    handleUploadFile(documentFileEvent, documentLabel);
    toogleDialog();
    setDocumentLabel('');
  };

  // const [ images, setImages ] = useState([]);
  // useEffect(() => {
  //   if (!fileList.filter) {
  //     setImages([...fileList.images]);
  //   } else {
  //     let img = [];
  //     if (fileList.length > 0) {
  //       fileList.forEach(file => {
  //         if (file.images && file.images.length > 0) {
  //           file.images.forEach(image => {
  //             image.label = image.name.substring(image.name.lastIndexOf('/') + 1, image.name.indexOf('-')).toUpperCase();
  //             img.push(image);
  //           });
  //         }
  //       });
  //     }
  //     if (typeof fileList === 'object' && typeof fileList[Symbol.iterator] != 'function') {
  //       if (fileList.images && fileList.images.length > 0) {
  //         fileList.images.forEach(image => {
  //           image.label = image.name.substring(image.name.lastIndexOf('/') + 1, image.name.indexOf('-')).toUpperCase();
  //           img.push(image);
  //         });
  //       }
  //     }
  //     setImages(img);
  //   }
  // }, [fileList]);

  const handleUpload = () => {
    handleUploadDocument();
  }

  return (
    <Fragment>
      <Dialog
        className="DocumentList__dialogModal"
        open={openDialog}
        title="EDITAR NOMBRE DEL DOCUMENTO"
        stylePaper={{
          style: {
            padding: '4px 100px 20px 0px',
          },
        }}
        actions={
          <div className="DocumentList__dialogModal__actions">
            <Button
              className="DocumentList__dialogModal__actions__button"
              variant="contained"
              onClick={() => handleUpload()}
              disabled={disableButtonDialog}
            >
              CARGAR
            </Button>
          </div>
        }
      >
        <div className="DocumentList__dialogModal__content">
          <DeleteButton
            label={false}
            style={{ position: 'absolute', top: '20px', right: '20px' }}
            onClick={toogleDialog}
          />
          <TextFields
            name="document_name"
            label="NOMBRE DOCUMENTO:"
            onChange={e => handleChange(e.target.value)}
          />
        </div>
      </Dialog>

      <div className={`DocumentList DocumentList--${subsidiary}`}>
        <div className="DocumentList__list">
        {fileList && fileList.length > 0 ?
          // fileList.map((doc, index) => {
          //   if (doc && doc.infoPropiedad && doc.infoPropiedad.documentos) {
          //     return doc.infoPropiedad.documentos.map((document, index) => {
          //       return <DocumentItemPromise
          //         subsidiary={subsidiary}
          //         name={document.tipoDocumento}
          //         idNegocio={doc.infoPropiedad.idNegocio}
          //         depto={`${document.unidad} - (${doc.nombreProyecto})`}
          //         onChecked={() => onPropertyChecked(doc.id_Propiedad)}
          //         checked={doc.checked || false}
          //         link={`${process.env.REACT_APP_SERVER_URL}/documentos/getDoc/${doc.infoPropiedad.idNegocio}/${index}`}
          //         fecha={document.fechaDocumento}
          //       />
          //     })
          //   }
          // })
          fileList.map((doc, index) => {
            return <DocumentItemPromise
              subsidiary={subsidiary}
              name={doc.tipoDocumento}
              idNegocio={doc.idNegocio}
              depto={`${doc.unidad} - (${doc.proyecto})`}
              onChecked={!!onPropertyChecked && (() => onPropertyChecked(doc.id_Propiedad))}
              checked={doc.checked || false}
              // link={`${process.env.REACT_APP_SERVER_URL}/documentos/getDoc/${doc.idNegocio}/${index}`}
              link={doc.adjunto}
              fecha={doc.fechaDocumento}
              />
            })
         : null
        }

        {typeof fileList === 'object' && typeof fileList[Symbol.iterator] != 'function' ?
          fileList && fileList.length > 0 ?
            fileList.map((document, index) => {
              return <DocumentItemPromise
                subsidiary={subsidiary}
                name={document.tipoDocumento}
                idNegocio={fileList.idNegocio}
                depto={`${document.unidad} - (${document.proyecto})`}
                onChecked={() => onPropertyChecked(fileList.id_Propiedad)}
                checked={fileList.checked || false}
                // link={`${process.env.REACT_APP_SERVER_URL}/documentos/getDoc/${fileList.infoPropiedad.idNegocio}/${index}`}
                link={document.adjunto}
                fecha={document.fechaDocumento}
              />
            }
          ) : null
          : null
          }
        </div>
      </div>
    </Fragment>
  );
};

DocumentList.propTypes = {
  subsidiary: PropTypes.string,
  fileList: PropTypes.array,
  uploadCallback: PropTypes.func,
  idPropiedad: PropTypes.number,
  deleteCallback: PropTypes.func,
  listFiles: PropTypes.array,
  deptos: PropTypes.array,
};

export default memo(DocumentList);
