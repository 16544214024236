import { useEffect, useState } from "react";
import { listClientDocuments, listProjectDocuments } from "../services/documents";

export const useProjectDocuments = (idProyecto, idEjecutivo) => {
  const [projectDocuments, setProjectDocuments] = useState([]);
  useEffect(() => {
    if (idProyecto && idProyecto !== '') {
      listProjectDocuments(idProyecto, idEjecutivo, undefined, undefined,
      ({ data }) => {
        setProjectDocuments(data);
      }, err => {
        console.error(err);
      }
      );
    }
  }, [idProyecto, idEjecutivo]);
  return { projectDocuments, setProjectDocuments };
}

export const useClientDocuments = (idProyecto, idCliente) => {
  const [clientDocuments, setClientDocuments] = useState([]);
  const [projectDocuments, setProjectDocuments] = useState([]);
  useEffect(() => {
    if (idCliente && idCliente !== '' && idProyecto && idProyecto !== '') {
      listClientDocuments(idProyecto, undefined, idCliente, undefined,
      ({ data }) => {
        setClientDocuments(data.docClientes);
        setProjectDocuments(data.docProyectos);
      }
      , err => {
        console.error(err);
      }
      );
    }
  }, [idProyecto, idCliente]);
  return { clientDocuments, setClientDocuments, projectDocuments, setProjectDocuments };
}
