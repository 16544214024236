import axios from 'axios';

import {
  messagesByProject,
  answerMessage,
  markAsRead,
  sendMessage,
  filterMessagesByText,
  massiveMessage,
} from '../constants/endpoints';

export const sendNewMessage = (
  { projectId, asunto, emisor, receptor, sendByExecutive, message, fullName, file },
  successCallback,
  errorCallback,
) =>
  axios
    .post(sendMessage, {
      id_proyecto: projectId,
      asunto,
      emisor,
      nombre_emisor: fullName,
      receptor,
      envia_ejecutivo: sendByExecutive,
      mensaje: message,
      file: file,
    })
    .then(successCallback)
    .catch(errorCallback);

export const getMessagesByProject = (
  projectId,
  receptorId,
  filter = '',
  page = 1,
  limit = 10,
  envia_ejecutivo = false,
  successCallback,
  errorCallback,
) => {
  return axios
    .post(
      `${messagesByProject}?${filter}${page ? `page=${page}` : ''}${
        limit ? `&limit=${limit}&` : ''
      }order=desc`,
      {
        rut: receptorId,
        id_proyecto: projectId,
        envia_ejecutivo,
      },
    )
    .then(successCallback)
    .catch(errorCallback);
};

export const markMessageAsRead = (messageId, successCallback, errorCallback) =>
  axios
    .put(markAsRead, {
      id: messageId,
    })
    .then(successCallback)
    .catch(errorCallback);

export const answerByMessage = (messageId, message, successCallback, errorCallback) =>
  axios
    .put(`${answerMessage}/${messageId}`, {
      mensaje: message,
    })
    .then(successCallback)
    .catch(errorCallback);

export const filterMessage = (text, successCallback, errorCallback) => {
  axios
    .get(`${filterMessagesByText}?q=${text}&page=1&limit=20&order=desc`)
    .then(successCallback)
    .catch(errorCallback);
};

// projectId, asunto, emisor,  clients, message
export const sendMassiveMessage = (
  idProject,
  subject,
  from,
  clients,
  message,
  executiveName,
  file,
  successCallback,
  errorCallback,
) => {
  axios
    .post(massiveMessage, {
      id_proyecto: idProject,
      emisor: from,
      asunto: subject,
      mensaje: message,
      envia_ejecutivo: true,
      clientes: clients,
      nombre_emisor: executiveName,
      file: file,
    })
    .then(successCallback)
    .catch(errorCallback);
}
