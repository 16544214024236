import React from "react";

import "./UnitList.scss";
import { Card, CardContent, Typography } from "@material-ui/core";
import { colorConfig } from '../../../../constants/colors';

const UnitList = ({
  units = [],
  hasAdditionals = false,
  subsidiary,
}) => {
  if (!hasAdditionals) {
    return (
      <div className="UnitList__container">
        <div className={`UnitList__container__header`}>
          <span style={{color: colorConfig[subsidiary].primary}}>No registra usuarios adicionales.</span>
        </div>
      </div>
    )
  } else {
    return (
      <div className="UnitList__container">
          {units.length > 0  ? (
            <div className={`UnitList__container__header`}>
              <span style={{color: colorConfig[subsidiary].primary}}>Unidades gestionando propietario ({units.length})</span>
            </div>
          ) : (
            <div className={`UnitList__container__header`}>
              <span style={{color: colorConfig[subsidiary].primary}}>Todas sus unidades han sido asignadas a un usuario adicional</span>
            </div>
          )}
          <div className="UnitList__container__cardContainer">
            {units.length > 0 &&
              units.map(unit => {
                return (
                  <Card>
                    <CardContent className="UnitList__container__body" style={{ padding: "0px 30px" }}>
                      <div>
                        <span style={{fontSize: 'small'}}>{unit.project} </span>
                        <Typography variant="h6" className="UnitList__Card UnitList__Card__header" component='h2' style={{ fontSize: "1rem", textTransform: 'capitalize'}}>
                          {unit.numeroProducto}
                        </Typography>
                      </div>
                      <div className="UnitList__container__body__address">
                        <span style={{fontSize: '0.7rem'}}> {unit.direccion} </span>
                      </div>

                    </CardContent>
                  </Card>
                );
              })}
          </div>
      </div>
    )
  }

};

export default UnitList;
