import React, { useMemo, useState, Fragment } from 'react';
import { Container } from '@material-ui/core';

import PropTypes from 'prop-types';
import ExecutiveDetail from '../../components/ExecutiveDetail';

// Services
import { getExecutiveById, saveExecutiveById } from '../../services/executive';

import './EnterpriseExecutiveDetail.scss';

const useExecutiveDetail = idExecutive => {
  const [executiveDetail, setExecutiveDetail] = useState({});
  useMemo(() => {
    const rut = `${idExecutive}`;
    getExecutiveById(
      rut,
      ({ data: { data } }) => {
        if (data) {
          setExecutiveDetail(...data);
        } else {
          setExecutiveDetail(null);
          throw Error('sin data');
        }
      },
      err => {
        console.error(err);
      },
    );
  }, []);

  return { executiveDetail };
};
const goBack = history => {
  history.push('/empresas/Home/executives/list/updated');
};

const submitExecutive = (values, history) => {
  saveExecutiveById(
    values,
    res => {
      if (res.status === 200) {
        goBack(history);
      }
    },
    err => {
      console.error(err);
    },
  );
};

const EnterpriseExecutiveDetail = ({
  match: {
    params: { idExecutive },
  },
  history,
}) => {
  const { executiveDetail } = useExecutiveDetail(idExecutive);
  return (
    <Container maxWidth="lg">
      {executiveDetail && (
        <ExecutiveDetail
          goBack={goBack}
          executive={executiveDetail}
          onSubmit={values => submitExecutive(values, history)}
        />
      )}
      {!executiveDetail && <Fragment>Sin Data</Fragment>}
    </Container>
  );
};

EnterpriseExecutiveDetail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      idExecutive: PropTypes.string,
    }),
  }),
  history: PropTypes.object,
};

export default EnterpriseExecutiveDetail;
