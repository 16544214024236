import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import Button from '../ButtonTypes';
import NewNoteForm from './NewNoteForm';
import PlusIconButton from '../Icons/PlusIconButton';
import NoteItem from './NoteItem';

import './NoteList.scss';

const useAddNote = () => {
  const [addNote, setAddNote] = useState(false);
  const [editNote, setEditNote] = useState(false);
  const [selectedNote, setSelectedNote] = useState({ title: '', text: '', clientId: '' });
  const ToogleAddNote = useCallback(() => {
    setAddNote(!addNote);
  }, [addNote]);
  const handleEditCallback = useCallback(({ noteId, title, text }) => {
    setAddNote(true);
    setEditNote(true);
    setSelectedNote({ noteId, title, text });
  }, []);
  const EditSubmitCallback = () => {
    setAddNote(false);
    setEditNote(false);
    setSelectedNote({ noteId: '', title: '', text: '' });
  };
  return { addNote, ToogleAddNote, editNote, handleEditCallback, EditSubmitCallback, selectedNote };
};

const NoteList = ({ clientNotes = [], onSubmit, editCallback, deleteCallback }) => {
  const {
    addNote,
    ToogleAddNote,
    editNote,
    handleEditCallback,
    EditSubmitCallback,
    selectedNote,
  } = useAddNote();
  return (
    <div className="NoteList">
      {!addNote ? (
        <div className="NoteList__container">
          {clientNotes.length > 0 ? (
            <ul className="NoteList__container__list">
              {clientNotes.map(({ fecha_creacion: creationDate, titulo, nota, _id }) => (
                <NoteItem
                  noteId={_id}
                  creationDate={creationDate}
                  title={titulo}
                  text={nota}
                  handleEditCallback={handleEditCallback}
                  deleteCallback={deleteCallback}
                  key={_id}
                />
              ))}
            </ul>
          ) : (
            <span>No Existen Notas</span>
          )}
        </div>
      ) : (
        <div className="NoteList__form">
          <NewNoteForm
            onSubmit={editNote ? editCallback : onSubmit}
            onCancel={ToogleAddNote}
            editNote={editNote}
            selectedNote={selectedNote}
            EditSubmitCallback={EditSubmitCallback}
          >
            <div className="NoteList__form__buttons">
              <Button type="submit">GUARDAR</Button>
              <Button type="button" variant="outlined" onClick={ToogleAddNote}>
                cancelar
              </Button>
            </div>
          </NewNoteForm>
        </div>
      )}
      {!addNote && (
        <div className="NoteList__buttonContainer">
          <Button variant="text" onClick={ToogleAddNote}>
            <span className="NoteList__buttonContainer__button">
              Crear nuevA NOTA{' '}
              <PlusIconButton
                color="#0093ca"
                className="NoteList__buttonContainer__button__component"
              />
            </span>
          </Button>
        </div>
      )}
    </div>
  );
};

NoteList.propTypes = {
  clientNotes: PropTypes.array,
  onSubmit: PropTypes.func,
  editCallback: PropTypes.func,
  deleteCallback: PropTypes.func,
};

export default NoteList;
