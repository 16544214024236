import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';

const CheckBoxTypes = ({ color = 'primary', ...props }) => <Checkbox color={color} {...props} />;

CheckBoxTypes.propTypes = {
  color: PropTypes.string,
};

export default CheckBoxTypes;
