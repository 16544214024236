import React from 'react';
import PropTypes from 'prop-types';

const CloseButtonIcon = ({ color = '#ACACAC', className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    className={className}
  >
    <g fill={color} fillRule="nonzero">
      <path d="M11 22c6.075 0 11-4.925 11-11S17.075 0 11 0 0 4.925 0 11s4.925 11 11 11zm0-1.467a9.533 9.533 0 1 1 0-19.066 9.533 9.533 0 0 1 0 19.066z" />
      <path
        stroke={color}
        strokeWidth=".833"
        d="M11.64 11.121l3.897 3.897a.367.367 0 0 1-.52.518l-3.896-3.897-3.897 3.9a.371.371 0 0 1-.517-.003.366.366 0 0 1 0-.518l3.897-3.897-3.897-3.896a.366.366 0 1 1 .518-.518l3.896 3.897 3.897-3.897a.366.366 0 1 1 .518.518l-3.897 3.896zm3.637 4.155l-.632-.632.632.632zm-8.31 0l.634-.633-.633.633z"
      />
    </g>
  </svg>
);

CloseButtonIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

export default CloseButtonIcon;
