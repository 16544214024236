import React, { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

// Formik
import { withFormik, Form } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';

// Inputs
import TextFields from '../../components/TextFields';
import PasswordTextBox from '../../components/PasswordTextBox';

import ButtonTypes from '../../components/ButtonTypes';

import { format, validate } from 'rut.js';

const passwordSchema = Yup.string()
  .min(4, 'La contraseña debe tener almenos 4 caracteres de largo')
  .max(20, 'La contraseña debe tener maximo 20 caracteres de largo')
  .required('Debes Ingresar una contraseña');

export const ExecutiveSchema = Yup.object().shape({
  username: Yup.string()
    .email('Por favor ingrese un Email correcto')
    .required('Por favor Ingrese su Email'),
  password: passwordSchema,
  captchaToken: Yup.string(),
});

export const LoginSchema = Yup.object().shape({
  username: Yup.string()
    .max(10, 'el largo maximo del rut es 10')
    .required('Por favor Ingrese el rut')
    .test('rut', ' RUT invalido, inténtalo nuevamente.', value => validate(value)),
  password: passwordSchema,
  captchaToken: Yup.string(),
});

export const LoginInnerForm = ({
  errors,
  touched,
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  inputColor,
  emailPrimary = false,
  captchaRef,
  // isValid,
}) => {
  const [captchaToken, setCaptchaToken] = useState('');

  const onCheckHuman = () => {
    const token = captchaRef.current.getValue();
    if (!!token) {
      setCaptchaToken(token);
    }
  };

  useEffect(() => {
    if (!captchaToken) return;

    values.captchaToken = captchaToken;
  }, [captchaToken]);

  useEffect(() => {
    if (process.env.REACT_APP_ENV_NAME === 'local' || process.env.REACT_APP_ENV_NAME === 'dev') {
      setCaptchaToken(true);
    }
  }, []);

  return (
    <div className="Login__form">
      <Form onSubmit={handleSubmit}>
        <div className="Login__form__input">
          <TextFields
            name="username"
            value={values.username}
            color={inputColor}
            onChange={e =>
              setFieldValue(
                'username',
                emailPrimary ? e.target.value : format(e.target.value, { dots: false }),
              )
            }
            onBlur={handleBlur}
            error={errors.username && touched.username}
            valid={touched.username && !errors.username && values.username !== ''}
            helperText={errors.username}
            label={`Ingresa tu ${emailPrimary ? 'Email' : 'rut sin puntos y con guión'}`}
            data-testid="textField-username"
          />
        </div>
        <div className="Login__form__input">
          <PasswordTextBox
            name="password"
            value={values.password}
            color={inputColor}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.password && touched.password}
            helperText={errors.password}
            label="Clave de acceso"
            data-testid="textField-password"
          />
        </div>
        <div>
          {process.env.REACT_APP_ENV_NAME !== 'local' &&
            process.env.REACT_APP_ENV_NAME !== 'dev' && (
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_SITE_KEY}
                ref={captchaRef}
                onChange={() => onCheckHuman()}
              />
            )}
        </div>
        <div className="Login__form__button">
          <ButtonTypes type="submit" disabled={!captchaToken}>
            Ingresar
          </ButtonTypes>
        </div>
      </Form>
    </div>
  );
};

LoginInnerForm.propTypes = {
  errors: PropTypes.object,
  touched: PropTypes.object,
  values: PropTypes.object,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  handleSubmit: PropTypes.func,
  inputColor: PropTypes.string,
  isValid: PropTypes.bool,
  emailPrimary: PropTypes.bool,
  captchaRef: PropTypes.object,
};

export default withFormik({
  mapPropsToValues: () => {
    return {
      username: '',
      password: '',
      captchaToken: '',
    };
  },
  enableReinitialize: true,
  validateOnChange: false,
  isInitialValid: false,
  validationSchema: ({ emailPrimary }) => (emailPrimary ? ExecutiveSchema : LoginSchema),
  handleSubmit: (values, { props: { onSubmit } }) => {
    onSubmit(values);
  },
})(LoginInnerForm);
