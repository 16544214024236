import axios from 'axios';

import { deleteProjectImage, uploadProjectImage, listProjectImage, setAvatarImage, uploadFileMessages } from '../constants/endpoints';

export const uploadProjectImageBySize = (
  idProyecto,
  label,
  file,
  successCallback,
  errorCallback,
) => {
  const formData = new FormData();
  formData.append('file', file[0]);
  formData.append('idProyecto', idProyecto);
  formData.append('label', label);
  return axios
    .post(`${uploadProjectImage}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(successCallback)
    .catch(errorCallback);
};

export const listProjectImages = (idProyecto, successCallback, errorCallback) => {
  return axios
    .get(`${listProjectImage}`, { idProyecto })
    .then(successCallback)
    .catch(errorCallback);
};

export const deleteImage = (imageName, successCallback, errorCallback) => {
  return axios
    .delete(deleteProjectImage, { data: { nombre_img: imageName } })
    .then(successCallback)
    .catch(errorCallback);
};

export const setAvatar = ({token, file}, successCallback, errorCallback) => {
  let formData = new FormData();
  formData.append('avatar', file);
  formData.append('token', token);
  axios.post(setAvatarImage,
    //{token, avatar: file},
    formData,
    {
      headers: {
        'accept': 'application/json',
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      }
  })
  .then(successCallback)
  .catch(errorCallback);

}

export const uploadFileMessage = (file, idEjecutivo, idProyecto, successCallback, errorCallback) => {
  let formData = new FormData();
  formData.append('file', file);
  formData.append('idEjecutivo', idEjecutivo);
  formData.append('idProyecto', idProyecto);
  axios
    .post(`${uploadFileMessages}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(successCallback)
    .catch(errorCallback);
}
