import React, { useState, useMemo, useCallback, useContext, Fragment } from 'react';
import PropTypes from 'prop-types';

import SelectInput from '../SelectInput';

import { getExecutiveList } from '../../services/executive';
import { EnterpriseContext } from '../../providers/EnterpriseProvider';
import { GlobalContext } from '../../providers/GlobalStateProvider';

import './ExecutiveSelector.scss';

const useExecutiveList = (executiveRut, selectExecutive) => {
  const [executiveList, setExecutiveList] = useState([]);
  const handleChange = useCallback(
    event => {
      selectExecutive(event.target.value);
    },
    [selectExecutive],
  );
  useMemo(() => {
    if (executiveRut && executiveRut !== '') {
      getExecutiveList(
        ({ data: { data } }) => {
          setExecutiveList([
            ...data.map(({ nombres, apellidos, rut }) => ({
              value: rut,
              label: `${nombres} ${apellidos}`,
            })),
          ]);
          selectExecutive(executiveRut);
        },
        err => {
          console.error(err);
        },
      );
    }
  }, [executiveRut]);
  return { executiveList, handleChange };
};

const ExecutiveSelector = ({ className = '' }) => {
  const {
    state: { executiveRut },
    selectExecutive,
  } = useContext(EnterpriseContext);
  const {
    state: { rut },
  } = useContext(GlobalContext);

  const { executiveList, handleChange } = useExecutiveList(rut, selectExecutive);
  return (
    <div className={`ExecutiveSelector ${className}`}>
      {executiveList.length > 0 && executiveRut && (
        <Fragment>
          <h4 className="ExecutiveSelector__label">SELECCIONE UN EJECUTIVO</h4>
          <SelectInput options={executiveList} value={executiveRut} onChange={handleChange} />
        </Fragment>
      )}
    </div>
  );
};

ExecutiveSelector.propTypes = {
  className: PropTypes.string,
};

export default ExecutiveSelector;
