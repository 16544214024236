import React from 'react';
import PropTypes from 'prop-types';
import { currentColor } from '../../../constants/colors';

const PlusIconButton = ({ color = currentColor, className }) => (
  <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" className={className}>
    <g id="UI-Ejecutivo-UPDATE*" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="05-mantenedor-de-documentos-Clientes" transform="translate(-1207.000000, -608.000000)">
        <g id="Group" transform="translate(1207.000000, 608.000000)">
          <rect
            id="Rectangle-Copy-17"
            stroke={color}
            strokeWidth="1.5"
            x="0.75"
            y="0.75"
            width="16.5"
            height="16.5"
            rx="8.25"
          />
          <rect
            id="Rectangle-Copy-18"
            fill={color}
            x="8"
            y="4"
            width="1.48514851"
            height="9.65346535"
            rx="0.742574257"
          />
          <path
            d="M8.74257426,4 C9.1526867,4 9.48514851,4.33246182 9.48514851,4.74257426 L9.48514851,12.9108911 C9.48514851,13.3210035 9.1526867,13.6534653 8.74257426,13.6534653 C8.33246182,13.6534653 8,13.3210035 8,12.9108911 L8,4.74257426 C8,4.33246182 8.33246182,4 8.74257426,4 Z"
            id="Rectangle-Copy-19"
            fill={color}
            transform="translate(8.742574, 8.826733) rotate(-270.000000) translate(-8.742574, -8.826733) "
          />
        </g>
      </g>
    </g>
  </svg>
);

PlusIconButton.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

export default PlusIconButton;
