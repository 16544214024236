import PropTypes from 'prop-types';

const iconPropTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
  secondaryColor: PropTypes.string,
};

export default iconPropTypes;
