export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const FETCH_INIT = 'FETCH_INIT';
export const FETCH_END = 'FETCH_END';
export const FETCH_ERROR = 'FETCH_ERROR';
export const DROP_MESSAGE = 'DROP_MESSAGE';
export const SELECT_EXECUTIVE = 'SELECT_EXECUTIVE';
export const UPDATE_PROJECTLIST = 'UPDATE_PROJECTLIST';
export const SELECT_PROJECTLIST = 'SELECT_PROJECTLIST';
export const SELECT_CLIENT = 'SELECT_CLIENT';
export const SELECT_FILIAL = 'SELECT_FILIAL';
export const REHYDRATE_EXECUTIVE_STATE = 'REHYDRATE_EXECUTIVE_STATE';
export const TOOGLE_STATE_LOAD = 'TOOGLE_STATE_LOAD';
