export const ImageSizes = [
  {
    value: '1 Proyecto (870 x 236)',
    label: '1 Proyecto (870 x 236)',
  },
  {
    value: 'Varios Proyectos (420 x 236)',
    label: 'Varios Proyectos (420 x 236)',
  },
  {
    value: 'Version Mobile (345 x 236)',
    label: 'Version Mobile (345 x 236)',
  },
];

export const oneProject = '1 Proyecto (870 x 236)';
export const multipleProjects = 'Varios Proyectos (420 x 236)';
export const mobile = 'Version Mobile (345 x 236)';
