/* eslint-disable import/prefer-default-export */
import { project } from 'ramda';
import { useEffect, useState } from 'react';
import { getFileList } from '../services/projectsService';

export const useSignedDocumentsByUnit = (cliendId, propertyId, projectId = null) => {
  const [signedDocuments, setSignedDocuments] = useState([]);
  useEffect(() => {
    if (!!cliendId && !!propertyId) {
      getFileList(
        projectId,
        propertyId,
        cliendId,
        ({ data }) => {
          setSignedDocuments(data);
        },
        err => {
          console.error(err);
        },
      );
    }
  }, [cliendId, propertyId]);
  return { signedDocuments, setSignedDocuments };
};
