import React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import Button from '../../components/ButtonTypes';
import TextField from '../../components/TextFields';
import DatePicker from '../../components/DatePicker';
import CheckBox from '../../components/CheckboxTypes';
import RadioGroup from '../../components/RadioGroup';

import ThemeManager from '../../themes/mainTheme';

import './Toolkit.scss';
import ClipIcon from '../../assets/paper-clip.svg';

const Toolkit = () => {
  return (
    <div className="Toolkit">
      <div className="Toolkit__buttonShowCase">
        <ThemeProvider theme={ThemeManager()}>
          <ThemeProvider theme={ThemeManager('almagro')}>
            <Button>Almagro</Button>
          </ThemeProvider>
          <ThemeProvider theme={ThemeManager('socovesa')}>
            <Button>Socovesa</Button>
          </ThemeProvider>
          <ThemeProvider theme={ThemeManager('pilares')}>
            <Button>Pilares</Button>
          </ThemeProvider>
          <ThemeProvider theme={ThemeManager('terciary')}>
            <Button>Terciario</Button>
          </ThemeProvider>
        </ThemeProvider>
      </div>
      <div className="Toolkit__buttonShowCase">
        <ThemeProvider theme={ThemeManager()}>
          <ThemeProvider theme={ThemeManager('almagro')}>
            <Button color="secondary">Almagro</Button>
          </ThemeProvider>
          <ThemeProvider theme={ThemeManager('socovesa')}>
            <Button color="secondary">Socovesa</Button>
          </ThemeProvider>
          <ThemeProvider theme={ThemeManager('pilares')}>
            <Button color="secondary">Pilares</Button>
          </ThemeProvider>
          <ThemeProvider theme={ThemeManager('terciary')}>
            <Button color="secondary">Terciario</Button>
          </ThemeProvider>
        </ThemeProvider>
      </div>
      <div className="Toolkit__inputShowCase">
        <ThemeProvider theme={ThemeManager()}>
          <ThemeProvider theme={ThemeManager('almagro')}>
            <TextField label="Label" placeholder="Label" />
          </ThemeProvider>
          <ThemeProvider theme={ThemeManager('socovesa')}>
            <TextField label="Label" placeholder="Label" />
          </ThemeProvider>
          <ThemeProvider theme={ThemeManager('pilares')}>
            <TextField label="Label" placeholder="Label" />
          </ThemeProvider>
          <ThemeProvider theme={ThemeManager('terciary')}>
            <TextField label="Label" placeholder="Label" />
          </ThemeProvider>
        </ThemeProvider>
      </div>
      <div className="Toolkit__inputShowCase">
        <ThemeProvider theme={ThemeManager()}>
          <ThemeProvider theme={ThemeManager('almagro')}>
            <TextField search label="Label" placeholder="Label" />
          </ThemeProvider>
          <ThemeProvider theme={ThemeManager('socovesa')}>
            <TextField search label="Label" placeholder="Label" />
          </ThemeProvider>
          <ThemeProvider theme={ThemeManager('pilares')}>
            <TextField search label="Label" placeholder="Label" />
          </ThemeProvider>
          <ThemeProvider theme={ThemeManager('terciary')}>
            <TextField search label="Label" placeholder="Label" />
          </ThemeProvider>
        </ThemeProvider>
      </div>
      <div>
        <ThemeProvider theme={ThemeManager()}>
          <ThemeProvider theme={ThemeManager('almagro')}>
            <DatePicker />
          </ThemeProvider>
          <ThemeProvider theme={ThemeManager('socovesa')}>
            <DatePicker />
          </ThemeProvider>
          <ThemeProvider theme={ThemeManager('pilares')}>
            <DatePicker />
          </ThemeProvider>
          <ThemeProvider theme={ThemeManager('terciary')}>
            <DatePicker />
          </ThemeProvider>
        </ThemeProvider>
      </div>
      <div className="Toolkit__inputShowCase">
        <TextField label="Error" error placeholder="Error" />
      </div>
      <div>
        <ThemeProvider theme={ThemeManager()}>
          <ThemeProvider theme={ThemeManager('almagro')}>
            <CheckBox />
          </ThemeProvider>
          <ThemeProvider theme={ThemeManager('socovesa')}>
            <CheckBox />
          </ThemeProvider>
          <ThemeProvider theme={ThemeManager('pilares')}>
            <CheckBox />
          </ThemeProvider>
          <ThemeProvider theme={ThemeManager('terciary')}>
            <CheckBox />
          </ThemeProvider>
        </ThemeProvider>
      </div>
      <div>
        <ThemeProvider theme={ThemeManager()}>
          <ThemeProvider theme={ThemeManager('almagro')}>
            <RadioGroup
              legend="almagro"
              fieldName="almagro"
              options={['almagro', 'socovesa', 'pilares', 'terciary']}
            />
          </ThemeProvider>
          <ThemeProvider theme={ThemeManager('socovesa')}>
            <RadioGroup
              legend="socovesa"
              fieldName="socovesa"
              options={['almagro', 'socovesa', 'pilares', 'terciary']}
            />
          </ThemeProvider>
          <ThemeProvider theme={ThemeManager('pilares')}>
            <RadioGroup
              legend="pilares"
              fieldName="pilares"
              options={['almagro', 'socovesa', 'pilares', 'terciary']}
            />
          </ThemeProvider>
          <ThemeProvider theme={ThemeManager('terciary')}>
            <RadioGroup
              legend="terciary"
              fieldName="terciary"
              options={['almagro', 'socovesa', 'pilares', 'terciary']}
            />
          </ThemeProvider>
        </ThemeProvider>
      </div>
      <div>
        <ThemeProvider theme={ThemeManager()}>
          <ThemeProvider theme={ThemeManager('almagro')}>
            <RadioGroup
              legend="almagro"
              row
              fieldName="almagro"
              options={['almagro', 'socovesa', 'pilares', 'terciary']}
            />
          </ThemeProvider>
        </ThemeProvider>
      </div>
      <div>
        <img src={ClipIcon} width="25px" height="25px" />
      </div>
    </div>
  );
};

export default Toolkit;
