import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import InnerFormForgotPassword from './InnerFormPassword';
import BackArrow from '../../components/Icons/BackArrow';
import SendIcon from '../../components/Icons/SendIcon';

// Constants
import { getLogos } from '../../utils/getLogos';
import { almagro } from '../../constants/filialesNames';
import { primary, secondary } from '../../constants/colorTypes';
import { white, black } from '../../constants/colors';
import { RecoverPassRequest } from '../../services/authentication';

import './ForgotPassword.scss';

const SuccessSendRecoverEmail = ({ rut }, subsidiary, setSendedPass, setUserRut) => {
  setUserRut(rut);
  RecoverPassRequest(
    { rut, subsidiary },
    () => {
      setSendedPass(true);
    },
    error => {
      console.error(error);
    },
  );
};

const ForgotPassword = ({
  match: {
    params: { subsidiary },
  },
  history,
}) => {
  const [sendedPass, setSendedPass] = useState(false);
  const [userRut, setUserRut] = useState('');
  return (
    <div className={`ForgotPassword ForgotPassword--${subsidiary}`}>
      <div className="ForgotPassword__header">
        <BackArrow
          className="ForgotPassword__header__arrow"
          color={subsidiary === almagro ? white : black}
          onClick={() => history.goBack()}
        />
        <img alt="logo" className="ForgotPassword__header__logo" {...getLogos(subsidiary)} />
      </div>
      {!sendedPass ? (
        <Fragment>
          <h2 className="ForgotPassword__title">Olvidé mi clave de acceso</h2>
          <p className="ForgotPassword__paragraph">
            Ingresa tu RUT, si estás registrado en nuestras bases datos se enviará un email con
            instrucciones de como restablecer tu clave de acceso.
          </p>
          <InnerFormForgotPassword
            inputColor={subsidiary === almagro ? secondary : primary}
            onSubmit={values => {
              SuccessSendRecoverEmail(values, subsidiary, setSendedPass, setUserRut);
            }}
          />
        </Fragment>
      ) : (
        <div className={`ForgotPassword__passwordSend ForgotPassword__passwordSend--${subsidiary}`}>
          <div className="ForgotPassword__passwordSend__header">
            <SendIcon
              className="ForgotPassword__passwordSend__header__icon"
              color={subsidiary === almagro ? white : black}
              secondaryColor={subsidiary === almagro ? black : white}
            />
            <span className="ForgotPassword__passwordSend__header__label">Email enviado</span>
          </div>
          <span className="ForgotPassword__passwordSend__span">
            Te enviamos un email con las instrucciones
          </span>
          <span className="ForgotPassword__passwordSend__span">
            para restablecer tu clave de acceso a:
          </span>
          <b>{userRut}</b>
        </div>
      )}
    </div>
  );
};

ForgotPassword.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      subsidiary: PropTypes.string,
    }),
  }),
  history: PropTypes.object,
};

export default ForgotPassword;
