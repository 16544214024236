/* eslint-disable no-confusing-arrow */
import React from 'react';
import PropTypes from 'prop-types';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import { compareDesc, compareAsc, parse } from 'date-fns';
import { isBetween, firstLetterUppercase } from '../../utils/utils';

import DownArrowIconComponent from '../Icons/DownArrow';
import './Milestones.scss';

const statusMilestone = (subsidiary, fechaInicio, fechaFin, type = 'elemento') => {
  const f1 = parse(fechaInicio, 'd/M/y', new Date());
  const f2 = parse(fechaFin, 'd/M/y', new Date());
  const hoy = new Date();

  let etapa = '';
  let css = '';
  if (isBetween(hoy, f1, f2, '[]')) {
    etapa = 'Etapa activa';
    css = 'activa';
  } else {
    if (compareAsc(f2, new Date()) === 1) {
      // si aun no empieza el hito
      etapa = 'Próxima etapa';
      css = 'proxima';
    }

    if (compareDesc(f1, new Date()) === 1) {
      // si ya termino el hito
      etapa = 'Etapa finalizada';
      css = 'finalizada';
    }
  }
  const html = (
    <Typography
      className={`Milestones__sub_title Milestones__sub_title--${subsidiary}`}
      variant="h5"
    >
      {etapa}
    </Typography>
  );
  return type === 'elemento' ? html : css;
};

const Milestones = ({
  idHito,
  subsidiary,
  nombreHitoNatural,
  descripcionHito,
  fechaInicio,
  fechaFin,
}) => {
  return (
    <div>
      <ExpansionPanel
        key={idHito}
        className={`Milestones__panel__children ${statusMilestone(
          subsidiary,
          fechaInicio,
          fechaFin,
          'css',
        )}`}
      >
        <ExpansionPanelSummary
          expandIcon={<DownArrowIconComponent color="#676977" className="test" />}
          aria-label="Expand"
          aria-controls="additional-actions1-content"
          id="additional-actions1-header"
        >
          <div className={`Milestones__icon Milestones__icon--${subsidiary}`} />
          <div className="Milestones__header">
            <Typography
              variant="h2"
              className={`Milestones__title Milestones__title--${subsidiary}`}
            >
              {firstLetterUppercase(nombreHitoNatural)}
            </Typography>
            {statusMilestone(subsidiary, fechaInicio, fechaFin, 'elemento')}
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className="Milestones__container">
          <div className="Milestones">
            <p className={`Milestones__detail Milestones__detail--${subsidiary}`}>
              {firstLetterUppercase(descripcionHito)}
            </p>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <hr />
    </div>
  );
};

Milestones.propTypes = {
  idHito: PropTypes.number,
  last: PropTypes.bool,
  active: PropTypes.bool,
  subsidiary: PropTypes.string,
  nombreHitoNatural: PropTypes.string,
  fechaFin: PropTypes.string,
  fechaInicio: PropTypes.string,
  descripcionHito: PropTypes.string,
};

export default Milestones;
