import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import SnackBar from '../SnackBar';
import { FetchContext } from '../../providers/RequestProvider';

const HandleNotifications = (error, clearMessage) => {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setOpen(error !== undefined);
  }, [error]);
  const closeNotification = () => {
    setOpen(false);
    clearMessage();
  };
  return { open, setOpen, closeNotification };
};

const SnackBarHandler = () => {
  const {
    state: { error },
    clearMessage,
  } = useContext(FetchContext);
  const { open, closeNotification } = HandleNotifications(error, clearMessage);
  return <SnackBar open={open} message={error} closeNotification={closeNotification} />;
};

SnackBarHandler.propTypes = {
  errorMessage: PropTypes.string,
};

export default SnackBarHandler;
