import { FETCH_INIT, FETCH_END, FETCH_ERROR, DROP_MESSAGE } from '../actions/actionTypes';

export const inicialState = {
  requestList: [],
  error: undefined,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case FETCH_INIT:
      return {
        ...state,
        requestList: [...state.requestList, action.request],
      };
    case FETCH_END:
      return {
        ...state,
        requestList: [...state.requestList.filter(({ url }) => `${url}` !== action.requestUrl)],
      };
    case FETCH_ERROR:
      return {
        ...state,
        requestList: [...state.requestList.filter(({ url }) => `${url}` !== action.requestUrl)],
        error: action.message,
      };
    case DROP_MESSAGE:
      return {
        ...state,
        error: undefined,
      };
    default:
      return {
        ...state,
      };
  }
};
