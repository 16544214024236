import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Input,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@material-ui/core';
import MoreHorizonIcon from '@material-ui/icons/MoreHoriz';
import React, { useEffect, useState, useContext } from 'react';

import { format, validate } from 'rut.js';
import Button from '../../../ButtonTypes';
import { agregarAdicional, editarAdicional } from '../../../../services/postventaService';
import { colorConfig } from '../../../../constants/colors';

import { GlobalContext } from '../../../../providers/GlobalStateProvider';
import ConfirmationModal from './ConfirmationModal';

const AdditionalModal = ({
  open,
  setOpen,
  additionalUser,
  additionalUsers,
  onChangeAdditional,
  getData,
  ownerID,
  ownerProperties,
  edit,
  relationships,
  subsidiary,
}) => {
  const handleClose = () => {
    setProperties([]);
    setSelecteableProperties(ownerProperties);
    setOpen(false);
  };
  const [loading, setLoading] = useState(false);
  const [rutError, setRutError] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [buttonDisabledTitle, setButtonDisabledTitle] = useState('');
  const [textError, setTextError] = useState('');
  const [properties, setProperties] = useState([]);
  const [addedProperties, setAddedProperties] = useState([]);
  const [selecteableProperties, setSelecteableProperties] = useState([]);
  const [selecteableRelationships, setSelecteableRelationships] = useState([]);
  const [formattedRut, setFormattedRut] = useState(format(additionalUser.rut, { dots: false }));
  const [usedRuts, setUsedRuts] = useState([]);
  const [emailError, setEmailError] = useState(false);
  const [textEmailError, setTextEmailError] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const { state: { fullName = '' } = {} } = useContext(GlobalContext);

  useEffect(() => {
    validateEmail();
  }, [additionalUser.email]);
  const isValidEmail = email => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };
  const validateEmail = () => {
    if (!isValidEmail(additionalUser.email) && additionalUser.email !== '') {
      setEmailError(true);
      setTextEmailError('El correo ingresado es inválido');
    } else {
      setEmailError(false);
      setTextEmailError('');
    }
  };

  useEffect(() => {
    if (!edit) {
      setSelecteableProperties(ownerProperties);
      setProperties([]);
      return;
    }

    const previousProperties = ownerProperties.filter(ownerProperty =>
      additionalUser.properties.includes(ownerProperty.unidadId),
    );
    const selecteableOwnerProperties = ownerProperties.filter(
      ownerProperty => !additionalUser.properties.includes(ownerProperty.unidadId),
    );
    additionalUsers = additionalUsers.filter(user => user.id_adi !== additionalUser.id);
    setUsedRuts(additionalUsers.map(addUs => addUs.solicitanterut_adi.replace('-', '')));
    setProperties(previousProperties);
    setSelecteableProperties(selecteableOwnerProperties);
  }, [ownerProperties, edit, open]);

  useEffect(() => {
    setRutError(false);
    setTextError('');
    const finalRut = format(additionalUser.rut, { dots: false });
    setFormattedRut(finalRut);
    if (!validate(finalRut) && finalRut !== '-') {
      setRutError(true);
      setTextError('El Rut ingresado es inválido');
      return;
    }
    if (!edit) checkValidRut(finalRut);
  }, [additionalUser.rut, usedRuts, edit]);

  useEffect(() => {
    setUsedRuts(additionalUsers.map(addUs => addUs.solicitanterut_adi.replace('-', '')));
  }, [additionalUsers]);

  useEffect(() => {
    setAddedProperties(
      properties.map(property => ({
        unidadid_adi: property.unidadId,
        loteunidad_adi: property.producto,
        numeroProducto: property.numeroProducto,
        project: property.project.toLowerCase().replace(/\b[a-z]/g, letter => letter.toUpperCase()),
      })),
    );
  }, [properties]);

  useEffect(() => {
    setButtonDisabled(false);
    setButtonDisabledTitle('');

    if (
      !additionalUser.name ||
      !additionalUser.rut ||
      !additionalUser.email ||
      !additionalUser.relationship ||
      loading ||
      rutError ||
      emailError
    ) {
      setButtonDisabled(true);
      if (!loading && !rutError && !emailError) {
        setButtonDisabledTitle('Faltan campos por llenar');
      }
    }
  }, [additionalUser, loading, rutError, emailError, properties]);

  const actuallySave = () => {
    setLoading(true);
    if (edit) {
      editarAdicional(
        additionalUser.id,
        additionalUser.name,
        formattedRut,
        additionalUser.email,
        additionalUser.phone,
        additionalUser.relationship,
        additionalUser.status,
        addedProperties,
        ownerID,
        fullName,
        subsidiary,
        res => {
          setLoading(false);
          onChangeAdditional({
            name: '',
            rut: '',
            email: '',
            relationship: '',
            status: true,
            properties: [],
          });
          getData();
          handleClose();
        },
        err => {
          console.error(err);
          setLoading(false);
        },
      );
    } else {
      agregarAdicional(
        additionalUser.name,
        formattedRut,
        additionalUser.email,
        additionalUser.phone,
        additionalUser.relationship,
        additionalUser.status,
        addedProperties,
        ownerID,
        subsidiary,
        fullName,
        res => {
          setLoading(false);
          onChangeAdditional({
            name: '',
            rut: '',
            email: '',
            relationship: '',
            status: true,
            properties: [],
          });
          getData();
          handleClose();
        },
        err => {
          console.error(err);
          setLoading(false);
        },
      );
    }
    setConfirmOpen(false);
  };

  const handleSave = () => {
    if (addedProperties.length === 0) {
      setConfirmOpen(true);
      return;
    }
    actuallySave();
  };

  const checkValidRut = rut => {
    if (usedRuts.includes(rut.replace('-', ''))) {
      setRutError(true);
      setTextError('el Rut ya se encuentra en uso');
      return;
    }
    setRutError(false);
    setTextError('');
  };

  const handleSelectProperty = newProperties => {
    setProperties(newProperties);
    let newSelecteableProperties = selecteableProperties;
    newSelecteableProperties = newSelecteableProperties.filter(pr => !newProperties.includes(pr));
    setSelecteableProperties(newSelecteableProperties);
    setDropdownOpen(false);

    if (selecteableProperties.length === 1) {
      setDropdownOpen(false);
      const chipInput = document.getElementById('select-multiple-chip');
      if (chipInput) {
        chipInput.setAttribute('aria-expanded', 'false');
      }
      document.getElementById('select-multiple-chip').blur();
      const saveButton = document.getElementById('save-button');
      if (saveButton) {
        setTimeout(() => {
          saveButton.focus();
        }, 100);
      }
    } else {
      document.activeElement.blur();
    }
  };

  const handleRemoveProperty = value => {
    let newProperties = properties;
    newProperties = newProperties.filter(pr => pr !== value);
    setProperties(newProperties);
    const newSelecteableProperties = selecteableProperties;
    newSelecteableProperties.push(value);
    setSelecteableProperties(newSelecteableProperties);
  };

  useEffect(() => {
    if (!relationships) return;

    setSelecteableRelationships(relationships);
  }, [relationships]);

  return (
    <div>
      <Dialog
        maxWidth="xs"
        open={open}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {`${edit ? 'Edite' : 'Agregue'} los datos del usuario adicional`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description ">
            <span color={colorConfig[subsidiary].headerText}>
              Verifique los datos a ingresar sean los correctos
            </span>
            <TextField
              id="standard-basic"
              label="Rut"
              value={formattedRut}
              style={{ width: '100%', marginTop: '1rem' }}
              onChange={e => {
                onChangeAdditional({ ...additionalUser, rut: e.target.value });
              }}
              error={rutError}
              helperText={textError}
              disabled={edit}
            />
            <TextField
              id="standard-basic"
              label="Nombre"
              value={additionalUser.name}
              style={{ width: '100%', marginTop: '1rem' }}
              onChange={e => {
                onChangeAdditional({ ...additionalUser, name: e.target.value });
              }}
            />
            <TextField
              id="standard-basic"
              label="Correo"
              value={additionalUser.email}
              style={{ width: '100%', marginTop: '1rem' }}
              onChange={e => {
                onChangeAdditional({ ...additionalUser, email: e.target.value });
              }}
              error={emailError}
              helperText={textEmailError}
            />
            <TextField
              id="standard-basic"
              label="Teléfono"
              value={additionalUser.phone}
              style={{ width: '100%', marginTop: '1rem' }}
              onChange={e => {
                onChangeAdditional({ ...additionalUser, phone: e.target.value });
              }}
            />
            <FormControl style={{ width: '100%', marginTop: '1rem' }}>
              <InputLabel
                for="relationship"
                id="demo-simple-select-label"
                shrink={!!additionalUser.relationship}
              >
                Relación
              </InputLabel>
              <Select
                name="relationship"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={additionalUser.relationship}
                onChange={e => {
                  onChangeAdditional({ ...additionalUser, relationship: e.target.value });
                }}
              >
                {selecteableRelationships.map(selecteableRelationship => (
                  <MenuItem value={selecteableRelationship.id}>
                    {selecteableRelationship.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl style={{ width: '100%', marginTop: '1rem' }}>
              <span color={colorConfig[subsidiary].headerText}>
                Estado del usuario adicional : {additionalUser.status ? 'Activo' : 'Desactivado'}
              </span>
              <Switch
                className="status-switch"
                checked={additionalUser.status}
                color="green"
                onChange={e => {
                  onChangeAdditional({ ...additionalUser, status: e.target.checked });
                }}
                name="status"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            </FormControl>
            <FormControl
              style={{
                width: '100%',
                marginTop: '1rem',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <InputLabel id="mutiple-chip-property">Seleccionar propiedades</InputLabel>
              <Select
                labelId="mutiple-chip-property"
                id="mutiple-chip"
                multiple
                value={properties}
                onChange={e => handleSelectProperty(e.target.value)}
                input={<Input id="select-multiple-chip" />}
                className="selector"
                style={{ width: '100%', marginTop: '1rem', maxWidth: '90%' }}
                open={dropdownOpen}
                onOpen={() => setDropdownOpen(true)}
                onClose={() => setDropdownOpen(false)}
                renderValue={selected =>
                  selected.map(value => (
                    <Chip
                      key={value.unidadId}
                      onMouseDown={e => {
                        e.stopPropagation();
                      }}
                      onDelete={e => handleRemoveProperty(value)}
                      label={`${value.numeroProducto} - ${value.project}`}
                      className="mx-2"
                    />
                  ))
                }
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
              >
                {selecteableProperties &&
                  selecteableProperties.map(prop => (
                    <MenuItem
                      key={prop.id_Propiedad}
                      value={prop}
                      style={{ width: '100%', maxWidth: '100%' }}
                    >
                      <span style={{ fontSize: 'small' }}>
                        {`${prop.numeroProducto} - ${prop.project}`}
                      </span>
                    </MenuItem>
                  ))}
              </Select>
              <ListItemIcon onClick={() => setDropdownOpen(true)} style={{ cursor: 'pointer' }}>
                <MoreHorizonIcon />
              </ListItemIcon>
            </FormControl>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <span title={buttonDisabledTitle}>
            <Button
              id="save-button"
              onClick={handleSave}
              color="primary"
              loading={loading}
              disabled={buttonDisabled}
            >
              {edit ? 'Guardar' : 'Agregar'}
            </Button>
          </span>
        </DialogActions>
      </Dialog>
      <ConfirmationModal
        open={confirmOpen}
        title="Confirmar"
        mainText="Se guardará un usuario sin propiedades. ¿Continuar?"
        handleNo={() => setConfirmOpen(false)}
        handleYes={actuallySave}
      />
    </div>
  );
};

export default AdditionalModal;
