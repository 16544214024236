import { useState, useEffect } from 'react';
import { getOnlyProjectsByUser, getOnlyPropertyByProject } from '../services/projectsService';

const useCurrentProject = (username, projectId, propertyId) => {
  const [selectedProject, setSelectedProject] = useState({});
  const [selectedProperty, setSelectedProperty] = useState({});
  useEffect(() => {
    if (username !== '' && projectId !== '' && propertyId !== '') {
      getOnlyProjectsByUser(
        username,
        ({ data: { proyectos = [] } }) => {
          const filterProject = proyectos.filter(
            ({ id_Proyecto: idProject }) => projectId === idProject,
          );
          setSelectedProject(filterProject[0]);
          getOnlyPropertyByProject(
            filterProject[0].id_Proyecto,
            username,
            ({ data: { data } }) => {
              const filterProperty = data.filter(
                ({ id_Propiedad: idProperty }) => propertyId === idProperty,
              );
              setSelectedProperty(filterProperty[0]);
            },
            error => {
              console.error(error);
            },
          );
        },
        err => {
          console.error(err);
        },
      );
    }
  }, [username, propertyId]);
  return { selectedProject, selectedProperty };
};

export default useCurrentProject;
