import React, { useState } from 'react';
import './AdditionalList.scss'
import { Card, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '@material-ui/core';
import DownArrowIconComponent from '../../../Icons/DownArrow';
import { colorConfig } from '../../../../constants/colors';

import { AdditionalItemDesktop, AdditionalItemMobile } from '../AdditionalItem';

const AdditionalList = ({
  additionalList,
  listName,
  onItemEdit,
  relationshipArray= [],
  units = [],
  defaultExpanded = false,
  subsidiary,
}) => {
  const [expanded, setExpanded] = useState(defaultExpanded);

  return (
    <div className="AdditionalList__container" style={{ backgroundColor: colorConfig[subsidiary].secondary }}>
      <ExpansionPanel
        key={listName}
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
        style={{ boxShadow: 'none' }}
      >
        <ExpansionPanelSummary
          expandIcon={<DownArrowIconComponent color={colorConfig[subsidiary].icons} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ border: '#b7aec8 1px solid', borderRadius: '5px' }}
        >
          <div className="AdditionalDetail__expansionPanelSummary">
            <div className='ProjectDetailPV__expansionPanelSummary__AddName'>
              <span>{listName}</span>
            </div>
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div className="AdditionalDetail">
            {additionalList.length === 0 && (
              <div className="AdditionalDetail__noAdditional">
                <span>No hay {listName}</span>
              </div>
            )}
            {additionalList.length > 0 &&
              additionalList.map(addi => {
                return (
                  <Card className={`additional-card-${subsidiary}`} style={{ padding: '0.25rem' }}>
                    <AdditionalItemDesktop
                      additional={addi}
                      relationshipArray={relationshipArray}
                      units={units}
                      onEditAdditional={onItemEdit}
                    />
                    <AdditionalItemMobile
                      additional={addi}
                      relationshipArray={relationshipArray}
                      units={units}
                      onEditAdditional={onItemEdit}
                    />
                  </Card>
                )
              })}
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}

export default AdditionalList;
