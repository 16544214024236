import axios from 'axios';
import {
  loginEndpoint,
  recoverPassEndpoint,
  restablecerPassEndpoint,
  executiveLoginEndpoint,
} from '../constants/endpoints';
import { empresas } from '../constants/filialesNames';

export const ClientLoginRequest = ({ username, password, subsidiary, captchaToken }, successCallback, errorCallback) => {
  axios
    .post(loginEndpoint, {
      rut: username.substring(0, username.length - 1),
      password,
      subsidiary,
      captchaToken
    })
    .then(successCallback)
    .catch(errorCallback);
  }

export const ExecutiveLoginRequest = ({ username, password, captchaToken }, successCallback, errorCallback) =>
  axios
    .post(executiveLoginEndpoint, {
      email: username,
      password,
      captchaToken
    })
    .then(successCallback)
    .catch(errorCallback);

export const RecoverPassRequest = (params, successCallback, errorCallback) => {
  const tipo = params.subsidiary === empresas ? 'ejecutivo' : 'cliente';
  return axios
    .post(recoverPassEndpoint, { ...params, tipo })
    .then(successCallback)
    .catch(errorCallback);
}

export const RestablecerPassRequest = (token, newPassword, successCallback, errorCallback) => {
  return axios
    .put(`${restablecerPassEndpoint}/${token}`, { newPassword })
    .then(successCallback)
    .catch(errorCallback);
}