import React from 'react';
import PropTypes from 'prop-types';

// Formik
import { withFormik, Form } from 'formik';
import * as Yup from 'yup';

// Inputs
import TextFields from '../TextFields';
import Button from '../ButtonTypes';

import './AnswerMessageForm.scss';

export const messageSchema = Yup.object().shape({
  message: Yup.string().required('Por favor Ingrese un mensaje'),
});

const AnswerMessageForm = ({
  errors,
  touched,
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  toogleResponse,
}) => {
  return (
    <div className="MessageForm">
      <Form className="MessageForm__form" onSubmit={handleSubmit}>
        <div className="MessageForm__form__input">
          <TextFields
            name="message"
            value={values.message}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.message && touched.message}
            valid={touched.message && !errors.message && values.message !== ''}
            helperText={errors.message}
            label=" MENSAJE:"
            multiline
            rows={3}
          />
        </div>
        <div className="MessageForm__form__buttons">
          <Button className="MessageForm__form__buttons__button" type="submit">
            Enviar mensaje
          </Button>
          <Button
            className="MessageForm__form__buttons__button"
            variant="outlined"
            onClick={toogleResponse}
          >
            cancelar
          </Button>
        </div>
      </Form>
    </div>
  );
};

AnswerMessageForm.propTypes = {
  values: PropTypes.shape({
    title: PropTypes.string,
    message: PropTypes.string,
  }),
  errors: PropTypes.shape({
    title: PropTypes.string,
    message: PropTypes.string,
  }),
  touched: PropTypes.shape({
    title: PropTypes.bool,
    message: PropTypes.bool,
  }),
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  toogleResponse: PropTypes.func,
};

export default withFormik({
  mapPropsToValues: () => {
    return {
      message: '',
    };
  },
  enableReinitialize: true,
  validateOnBlur: false,
  isInitialValid: false,
  validationSchema: messageSchema,
  handleSubmit: ({ message }, { props: { onSubmit, messageId, toogleResponse } }) => {
    onSubmit(messageId, message, toogleResponse);
  },
})(AnswerMessageForm);
