import axios from 'axios';

import {
  postNote,
  listClientNotes,
  editNote,
  deletetNote,
  listNotifications,
  markAsView,
  getClients,
  activateClient,
  reEnviarCorreoActivacion,
  listMilestonDate,
  createNewMilestonDate,
  clientByRut,
  deleteMilestonDate,
  editMilestonDate,
} from '../constants/endpoints';

export const postClientNote = (clientId, title, text, successCallback, errorCallback) =>
  axios
    .post(`${postNote}`, { id_cliente: clientId, titulo: title, nota: text })
    .then(successCallback)
    .catch(errorCallback);

export const editClientNote = (noteId, title, text, successCallback, errorCallback) =>
  axios
    .put(`${editNote}${noteId}`, { titulo: title, nota: text })
    .then(successCallback)
    .catch(errorCallback);

export const listClientNote = (clientId, successCallback, errorCallback) =>
  axios
    .post(`${listClientNotes}`, { id_cliente: clientId })
    .then(successCallback)
    .catch(errorCallback);

export const deleteClientNote = (noteId, successCallback, errorCallback) =>
  axios
    .delete(`${deletetNote}${noteId}`)
    .then(successCallback)
    .catch(errorCallback);

export const getNotificationList = (userId, successCallback, errorCallback) => {
  return axios
    .post(listNotifications, { id_usuario: userId })
    .then(successCallback)
    .catch(errorCallback);
  }

export const markNotificationAsRead = (successCallback, errorCallback) =>
  axios
    .post(markAsView)
    .then(successCallback)
    .catch(errorCallback);

export const getClientList = (
  executiveId,
  activeSubsidiary,
  filterByText,
  page = 1,
  limit = 100,
  filter = '*',
  successCallback,
  errorCallback,
) => {
  axios
    .post(
      `${getClients}?${activeSubsidiary ? `filial=${activeSubsidiary}&` : ''}${
        filterByText ? `q=${filterByText}&` : ''
      }page=${page}&limit=${limit}&filter=${filter}`,
      {
        id_ejecutivo: executiveId,
      },
    )
    .then(successCallback)
    .catch(errorCallback);
};

export const sendActivationEmail = (clientId, restore = false, successCallback, errorCallback) => {
  axios
    .post(`${reEnviarCorreoActivacion}`, { rut: clientId, restore })
    .then(successCallback)
    .catch(errorCallback);
};

export const forceClientActivation = (clientId, successCallback, errorCallback) => {
  axios
    .get(`${activateClient}/${clientId}`, { rut: clientId })
    .then(successCallback)
    .catch(errorCallback);
};

export const getMilestonsCalendar = (
  projectId,
  propertyId,
  clientId,
  successCallback,
  errorCallback,
) => {
  axios
    .post(`${listMilestonDate}`, {
      id_proyecto: projectId,
      id_propiedad: propertyId,
      id_cliente: clientId,
    })
    .then(successCallback)
    .catch(errorCallback);
};

export const getCalendarProject = (
  projectId,
  successCallback,
  errorCallback,
) => {
  axios
    .get(`${listMilestonDate}/${projectId}`)
    .then(successCallback)
    .catch(errorCallback);
}

export const createNewMilestonsCalendar = (data, successCallback, errorCallback) =>
  axios
    .post(`${createNewMilestonDate}`, data)
    .then(successCallback)
    .catch(errorCallback);

export const editMilestonsCalendar = (idMongoMilestone, data, successCallback, errorCallback) =>
  axios
    .put(`${editMilestonDate}/${idMongoMilestone}`, data)
    .then(successCallback)
    .catch(errorCallback);

export const deleteMilestonsCalendar = (idMongoMilestone, successCallback, errorCallback) =>
  axios
    .delete(`${deleteMilestonDate}/${idMongoMilestone}`)
    .then(successCallback)
    .catch(errorCallback);

export const getClientByRut = (rut, successCallback, errorCallback) =>
{
  axios
    .get(`${clientByRut}/${rut}`)
    .then(successCallback)
    .catch(errorCallback);
}
