import React, { useState, useRef, useLayoutEffect, useEffect } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { DateRange } from 'react-date-range';
import { es } from 'date-fns/locale';
import { formatDate } from '../../utils/utils';
import { colorConfig } from '../../constants/colors';

// Components
import CalendarItem from './CalendarItem';
import Button from '../ButtonTypes';
import RightArrowButton from '../Icons/RightArrowButton';
import RightArrowIcon from '../Icons/RightArrowIcon';
import LeftArrowIcon from '../Icons/LeftArrowIcon';

import './CalendarCard.scss';

const useGetSizeComponent = () => {
  const targetRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useLayoutEffect(() => {
    if (targetRef.current) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight,
      });
    }
  }, [targetRef]);
  return { dimensions, targetRef };
};

const useFormatDates = calendarList => {
  const [fomatedCalendarList, setFomatedCalendarList] = useState([]);
  useEffect(() => {
    const formatedList = calendarList.map(
      ({ _id: id, fecha_desde: date, fecha_hasta: expireDate }) => ({
        startDate: new Date(date),
        endDate: new Date(expireDate),
        key: id,
      }),
    );
    setFomatedCalendarList([...formatedList]);
  }, [calendarList]);
  return { fomatedCalendarList };
};

const CalendarCard = ({
  calendarList,
  subsidiary,
  updateCalendarList,
  onShownDateChange,
  onChange = () => {},
  onPreviewChange = () => {},
  shownDate,
}) => {
  const { fomatedCalendarList } = useFormatDates(calendarList);
  const [active, setActive] = useState(false);

  const {
    dimensions: { height, width },
    targetRef,
  } = useGetSizeComponent();

  const toogleActive = () => {
    setActive(!active);
  };

  const getMonthAndYear = simbol => {
    let number = 1;
    number = simbol === '+' ? number : (number = -1);
    const newDate = new Date(shownDate.setMonth(shownDate.getMonth() + number));
    onPreviewChange(shownDate);
    onShownDateChange(newDate);
  };
  return !active ? (
    <div className={`CalendarCard CalendarCard--${subsidiary}`} ref={targetRef}>
      <Paper>
        <DateRange
          className={`CalendarCard__calendar CalendarCard__calendar--${subsidiary}`}
          showDateDisplay={false}
          editableDateInputs
          rangeColors={[
            colorConfig[subsidiary].primary,
            colorConfig[subsidiary].primary,
            colorConfig[subsidiary].primary,
          ]}
          onChange={onChange}
          onPreviewChange={() => {}}
          onShownDateChange={onShownDateChange}
          color={colorConfig[subsidiary].primary}
          locale={es}
          ranges={fomatedCalendarList.length > 0 ? fomatedCalendarList : undefined}
        />
        <div className="CalendarCard__calendarFooter">
          <span className="CalendarCard__alertIcon">
            <div className={`CalendarCard__alertIcon--${subsidiary}`} />
            {`Tienes ${calendarList.length} hitos en ${formatDate(shownDate, 'LLLL')}`}
          </span>
          <Button
            variant="text"
            className="CalendarCard__detailButton"
            onClick={() => {
              toogleActive();
            }}
          >
            ver detalle
            <RightArrowButton className="CalendarCard__detailButton__icon" />
          </Button>
        </div>
      </Paper>
    </div>
  ) : (
    <div
      className={`CalendarCard CalendarCard--${subsidiary}--active`}
      // style={
      //   calendarList && calendarList.length > 0
      //     ? { overflowY: 'scroll', width, height }
      //     : { width, height }
      // }
    >
      <div className="CalendarCard__header CalendarCard__header--active">
        <span>
          <Button
            variant="text"
            className="CalendarCard__body__backArrow"
            onClick={() => {
              getMonthAndYear('-');
            }}
          >
            <LeftArrowIcon />
            Mes anterior
          </Button>
        </span>
        <span>{`${formatDate(shownDate, 'LLLL')} ${shownDate.getFullYear()}`}</span>
        <span>
          <Button
            variant="text"
            className="CalendarCard__body__backArrow"
            onClick={() => {
              getMonthAndYear('+');
            }}
          >
            Próximo mes
            <RightArrowIcon />
          </Button>
        </span>
      </div>
      <div className="CalendarCard__body CalendarCard__body--active">
        <Button
          variant="text"
          className="CalendarCard__body__backArrow"
          onClick={() => {
            toogleActive();
          }}
        >
          <LeftArrowIcon />
          volver
        </Button>

        {calendarList && calendarList.length > 0 ? (
          <div className="CalendarCard__body__list">
            {calendarList.map(
              ({
                _id: id,
                fecha_desde: date,
                titulo: title,
                fecha_hasta: expireDate,
                descripcion: description,
              }) => (
                <CalendarItem
                  key={id}
                  title={title}
                  date={formatDate(new Date(date), 'dd LLLL')}
                  description={description}
                  expireDate={expireDate && formatDate(new Date(expireDate), 'dd LLLL')}
                  subsidiary={subsidiary}
                />
              ),
            )}
          </div>
        ) : (
          <div className="CalendarCard__body__errorMessage">
            <span>No existen hitos para la fecha seleccionada.</span>
          </div>
        )}
      </div>
    </div>
  );
};

CalendarCard.propTypes = {
  calendarList: PropTypes.array,
  subsidiary: PropTypes.string,
  updateCalendarList: PropTypes.func,
  onShownDateChange: PropTypes.func,
  onChange: PropTypes.func,
  onPreviewChange: PropTypes.func,
  shownDate: PropTypes.instanceOf(Date),
};

export default CalendarCard;
