import React, { useState, Fragment, useLayoutEffect, useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { getLogos } from '../../utils/getLogos';

// Components
import PropertyList from '../PropertyList';
import ProjectButton from '../ProjectButton';

import example from '../../assets/exampleProject.jpg';
import { gallery, list } from '../../constants/filialesNames';
import { oneProject, multipleProjects } from '../../constants/ImageSizes';
import { capitalizeEveryWord } from '../../utils/utils';

import './ProjectItem.scss';

const HandleDetails = () => {
  const [showDetails, setShowDetails] = useState(false);
  const toogleDetails = () => {
    setShowDetails(!showDetails);
  };
  return { showDetails, setShowDetails, toogleDetails };
};

const HandleCardSize = () => {
  const ref = useRef();
  const [size, setSize] = useState();
  const updateSize = () => {
    setSize({
      height: `${ref.current ? ref.current.clientHeight : 0}px`,
      width: `${ref.current ? ref.current.offsetWidth : 0}px`,
    });
  };
  useLayoutEffect(() => {
    updateSize();
  }, []);

  return { size, setSize, ref, updateSize };
};

const useHandleImages = (imageList, listOrganization) => {
  const [currentImage, setCurrentImage] = useState();
  const setImageSize = (size = '') => {
    const image = imageList.find(({ nombre_imagen: imageName = '' }) => imageName.split('-')[0].split('/')[1] === size);
    const url = image ? image.url : example;
    setCurrentImage(url);
  };
  useEffect(() => {
    if (imageList.length > 0) {
      switch (listOrganization) {
        case gallery:
          setImageSize(oneProject);
          break;
        case list:
          setImageSize(multipleProjects);
          break;
        default:
          setImageSize();
          break;
      }
    } else {
      setCurrentImage(example);
    }
  }, [imageList, listOrganization]);
  return { currentImage };
};
const PropertyListGalery = ({
  subsidiary,
  propiedades = [],
  nombreProyecto = 'nombre',
  imageList: imageList = [],
  listOrganization,
  onClick,
  stageName,
  filial,
}) => {
  const { showDetails, toogleDetails } = HandleDetails();
  const { size, ref, updateSize } = HandleCardSize();
  const { currentImage } = useHandleImages(imageList, listOrganization);
  const srcCurrentImage = useMemo(() => currentImage + '?' + new Date().getTime(), [currentImage]);

  return !showDetails ? (
    <div className={`ProjectItem ProjectItem--${subsidiary}`} ref={ref}>
      <div className="ProjectItem__header">
        <div className="ProjectItem__header__grid">
          <img
            alt={`Icono ${nombreProyecto}`}
            className="ProjectItem__header__imageLogo"
            {...getLogos(filial, false, true)}
          />
          <div>
            <h5 className="ProjectItem__header__name">{nombreProyecto}</h5>
            <span
              className={`ProjectItem__header__apartments ProjectItem__header__apartments--${subsidiary}`}
            >
              {propiedades.length === 1 ? (
                `${propiedades[0].tipo_Unidad} ${propiedades[0].numero_Departamento}`
              ) : (
                <Fragment>
                  <span
                    className={`ProjectItem__header__apartments__number ProjectItem__header__apartments__number--${subsidiary}`}
                  >
                    {propiedades.length}
                  </span>
                  UNIDADES
                </Fragment>
              )}
            </span>
          </div>
          <ProjectButton
            subsidiary={subsidiary}
            onClick={toogleDetails}
            className="ProjectItem__header__button"
          />
        </div>
      </div>
      <div className="ProjectItem__body">
        <img
          src={currentImage + '?' + parseInt(new Date().getTime()/1000/60/2)}
          className="ProjectItem__body__image"
          alt="project"
          onLoad={updateSize}
        />
      </div>
      <div className="ProjectItem__footer">
        <span className="ProjectItem__footer__label">progreso de obra</span>
        Etapa: {capitalizeEveryWord(stageName)}
      </div>
    </div>
  ) : (
    <div className="ProjectItem" style={listOrganization === gallery ? { ...size } : {}}>
      <PropertyList
        properties={propiedades}
        nombreProyecto={nombreProyecto}
        subsidiary={subsidiary}
        changeState={toogleDetails}
        onClick={onClick}
      />
    </div>
  );
};

PropertyListGalery.propTypes = {
  subsidiary: PropTypes.string,
  nombreProyecto: PropTypes.string,
  propiedades: PropTypes.array,
  imageList: PropTypes.array,
  listOrganization: PropTypes.string,
  onClick: PropTypes.func,
  stageName: PropTypes.string,
  filial: PropTypes.string,
};

export default PropertyListGalery;
