import React from 'react';
import IconPropTypes from '../IconPropTypes';

const MessageNotificationIcon = ({ color, className }) => (
  <svg width="16px" height="14px" viewBox="0 0 16 14" className={className}>
    <g id="Home-Ui" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="home-socovesa"
        transform="translate(-385.000000, -201.000000)"
        fill="#FFFFFF"
        fillRule="nonzero"
      >
        <g id="ui-chat-button" transform="translate(385.000000, 201.000000)">
          <g id="icons">
            <path
              d="M14.4615385,0.307692308 C15.1403953,0.307692308 15.6923077,0.859604692 15.6923077,1.53846154 L15.6923077,9.53846154 C15.6923077,10.2173184 15.1403953,10.7692308 14.4615385,10.7692308 L13.8461538,10.7692308 L13.8461538,12.6153846 C13.8461538,12.8279839 13.7360911,13.0255582 13.5533268,13.1399244 C13.4559501,13.1995677 13.3443808,13.2307692 13.2307692,13.2307692 C13.135882,13.2307692 13.0414217,13.2084136 12.9559482,13.1656769 L8.16244885,10.7692308 L1.53846154,10.7692308 C0.859604692,10.7692308 0.307692308,10.2173184 0.307692308,9.53846154 L0.307692308,1.53846154 C0.307692308,0.859604692 0.859604692,0.307692308 1.53846154,0.307692308 L14.4615385,0.307692308 Z M1.53846154,1.53846154 L1.53846154,9.53846154 L8.30769231,9.53846154 C8.40060475,9.53846154 8.4925627,9.56015262 8.5831729,9.60296061 L12.6153846,11.6196206 L12.6153846,10.1538462 C12.6153846,9.81406623 12.8909893,9.53846154 13.2307692,9.53846154 L14.4615385,9.53846154 L14.4615385,1.53846154 L1.53846154,1.53846154 Z"
              id="Shape"
              fill={color}
            />
            <path
              d="M12.562249,3.49333333 L3.437751,3.49333333 C3.01189288,3.49333333 2.66666667,3.79181017 2.66666667,4.16 C2.66666667,4.52818983 3.01189288,4.82666667 3.437751,4.82666667 L12.562249,4.82666667 C12.9881071,4.82666667 13.3333333,4.52818983 13.3333333,4.16 C13.3333333,3.79181017 12.9881071,3.49333333 12.562249,3.49333333 Z"
              id="Path"
              fill={color}
            />
            <path
              d="M7.23809524,5.49333333 L3.42857143,5.49333333 C3.00778305,5.49333333 2.66666667,5.79181017 2.66666667,6.16 C2.66666667,6.52818983 3.00778305,6.82666667 3.42857143,6.82666667 L7.23809524,6.82666667 C7.65888362,6.82666667 8,6.52818983 8,6.16 C8,5.79181017 7.65888362,5.49333333 7.23809524,5.49333333 Z"
              id="Path"
              fill={color}
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

MessageNotificationIcon.propTypes = IconPropTypes;

export default MessageNotificationIcon;
