
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import React from "react";
import Button from '../../../ButtonTypes';

const ConfirmationModal = ({ title, mainText,  open, handleNo, handleYes }) => (
  <Dialog open={open} onClose={handleNo}>
    <DialogTitle> {title} </DialogTitle>
    <DialogContent>
      <DialogContentText> {mainText} </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleNo} color="primary">
        Volver
      </Button>
      <Button onClick={handleYes} color="primary" autoFocus>
        Guardar
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmationModal;
