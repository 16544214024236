import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import TextField from '../TextFields';
import InputAdornment from '../InputAdornment';
import SightIcon from '../Icons/SightIcon';

// TextFields style
import { textFieldColors } from '../TextFields/TextFields';

const PasswordTextBox = ({ color, ...props }) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClick = useCallback(() => setShowPassword(!showPassword), [showPassword]);
  return (
    <TextField
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" color={textFieldColors[color]}>
            <SightIcon onClick={handleClick} color={textFieldColors[color]} isOpen={showPassword} />
          </InputAdornment>
        ),
      }}
      {...{ color, ...props }}
      type={showPassword ? 'text' : 'password'}
    />
  );
};

PasswordTextBox.propTypes = {
  color: PropTypes.string,
};

export default PasswordTextBox;
