import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import * as Yup from 'yup';
import { withFormik, Form } from 'formik';
import LeftArrowIcon from '../Icons/LeftArrowIcon';
import TextFields from '../TextFields';
import Button from '../ButtonTypes';
import DatePicker from '../DatePicker/DatePicker';

import './MilestoneCalendarForm.scss';

export const MilestoneCalendarFormSchema = Yup.object().shape({
  title: Yup.string()
    .required('Por favor Ingrese el título')
    .max(100, 'El título debe tener maximo 100 caracteres de largo'),
  introductorio: Yup.string()
    .required('Por favor Ingrese el texto Introductorio')
    .max(500, 'El texto introductorio debe tener maximo 500 caracteres de largo'),
  fecha_desde: Yup.date().required('Por favor Ingrese una fecha'),
  fecha_hasta: Yup.date().min(
    Yup.ref('fecha_desde'),
    ({ fecha_desde }) => 'Fecha final debe ser mayor a la inicial',
  ),
});

const MilestonCalendarForm = ({
  handleShowNewMilestonForm,
  handleChange,
  editObj,
  errors,
  values,
  touched,
  handleSubmit,
  setFieldValue,
}) => {

    return (
    <Form className="MilestoneCalendarForm__form" onSubmit={handleSubmit}>
      <div className="MilestoneCalendarForm">
        <div className="MilestoneCalendarForm__items_head">
          <Grid container spacing={10}>
            <Grid item xs={3}>
              <Button
                variant="text"
                className="MilestoneCalendarForm__arrows"
                onClick={() => {
                  handleShowNewMilestonForm();
                }}
              >
                <LeftArrowIcon />
                VOLVER
              </Button>
            </Grid>
            <Grid item xs={6}>
              <b>Nuevo hito</b>
            </Grid>
            <Grid item xs={3} />
          </Grid>
        </div>
        <div className="MilestoneCalendarForm__form__body">
          <Grid container spacing={10}>
            <Grid item xs={12}>
              <TextFields
                name="title"
                label="TITULO DE EVENTO:"
                value={values.title}
                error={errors.title && touched.title}
                valid={touched.title && !errors.title && values.title !== ''}
                helperText={errors.title}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={10}>
            <Grid item xs={12} sm={6}>
              <label className="MilestoneCalendarForm__form__label">Inicia</label>
              <DatePicker
                className="MilestoneCalendarForm__form__input"
                name="fecha_desde"
                value={values.fecha_desde}
                error={errors.fecha_desde && touched.fecha_desde}
                valid={touched.fecha_desde && !errors.fecha_desde && values.fecha_desde !== ''}
                helperText={errors.fecha_desde}
                onChange={setFieldValue}
                variant="dialog"
                cancelLabel={<div>Cancelar</div>}
                firstValue={true}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label className="MilestoneCalendarForm__form__label">Termina</label>
              <DatePicker
                className="MilestoneCalendarForm__form__input"
                name="fecha_hasta"
                value={values.fecha_hasta}
                error={errors.fecha_hasta && touched.fecha_hasta}
                valid={touched.fecha_hasta && !errors.fecha_hasta && values.fecha_hasta !== ''}
                helperText={errors.fecha_hasta}
                onChange={setFieldValue}
                variant="dialog"
                cancelLabel={<div>Cancelar</div>}
                firstValue={true}
              />
            </Grid>
          </Grid>
          <Grid container spacing={10}>
            <Grid item xs={12}>
              <TextFields
                name="introductorio"
                label="Texto introductorio"
                error={errors.introductorio && touched.introductorio}
                valid={
                  touched.introductorio && !errors.introductorio && values.introductorio !== ''
                }
                helperText={errors.introductorio}
                value={values.introductorio}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="MilestoneCalendarForm__footer">
        <div className="MilestoneCalendarForm__form__buttons">
          <Button
            variant="outlined"
            onClick={() => {
              handleShowNewMilestonForm();
            }}
          >
            Cancelar
          </Button>
          <Button className="MilestoneCalendarForm__form__button" type="submit">
            Guardar
          </Button>
        </div>
      </div>
    </Form>
  );
};

MilestonCalendarForm.propTypes = {};

export default withFormik({
  mapPropsToValues: ({ editObj }) => {
    if (editObj) {
      return {
        title: editObj.titulo,
        introductorio: editObj.descripcion,
        fecha_desde: editObj && editObj.fecha_desde ? editObj.fecha_desde : new Date(),
        fecha_hasta: editObj && editObj.fecha_hasta ? editObj.fecha_hasta : new Date(),
      };
    }
    return {
      title: '',
      introductorio: '',
      fecha_hasta: new Date(),
      fecha_desde: new Date(),
    };
  },
  enableReinitialize: true,
  validateOnBlur: false,
  isInitialValid: false,
  validationSchema: MilestoneCalendarFormSchema,
  handleSubmit: (values, { props: { onSubmit } }) => {
    onSubmit(values);
  },
})(MilestonCalendarForm);
