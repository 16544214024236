import React, { useState, useMemo, memo } from 'react';
import PropTypes from 'prop-types';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import { subsidiaryCodeToText } from '../../utils/utils';

// Constants
import { subsidiaryOptions } from '../../constants/filialesNames';

import { socovesaPrimary, pilaresPrimary } from '../../constants/colors';

const tabStyles = makeStyles({
  almagro: {
    color: '#303446!important',
    fontWeight: 'bold',
    fontStyle: 'normal',
    lineHeight: '1.29',
  },
  socovesa: {
    color: `${socovesaPrimary}!important`,
    fontWeight: 'bold',
    fontStyle: 'normal',
    lineHeight: '1.29',
  },
  pilares: {
    color: `${pilaresPrimary}!important`,
    fontWeight: 'bold',
    fontStyle: 'normal',
    lineHeight: '1.29',
  },
});

const TabsStyles = {
  almagro: {
    backgroundColor: '#303446',
    borderBottom: '2px solid #303446!important',
  },
  socovesa: {
    borderBottom: `3px solid  ${socovesaPrimary}!important`,
  },
  pilares: {
    backgroundColor: pilaresPrimary,
    borderBottom: `2px solid  ${pilaresPrimary}!important`,
  },
};

const useTabOptions = (options = []) => {
  const [tabOptions, setTabOptions] = useState([]);
  useMemo(() => {
    setTabOptions([...options]);
  }, [options]);
  return { tabOptions };
};

const SubsidiaryTabs = ({ options = subsidiaryOptions, value, onChange, classes, className }) => {
  const { tabOptions } = useTabOptions(options);
  const classesTabChild = tabStyles();
  return (
    <Tabs
      value={value}
      onChange={onChange}
      classes={{
        indicator: classes[value] ? classes[value] : classes[subsidiaryCodeToText(value)],
      }}
      aria-label="tabs"
      textColor="primary"
      className={className}
    >
      {tabOptions.length > 0 &&
        tabOptions.map(({ label, value: optionValue }) => (
          <Tab
            className={classesTabChild[label]}
            label={label}
            value={optionValue}
            key={optionValue}
          />
        ))}
    </Tabs>
  );
};

SubsidiaryTabs.propTypes = {
  options: PropTypes.array,
  value: PropTypes.string,
  onChange: PropTypes.func,
  classes: PropTypes.object,
  className: PropTypes.string,
};

export default memo(withStyles(TabsStyles)(SubsidiaryTabs));
