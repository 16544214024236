import React, { memo, useCallback, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// Components
import ProjectItem from './ProjectTableItem';
import SubsidiaryTabs from '../SubsidiaryTabs';
import Searchbar from '../Searchbar';
import AlphabeticalFilter from '../AlphabetFilter';
import Button from '../ButtonTypes';
import ArrowDownCircle from '../Icons/ArrowDownCircle';

import { almagroCode } from '../../constants/filialesNames';

import './ProjectTable.scss';
import SortIcon from '../../components/Icons/SortIcon';

const formatList = (columns, rawList = []) =>
  rawList.reduce(
    (acom, item) => [
      ...acom,
      {
        ...columns.reduce((acc, { key, defaultValue, circle = false, id = false, label }) => {
          const obj = {
            ...acc,
            [id ? 'id' : key]: { value: item[key] || defaultValue, circle, label },
          };
          if ((obj[key] !== undefined && obj[key].value !== undefined) || id) return obj;
          delete obj[key];
          return obj;
        }, {}),
      },
    ],
    [],
  );

// Main Component
const ProjectTable = ({
  itemList = [],
  columns: columnList = [],
  subsidiary,
  activeSubsidiary = localStorage.getItem('filial') || almagroCode,
  subsidiaryOptions,
  onChangeSubsidiary,
  onChangeSearch,
  onLetterChangeCallback,
  onClientStatusChange,
  className,
  onItemClick,
  addMoreRowsLabel,
  addMoreRowsCallback,
  messageEmpty,
  onChangeOrder,
}) => {
  const [textFilter, setFilterText] = useState('');
  const handleSearchChange = useCallback(
    (newValue) => {
      if (onChangeSearch) onChangeSearch(newValue);
      setFilterText(newValue);
    },
    [onChangeSearch]
  );

  const handleTabChange = useCallback(
    (event, newValue) => {
      localStorage.setItem('filial', newValue);
      if (onChangeSubsidiary) onChangeSubsidiary(newValue);
      if (onChangeSearch) onChangeSearch(textFilter);
    },
    [onChangeSubsidiary, textFilter],
  );

  const useHandleList = (propList = [], propColumns = []) => {
    const [list, setList] = useState([]);
    const [columns, setColumns] = useState([]);
    const updateList = newList => {
      if (!R.equals(newList, list)) setList([...newList]);
    };
    useEffect(() => {
      if (columns.length > 0 && propList.length > 0) {
        const newList = formatList(columns, propList);
        updateList([...formatList(columns, propList)]);
      } else {
        updateList([]);
      }
    }, [propList, columns]);
    useEffect(() => {
      if (propColumns.length > 0) {
        setColumns(propColumns.filter(({ label = '', id }) => (label && label !== '') || id));
      }
    }, [propColumns]);
    return { list, columns };
  };
  const { list, columns } = useHandleList(itemList, columnList);
  const mobile = useMediaQuery('(max-width: 768px)');
  return (
    <div className={`ProjectTable ${className}`}>
      <div className="ProjectTable__header">
        {activeSubsidiary && onChangeSubsidiary && (
          <SubsidiaryTabs
            value={activeSubsidiary}
            options={subsidiaryOptions}
            onChange={handleTabChange}
          />
        )}
      </div>
      {window.location.href.split('/').pop() != 'Home' && <div className="EnterpriseMessageList__searchbar">
        <Searchbar
            helperMessage="¿Estás buscando un cliente en particular?"
            onChange={setFilterText}
            onSubmit={handleSearchChange}
            value={textFilter}
          />
      </div>
      }
      <div className="ProjectTable__subheader">
        {onLetterChangeCallback && (
          <AlphabeticalFilter
            subsidiary={subsidiary}
            onLetterChangeCallback={onLetterChangeCallback}
          />
        )}
      </div>
      {!mobile ? (
        <div className="ProjectTable__desktop">
          {list && list.length > 0 ? (
            <div className="ProjectTable__body">
              <table className="ProjectTable__body__table">
                <thead>
                  <tr className="ProjectTable__body__table__header">
                    {columns.length > 0 &&
                      columns.map(
                        ({ label, key, circle, id, ordered, keyOrder }) =>
                          !id && (
                            <th
                              className={
                                circle
                                  ? 'ProjectTable__body__table__header__item ProjectTable__body__table__header__item__center'
                                  : 'ProjectTable__body__table__header__item'
                              }
                              key={key}
                            >
                              {ordered !== undefined ?
                                  <span onClick={() => onChangeOrder(keyOrder)} style={{cursor: 'pointer'}}>
                                    {label}
                                    {ordered ?
                                        <SortIcon color='rgb(0,147,202)' /> :
                                        <SortIcon />
                                    }
                                  </span> :
                                label
                              }
                              {/* {label} */}
                            </th>
                          ),
                      )}
                  </tr>
                </thead>
                <tbody className="ProjectTable__body__list">
                  {list.length === itemList.length && list.map(({ id: { value } = {}, ...item }, index) => {
                    return <ProjectItem
                      {...item}
                      onClick={onItemClick}
                      id={value}
                      key={value}
                      onClientStatusChange={onClientStatusChange}
                      email={itemList[index].eMailParticular}
                    />
                    })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="ProjectTable__body__emptyMessage">{messageEmpty}</div>
          )}
        </div>
      ) : (
        <div className="ProjectTable__mobile">
          {list.map(({ id: { value } = {}, ...item }) => {
            return <ProjectItem
              {...item}
              onClick={onItemClick}
              id={value}
              key={value}
              mobile={mobile}
              onClientStatusChange={onClientStatusChange}
            />
          })}
        </div>
      )}
      <div className="ProjectTable__footer">
        {addMoreRowsLabel && list.length > 0 && (
          <Button variant="text" onClick={addMoreRowsCallback} padding="0px">
            <span className="ProjectTable__footer__addMoreLabel">
              {addMoreRowsLabel}
              <ArrowDownCircle className="ProjectTable__footer__addMoreLabel__icon" />
            </span>
          </Button>
        )}
      </div>
    </div>
  );
};

ProjectTable.propTypes = {
  itemList: PropTypes.array,
  subsidiaryFilter: PropTypes.array,
  columns: PropTypes.array,
  subsidiary: PropTypes.string,
  activeSubsidiary: PropTypes.string,
  subsidiaryOptions: PropTypes.array,
  onChangeSubsidiary: PropTypes.func,
  onChangeSearch: PropTypes.func,
  onLetterChangeCallback: PropTypes.func,
  onClientStatusChange: PropTypes.func,
  className: PropTypes.string,
  onItemClick: PropTypes.func,
  addMoreRowsLabel: PropTypes.string,
  addMoreRowsCallback: PropTypes.func,
  messageEmpty: PropTypes.string,
};

export default memo(ProjectTable);
