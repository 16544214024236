import React from 'react';

import IconProps from '../IconPropTypes';

const AddIcon = ({ className, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path fill="none" d="M0 0h24v24H0V0z" />
    <path fill={color} d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
  </svg>
);

AddIcon.propTypes = IconProps;

export default AddIcon;
