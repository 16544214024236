import { CardContent, Chip, Typography } from "@material-ui/core";
import React from "react";

import Button from "../../../../components/ButtonTypes";

import '../AdditionalList/AdditionalList.scss'

const AdditionalItemDesktop = ({
  additional,
  relationshipArray = [],
  onEditAdditional,
  units = [],
}) => {

  return (
    <CardContent  className="ClientAdditional__desktop">
      <div style={{
              width: '40%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}>
        <Typography variant="h5" component="h2">
          <span>
            <Chip
              label={additional.estadoadiciona_adi === '1' ? 'Activo' : 'Desactivado'}
              style={{
                color: 'white',
                marginInline: '0.5rem',
                backgroundColor:
                additional.estadoadiciona_adi === '1' ? '#5cb85c' : '#ff3333',
              }}
            />
            <b>{additional.solicitantenombre_adi}</b>
          </span>
        </Typography>
        <Typography color="textSecondary"
          style={{
            textAlign: 'center',
            width: '50%',
          }}
        >
          {additional.solicitanterut_adi}
        </Typography>
        <Typography variant="body2" component="p" style={{ paddingTop: '0.5rem' }}>
          {
            relationshipArray.find(
              relationship =>
                relationship.id === +additional.solicitanterelacionPropietarioID_adi,
            ).name
          }{' '}
          - {additional.solicitanteemail_adi} - {additional.solicitantephone_adi}
        </Typography>
      </div>
      <div style={{ width: '30%', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
        {units
          .filter(unit => additional.unidades.map(unidad => unidad.unidadid_adi).includes(unit.unidadId) )
          .map(unit => (
            <div>
              <span style={{fontSize: 'small'}}>{`${unit.project} - ${unit.numeroProducto}`}</span>
            </div>
          ))}
      </div>
      <div
        style={{
          width: '30%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
        }}
      >
        <Button size="small" onClick={() => onEditAdditional(additional)}>
            Editar
        </Button>
      </div>
    </CardContent>
  )

};

export default AdditionalItemDesktop;
