import axios from 'axios';
import {
  projectDocumentsList,
  clientDocumentsList,
  UploadFileProject,
  UploadFileClient,
  deleteFileProject,
} from '../constants/endpoints';

export const listProjectDocuments = (idProyecto, idEjecutivo, idCliente, label, successCallback, errorCallback) => {
  const url = projectDocumentsList + '?id_proyecto=' + idProyecto
  + (idEjecutivo ? '&id_ejecutivo=' + idEjecutivo : '')
  + (idCliente ? '&id_cliente=' + idCliente : '')
  + (label ? '&label=' + label : '');
  return axios
    .get(url)
    .then(successCallback)
    .catch(errorCallback);
}

export const listClientDocuments = (idProyecto, idEjecutivo, idCliente, label, successCallback, errorCallback) => {
  const url = clientDocumentsList + '?id_proyecto=' + idProyecto
  + (idEjecutivo ? '&id_ejecutivo=' + idEjecutivo : '')
  + (idCliente ? '&id_cliente=' + idCliente : '')
  + (label ? '&label=' + label : '');
  return axios
    .get(url)
    .then(successCallback)
    .catch(errorCallback);
}

export const uploadFileProject = (idProyecto, idEjecutivo, idCliente, toType, label, file, successCallback, errorCallback) => {
  const formData = new FormData();
  formData.append('file', file[0]);
  formData.append('idProyecto', idProyecto);
  formData.append('idEjecutivo', idEjecutivo);
  formData.append('label', label);
  formData.append('toType', toType);
  if (idCliente) {
    if (Array.isArray(idCliente)) {
      idCliente.forEach((cliente, index) => {
        formData.append(`idCliente[${index}]`, cliente);
      });
    } else {
      formData.append('idCliente', idCliente);
    }
  }
  return axios
    .post(UploadFileProject, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(successCallback)
    .catch(errorCallback);
}

export const uploadFileClient = (idProyecto, idEjecutivo, idCliente, label, file, successCallback, errorCallback) => {
  const formData = new FormData();
  formData.append('file', file);
  const a = idProyecto ? formData.append('idProyecto', idProyecto) : '';
  formData.append('idEjecutivo', idEjecutivo);
  formData.append('idCliente', idCliente);
  formData.append('label', label);
  return axios
    .post(UploadFileClient, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(successCallback)
    .catch(errorCallback);
}

export const deleteProjectDocument = (idDocumento, successCallback, errorCallback) => {
  const url = deleteFileProject + '/' + idDocumento;
  return axios
    .delete(url)
    .then(successCallback)
    .catch(errorCallback);
}

export const deleteMessageDocument = (idDocumento, successCallback, errorCallback) => {
  const url = deleteFileProject + '/' + idDocumento + '?message=true';
  return axios
    .delete(url)
    .then(successCallback)
    .catch(errorCallback);
}
