import React, { useContext, memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Components
import Typography from '@material-ui/core/Typography';
import ProjectList from '../../components/ProjectList';
import DocumentList from '../../components/DocumentList';
import WorkProgress from '../../components/WorkProgress';
import CalendarCard from '../../components/CalendarCard';
import NotificationList from '../../components/NotificationList';
import DocumentListPostVenta from '../../components/DocumentList/DocumentListPV';

import { capitalizeEveryWord } from '../../utils/utils';

import { GlobalContext } from '../../providers/GlobalStateProvider';

import { HandleCalendar, HandleDocuments, HandleDocumentsByProject, HandleMilestones, HandleProjects } from '../../hooks';

// Services
import { getCalendar } from '../../services/projectsService';
import { useSolicitudPorUnidad } from '../../customHooks/postventa';
import './Home.scss';
import { useIslogged } from '../../customHooks/useIslogged';
import DocumentListExec from '../../components/DocumentList/DocumentListExec';
import { useClientDocuments } from '../../customHooks/documents';
import { getActasbyProducto } from '../../services/postventaService';
import { ImagenActa } from '../../constants/endpoints';
import useCurrentProject from '../../hooks/projectHooks';

const Home = ({
  match: {
    params: { subsidiary },
  },
  location: { pathname },
  history,
  notificationList,
  availableSubsidiaries,
}) => {

  const { state: { username = '', fullName = '' } = {} } = useContext(GlobalContext);
  if (!useIslogged()) history.push(`/${subsidiary}`)

  const { projectList, activeProject, activeProperty, multipleProperty } = HandleProjects(username);
  const { milestones } = HandleMilestones(activeProject);
  const { documents } = HandleDocuments(username, activeProject, activeProperty);
  // const { images } = HandleDocumentsByProject(username, activeProject);
  // const { images } = HandleDocumentsByProject(username, activeProject);
  const { calendarList, setCalendarList, onChangeFilter, filterCalendar } = HandleCalendar(
    activeProperty,
    activeProject,
    username,
    new Date(),
  );
  const {clientDocuments, setClientDocuments, projectDocuments, setProjectDocuments} = useClientDocuments(activeProject, username);

  const selectProperty = (projectId, propertyId) => {
    history.push(`${pathname}/${projectId}/${propertyId}`);
  };
  const { selectedProperty } = useCurrentProject(username, activeProject, activeProperty);
  const idProperty = selectedProperty ? selectedProperty.id_PropiedadPV : '';
  const { solicitudes } = useSolicitudPorUnidad(idProperty, undefined);

  // const [docsClientes, setDocsClientes] = useState([]);
  // const [docsProyecto, setDocsProyecto] = useState([]);
  // useEffect(() => {
  //   if (images.documentosProyecto && images.documentosProyecto.length > 0) {
  //     setDocsProyecto(images.documentosProyecto);
  //   }
  //   if (images.documentosCliente && images.documentosCliente.length > 0) {
  //     setDocsClientes(images.documentosCliente);
  //   }
  // }, [images]);

  // const [docsClientes, setDocsClientes] = useState([]);
  // const [docsProyecto, setDocsProyecto] = useState([]);
  // useEffect(() => {
  //   if (images.documentosProyecto && images.documentosProyecto.length > 0) {
  //     setDocsProyecto(images.documentosProyecto);
  //   }
  //   if (images.documentosCliente && images.documentosCliente.length > 0) {
  //     setDocsClientes(images.documentosCliente);
  //   }
  // }, [images]);

  const updateCalendarList = () => {
    getCalendar(
      {
        idPropiedad: activeProperty,
        idProyecto: activeProject,
        idCliente: username,
        filterDate: new Date(filterCalendar),
      },
      ({ data: { data } }) => {
        setCalendarList([...data]);
      },
      err => {
        console.error(err);
      },
    );
  };

  const redirectToMenssages = () => {
    history.push(`${pathname}/messages`);
  };


  const availableAfterSalesSubsidiaries = process.env.REACT_APP_AVAILABLE_AFTER_SALES_SUBSIDIARIES.split(',')
  const isSubsidiaryAfterSaleAvailable =  availableAfterSalesSubsidiaries.includes(subsidiary.toLowerCase())

  const [ actas, setActas ] = useState([]);
  
  useEffect(() => {
    if (activeProject && activeProperty) {
      getActasbyProducto(activeProperty, ({ data }) => {
        setActas(data.map(acta => {
          const { revisionFechaInicio } = acta;
          return {
            name: acta.revisionActaBlobNombre,
            lastModified: `${revisionFechaInicio.split(' ')[0].split('-').reverse().join('-')} ${revisionFechaInicio.split(' ')[1]}`,
            etag: acta.revisionActaId,
            link: `${ImagenActa}/${activeProperty}/${acta.revisionActaId}`,
            label: acta.revisionActaBlobNombre.trim(),
          }
        }));
      }, err => {
        console.error(err);
      });
    }
  }, [activeProject, activeProperty]);

  return (
    <div className={`Home__body Home__body--${subsidiary}`}>
      <span className={`Home__body__greeting Home__body__greeting--${subsidiary}`}>
        {'¡Bienvenido!'}
      </span>
      <Typography
        variant="h5"
        className={`Home__body__username Home__body__username--${subsidiary}`}
      >
        {capitalizeEveryWord(fullName)}
      </Typography>
      <NotificationList
        subsidiary={subsidiary}
        notificationList={notificationList}
        onClick={redirectToMenssages}
      />
      <div className="Home__body__section">
        <div className="Home__body__section__container">
          <div className="Home__body__section__container__header">
            <h5
              className={`Home__body__section__container__header__name Home__body__section__container__header__name--${subsidiary}`}
            >
              MIS PROPIEDADES DE {subsidiary.toUpperCase()}
            </h5>
            {/* <span
              className="Home__body__section__container__header__detail"
            >
              {projectList.length} PROYECTOS
            </span> */}
          </div>
          <div className="Home__body__section__container__list">
            <ProjectList
              subsidiary={subsidiary}
              projects={projectList}
              selectProperty={selectProperty}
            />
          </div>
        </div>
      </div>
      {!multipleProperty && documents && documents.length > 0 ? (
        <>
          <div className="Home__body__section">
            <div className="Home__body__section__container">
              <div className="Home__body__section__container__header">
                <h5 className={`Home__body__section__container__header__name Home__body__section__container__header__name--${subsidiary}`}>MIS DOCUMENTOS FIRMADOS</h5>
              </div>
              <div className="Home__body__section__container__list">
                <div
                  className={`Home__body__section__container__list__item Home__body__section__container__list__item--${subsidiary}`}
                >
                    <DocumentList fileList={documents} subsidiary={subsidiary} />
                </div>
              </div>
            </div>
          </div>

          {availableSubsidiaries &&
            availableSubsidiaries.documentsPV &&
            !multipleProperty &&
            solicitudes &&
            solicitudes.length > 0 && (
            <div className="Home__body__section">
              <div className="Home__body__section__container">
                <div className="Home__body__section__container__header">
                  <h5
                    className={`Home__body__section__container__header__name Home__body__section__container__header__name--${subsidiary}`}
                  >
                    MIS DOCUMENTOS DE POSTVENTA
                  </h5>
                </div>
                <div className="Home__body__section__container__list">
                  <div
                    className={`Home__body__section__container__list__item Home__body__section__container__list__item--${subsidiary}`}
                  >
                    {<DocumentListPostVenta requirementList={solicitudes} subsidiary={subsidiary} />}
                  </div>
                </div>
              </div>
            </div>
          )}
        {isSubsidiaryAfterSaleAvailable && actas && actas.length > 0 ?
          <div className="Home__body__section">
          <div className="Home__body__section__container">
            <div className="Home__body__section__container__header">
                <h5 className={`Home__body__section__container__header__name Home__body__section__container__header__name--${subsidiary}`}>MIS ACTAS DE ENTREGA</h5>
            </div>
            <div className="Home__body__section__container__list">
              <div
                className={`Home__body__section__container__list__item Home__body__section__container__list__item--${subsidiary}`}
                >
                  <DocumentListExec fileList={actas} subsidiary={subsidiary} />
              </div>
            </div>
          </div>
        </div>
      : null}
          {clientDocuments.length > 0 ?
              <div className="Home__body__section">
                <div className="Home__body__section__container">
                  <div className="Home__body__section__container__header">
                      <h5 className={`Home__body__section__container__header__name Home__body__section__container__header__name--${subsidiary}`}>MIS DOCUMENTOS DEL EJECUTIVO</h5>
                  </div>
                  <div className="Home__body__section__container__list">
                    <div
                      className={`Home__body__section__container__list__item Home__body__section__container__list__item--${subsidiary}`}
                      >
                        <DocumentListExec fileList={clientDocuments} subsidiary={subsidiary} />
                    </div>
                  </div>
                </div>
              </div>
          : null}
          {projectDocuments.length > 0 ?
              <div className="Home__body__section">
                <div className="Home__body__section__container">
                  <div className="Home__body__section__container__header">
                      <h5 className={`Home__body__section__container__header__name Home__body__section__container__header__name--${subsidiary}`}>MIS DOCUMENTOS DEL PROYECTO</h5>
                  </div>
                  <div className="Home__body__section__container__list">
                    <div
                      className={`Home__body__section__container__list__item Home__body__section__container__list__item--${subsidiary}`}
                      >
                        <DocumentListExec fileList={projectDocuments} subsidiary={subsidiary} />
                    </div>
                  </div>
                </div>
              </div>
          : null}
        </>
      ) : null}
      <div className="Home__body__section">
        {!multipleProperty && milestones && milestones.fases && milestones.fases.length > 0 ? (
          <div className="Home__body__section__container">
            <>
              <div className="Home__body__section__container__header">
                <h5
                  className={`Home__body__section__container__header__name Home__body__section__container__header__name--${subsidiary}`}
                >
                  AVANCES DE OBRA
                </h5>
              </div>
              <div className="Home__body__section__container__list">
                <div
                  className={`Home__body__section__container__list__item Home__body__section__container__list__item--${subsidiary}`}
                >
                  <WorkProgress subsidiary={subsidiary} milestones={milestones} />
                </div>
              </div>
            </>
          </div>
        ) : null}
        <div className="Home__body__section__container" style={{ flexBasis: '50%' }}>
          {!multipleProperty && (
            <>
              <div className="Home__body__section__container__header">
                <h5
                  className={`Home__body__section__container__header__name Home__body__section__container__header__name--${subsidiary}`}
                >
                  MI CALENDARIO
                </h5>
              </div>
              <div className="Home__body__section__container__list">
                <div
                  className={`Home__body__section__container__list__item Home__body__section__container__list__item--${subsidiary}`}
                >
                  <CalendarCard
                    calendarList={calendarList}
                    subsidiary={subsidiary}
                    updateCalendarList={updateCalendarList}
                    onShownDateChange={onChangeFilter}
                    shownDate={filterCalendar}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div />
      <div className={`Home__body__rectangle Home__body__rectangle--${subsidiary}`} />
    </div>
  );
};

Home.propTypes = {
  history: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.shape({
      subsidiary: PropTypes.string,
    }),
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  notificationList: PropTypes.object,
  availableSubsidiaries: PropTypes.array,
};

export default memo(Home);
