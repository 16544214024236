import axios from 'axios';

import {
  getExecutiveList as getExecutiveListEndpoint,
  getExecutiveDetail as getExecutiveDetailEndpoint,
  getExecutiveByClient as getExecutiveByClientEndpoint
} from '../constants/endpoints';

export const getExecutiveList = (successCallback, errorCallback) => {
  return axios
    .get(getExecutiveListEndpoint)
    .then(successCallback)
    .catch(errorCallback);
};

export const getExecutiveByClient = (rut, successCallback, errorCallback) => {
  return axios
    .get(`${getExecutiveByClientEndpoint}/${rut}`)
    .then(successCallback)
    .catch(errorCallback);
};

export const getExecutiveById = (rut, successCallback, errorCallback) => {
  const endpoint = `${getExecutiveDetailEndpoint}/${rut}`;
  return axios
    .get(endpoint)
    .then(successCallback)
    .catch(errorCallback);
};

export const saveExecutiveById = (params, successCallback, errorCallback) => {
  const { rut } = params;
  const endpoint = `${getExecutiveDetailEndpoint}/${rut}`;
  return axios
    .put(endpoint, { ...params })
    .then(successCallback)
    .catch(errorCallback);
};
