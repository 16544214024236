import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

const ProtectedRoute = props => {
  return <Route {...props} />;
};

ProtectedRoute.propTypes = {
  location: PropTypes.object,
};

export default ProtectedRoute;
