/* eslint-disable import/prefer-default-export */
import { project } from 'ramda';
import { useEffect, useState } from 'react';
import { getOnlyPropertyByProject } from '../services/projectsService';

export const usePropertiesByClientAndProject = (clientId, projectId) => {
  const [properties, setProperties] = useState([]);
  useEffect(() => {
    if (!!clientId && !!projectId) {
      getOnlyPropertyByProject(
        projectId,
        clientId,
        ({ data: { data } }) => {
          setProperties(data);
        },
        err => {
          console.error(err);
        },
      );
    }
  }, [clientId, projectId]);
  return { properties, setProperties };
};
