// Materials
import { createTheme } from '@material-ui/core/styles';
import {
  white,
  black,
  socovesaPrimary,
  almagroPrimary,
  pilaresPrimary,
  grey,
} from '../constants/colors';

const globalOverrides = {
  MuiPickersToolbar: {
    toolbar: {
      text: {
        color: black,
      },
    },
  },
  MuiPickersCalendarHeader: {
    switchHeader: {
      color: black,
    },
  },
};

const ThemeManager = (theme = 'socovesa') => {
  const themes = {
    almagro: {
      palette: {
        primary: {
          main: '#ee3424',
        },
        secondary: {
          main: white,
        },
      },
      typography: {
        useNextVariants: true,
        fontFamily: ['Overpass', 'sans-serif'].join(','),
      },
      overrides: {
        ...globalOverrides,
        MuiInputLabel: {
          root: {
            color: white,
            '&$focused': {
              color: white,
            },
          },
        },
        MuiTab: {
          root: {
            color: white,
            '&$selected': {
              color: white,
              borderColor: almagroPrimary,
            },
          },
          textColorInherit: {
            color: white,
            opacity: '1.0',
            fontWeight: '600',
          },
          wrapper: {
            color: white,
          },
        },
        MuiFormHelperText: {
          root: {
            color: white,
            '&$selected': {
              color: socovesaPrimary,
            },
          },
        },
        MuiInput: {
          underline: {
            '&:hover:not($disabled):after': {
              borderBottom: `2px solid ${white}`,
            },
            '&:hover:not($disabled):before': {
              borderBottom: `2px solid ${white}`,
            },
            '&:before': {
              borderBottom: `1px solid ${grey}`,
            },
            '&:after': {
              borderBottom: `2px solid ${white}`,
            },
            color: black,
          },
        },
        MuiInputBase: {
          input: {
            color: white,
          },
        },
        MuiPickersCalendarHeader: {
          switchHeader: {
            backgroundColor: '#333333',
            color: white,
            padding: '25px',
            borderBottom: '1px #bebfc5 solid',
          },
          dayLabel: {
            color: white,
          },
          iconButton: {
            height: 'auto',
            width: 'auto',
            backgroundColor: 'inherit',
            color: white,
            padding: 0,
          },
        },
        MuiPaper: {
          root: {
            backgroundColor: '#333333',
            color: white,
          },
        },
        MuiPickersDay: {
          day: {
            color: white,
            backgroundColor: '#333333',
          },
          daySelected: {
            backgroundColor: almagroPrimary,
          },
          current: {
            color: white,
          },
        },
      },
    },
    socovesa: {
      palette: {
        primary: {
          main: '#53308f',
        },
        secondary: {
          main: white,
        },
      },
      typography: {
        useNextVariants: true,
        fontFamily: ['Overpass', 'sans-serif'].join(','),
      },
      overrides: {
        ...globalOverrides,
        MuiTab: {
          root: {
            color: socovesaPrimary,
            '&$selected': {
              color: socovesaPrimary,
              borderColor: socovesaPrimary,
            },
          },
          wrapper: {
            color: socovesaPrimary,
            fontWeight: 'bold',
          },
        },
        MuiPickersCalendarHeader: {
          switchHeader: {
            backgroundColor: '#ffffff',
            color: black,
            padding: '25px',
            borderBottom: '1px #ededef solid',
          },
          dayLabel: {
            color: black,
          },
          iconButton: {
            height: 'auto',
            width: 'auto',
            backgroundColor: 'inherit',
            color: white,
            padding: 0,
          },
        },
      },
    },
    pilares: {
      palette: {
        primary: {
          main: '#ff6900',
        },
        secondary: {
          main: white,
        },
      },
      typography: {
        useNextVariants: true,
        fontFamily: ['Overpass', 'sans-serif'].join(','),
      },
      overrides: {
        ...globalOverrides,
        MuiTab: {
          root: {
            color: pilaresPrimary,
            '&$selected': {
              color: pilaresPrimary,
              borderColor: pilaresPrimary,
            },
          },
          wrapper: {
            color: almagroPrimary,
            fontWeight: 'bold',
          },
        },
        MuiPickersCalendarHeader: {
          switchHeader: {
            backgroundColor: '#ffffff',
            color: black,
            padding: '25px',
            borderBottom: '1px #ededef solid',
          },
          dayLabel: {
            color: black,
          },
          iconButton: {
            height: 'auto',
            width: 'auto',
            backgroundColor: 'inherit',
            color: white,
            padding: 0,
          },
        },
      },
    },
    empresas: {
      palette: {
        primary: {
          main: '#0093ca',
        },
        secondary: {
          main: white,
        },
      },
      typography: {
        useNextVariants: true,
        fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
      },
      overrides: {
        ...globalOverrides,
        MuiTypography: {
          h1: {
            fontSize: '56px',
          },
          h2: {
            fontSize: '32px',
            fontWeight: 'normal',
            letterSpacing: '-0.03px',
          },
          subtitle1: {
            fontSize: '18px',
            fontWeight: 'bold',
            letterSpacing: '-0.02px',
          },
        },
      },
    },
    terciary: {
      palette: {
        primary: {
          main: '#676977',
        },
        secondary: {
          main: white,
        },
      },
      typography: {
        useNextVariants: true,
        fontFamily: ['Overpass', 'sans-serif'].join(','),
      },
      overrides: {
        ...globalOverrides,
      },
    },
  };
  return createTheme({ ...themes[theme] });
};

export default ThemeManager;
