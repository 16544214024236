import { useState, useEffect } from 'react';
import { checkSubsidiarysMenus } from '../services/projectsService';

const useAvailablePermissionsBySubsidiary = subsidiary => {
  const [availableSubsidiaries, setAvailableSubsidiaries] = useState({
    admin: false,
    additional: false,
    messages: false,
    documentsPV: false,
  });
  useEffect(() => {
    checkSubsidiarysMenus(
      subsidiary,
      response => {
        setAvailableSubsidiaries(response.data);
      },
      error => {
        console.error(error);
      },
    );
  }, []);
  return { availableSubsidiaries, setAvailableSubsidiaries };
};

export default useAvailablePermissionsBySubsidiary;
