import React from 'react';
import PropTypes from 'prop-types';
import { Snackbar, SnackbarContent } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { empresasPrimary } from '../../constants/colors'

import ClearIcon from '../Icons/ClearIcon';

const SnackbarContentStyles = theme => ({
  success: {
    backgroundColor: 'green',
  },
  error: {
    backgroundColor: empresasPrimary,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: empresasPrimary,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
});

const validateMessage = message => {
  return typeof message === 'object' && message !== null ? 'Error' : message;
};

const SnackBarContainer = ({
  classes,
  anchorOrigin = { vertical: 'top', horizontal: 'right' },
  autoHideDuration = 2000,
  message,
  onClose,
  closeNotification,
  open: openProp,
  variant = 'error',
}) => {
  const closeCallback = () => {
    if (onClose) onClose();
    closeNotification(false);
  };
  return (
    <Snackbar
      aria-describedby={`${variant}-snackbar`}
      open={openProp}
      anchorOrigin={anchorOrigin}
      autoHideDuration={autoHideDuration}
      onClose={closeCallback}
    >
      <SnackbarContent
        className={classes[variant]}
        message={<span id={`${variant}-snackbar`}>{validateMessage(message)}</span>}
        action={<ClearIcon aria-label="close" onClick={closeCallback} color="currentColor" />}
      />
    </Snackbar>
  );
};

SnackBarContainer.propTypes = {
  classes: PropTypes.object,
  anchorOrigin: PropTypes.shape({
    vertical: PropTypes.string,
    horizontal: PropTypes.string,
  }),
  autoHideDuration: PropTypes.number,
  message: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  variant: PropTypes.oneOf(['success', 'error', 'warning', 'info']),
  closeNotification: PropTypes.func,
};

export default withStyles(SnackbarContentStyles)(SnackBarContainer);
