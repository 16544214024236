/* eslint-disable no-extra-boolean-cast */
/* eslint-disable import/prefer-default-export */
import { useEffect, useState } from 'react';
import { getClientByRut } from '../services/clientService';

export const useClientByRut = clientRut => {
  const [client, setClient] = useState({});
  useEffect(() => {
    if (!!clientRut) {
      getClientByRut(
        clientRut,
        ({ data: { data } }) => {
          setClient(data[0]);
        },
        err => {
          console.error(err);
        },
      );
    }
  }, [clientRut]);
  return { client, setClient };
};
