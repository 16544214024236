import React from 'react';

const DeleteButtonSmall = () => (
  <svg width="15px" height="15px" viewBox="0 0 15 15" version="1.1">
    <g id="UI-Ejecutivo-UPDATE*" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="05-MASS-MENSAJES"
        transform="translate(-518.000000, -635.000000)"
        fill="#4A4D5D"
        fillRule="nonzero"
      >
        <g
          id="buttons/transversal/outlined/neutro-copy-8"
          transform="translate(413.000000, 629.000000)"
        >
          <g id="buttons/transversal/outlined/neutro-copy-7">
            <g id="Group">
              <g id="Group-38" transform="translate(16.000000, 6.000000)">
                <g id="botoneliminar" transform="translate(89.000000, 0.000000)">
                  <g id="Group-4-Copy-3">
                    <g id="buttons/transversal/outlined/neutro/cerrar">
                      <g id="cerrar">
                        <path
                          d="M7.5,15 C11.6421356,15 15,11.6421356 15,7.5 C15,3.35786438 11.6421356,0 7.5,0 C3.35786438,0 0,3.35786438 0,7.5 C0,11.6421356 3.35786438,15 7.5,15 Z M7.5,14 C3.91014913,14 1,11.0898509 1,7.5 C1,3.91014913 3.91014913,1 7.5,1 C11.0898509,1 14,3.91014913 14,7.5 C14,11.0898509 11.0898509,14 7.5,14 Z"
                          id="Oval"
                        />
                        <path
                          d="M7.93585932,7.58279238 L10.5937008,10.2394242 C10.6912664,10.3369897 10.6912664,10.4948853 10.5914062,10.5946874 C10.5435463,10.6401543 10.4816185,10.6653125 10.4165625,10.6653125 C10.3501777,10.6653125 10.2874601,10.6404868 10.2394242,10.5924508 L7.58283168,7.93585838 L4.92515618,10.5946874 C4.87729629,10.6401543 4.81536848,10.6653125 4.7503125,10.6653125 C4.68392769,10.6653125 4.62121009,10.6404868 4.57317415,10.5924508 C4.47560862,10.4948853 4.47560862,10.3369897 4.57317415,10.2394242 L7.2297858,7.5828125 L4.57317415,4.92620085 C4.47560862,4.82863531 4.47560862,4.67073969 4.57317415,4.57317415 C4.67073969,4.47560862 4.82863531,4.47560862 4.92620085,4.57317415 L7.5828125,7.2297858 L10.2394242,4.57317415 C10.3369897,4.47560862 10.4948853,4.47560862 10.5924508,4.57317415 C10.6900164,4.67073969 10.6900164,4.82863531 10.5924508,4.92620085 L7.93585932,7.58279238 Z M10.4159117,10.4153851 C10.4159351,10.4153741 10.2722923,10.2717313 9.98498335,9.98445665 L10.4159117,10.4153851 Z M4.75085608,10.4152956 C4.75091152,10.4153158 4.89470007,10.2715273 5.18222174,9.98392996 L4.75085608,10.4152956 Z"
                          id="Shape"
                          stroke="#4A4D5D"
                          strokeWidth="0.833333333"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default DeleteButtonSmall;
