import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@material-ui/core';

import DocumentList from '../../components/DocumentList';
import WorkProgress from '../../components/WorkProgress';
import CalendarCard from '../../components/CalendarCard';

import { GlobalContext } from '../../providers/GlobalStateProvider';

import { HandleCalendar, HandleDocuments, HandleMilestones } from '../../hooks';

import { capitalizeEveryWord } from '../../utils/utils';
import { useSolicitudPorUnidad, useRequerimientosPorSolicitud } from '../../customHooks/postventa';

// Services
import {
  getCalendar,
  getOnlyProjectsByUser,
  getOnlyPropertyByProject,
} from '../../services/projectsService';

import './ClientPropertyDetail.scss';
import { useIslogged } from '../../customHooks/useIslogged';
import DocumentListExec from '../../components/DocumentList/DocumentListExec';
import { useClientDocuments } from '../../customHooks/documents';
import { getActasbyProducto } from '../../services/postventaService';
import { ImagenActa } from '../../constants/endpoints';
import DocumentListPostVenta from '../../components/DocumentList/DocumentListPV';
import useCurrentProject from '../../hooks/projectHooks';

const ClientPropertyDetail = ({
  availableSubsidiaries,
  match: {
    params: { subsidiary, projectId, propertyId },
  },
  history,
}) => {
  if (!useIslogged()) history.push(`/${subsidiary}`);
  const { state: { username = '' } = {} } = useContext(GlobalContext);
  const { selectedProject, selectedProperty } = useCurrentProject(username, projectId, propertyId);
  const { milestones } = HandleMilestones(projectId);
  const { documents } = HandleDocuments(username, projectId, propertyId);
  // const { images } = HandleDocumentsByProject(username, projectId);
  const { calendarList, setCalendarList, onChangeFilter, filterCalendar } = HandleCalendar(
    propertyId,
    projectId,
    username,
    new Date(),
  );
  const { clientDocuments, projectDocuments } = useClientDocuments(projectId, username);

  const updateCalendarList = () => {
    getCalendar(
      {
        idPropiedad: propertyId,
        idProyecto: projectId,
        idCliente: username,
        filterDate: new Date(filterCalendar),
      },
      ({ data: { data } }) => {
        setCalendarList([...data]);
      },
      err => {
        console.error(err);
      },
    );
  };
  const availableAfterSalesSubsidiaries = process.env.REACT_APP_AVAILABLE_AFTER_SALES_SUBSIDIARIES.split(
    ',',
  );
  const isSubsidiaryAfterSaleAvailable = availableAfterSalesSubsidiaries.includes(
    subsidiary.toLowerCase(),
  );

  const [actas, setActas] = useState([]);

  const { solicitudes } = useSolicitudPorUnidad(selectedProperty.id_PropiedadPV, undefined);
  useEffect(() => {
    if (projectId && propertyId) {
      getActasbyProducto(
        propertyId,
        ({ data }) => {
          setActas(
            data.map(acta => {
              const { revisionFechaInicio } = acta;
              return {
                name: acta.revisionActaBlobNombre,
                lastModified: `${revisionFechaInicio
                  .split(' ')[0]
                  .split('-')
                  .reverse()
                  .join('-')} ${revisionFechaInicio.split(' ')[1]}`,
                etag: acta.revisionActaId,
                link: `${ImagenActa}/${propertyId}/${acta.revisionActaId}`,
                label: acta.revisionActaBlobNombre.trim(),
              };
            }),
          );
        },
        err => {
          console.error(err);
        },
      );
    }
  }, [projectId, propertyId]);

  return (
    <div className="ClientPropertyDetail">
      <Typography
        variant="h5"
        className={`ClientPropertyDetail__ProjectName ClientPropertyDetail__ProjectName--${subsidiary}`}
      >
        {capitalizeEveryWord(selectedProject.nombre_Proyecto)}
      </Typography>
      <span
        className={`ClientPropertyDetail__greeting ClientPropertyDetail__greeting--${subsidiary}`}
      >
        {`Propiedad ${selectedProperty.numero_Departamento}`}
      </span>
      <div className={`ClientPropertyDetail__detail ClientPropertyDetail__detail--${subsidiary}`}>
        <div className="ClientPropertyDetail__detail__item">
          <span className="ClientPropertyDetail__detail__item__name">Tipo Unidad:</span>
          <span>{selectedProperty.tipo_Unidad}</span>
        </div>
        <div className="ClientPropertyDetail__detail__item">
          <span className="ClientPropertyDetail__detail__item__name">Etapa:</span>
          <span>{selectedProperty.estado_Cliente}</span>
        </div>
        <div className="ClientPropertyDetail__detail__item">
          <span className="ClientPropertyDetail__detail__item__name">Direccion:</span>
          <span>{capitalizeEveryWord(selectedProject.direccion_Proyecto)}</span>
        </div>
        <div className="ClientPropertyDetail__detail__item">
          <span className="ClientPropertyDetail__detail__item__name">Comuna:</span>
          <span>{capitalizeEveryWord(selectedProject.comuna)}</span>
        </div>
        <div className="ClientPropertyDetail__detail__item">
          <span className="ClientPropertyDetail__detail__item__name">Metros Cuadrados:</span>
          <span>{selectedProperty.metros_Cuadrados}</span>
        </div>
        <div className="ClientPropertyDetail__detail__item">
          <span className="ClientPropertyDetail__detail__item__name">Numero Dormitorios:</span>
          <span>{selectedProperty.n_Dormitorios}</span>
        </div>
        <div className="ClientPropertyDetail__detail__item">
          <span className="ClientPropertyDetail__detail__item__name">Numero Baños:</span>
          <span>{selectedProperty.n_Banos}</span>
        </div>
      </div>
      {documents.length > 0 && (
        <div className="Home__body__section">
          <div className="Home__body__section__container">
            <div className="Home__body__section__container__header">
              <h5
                className={`Home__body__section__container__header__name Home__body__section__container__header__name--${subsidiary}`}
              >
                MIS DOCUMENTOS FIRMADOS
              </h5>
            </div>
            <div className="Home__body__section__container__list">
              <div
                className={`Home__body__section__container__list__item Home__body__section__container__list__item--${subsidiary}`}
              >
                <DocumentList fileList={documents} subsidiary={subsidiary} />
              </div>
            </div>
          </div>
        </div>
      )}
      {availableSubsidiaries &&
        availableSubsidiaries.documentsPV &&
        solicitudes &&
        solicitudes.length > 0 && (
        <div className="Home__body__section">
          <div className="Home__body__section__container">
            <div className="Home__body__section__container__header">
              <h5
                className={`Home__body__section__container__header__name Home__body__section__container__header__name--${subsidiary}`}
              >
                MIS DOCUMENTOS DE POSTVENTA
              </h5>
            </div>
            <div className="Home__body__section__container__list">
              <div
                className={`Home__body__section__container__list__item Home__body__section__container__list__item--${subsidiary}`}
              >
                {<DocumentListPostVenta requirementList={solicitudes} subsidiary={subsidiary} />}
              </div>
            </div>
          </div>
        </div>
      )}
      {isSubsidiaryAfterSaleAvailable && actas && actas.length > 0 && (
        <div className="Home__body__section">
          <div className="Home__body__section__container">
            <div className="Home__body__section__container__header">
              <h5
                className={`Home__body__section__container__header__name Home__body__section__container__header__name--${subsidiary}`}
              >
                MIS ACTAS DE ENTREGA
              </h5>
            </div>
            <div className="Home__body__section__container__list">
              <div
                className={`Home__body__section__container__list__item Home__body__section__container__list__item--${subsidiary}`}
              >
                <DocumentListExec fileList={actas} subsidiary={subsidiary} />
              </div>
            </div>
          </div>
        </div>
      )}
      {clientDocuments.length > 0 && (
        <div className="Home__body__section">
          <div className="Home__body__section__container">
            <div className="Home__body__section__container__header">
              <h5
                className={`Home__body__section__container__header__name Home__body__section__container__header__name--${subsidiary}`}
              >
                MIS DOCUMENTOS DEL EJECUTIVO
              </h5>
            </div>
            <div className="Home__body__section__container__list">
              <div
                className={`Home__body__section__container__list__item Home__body__section__container__list__item--${subsidiary}`}
              >
                <DocumentListExec fileList={clientDocuments} subsidiary={subsidiary} />
              </div>
            </div>
          </div>
        </div>
      )}
      {projectDocuments.length > 0 && (
        <div className="Home__body__section">
          <div className="Home__body__section__container">
            <div className="Home__body__section__container__header">
              <h5
                className={`Home__body__section__container__header__name Home__body__section__container__header__name--${subsidiary}`}
              >
                MIS DOCUMENTOS DEL PROYECTO
              </h5>
            </div>
            <div className="Home__body__section__container__list">
              <div
                className={`Home__body__section__container__list__item Home__body__section__container__list__item--${subsidiary}`}
              >
                <DocumentListExec fileList={projectDocuments} subsidiary={subsidiary} />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="Home__body__section">
        {milestones && milestones.fases && milestones.fases.length > 0 ? (
          <div className="Home__body__section__container">
            <>
              <div className="Home__body__section__container__header">
                <h5
                  className={`Home__body__section__container__header__name Home__body__section__container__header__name--${subsidiary}`}
                >
                  AVANCES DE OBRA
                </h5>
              </div>
              <div className="Home__body__section__container__list">
                <div
                  className={`Home__body__section__container__list__item Home__body__section__container__list__item--${subsidiary}`}
                >
                  <WorkProgress subsidiary={subsidiary} milestones={milestones} />
                </div>
              </div>
            </>
          </div>
        ) : null}
        <div className="Home__body__section__container">
          <div className="Home__body__section__container__header">
            <h5
              className={`Home__body__section__container__header__name Home__body__section__container__header__name--${subsidiary}`}
            >
              MI CALENDARIO
            </h5>
          </div>
          <div className="Home__body__section__container__list">
            <div
              className={`Home__body__section__container__list__item Home__body__section__container__list__item--${subsidiary}`}
            >
              <CalendarCard
                calendarList={calendarList}
                subsidiary={subsidiary}
                updateCalendarList={updateCalendarList}
                onShownDateChange={onChangeFilter}
                shownDate={filterCalendar}
              />
            </div>
          </div>
        </div>
      </div>
      <div />
      <div className={`Home__body__rectangle Home__body__rectangle--${subsidiary}`} />
    </div>
  );
};

ClientPropertyDetail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      subsidiary: PropTypes.string,
      projectId: PropTypes.string,
      propertyId: PropTypes.string,
    }),
  }),
};

export default ClientPropertyDetail;
