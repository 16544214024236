import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Edit } from '@material-ui/icons';
import * as Yup from 'yup';
import { Grid, TextField, Button } from '@material-ui/core';
import { withFormik, Form } from 'formik';
import ExecutiveFieldForm from './ExecutiveFieldForm';

const styles = () => ({
  inputLabel: {
    fontSize: '9px',
  },
  icon: {
    color: '#bebfc5',
  },
  input: {
    fontSize: '16px',
    paddingBottom: '10px',
  },
  contentInput: {
    marginBottom: '45px',
    '& .MuiFormHelperText-root.Mui-error': {
      fontSize: '10px',
      marginTop: '2px',
    },
  },
  formControl: {
    marginTop: '5px !important',
    // marginBottom: '45px',
    '&:after': {
      borderBottom: '2px solid #0093ca',
    },
    '&:hover:not($disabled):not($focused):not($error):before': {
      borderBottom: '2px solid #0093ca !important',
    },
  },
  item: {
    padding: '0 15px !important',
  },
  container: {
    width: 'calc(100% + 30px)',
    margin: '0 auto',
    marginLeft: '-15px',
  },
  focused: {
    color: '#0093ca !important',
  },
  center: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    '& button': {
      width: '180px',
      borderRadius: '18px',
      '&:first-of-type': {
        marginRight: '30px',
      },
    },
  },
  containerPassword: {
    padding: '15px 0 !important',
  },
});

const onKeyDown = event => {
  const keyCode = event.keyCode || event.which;
  const keyValue = String.fromCharCode(keyCode);
  if (!/^[0-9\b]+$/.test(keyValue)) event.preventDefault();
};

const ExecutiveSchema = Yup.object().shape({
  whatsapp: Yup.string()
    .label('Teléfono')
    .required('Debes ingresar un teléfono')
    .length(10, 'Debes ingresar un teléfono válido'),
  descripcion: Yup.string()
    .label('Descripción')
    .required('Debes ingresar una descripción válida')
    .max(500, 'Debes ingresar una descripción con máximo 500 caracteres'),
});

const items = [
  {
    label: 'nombres',
    name: 'nombres',
    value: 'nombres',
    disabled: true,
    icon: false,
  },
  {
    label: 'apellidos',
    name: 'apellidos',
    value: 'apellidos',
    disabled: true,
    icon: false,
  },
  {
    label: 'jefatura',
    name: 'jefatura',
    value: 'jefatura',
    disabled: true,
    icon: false,
  },
  {
    label: 'cargo',
    name: 'cargo',
    value: 'cargo',
    disabled: true,
    icon: false,
  },
  {
    label: 'teléfono',
    name: 'whatsapp',
    value: 'whatsapp',
    onKeyDown,
    disabled: false,
    icon: true,
  },
  {
    label: 'email',
    name: 'email',
    value: 'mail',
    disabled: true,
    icon: false,
  },
];

const ExecutiveForm = ({
  classes,
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  errors,
  touched,
  goBack,
}) => {
  return (
    <Form onSubmit={handleSubmit} autoComplete="off">
      <Grid container spacing={3} className={classes.container}>
        {items.map(item => (
          <Grid item xs={6} className={classes.item} key={item.name}>
            <ExecutiveFieldForm
              {...item}
              value={values[item.value]}
              onChange={handleChange}
              onBlur={handleBlur}
              name={item.name}
              label={item.label.toUpperCase()}
              error={errors[item.name] && touched[item.name]}
              helperText={errors[item.name] && touched[item.name] ? errors[item.name] : ''}
            />
          </Grid>
        ))}
        <Grid item xs={12} className={classes.item}>
          <TextField
            id="standard-basic"
            label="TEXTO INTRODUCTORIO"
            value={values.descripcion}
            name="descripcion"
            fullWidth
            multiline
            className={classes.contentInput}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.descripcion && touched.descripcion}
            helperText={errors.descripcion && touched.descripcion ? errors.descripcion : ''}
            InputProps={{
              endAdornment: <Edit className={classes.icon} />,
              classes: {
                root: classes.formControl,
                input: classes.input,
              },
            }}
            InputLabelProps={{
              shrink: true,
              classes: {
                root: classes.inputLabel,
                focused: classes.focused,
              },
              style: {
                fontSize: '20px',
                position: 'relative',
              },
            }}
          />
        </Grid>
      </Grid>
      <div className={classes.center}>
        <Button
          color="primary"
          variant="contained"
          type="submit"
          disabled={Object.keys(errors).length > 0}
        >
          Guardar
        </Button>
        <Link to="/empresas/Home/executives/list">
          <Button color="secondary" variant="contained">
            Cancelar
          </Button>
        </Link>
      </div>
    </Form>
  );
};

ExecutiveForm.propTypes = {
  values: PropTypes.object,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  handleSubmit: PropTypes.func,
  classes: PropTypes.object.isRequired,
  errors: PropTypes.shape({
    whatsapp: PropTypes.string,
    descripcion: PropTypes.string,
  }),
  touched: PropTypes.shape({
    whatsapp: PropTypes.bool,
    descripcion: PropTypes.bool,
  }),
  goBack: PropTypes.func,
};

const formikEnhancer = withFormik({
  mapPropsToValues: values => {
    return {
      nombres: '',
      apellidos: '',
      jefatura: '',
      cargo: '',
      whatsapp: '',
      mail: '',
      descripcion: '',
      // password: '',
      // confirmPassword: '',
      ...values.executive,
    };
  },
  validationSchema: ExecutiveSchema,
  handleSubmit: (values, { props: { onSubmit } }) => {
    onSubmit(values);
  },
  enableReinitialize: true,
  validateOnBlur: false,
  isInitialValid: false,
})(ExecutiveForm);

const executiveStyle = withStyles(styles)(formikEnhancer);

export default executiveStyle;
