import React, { createContext, useReducer, useCallback } from 'react';
import PropTypes from 'prop-types';
import { reducer, inicialState } from '../reducers/requestReducer';
import { FETCH_INIT, FETCH_END, FETCH_ERROR, DROP_MESSAGE } from '../actions/actionTypes';

const FetchContext = createContext('fetch');

const FetchStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, inicialState);
  const fetchInit = useCallback(
    request => {
      dispatch({ type: FETCH_INIT, request });
    },
    [dispatch],
  );
  const fetchEnd = useCallback(
    requestUrl => {
      dispatch({ type: FETCH_END, requestUrl });
    },
    [dispatch],
  );
  const fetchError = useCallback(
    (message, requestUrl) => {
      dispatch({ type: FETCH_ERROR, message, requestUrl });
    },
    [dispatch],
  );
  const clearMessage = useCallback(() => {
    dispatch({ type: DROP_MESSAGE });
  }, []);
  return (
    <FetchContext.Provider
      value={{ state, dispatch, fetchInit, fetchEnd, fetchError, clearMessage }}
    >
      {children}
    </FetchContext.Provider>
  );
};

FetchStateProvider.propTypes = {
  reducer: PropTypes.func,
  initialState: PropTypes.object,
  children: PropTypes.node,
};

export { FetchStateProvider, FetchContext };
