import { isEqual, isBefore, isAfter, format } from 'date-fns';
import es from 'date-fns/locale/es';
import {
  socovesa,
  almagro,
  pilares,
  socovesaCode,
  almagroCode,
  pilaresCode,
} from '../constants/filialesNames';

export function calculateDV(rut) {
  const cuerpo = `${rut}`;
  // Calcular Dígito Verificador
  let suma = 0;
  let multiplo = 2;

  // Para cada dígito del Cuerpo
  for (let i = 1; i <= cuerpo.length; i++) {
    // Obtener su Producto con el Múltiplo Correspondiente
    const index = multiplo * cuerpo.charAt(cuerpo.length - i);

    // Sumar al Contador General
    suma += index;

    // Consolidar Múltiplo dentro del rango [2,7]
    if (multiplo < 7) {
      multiplo += 1;
    } else {
      multiplo = 2;
    }
  }

  // Calcular Dígito Verificador en base al Módulo 11
  const dvEsperado = 11 - (suma % 11);
  if (dvEsperado === 10) return 'k';
  if (dvEsperado === 11) return '0';
  return `${dvEsperado}`;
}

export function rutEsValido(rut) {
  if (!rut || rut.trim().length < 3) return false;
  const rutLimpio = rut.replace(/[^0-9kK-]/g, '');

  if (rutLimpio.length < 3) return false;

  const split = rutLimpio.split('-');
  if (split.length !== 2) return false;

  const num = parseInt(split[0], 10);
  const dgv = split[1];

  const dvCalc = calculateDV(num);
  return dvCalc.toUpperCase() === dgv.toUpperCase();
}

export function formatRut(rut) {
  const actual = rut.replace(/^0+/, '');
  let rutPuntos = '';
  if (actual !== '' && actual.length > 1) {
    const sinPuntos = actual.replace(/\./g, '');
    const actualLimpio = sinPuntos.replace(/-/g, '');
    const inicio = actualLimpio.substring(0, actualLimpio.length - 1);
    let i = 0;
    for (i = inicio.length - 1; i >= 0; i--) {
      const letra = inicio.charAt(i);
      rutPuntos = letra + rutPuntos;
    }
    const dv = actualLimpio.substring(actualLimpio.length - 1);
    rutPuntos = `${rutPuntos}-${dv}`;
  }
  return rutPuntos !== '' ? rutPuntos : rut;
}

export function firstLetterUppercase(string = '') {
  if (typeof string !== 'string') return '';
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export function capitalizeEveryWord(string = '') {
  return string
    .toLowerCase()
    .split(' ')
    .map(s => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');
}

export const isBetween = (date, from, to, inclusivity = '()') => {
  if (!['()', '[]', '(]', '[)'].includes(inclusivity)) {
    throw new Error('Inclusivity parameter must be one of (), [], (], [)');
  }

  const isBeforeEqual = inclusivity[0] === '[';
  const isAfterEqual = inclusivity[1] === ']';

  return (
    (isBeforeEqual ? isEqual(from, date) || isBefore(from, date) : isBefore(from, date)) &&
    (isAfterEqual ? isEqual(to, date) || isAfter(to, date) : isAfter(to, date))
  );
};

export function checkIfFunction(functionToCheck) {
  return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}

export function subsidiaryCodeToText(code) {
  switch (code) {
    case socovesaCode:
      return socovesa;
    case almagroCode:
      return almagro;
    case pilaresCode:
      return pilares;
    default:
      return undefined;
  }
}

export function formatDate(date, unit = 'PPPP') {
  const formatedDate = new Date(date);
  // eslint-disable-next-line no-restricted-globals
  if (Object.prototype.toString.call(formatedDate) === '[object Date]' && !isNaN(formatedDate)) {
    return format(formatedDate, unit, { locale: es });
  }
  return '';
}

export function genCharArray(charA, charZ) {
  const a = [];
  let i = charA.charCodeAt(0);
  const j = charZ.charCodeAt(0);
  for (; i <= j; ++i) {
    a.push(String.fromCharCode(i));
  }
  return a;
}

export function checkIfRutHasDash(rut) {
  return /-\d$/.test(rut) ? rut.substring(0, rut.length - 2) : rut;
}

export function downloadBlob(file) {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style = 'display: none';
  return function generateBlob(data, fileName) {
    const blob = new Blob([file], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };
}

export function openDocOnNewTab(url) {
  const win = window.open(url, '_blank');
  win.focus();
}

export function openPromiseOnNewTab(idNegocio) {

  let url = '';
  const devUrl = `${process.env.REACT_APP_SERVER_URL}/propiedades/promesa/${idNegocio}`;
  const prodUrl = `${process.env.REACT_APP_PROD_SERVER_URL}/propiedades/promesa/${idNegocio}`;

  if (window.location.href.includes('brazilsouth')){
    url = prodUrl;
  }else{
    url = devUrl;
  }
  url = idNegocio;
  const win = window.open(url, '_blank');
  win.focus();
}

export function parseDocumentName(name) {
  if (name) {
    const separateName = name.split('/');
    const parsedName = separateName[1].split('-');
    return parsedName.slice(0, -3).join('-');
  }
  return '';
}
