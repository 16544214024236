import React from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useIslogged } from '../../customHooks/useIslogged';
import ProjectListPostVenta from '../../components/ProjectListPostVenta';
import { colorConfig } from '../../constants/colors';

const ClientPostVenta = ({
  history,
  match: {
    params: { subsidiary },
  },
  propiedadesPV,
  additional,
  admin,
}) => {
  if (!useIslogged()) history.push(`/${subsidiary}`);
  if (Object.keys(propiedadesPV).length === 0) return null;

  if (additional) {
    return propiedadesPV.owners.map(ownerProperties => (
      <div style={{ paddingBottom: '0.75rem', paddingTop: '4.5rem' }}>
        <Typography
          variant="h5"
          component="h2"
          style={{ color: colorConfig[subsidiary].headerTexts, paddingBottom: '0.25rem' , paddingLeft: '0.25rem'}}
        >
          {`${ownerProperties.filiales[0].filial}: Propietario ${ownerProperties.ownerFullName}`}
        </Typography>
        <ProjectListPostVenta propiedadesPV={ownerProperties.filiales} subsidiary={subsidiary} additional={additional} admin={admin} />
      </div>
    ));
  }
  return <ProjectListPostVenta propiedadesPV={propiedadesPV} subsidiary={subsidiary} additional={additional} admin={admin} />;
};

ClientPostVenta.propTypes = {
  history: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.shape({
      subsidiary: PropTypes.string,
    }),
  }),
  propiedadesPV: PropTypes.array,
  additional: PropTypes.bool,
  admin: PropTypes.bool,
};

export default ClientPostVenta;
