import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Button from '../ButtonTypes';
import FileIcon from '../Icons/FileIcon';
import ClearIcon from '../Icons/ClearIcon';
import ArrowDownIcon from '../Icons/ArrowDownIcon';

import { empresas } from '../../constants/filialesNames';
import { formatDate, parseDocumentName, openPromiseOnNewTab } from '../../utils/utils';

import './DocumentItem.scss';
import CheckBox from '../../components/CheckboxTypes';

const DeleteButton = ({ onClick, className }) => (
  <Button onClick={onClick} variant="text" className={className}>
    <span className="DeleteButton">
      <ClearIcon color="currentColor" className="DeleteButton__container" />
      <span className="DeleteButton__label">eliminar</span>
    </span>
  </Button>
);

DeleteButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
};

const DocumentItemPromise = ({ subsidiary, name, idNegocio, depto, onChecked, checked, link, fecha }) => {
  return (
    <div className={`DocumentItem DocumentItem--${subsidiary}`}>
      {subsidiary === 'empresas' && !!onChecked && (
        <CheckBox checked={checked} onClick={onChecked} key={depto} />
      )}
      <div className={`DocumentItem__simbol DocumentItem__simbol--${subsidiary}`}>
        <FileIcon className="DocumentItem__simbol__icon" color="currentColor" />
      </div>
      <div className={`DocumentItem__container DocumentItem__container--${subsidiary}`}>
        <a
          className="DocumentItem__container__name DocumentItem__container__name--pointer"
          rel="noopener noreferrer"
          // target="_blank"
          // onClick={() => openPromiseOnNewTab(idNegocio)}
          href={link}
        >
          <div className="DocumentItem__nameDateItem">
            <span className="DocumentItem__nameDateItem__date">
              {/* documento publicado {formatDate(new Date(), 'dd/MM/yyyy')} */}
              documento publicado {fecha}
            </span>
            <span className="DocumentItem__nameDateItem__docname">{name?.indexOf('-') > -1 ? parseDocumentName(name) : name} - {depto}</span>
            <span className="DocumentItem__nameDateItem__lastModifiedDate">
              {formatDate(new Date(), 'dd/MM/yyyy')}
            </span>
          </div>
        </a>
        <div className="DocumentItem__container__buttons">
          {subsidiary !== empresas && (
            <Fragment>
              <Button variant="text" onClick={() => window.open(link, '_blank')}>
                <ArrowDownIcon
                  className={`DocumentItem__container__icon DocumentItem__container__icon--${subsidiary}`}
                  color="currentColor"
                />
              </Button>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

DocumentItemPromise.propTypes = {
  subsidiary: PropTypes.string,
  name: PropTypes.string,
  idNegocio: PropTypes.number,
  depto: PropTypes.string,
};

export default DocumentItemPromise;
