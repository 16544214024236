import { UPDATE_PROJECTLIST, SELECT_PROJECTLIST } from '../actions/actionTypes';

export const inicialState = {
  projectList: [],
  activeProject: '',
};

export const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_PROJECTLIST:
      return {
        ...state,
        projectList: [...action.projectList],
      };
    case SELECT_PROJECTLIST:
      return {
        ...state,
        activeProject: action.activeProject,
      };
    default:
      return {
        ...state,
      };
  }
};
