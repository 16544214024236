import React from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Form, withFormik } from 'formik';

import TextFields from '../TextFields';

export const NoteSchema = Yup.object().shape({
  title: Yup.string().required('Por favor Ingrese un titulo'),
  text: Yup.string().required('Por favor Ingrese algun texto'),
});

const NewNoteForm = ({
  errors,
  touched,
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  children,
}) => (
  <Form onSubmit={handleSubmit}>
    <TextFields
      name="title"
      value={values.title}
      onChange={handleChange}
      onBlur={handleBlur}
      error={errors.title && touched.title}
      valid={touched.title && !errors.title && values.title !== ''}
      helperText={errors.title}
      label="TITULO:"
    />
    <TextFields
      name="text"
      value={values.text}
      onChange={handleChange}
      onBlur={handleBlur}
      error={errors.text && touched.text}
      valid={touched.text && !errors.text && values.text !== ''}
      helperText={errors.text}
      label="MENSAJE:"
      multiline
      rows={8}
    />
    {children}
  </Form>
);

NewNoteForm.propTypes = {
  values: PropTypes.shape({
    text: PropTypes.string,
    title: PropTypes.string,
  }),
  errors: PropTypes.shape({
    text: PropTypes.string,
    title: PropTypes.string,
  }),
  touched: PropTypes.shape({
    text: PropTypes.bool,
    title: PropTypes.bool,
  }),
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  handleSubmit: PropTypes.func,
  children: PropTypes.node,
};

export default withFormik({
  mapPropsToValues: ({ editNote, selectedNote: { title, text } }) => {
    return {
      title: editNote ? title : '',
      text: editNote ? text : '',
    };
  },
  enableReinitialize: true,
  validateOnBlur: false,
  isInitialValid: false,
  validationSchema: NoteSchema,
  handleSubmit: (
    { title, text },
    {
      props: {
        onSubmit,
        editNote,
        selectedNote: { noteId },
        EditSubmitCallback,
      },
    },
  ) => {
    if (editNote) {
      onSubmit(noteId, title, text);
      EditSubmitCallback();
    } else {
      onSubmit(title, text);
      EditSubmitCallback();
    }
  },
})(NewNoteForm);
