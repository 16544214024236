import React, {useEffect, useState} from 'react';
import { colorConfig } from '../../constants/colors';


const PruebaAPI = () => {
  const host = window.location.host.split('.');
  const filial = host.length > 2 ? host[1] : 'empresassocovesa';
  const [subsidiary, setSubsidiary] = useState(filial === 'empresassocovesa' ? 'empresas' : filial);

  const [url, setUrl] = useState('');
  const [gateway, setGateway] = useState(true);
  const [token, setToken] = useState('');
  const [user, setUser] = useState({});
  const [timeToken, setTime] = useState({});

  const [delegado, setDelegado] = useState(2);
  const [unidadesDelegado, setUnidadesDelegado] = useState([]);
  const [timeUnidades, setTimeUnidades] = useState({});

  const [unidadCodSAP, setUnidadCodSAP] = useState('2118D10201');
  const [unidad, setUnidad] = useState();
  const [timeUnidad, setTimeUnidad] = useState({});

  const [tipoSolicitud, setTipoSolicitud] = useState(2);
  const [retAddSolicitud, setRetAddSolicitud] = useState();
  const [timeAddSolicitud, setTimeAddSolicitud] = useState({});

  const [error, setError] = useState();
  const [step, setStep] = useState('');

  useEffect(() => {
    if (gateway) {
      setUrl(process.env.REACT_APP_SERVER_URL);
    } else {
      setUrl(`https://pvweb-des.brazilsouth.cloudapp.azure.com`);
    }
  }, [gateway]);

  const hadleClick = (idBoton) => {
    const headers = new Headers();
    headers.append('Accept', '*/*');
    if (token && token !== '') {
      headers.append('Authorization', `Bearer ${token}`);
    }
    if (idBoton !== 'btn1') {
      headers.append('Content-Type', 'application/json');
    }
    if (idBoton === 'btn1') {
      setToken('');
      setTime({});
      setUser({});
      setUnidadesDelegado([]);
      setUnidad();
      setTimeUnidad({});
      setTimeUnidades({});
      setRetAddSolicitud();
      setTimeAddSolicitud({});
      setStep('Get Token');
      setError();
      const start = new Date();
      let urlencoded;
      if (gateway) {
        headers.append('Content-Type', 'application/json');
        urlencoded = JSON.stringify({
          username: 'SocoApiDev',
          password: 'Socovesa.qwer.1234',
        });
      } else {
        urlencoded = new URLSearchParams();
        urlencoded.append('client_id', '351ce40c983c4fcdb20a600650a7ad0d');
        urlencoded.append('grant_type', 'password');
        urlencoded.append('scope', 'FullControl');
        urlencoded.append('username', 'SocoApiDev');
        urlencoded.append('password',  'Socovesa.qwer.1234');
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
      }
      fetch(`${url}/oauth/access_token`, {
        method: 'POST',
        headers: headers,
        body: urlencoded,
      }).then(response => response.json())
      .then(data => {
        setToken(data.access_token);
        setUser(data);
        setTime(data.time);
      })
      .catch(error => {
        console.error({error});
        setError(error);
      });
      if (!gateway) {
        setTime({
          start: start.toLocaleTimeString(),
          end: new Date().toLocaleTimeString(),
          duration: new Date().getTime() - start.getTime()
        });
      }
    }
    if (idBoton === 'btn2') {
      setUnidadesDelegado([]);
      setTimeUnidades({});
      setError();
      setStep('Get Unidades Delegado');
      const deleg = parseInt(prompt('Delegado:', delegado));
      if (deleg !== null && String(deleg) !== 'NaN') {
        setDelegado(deleg);
        const start = new Date();
        fetch(`${url}/API/Unidades/GetUnidadesDelegado?Delegadoid=${deleg}`, {
          method: 'GET',
          headers: headers,
        }).then(response => response.json())
        .then(data => {
          if (gateway) {
            if (data.error) {
              setError(data.error);
            } else {
              setUnidadesDelegado(data.unidades);
              setTimeUnidades(data.time);
            }
          } else {
            setUnidadesDelegado(data);
          }
        });
        if (!gateway) {
          setTimeUnidades({
            start: start.toLocaleTimeString(),
            end: new Date().toLocaleTimeString(),
            duration: new Date().getTime() - start.getTime()
          });
        }
      }
    }
    if (idBoton === 'btn3') {
      setUnidad(undefined);
      setTimeUnidad({});
      setError();
      setStep('Get Datos Unidad');
      const unidad = prompt('Codigo SAP Unidad:', unidadCodSAP);
      if (unidad !== null && String(unidad) !== 'NaN') {
        setUnidadCodSAP(unidad);
        const start = new Date();
        fetch(`${url}/API/Unidades/GetDatosUnidad?Unidadcodigosap=${unidad}`, {
          method: 'GET',
          headers: headers,
        }).then(response => response.json())
        .then(data => {
          if (data.error) {
            setError(data.error);
          } else {
            if (gateway) {
              setUnidad(data.unidad);
              setTimeUnidad(data.time);
            } else {
              setTimeUnidad({
                start: start.toLocaleTimeString(),
                end: new Date().toLocaleTimeString(),
                duration: new Date().getTime() - start.getTime()
              });
              setUnidad(data);
            }
          }
        });
      }
    }
    if (idBoton === 'btn4') {
      setRetAddSolicitud(undefined);
      setTimeAddSolicitud({});
      setError();
      setStep('Agregar Solicitud');
      const tipo = parseInt(prompt('Tipo Solicitud:', tipoSolicitud));
      if (tipo !== null && String(tipo) !== 'NaN') {
        setTipoSolicitud(tipo);
        const start = new Date();
        const body = {
          SDT_Solicitud: {
            DelegadoId: parseInt(delegado),
            UnidadCodigoSAP: unidadCodSAP,
            TipoSolicitudCodigo: tipo,
          }
        }
        fetch(`${url}/API/Solicitudes/AgregarSolicitud`, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(body),
        }).then(response => response.json())
        .then(data => {
          if (data.error) {
            setError(data.error);
          } else {
            if (gateway) {
              setRetAddSolicitud(data.solicitud);
              setTimeAddSolicitud(data.time);
            } else {
              setTimeAddSolicitud({
                start: start.toLocaleTimeString(),
                end: new Date().toLocaleTimeString(),
                duration: new Date().getTime() - start.getTime()
              });
              setRetAddSolicitud(data);
            }
          }
        });
      }
    }
  }

  return (
    <div style={{color: colorConfig[subsidiary].secondary, display:'flex', justifyContent: 'center'}}>
      <div style={{display: 'flex', gap: '20px', flexDirection: 'column', alignItems: 'center', maxWidth: '950px'}}>
        <div style={{display: 'flex', width: '350px', justifyContent: 'space-between'}}>
          <strong>Prueba API</strong>
          <div><input type='checkbox' id='gateway' onChange={(e) => setGateway(e.target.checked)} checked={gateway}/>Backend Gateway</div>
        </div>
        <input type='text' value={url} onChange={(e) => setUrl(e.target.value)} style={{width: '400px', color: colorConfig[subsidiary].secondary}} />
        {error && <div style={{color: '#D8000C', backgroundColor: '#FFBABA'}}><span>ERROR en </span><strong>{step}: </strong><br /><p>{JSON.stringify(error)}</p></div>}
        <button onClick={() => hadleClick('btn1')} style={{width: '180px', color: colorConfig[subsidiary].terciary, backgroundColor: colorConfig[subsidiary].secondary, borderColor: colorConfig[subsidiary].primary}}>Get Token</button>
        {token && user && timeToken && (
          <div style={{width: '950px'}}>
            <div><strong>access_token: </strong><span>{token}</span></div>
            <div><strong>token_type: </strong><span>{user.token_type}</span></div>
            <div><strong>expires_in: </strong><span>{user.expires_in}</span></div>
            <div><strong>refresh_token: </strong><span>{user.refresh_token}</span></div>
            <div><strong>scope: </strong><span>{user.scope}</span></div>
            <div><strong>user_guid: </strong><span>{user.user_guid}</span></div>
            <br />
            <div><strong>start: </strong><span>{timeToken.start}</span></div>
            <div><strong>end: </strong><span>{timeToken.end}</span></div>
            <div><strong>duration: </strong><span>{timeToken.duration} ms</span></div>
          </div>
        )}
        <button onClick={() => hadleClick('btn2')} disabled={!token} style={{width: '180px', color: colorConfig[subsidiary].terciary, backgroundColor: colorConfig[subsidiary].secondary, borderColor: colorConfig[subsidiary].primary}}>Get Unidades Delegado</button>
        {unidadesDelegado && unidadesDelegado.length > 0 && (
          <div style={{width: '950px'}}>
            <div><strong>delegado: </strong><span>{delegado}</span></div>
            <table border='1'>
              <thead>
                <tr>
                  <th>FilialID</th>
                  <th>FilialNombre</th>
                  <th>ProyectoNombreSCI</th>
                  <th>EtapaNombre</th>
                  <th>UnidadId</th>
                  <th>UnidadNombre</th>
                  <th>UnidadCodigoSAP</th>
                </tr>
              </thead>
              <tbody>
                {unidadesDelegado.map(({FilialID, FilialNombre, ProyectoNombreSCI, EtapaNombre, UnidadId, UnidadNombre, UnidadCodigoSAP}) => (
                  <tr key={UnidadId}>
                    <td>{FilialID}</td>
                    <td>{FilialNombre}</td>
                    <td>{ProyectoNombreSCI}</td>
                    <td>{EtapaNombre}</td>
                    <td>{UnidadId}</td>
                    <td>{UnidadNombre}</td>
                    <td>{UnidadCodigoSAP}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div><strong>start: </strong><span>{timeUnidades.start}</span></div>
            <div><strong>end: </strong><span>{timeUnidades.end}</span></div>
            <div><strong>duration: </strong><span>{timeUnidades.duration} ms</span></div>
          </div>
        )}
        <button onClick={() => hadleClick('btn3')} disabled={!token} style={{width: '180px', color: colorConfig[subsidiary].terciary, backgroundColor: colorConfig[subsidiary].secondary, borderColor: colorConfig[subsidiary].primary}}>Get Datos Unidad</button>
        {unidad && (
          <div style={{width: '950px'}}>
            <div><strong>unidadCodSAP: </strong><span>{unidadCodSAP}</span></div>
            <br />
            <div><strong>UnidadID: </strong><span>{unidad.UnidadID}</span></div>
            <div><strong>UnidadNombre: </strong><span>{unidad.UnidadNombre}</span></div>
            <div><strong>ProyectoID: </strong><span>{unidad.ProyectoID}</span></div>
            <div><strong>Proyecto: </strong><span>{unidad.Proyecto}</span></div>
            <div><strong>Etapa: </strong><span>{unidad.Etapa}</span></div>
            <div><strong>Direccion: </strong><span>{unidad.Direccion}</span></div>
            <div><strong>Fecha_Rec_Municipal: </strong><span>{unidad.Fecha_Rec_Municipal}</span></div>
            <div><strong>Fecha_Entrega_Cliente: </strong><span>{unidad.Fecha_Entrega_Cliente}</span></div>
            <br />
            <div><strong>start: </strong><span>{timeUnidad.start}</span></div>
            <div><strong>end: </strong><span>{timeUnidad.end}</span></div>
            <div><strong>duration: </strong><span>{timeUnidad.duration} ms</span></div>
          </div>
        )}
        <button onClick={() => hadleClick('btn4')} disabled={!token} style={{width: '180px', color: colorConfig[subsidiary].terciary, backgroundColor: colorConfig[subsidiary].secondary, borderColor: colorConfig[subsidiary].primary}}>Agregar Solicitud</button>
        {retAddSolicitud && (
          <div style={{width: '950px'}}>
            <div><strong>SolicitudID: </strong><span>{retAddSolicitud.SolicitudId}</span></div>
            <h3>Messages: </h3>
            <ul>
              {retAddSolicitud.Messages && retAddSolicitud.Messages.map((Message) => (
                <>
                  <li><strong>Id: </strong><span>{Message.Id}</span></li>
                  <li><strong>Type: </strong><span>{Message.Type}</span></li>
                  <li><strong>Description: </strong><span>{Message.Description}</span></li>
                </>
              ))}
            </ul>
            <div><strong>start: </strong><span>{timeAddSolicitud.start}</span></div>
            <div><strong>end: </strong><span>{timeAddSolicitud.end}</span></div>
            <div><strong>duration: </strong><span>{timeAddSolicitud.duration} ms</span></div>
          </div>
        )}
      </div>
    </div>
  );
}

export default PruebaAPI;
