import React from 'react';
import PropTypes from 'prop-types';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/styles';

import CustomLabel from './CustomLabel';

import './DynamicLabel.scss';

const formatOptions = (option, index) => {
  return typeof option === 'object' && option !== null && !Array.isArray(option) ? (
    <MenuItem
      key={option.value + index}
      value={option.value}
      style={{ textTransform: 'uppercase' }}
    >
      {option.label}
    </MenuItem>
  ) : (
    <MenuItem key={option[0]} value={option[0]}>
      {option[0]}
    </MenuItem>
  );
};

const disabledColor = '#676977';

const selectStyles = ({
  palette: {
    primary: { main: primaryColor },
  },
}) => ({
  root: {},
  icon: {
    color: ({ disabled = false }) => {
      if (disabled) return disabledColor;
      return primaryColor;
    },
  },
  disabled: {
    color: '#bebfc5',
    backgroundColor: '#ededef',
  },
});

const conditionalLabel = 'outlined';

const DynamicLabel = ({ variant, children, ...props }) => {
  return variant === conditionalLabel ? (
    <CustomLabel variant={variant} {...props}>
      {children}
    </CustomLabel>
  ) : (
    <InputLabel variant={variant} {...props}>
      {children}
    </InputLabel>
  );
};

DynamicLabel.propTypes = {
  variant: PropTypes.string,
  children: PropTypes.node,
};

const SelectInput = ({
  value,
  onChange,
  options = [],
  label,
  fullWidth = true,
  className,
  name,
  variant = 'standard',
  placeholder = label,
  ...props
}) => {
  const styles = { width: fullWidth ? '100%' : 'auto' };
  return (
    <FormControl style={{ ...styles }} className={className}>
      <DynamicLabel htmlFor="select-multiple" variant={variant} className="DynamicLabel">
        {label}
      </DynamicLabel>
      <Select value={value} onChange={onChange} name={name} variant={variant} {...props}>
        {label && <MenuItem value="">{placeholder}</MenuItem>}
        {options.map((e, i) => formatOptions(e, i))}
      </Select>
    </FormControl>
  );
};

SelectInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.number]),
  onChange: PropTypes.func,
  options: PropTypes.array,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  name: PropTypes.string,
  variant: PropTypes.string,
};

export default withStyles(selectStyles)(SelectInput);
