import React from 'react';
import PropTypes from 'prop-types';

const ArrowDownCircle = ({ className }) => (
  <svg width="12" height="12" viewBox="0 0 12 12" className={className}>
    <g fill="none" fillRule="evenodd">
      <rect width="12" height="12" fill="#0093CA" rx="6" />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M2.63 5.292l2.939 3.034a.6.6 0 00.43.174.6.6 0 00.433-.175L9.37 5.292a.443.443 0 00.13-.309.459.459 0 00-.202-.371.627.627 0 00-.79.06L6 7.263 3.493 4.674a.626.626 0 00-.791-.062c-.239.17-.27.475-.073.68z"
      />
    </g>
  </svg>
);

ArrowDownCircle.propTypes = {
  className: PropTypes.string,
};

export default ArrowDownCircle;
