/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import RadioGroupMaterial from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const RadioOptions = ({ value, color, label }) => (
  <FormControlLabel value={value} control={<Radio color={color} />} label={label || value} />
);

RadioOptions.propTypes = {
  value: PropTypes.string,
  color: PropTypes.string,
  label: PropTypes.string,
};

function handleOptionsProps(option, index) {
  return typeof option === 'object'
    ? { ...option, key: index }
    : { value: option, label: option, key: index };
}

const RadioGroup = ({
  legend,
  fieldName,
  fieldValue = '',
  row = false,
  options = [],
  color = 'primary',
}) => {
  const [selectedRadio, setSelectedRadio] = useState(fieldValue);

  function handleChange({ target: { value = '' } }) {
    setSelectedRadio(value);
  }
  return (
    <FormControl component="fieldset">
      {legend && <FormLabel component="legend">{legend}</FormLabel>}
      <RadioGroupMaterial name={fieldName} row={row} onChange={handleChange} value={selectedRadio}>
        {options.map((option, index) => (
          <RadioOptions {...handleOptionsProps(option, index)} color={color} />
        ))}
      </RadioGroupMaterial>
    </FormControl>
  );
};

RadioGroup.propTypes = {
  legend: PropTypes.string,
  fieldName: PropTypes.string,
  fieldValue: PropTypes.string,
  row: PropTypes.bool,
  options: PropTypes.array,
  color: PropTypes.string,
};

export default RadioGroup;
