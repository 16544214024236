import React from 'react';
import PropTypes from 'prop-types';
import Button from '../ButtonTypes';

import PropertyItem from './PropertyItem';

import LeftArrowButton from '../Icons/LeftArrowButton';

import './PropertyList.scss';

const PropertyList = ({
  properties = [],
  styles,
  subsidiary,
  nombreProyecto,
  changeState,
  onClick,
}) => {
  return (
    <div className={`PropertyList PropertyList--${subsidiary}`} styles={styles}>
      <div className="PropertyList__header">
        <Button variant="text" onClick={changeState}>
          <LeftArrowButton />
        </Button>
        <div className="PropertyList__header__container">
          <span className="PropertyList__header__container__projectName">{nombreProyecto}</span>
          {properties.length > 1 ? (
            <span className="PropertyList__header__container__projectNumber">
              <span
                className={`PropertyList__header__container__projectNumber__detail PropertyList__header__container__projectNumber__detail--${subsidiary}`}
              >
                {properties.length}
              </span>
              UNIDADES
            </span>
          ) : (
            <span className="PropertyList__header__container__projectNumber">{`${properties[0].tipo_Unidad} ${properties[0].numero_Departamento}`}</span>
          )}
        </div>
      </div>
      <div className="PropertyList__container">
        {properties.map(
          ({
            id_Propiedad: idPropiedad,
            estado_Cliente: estadoCliente,
            numero_Departamento: apartmentNumber,
            tipo_Unidad: propertyKind,
          }) => (
            <PropertyItem
              numero={apartmentNumber}
              piso={estadoCliente}
              subsidiary={subsidiary}
              propertyKind={propertyKind}
              key={idPropiedad}
              onClick={() => onClick(idPropiedad)}
            />
          ),
        )}
      </div>
    </div>
  );
};

PropertyList.propTypes = {
  properties: PropTypes.array,
  styles: PropTypes.object,
  subsidiary: PropTypes.string,
  nombreProyecto: PropTypes.string,
  changeState: PropTypes.func,
  onClick: PropTypes.func,
};

export default PropertyList;
