import React, { useState } from 'react';
import { Collapse } from '@material-ui/core';
import PropTypes from 'prop-types';
import es from 'date-fns/locale/es';
import { format } from 'date-fns';
import Grid from '@material-ui/core/Grid';

import Button from '../ButtonTypes';
import EditButtonIcon from '../Icons/EditButtonIcon';
import CloseButtonIcon from '../Icons/CloseButtonIcon';
import RightArrowIcon from '../Icons/RightArrowIcon';

import './NoteItem.scss';

const useOpenNote = () => {
  const [openNote, setOpenNote] = useState(false);
  const ToogleOpenNote = () => {
    setOpenNote(!openNote);
  };
  return { openNote, ToogleOpenNote };
};

const NoteItem = ({ creationDate, title, text, noteId, deleteCallback, handleEditCallback }) => {
  const { openNote, ToogleOpenNote } = useOpenNote();
  return (
    <li className="NoteItem">
      <div className="NoteItem__container">
        <Grid container spacing={0}>
          <Grid item xs={7}>
            <div className="NoteItem__container__info">
              <span className="NoteItem__container__info__date">
                {format(new Date(creationDate), 'dd MMMM', { locale: es })}
              </span>
              <span className="NoteItem__container__info__title">{title}</span>
            </div>
          </Grid>
          <Grid item xs={5}>
            <div className="NoteItem__container__buttons">
              <Button variant="text" onClick={() => handleEditCallback({ noteId, title, text })}>
                <span className="NoteItem__container__buttons__withlabel">
                  <EditButtonIcon />
                  editar
                </span>
              </Button>
              <Button variant="text" onClick={() => deleteCallback(noteId)}>
                <span className="NoteItem__container__buttons__withlabel">
                  <CloseButtonIcon />
                  eliminar
                </span>
              </Button>
              <Button variant="text" onClick={ToogleOpenNote}>
                <RightArrowIcon
                  className={`NoteItem__container__buttons__arrowButton ${openNote &&
                    'NoteItem__container__buttons__arrowButton--active'}`}
                />
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
      <Collapse in={openNote}>
        <div className="NoteItem__collapse">{text}</div>
      </Collapse>
    </li>
  );
};

NoteItem.propTypes = {
  creationDate: PropTypes.string,
  title: PropTypes.string,
  noteId: PropTypes.string,
  text: PropTypes.string,
  deleteCallback: PropTypes.func,
  handleEditCallback: PropTypes.func,
};

export default NoteItem;
