import React from 'react';
// Formik
import { withFormik, Form } from 'formik';
import * as Yup from 'yup';

import PropTypes from 'prop-types';
import TextFields from '../../components/TextFields';
import Button from '../../components/ButtonTypes';

// Constants
import { primary } from '../../constants/colorTypes';
import { validate } from 'rut.js';

export const ForgotPasswordSchema = Yup.object().shape({
  rut: Yup.string()
    .required('Este campo es requerido')
    .test('valid-rut', 'el Rut ingresado es inválido', (value) => validate(value))
});

const InnerFormPassword = ({
  errors,
  touched,
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  // isValid,
  inputColor = primary,
}) => {
  return (
    <Form onSubmit={handleSubmit} className="ForgotPassword__form">
      <TextFields
        name="rut"
        value={values.rut}
        color={inputColor}
        onChange={handleChange}
        onBlur={handleBlur}
        error={errors.rut && touched.rut}
        // valid={touched.rut && !errors.rut && values.rut !== ''}
        helperText={errors.rut}
        label="Ingresa tu Rut"
      />
      <div className="ForgotPassword__form__buttons">
        <Button type="submit">ENVIAR UN EMAIL</Button>
      </div>
    </Form>
  );
};

InnerFormPassword.propTypes = {
  errors: PropTypes.object,
  touched: PropTypes.object,
  values: PropTypes.object,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  handleSubmit: PropTypes.func,
  inputColor: PropTypes.string,
  isValid: PropTypes.bool,
};

export default withFormik({
  mapPropsToValues: () => {
    return {
      rut: '',
    };
  },
  enableReinitialize: true,
  validateOnBlur: false,
  isInitialValid: false,
  validationSchema: ForgotPasswordSchema,
  handleSubmit: (values, { props: { onSubmit } }) => {
    onSubmit(values);
  },
})(InnerFormPassword);
