import React from 'react';
import PropTypes from 'prop-types';

// Component
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '../TextFields';
import SearchIcon from '../Icons/SearchIcon';

import './Searchbar.scss';

const Searchbar = ({ helperMessage, onChange, value, onSubmit, className }) => {

  const updateText = event => {
    onChange(event.target.value);
  };

  const keyUp = event => {
    if ((event.keyCode == 13) || (value === '')) {
      if(onSubmit) onSubmit(value);
    }
  }
  return (
    <div className={`Searchbar ${className ? `${className}` : ''}`}>
      <TextField
        variant="outlined"
        className="Searchbar__input"
        placeholder={helperMessage}
        onChange={updateText}
        onKeyUp={keyUp}
        value={value}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" onClick={() => {if(onSubmit) onSubmit(value)}} style={{cursor: 'pointer'}}>
              <SearchIcon color="black" />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

Searchbar.propTypes = {
  helperMessage: PropTypes.string,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  value: PropTypes.string,
  className: PropTypes.string,
};

export default Searchbar;
