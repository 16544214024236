import React from 'react';
import IconPropTypes from '../IconPropTypes';

const DownArrow = ({ className, color, active = SVGComponentTransferFunctionElement, onClick }) => {
  return active ? (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" onClick={onClick}>
      <path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z" fill={color} />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  ) : (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" onClick={onClick}>
      <path d="M18 16H-6V-8h24z" fill="none" />
      <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" fill={color} />
    </svg>
  );
};

DownArrow.propTypes = IconPropTypes;

export default DownArrow;
