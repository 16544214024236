import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Collapse from '@material-ui/core/Collapse';
import Button from '../ButtonTypes';
import RightArrowIcon from '../Icons/RightArrowIcon';

import './CalendarItem.scss';

const CalendarItem = ({ date, title, subsidiary, expireDate, description }) => {
  const [showDescription, setShowDescription] = useState(false);

  const handleCollapse = () => {
    setShowDescription(!showDescription);
  };

  return (
    <div className={`CalendarItem CalendarItem--${subsidiary}`}>
      <div className="CalendarItem__content">
        <span className="CalendarItem__content__date">
          {date}
          {expireDate && ` - ${expireDate}`}
        </span>
        <span className="CalendarItem__content__title">{title}</span>
        <Collapse in={showDescription} className="CalendarItem__content__colapse">
          <span>{description}</span>
        </Collapse>
      </div>
      <Button
        variant="text"
        className="CalendarItem__button"
        onClick={() => {
          handleCollapse();
        }}
      >
        <RightArrowIcon />
      </Button>
    </div>
  );
};

CalendarItem.propTypes = {
  date: PropTypes.string,
  expireDate: PropTypes.string,
  title: PropTypes.string,
  subsidiary: PropTypes.string,
  description: PropTypes.string,
};

export default CalendarItem;
