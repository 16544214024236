import React, { useState, useCallback, Fragment, useEffect, useContext } from 'react';
import { isSameDay } from 'date-fns';
import PropTypes from 'prop-types';

// Component
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import DownArrow from '../Icons/DownArrow';
import Button from '../ButtonTypes';
import AnswerMessageForm from './AnswerMessageForm';
import { formatDate, capitalizeEveryWord } from '../../utils/utils';

import './MessageItem.scss';
import { GlobalContext } from '../../providers/GlobalStateProvider';
import { empresas } from '../../constants/filialesNames';
import FileIcon from '../Icons/FileIcon';

const useToogle = (read = false, messages = [], username) => {
  const [response, setResponse] = useState(false);
  const [active, setActive] = useState(false);
  const [readFlag, setReadFlag] = useState(read);
  const toogleActive = useCallback(() => {
    setResponse(false);
    setActive(!active);
  }, [active]);
  const toogleResponse = useCallback(() => {
    setResponse(!response);
  }, [response]);
  useEffect(() => {
    if (messages.length > 0) {
      const newMessage = messages.every(({ leido, rut }) => (leido === true || rut === username));
      setReadFlag(newMessage !== undefined ? newMessage : read);
    }
  }, [read, messages]);
  return { response, toogleResponse, toogleActive, active, readFlag };
};

const isToday = lastMessageDate => {
  return isSameDay(new Date(lastMessageDate), new Date())
    ? 'HOY'
    : formatDate(new Date(lastMessageDate), 'PPPP');
};

const MessageItem = ({
  subsidiary,
  read = false,
  messages = [],
  subject,
  lastMessageDate,
  at,
  attached,
  from,
  messageId,
  onSubmit,
  onCollapseItem,
}) => {

  const { state: { username = '' } = {} } = useContext(GlobalContext);
  
  const { response, toogleResponse, toogleActive, active, readFlag } = useToogle(read, messages, username);
  const collapseCallback = useCallback(() => {
    if (!!onCollapseItem && !readFlag && !active) {
      onCollapseItem(messageId);
    }
    toogleActive();
  }, [toogleActive, onCollapseItem, messageId, readFlag, active]);
  return (
    <Fragment>
      <li
        className={`MessageItem MessageItem--${subsidiary} ${
          readFlag ? `MessageItem--${subsidiary}--read` : ''
        }`}
      >
        <div className="MessageItem__resume">
          <Grid container spacing={0}>
            <Grid item xs={11}>
              <Grid container spacing={0}>
                <Grid item xs={12} sm={4}>
                  <Button
                    variant="text"
                    onClick={collapseCallback}
                    className="MessageItem__resume__button"
                  >
                    <span
                      className={`MessageItem__resume__from ${
                        !readFlag ? `MessageItem__resume__from--${subsidiary}--notRead` : ''
                      } MessageItem__resume__from--${subsidiary}`}
                    >
                      {/* {(subsidiary === empresas) && messages && messages[0].rol === 'EJECUTIVO_ROLE' ? capitalizeEveryWord(at) : capitalizeEveryWord(from)} */}
                      {'DE: ' + capitalizeEveryWord(from)}
                      <br />
                      {'PARA: ' + capitalizeEveryWord(at)}
                    </span>
                  </Button>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <Button
                    variant="text"
                    className="MessageItem__resume__button"
                    onClick={collapseCallback}
                  >
                    <span className="MessageItem__resume__title">{subject}</span>
                  </Button>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Button
                    variant="text"
                    onClick={collapseCallback}
                    className="MessageItem__resume__button"
                  >
                    <span className="MessageItem__resume__date">{isToday(lastMessageDate)}</span>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={1} className="MessageItem__resume__collapseButton">
              <Button
                variant="text"
                onClick={collapseCallback}
                className="MessageItem__resume__button"
              >
                <DownArrow color="currentColor" active={active} />
              </Button>
            </Grid>
          </Grid>
        </div>
        <Collapse in={active}>
          <div className={`MessageItem__detail MessageItem__detail--${subsidiary}`}>
            <h5 className="MessageItem__detail__title">
              {subject && capitalizeEveryWord(subject.toLowerCase())}
            </h5>
            {messages.map(
              (
                { mensaje: message, leido: messageRead = false, _id, rut, nombres, apellidos, fecha },
                index,
              ) => (
                <div
                  className={`MessageItem__detail__body MessageItem__detail__body--${subsidiary} ${
                    !messageRead && rut !== username ? `MessageItem__detail__body--${subsidiary}--notRead` : ''
                  }`}
                  key={_id}
                >
                  {rut && nombres && apellidos && index !== 0 ? (
                    <div  className="MessageItem__detail__body__message">
                      <h5 className="MessageItem__detail__body__name">
                        {capitalizeEveryWord(`${nombres} ${apellidos}`)}
                      </h5>
                      <span>{('0' + new Date(fecha).getDate()).slice(-2) + '-' + ('0' + (new Date(fecha).getMonth() + 1)).slice(-2) + '-' + new Date(fecha).getFullYear() + ' ' + (new Date(fecha).toTimeString()).split(' ')[0]}</span>
                      {/* <span>para </span> */}
                    </div>
                  ) : (
                    undefined
                  )}
                  {message}
                </div>
              ),
            )}
            <div>
            {attached && attached.length > 0 && (
              <a href={attached} className="DocumentItem__container__name" target="_blank" style={{textDecoration: 'none'}}>
                <div className={`DocumentItem__simbol DocumentItem__simbol--${subsidiary}`}>
                  <FileIcon className="DocumentItem__simbol__icon" color="currentColor" />
                </div>
              </a>
            )}
            {!response && (
              <Button className="MessageItem__detail__button" onClick={toogleResponse}>
                responder
              </Button>
            )}
            </div>
          </div>
        </Collapse>
        <Collapse in={response}>
          <div className="MessageItem__response">
            <AnswerMessageForm
              onSubmit={onSubmit}
              messageId={messageId}
              toogleResponse={toogleResponse}
            />
          </div>
        </Collapse>
      </li>
    </Fragment>
  );
};

MessageItem.propTypes = {
  subsidiary: PropTypes.string,
  messages: PropTypes.array,
  subject: PropTypes.string,
  lastMessageDate: PropTypes.string,
  from: PropTypes.string,
  onSubmit: PropTypes.func,
  onCollapseItem: PropTypes.func,
  messageId: PropTypes.string,
  read: PropTypes.bool,
  open: PropTypes.bool,
};

export default MessageItem;
