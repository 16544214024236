/* eslint-disable import/prefer-default-export */
import { project } from 'ramda';
import { useEffect, useState } from 'react';
import { getActasbyProducto } from '../services/postventaService';
import { ImagenActa } from '../constants/endpoints';


export const useMinutesByUnit = (propertyId) => {
  const [minutes, setMinutes] = useState([]);
  useEffect(() => {
    if (!!propertyId) {
      getActasbyProducto(
        propertyId,
        ({ data }) => {
          setMinutes(data.map(minute => {
            const { revisionFechaInicio, revisionActaBlobNombre, revisionActaId } = minute;
            return {
              name: revisionActaBlobNombre,
              lastModified: `${revisionFechaInicio.split(' ')[0].split('-').reverse().join('-')} ${revisionFechaInicio.split(' ')[1]}`,
              etag: revisionActaId,
              link: `${ImagenActa}/${propertyId}/${revisionActaId}`,
              label: revisionActaBlobNombre.trim(),
            }
          }))
        },
        err => {
          console.error(err);
        },
      );
    }
  }, [propertyId]);
  return { minutes, setMinutes };
};
