import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

const buttonsStyles = ({
  palette: {
    primary: { main: primaryColor },
    secondary: { main: secundaryColor },
  },
}) => ({
  root: {
    fontSize: '12px',
    fontWeight: 'bold',
    padding: '10px 64px',
    borderRadius: '18px',
  },
  sizeLarge: {
    padding: '20px 60px',
  },
  disabled: {
    color: '#bebfc5!important',
    backgroundColor: '#ededef!important',
  },
  outlinedPrimary: {
    border: '',
  },
  containedPrimary: {
    color: secundaryColor,
  },
  text: {
    fontWeight: 'normal',
    padding: 0,
    minWidth: 'auto',
    '&:hover': {
      backgroundColor: 'auto',
      textDecoration: 'underline',
    },
  },
  textPrimary: {
    color: '#303446',
  },
  textSecondary: {
    color: secundaryColor,
  },
  containedSecondary: {
    color: primaryColor,
    border: `2px solid ${primaryColor}`,
    borderRadius: '30px',
  },
  // containedSizeLarge: {
  //   padding: '10px 64px',
  //   width: '90%',
  // },
});

const ButtonTypes = ({ color = 'primary', variant = 'contained', padding, ...props }) => {
  return <Button color={color} style={{ padding: padding || '' }} variant={variant} {...props} />;
};

ButtonTypes.propTypes = {
  color: PropTypes.string,
  variant: PropTypes.string,
  padding: PropTypes.string,
};

export default withStyles(buttonsStyles)(ButtonTypes);
