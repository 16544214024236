import React from 'react';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

import { currentColor } from '../../../constants/colors';

const RightArrow = ({ color = currentColor, className }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" className={className}>
    <path fill={color} d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" />
    <path fill="none" d="M0 0h24v24H0V0z" />
  </svg>
);

RightArrow.propTypes = {
  theme: PropTypes.object,
  color: PropTypes.string,
  className: PropTypes.string,
};

export default withStyles({}, { withTheme: true })(RightArrow);
