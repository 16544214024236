import React, { useState, useCallback, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';

// Formik
import { withFormik, Form } from 'formik';

// Inputs
import TextFields from '../TextFields';
import Button from '../ButtonTypes';
import FileUploadInput from '../FileUploadInput/FileUploadInput';
import { uploadFileMessage } from '../../services/fileService';

// Scss
import './NewMessageForm.scss';
import ClipIcon from '../../assets/paper-clip.svg';
import ClearIcon from '../Icons/ClearIcon/ClearIcon';
import { deleteMessageDocument } from '../../services/documents';
import { colorConfig } from '../../constants/colors';

const NewMessageForm = ({
  handleSubmit,
  values,
  handleBlur,
  handleChange,
  errors,
  touched,
  resetTab,
  from,
  projectId,
  setFieldValue,
  subsidiary,
}) => {

  const [ fileKey, setFileKey ] = useState(Date.now());
  const [ fileLoading, setFileLoading ] = useState(false);
  const [ selectedFile, setSelectedFile ] = useState([]);
  const [_openDialog, _setOpenDialog] = useState(false);
  const _toogleDialog = () => {
    _setOpenDialog(!_openDialog);
    setFileKey(Date.now());
  };
  const openModalDocumentName = event => {
    setSelectedFile(event.target.files);
    _toogleDialog();
  };
  useEffect(() => {
    if (selectedFile.length > 0) {
      setFileLoading(true);
      const file = selectedFile[0];
      uploadFileMessage(file, from, projectId, result => {
        setFileLoading(false)
        setFieldValue('file', result.data.link);
        setSelectedFile([]);
        setFileName({
          name: file.name,
          etag: result.data.etag,
        });
      })
    }
  }, [selectedFile, from, projectId]);

  const [fileName, setFileName] = useState();
  const deleteFile = (nombre) => {
    const label = nombre.split('/').slice(-2).join('/');
    deleteMessageDocument(label, () => {
      setFieldValue('file', '');
      setFileName();
    });
  }

  return (
    <Form className="NewMessageForm__form" onSubmit={handleSubmit}>
      <div className="NewMessageForm__form__input">
        <TextFields
          name="title"
          value={values.title}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.title && touched.title}
          valid={touched.title && !errors.title && values.title !== ''}
          helperText={errors.title}
          label="TITULO:"
        />
      </div>
      <div className="NewMessageForm__form__input">
        <TextFields
          name="message"
          value={values.message}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.message && touched.message}
          valid={touched.message && !errors.message && values.message !== ''}
          helperText={errors.message}
          label=" MENSAJE:"
          multiline
          rows={20}
        />
      </div>
      <div className='NewMessageForm__form__fileUpload'>
        <FileUploadInput
              name="file"
              accept="*"
              buttonLabel={<img src={ClipIcon} width="15px" height="15px" />}
              fileKey={fileKey}
              onChange={e => openModalDocumentName(e)}
            />
        {fileName && (
            <div className='NewMessageForm__form__fileUpload__file'>
            <a className='NewMessageForm__form__fileUpload__file__link' href={`${values.file}`} target='_blank' style={{color: colorConfig[subsidiary].primary}}>{fileName.name}</a>
            <ClearIcon className='NewMessageForm__form__fileUpload__file__icon' color={colorConfig[subsidiary].primary} onClick={()=>deleteFile(fileName.etag)}/>
          </div>
        )}
      </div>
      <div className="NewMessageForm__form__buttons">
        <Button variant="outlined" onClick={resetTab}>
          Cancelar
        </Button>
        <Button type="submit" diabled={!fileLoading}>Enviar mensaje</Button>
      </div>
    </Form>
  );
};

NewMessageForm.propTypes = {
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  onSubmit: PropTypes.func,
  handleSubmit: PropTypes.func,
  values: PropTypes.shape({
    to: PropTypes.array,
    title: PropTypes.string,
    message: PropTypes.string,
    from: PropTypes.string,
  }),
  errors: PropTypes.object,
  touched: PropTypes.object,
  resetTab: PropTypes.func,
};

export default withFormik({
  mapPropsToValues: () => {
    return {
      title: '',
      message: '',
      file: '',
    };
  },
  enableReinitialize: true,
  validateOnBlur: false,
  isInitialValid: false,
  handleSubmit: ({ message, title, file }, { props: { onSubmit, resetTab, from, to } }) => {
    const resetForm = () => {
      resetTab();
    };
    onSubmit(title, from, to, message, file, resetForm);
    //onSubmit(title, from, to, file || from, message, file, resetForm);
  },
})(NewMessageForm);
