import React, { memo } from 'react';

import { BrowserRouter, Switch } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { es } from 'date-fns/locale';

import './App.scss';

import Router from './routers/GeneralRouter';

// Global Provider
import { GlobalStateProvider } from './providers/GlobalStateProvider';

// Request Provider
import { FetchStateProvider } from './providers/RequestProvider';

// Global Components
import Snackbar from './components/SnackBarHandler';

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
          <FetchStateProvider>
            <Snackbar />
            <GlobalStateProvider>
              <Router />
            </GlobalStateProvider>
          </FetchStateProvider>
        </MuiPickersUtilsProvider>
      </Switch>
    </BrowserRouter>
  );
}

export default memo(App);
