import React from 'react';
import IconPropType from '../IconPropTypes';

const MessageIcon = ({ className, color = '#6B6B6B' }) => (
  <svg width="24px" height="20px" viewBox="0 0 24 20" className={className}>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Icons/24/transversal/grey/notification"
        transform="translate(0.000000, -4.000000)"
        fill={color}
        fillRule="nonzero"
      >
        <g id="ui-chat-button" transform="translate(0.000000, 4.000000)">
          <g id="icons">
            <g id="Group" transform="translate(0.461538, 0.461538)">
              <path
                d="M21.2307692,-1.77635684e-15 C22.2490545,-1.77635684e-15 23.0769231,0.827868577 23.0769231,1.84615385 L23.0769231,13.8461538 C23.0769231,14.8644391 22.2490545,15.6923077 21.2307692,15.6923077 L20.3076923,15.6923077 L20.3076923,18.4615385 C20.3076923,18.7804373 20.1425982,19.0767989 19.8684517,19.2483482 C19.7223867,19.337813 19.5550328,19.3846154 19.3846154,19.3846154 C19.2422846,19.3846154 19.1005941,19.351082 18.9723839,19.2869769 L11.7821348,15.6923077 L1.84615385,15.6923077 C0.827868577,15.6923077 -1.0658141e-13,14.8644391 -1.0658141e-13,13.8461538 L-1.0658141e-13,1.84615385 C-1.0658141e-13,0.827868577 0.827868577,-1.77635684e-15 1.84615385,-1.77635684e-15 L21.2307692,-1.77635684e-15 Z M1.84615385,1.84615385 L1.84615385,13.8461538 L12,13.8461538 C12.1393687,13.8461538 12.2773056,13.8786905 12.4132209,13.9429025 L18.4615385,16.9678925 L18.4615385,14.7692308 C18.4615385,14.2595609 18.8749455,13.8461538 19.3846154,13.8461538 L21.2307692,13.8461538 L21.2307692,1.84615385 L1.84615385,1.84615385 Z"
                id="Shape"
              />
              <path
                d="M6,9.26923077 C6.78594368,9.26923077 7.42307692,8.63209753 7.42307692,7.84615385 C7.42307692,7.06021016 6.78594368,6.42307692 6,6.42307692 C5.21405632,6.42307692 4.57692308,7.06021016 4.57692308,7.84615385 C4.57692308,8.63209753 5.21405632,9.26923077 6,9.26923077 Z"
                id="Oval"
              />
              <path
                d="M17.0769231,9.26923077 C17.8628668,9.26923077 18.5,8.63209753 18.5,7.84615385 C18.5,7.06021016 17.8628668,6.42307692 17.0769231,6.42307692 C16.2909794,6.42307692 15.6538462,7.06021016 15.6538462,7.84615385 C15.6538462,8.63209753 16.2909794,9.26923077 17.0769231,9.26923077 Z"
                id="Oval"
              />
              <path
                d="M11.5384615,9.26923077 C12.3244052,9.26923077 12.9615385,8.63209753 12.9615385,7.84615385 C12.9615385,7.06021016 12.3244052,6.42307692 11.5384615,6.42307692 C10.7525179,6.42307692 10.1153846,7.06021016 10.1153846,7.84615385 C10.1153846,8.63209753 10.7525179,9.26923077 11.5384615,9.26923077 Z"
                id="Oval"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

MessageIcon.propTypes = IconPropType;

export default MessageIcon;
