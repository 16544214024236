import React from 'react';

import IconPropTypes from '../IconPropTypes';

const LeftArrowButton = ({ styles }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    style={{ ...styles }}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="black"
        fillRule="nonzero"
        d="M15 0C6.716 0 0 6.716 0 15c0 8.284 6.716 15 15 15 8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15zm0 2c7.18 0 13 5.82 13 13s-5.82 13-13 13S2 22.18 2 15 7.82 2 15 2z"
      />
      <path d="M26 3v24H2V3z" />
      <path
        fill="black"
        d="M17.116 9.722a.956.956 0 0 1-.007 1.385L13.062 15l4.047 3.893c.4.385.398 1.01.007 1.385l-.034.034a1.042 1.042 0 0 1-1.434 0l-4.816-4.624a.944.944 0 0 1 0-1.376l4.816-4.624a1.049 1.049 0 0 1 1.434 0l.034.034z"
      />
    </g>
  </svg>
);

LeftArrowButton.propTypes = IconPropTypes;

export default LeftArrowButton;
