import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '../../components/ButtonTypes';
import AddIcon from '../../components/Icons/AddIcon';

// Services

import { useIslogged } from '../../customHooks/useIslogged';
import {
  getAdicionales,
  getRelaciones,
} from '../../services/postventaService';
import { GlobalContext } from '../../providers/GlobalStateProvider';
import './ClientAdditional.scss';
import { set } from 'date-fns';
import { colorConfig } from '../../constants/colors';
import { usePropiedadesPostventa } from '../../customHooks/postventa';
import AdditionalList from '../../components/PostVenta/Additional/AdditionalList';
import UnitList from '../../components/PostVenta/Additional/UnitList';
import AdditionalModal from '../../components/PostVenta/Additional/AdditionalModal/AdditionalModal';
import { EnterpriseContext } from '../../providers/EnterpriseProvider';
import { getFilialFromLocalStorage } from '../../services/additionalService';


const ClientAdditional = ({
  match: {
    params: { subsidiary, idClient: clientId },
  },
  history,
  propiedadesPV,
}) => {
  if (!useIslogged()) history.push(`/${subsidiary}`);
  const [additionalUsers, setAdditionalUsers] = useState([]);
  const [units, setUnits] = useState([]);
  const { state: { username = '' } = {} } = useContext(GlobalContext);
  const [newAdditional, setNewAdditional] = useState({
    name: '',
    rut: '',
    email: '',
    phone: '',
    relationship: null,
    status: true,
  });

  const contexto = useContext(EnterpriseContext);
  let filialSaved = '';
  let filial = '';
  let client = {};
  if (contexto) {
    filial = contexto?.state?.filial;
    filialSaved = getFilialFromLocalStorage();
    client = contexto?.state?.client;
  }

  const [activeFilial, setActiveFilial] = useState(filial || filialSaved || subsidiary || 'pilares');

  useEffect(() => {
    if (subsidiary === 'empresas') {
      setActiveFilial(filial || filialSaved || 'pilares');
    }
  }, [filial]);

  const [open, setOpen] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [relationshipArray, setRelationshipArray] = useState([]);
  const { propiedadesPV: propertiesPV } = usePropiedadesPostventa(clientId?.substring(0, clientId.length - 1) || username, activeFilial || subsidiary, null, true, false, propiedadesPV);
  const [asignedUnits, setAsignedUnits] = useState([]);
  const [unasignetUnits, setUnasignetUnits] = useState([]);
  const [additionalAsigned, setAdditionalAsigned] = useState([]);
  const [additionalUnasigned, setAdditionalUnasigned] = useState([]);

  const getData = async () => {
    getRelaciones(
      clientId?.substring(0, clientId.length - 1) || username,
      res => {
        setRelationshipArray(res.data);
      },
      err => {
        console.error(err);
      },
    );
    getAdicionales(
      clientId?.substring(0, clientId.length - 1) || username,
      res => {
        setAdditionalUsers(res.data);
      },
      err => {
        console.error(err);
      },
    );
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (Object.keys(propertiesPV).length === 0) return;

    const units = propertiesPV
      .map(filial =>
        filial.proyectos
          .map(project => {
            let units = project.unidades;
            units.map(unit => (unit.project = project.proyecto));
            return units;
          })
          .flat(),
      )
      .flat();
    setUnits(units);
  }, [propertiesPV]);

  const openAdditionalUserForm = additionalUser => {
    if (!additionalUser) {
      additionalUser = {
        name: '',
        rut: '',
        email: '',
        phone: '',
        relationship: '',
        status: true,
        properties: null,
      };
      setEditUser(false);
    } else {
      additionalUser = {
        id: additionalUser.id_adi,
        name: additionalUser.solicitantenombre_adi,
        rut: additionalUser.solicitanterut_adi,
        email: additionalUser.solicitanteemail_adi,
        phone: additionalUser.solicitantephone_adi,
        relationship: +additionalUser.solicitanterelacionPropietarioID_adi,
        status: +additionalUser.estadoadiciona_adi === 1,
        properties: additionalUser.unidades.map(unit => unit.unidadid_adi),
      };
      setEditUser(true);
    }

    setNewAdditional(additionalUser);
    setOpen(true);
  };

  useEffect(() => {
    if (additionalUsers.length > 0 && units.length > 0) {
      const asignedUnits = additionalUsers.map(user=>{
        return user.unidades.filter(unidad=>unidad.unidadid_adi != '0')
      }).flat();
      const unasignetUnits = units.filter(unit => {
        return !asignedUnits.some(asignedUnit => asignedUnit.unidadid_adi === unit.unidadId);
      });
      setAsignedUnits(asignedUnits);
      setUnasignetUnits(unasignetUnits);
      const additionalAsigned = additionalUsers.filter(user=>{
        return user.unidades.filter(unidad=>unidad.unidadid_adi != '0').length > 0
      })

      const additionalUnasigned = additionalUsers.filter(user=>{
        return user.unidades.filter(unidad=>unidad.unidadid_adi != '0').length === 0
      })
      setAdditionalAsigned(additionalAsigned);
      setAdditionalUnasigned(additionalUnasigned);
    }
  }, [additionalUsers, units]);
  const [nombreCliente, setNombreCliente] = useState('');
  useEffect(() => {
    if (client && client.fullName) {
      const nombreCliente = client.fullName
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
      setNombreCliente(nombreCliente);
    }
  }, [client]);


  return (
    <div className={`ClientAdditional__container--${subsidiary}`}>
      <AdditionalModal
        open={open}
        setOpen={setOpen}
        additionalUser={newAdditional}
        additionalUsers={additionalUsers}
        onChangeAdditional={setNewAdditional}
        getData={getData}
        ownerID={clientId?.substring(0, clientId.length - 1) || username}
        ownerProperties={units}
        edit={editUser}
        relationships={relationshipArray}
        subsidiary={activeFilial}
      />
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <div className="ClientAdditional__head ">
            <div className={`ClientAdditional__head__name_container__name--${activeFilial}`}>
              Usuarios adicionales
            </div>
            <Button
              className={`ClientAdditional__contact__button _button_action ClientAdditional__contact__button--${subsidiary}`}
              variant="text"
              startIcon={<AddIcon color="white" />}
              onClick={() => {
                openAdditionalUserForm();
              }}
            >
              Agregar usuario adicional
            </Button>
          </div>
          {subsidiary === 'empresas' && (
              <div className={`ClientAdditional__head__name_container__subname--${activeFilial}`}>
                De {nombreCliente} en {activeFilial.toUpperCase()}
              </div>
            )}
        </Grid>
        <Grid item xs={12}>
          <div className="ClientAdditional__Line" />
        </Grid>
        <Grid item xs={12}>
          <AdditionalList
            additionalList={additionalAsigned}
            listName={'Usuarios Adicionales habilitados'}
            subsidiary={activeFilial}
            onItemEdit={openAdditionalUserForm}
            defaultExpanded={true}
            relationshipArray={relationshipArray}
            units={units}
          />
          <AdditionalList
            additionalList={additionalUnasigned}
            listName={'Usuarios Adicionales sin asignación'}
            subsidiary={activeFilial}
            onItemEdit={openAdditionalUserForm}
            relationshipArray={relationshipArray}
            units={units}
          />
        </Grid>
        <Grid item xs={12}>
          <UnitList
            units={unasignetUnits}
            hasAdditionals ={additionalUsers.length > 0}
            subsidiary={activeFilial}
          />
        </Grid>
      </Grid>
    </div>
  );
};

ClientAdditional.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      subsidiary: PropTypes.string,
    }),
  }),
  history: PropTypes.object,
};

export default ClientAdditional;
