import React from 'react';
import PropTypes from 'prop-types';

import { currentColor } from '../../../constants/colors';

const LeftArrowIcon = ({ color = currentColor, className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z" fill={color} />
    <path fill="none" d="M0 0h24v24H0V0z" />
  </svg>
);

LeftArrowIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

export default LeftArrowIcon;
