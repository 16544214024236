import React from 'react';
import PropTypes from 'prop-types';

// Components
import PropertyListAsList from './PropertyListAsList';
import PropertyListGalery from './PropertyListGalery';

import { gallery } from '../../constants/filialesNames';

import './ProjectItem.scss';

const ProjectItem = ({
  subsidiary,
  propiedades = [],
  nombre_Proyecto: nombreProyecto = 'nombre',
  imagenes: imageList = [],
  listOrganization,
  nombre_Etapa: stageName,
  onClick,
  id_Proyecto: projectId,
  filial
}) => {
  const callback = idProperty => onClick(projectId, idProperty);
  return listOrganization === gallery ? (
    <PropertyListGalery
      nombreProyecto={nombreProyecto}
      propiedades={propiedades}
      subsidiary={subsidiary}
      imageList={imageList}
      listOrganization={listOrganization}
      stageName={stageName}
      onClick={callback}
      filial={filial}
    />
  ) : (
    <PropertyListAsList
      nombreProyecto={nombreProyecto}
      propiedades={propiedades}
      subsidiary={subsidiary}
      onClick={callback}
      filial={filial}
    />
  );
};

ProjectItem.propTypes = {
  subsidiary: PropTypes.string,
  nombre_Proyecto: PropTypes.string,
  propiedades: PropTypes.array,
  imagenes: PropTypes.array,
  listOrganization: PropTypes.string,
  nombre_Etapa: PropTypes.string,
  onClick: PropTypes.func,
  id_Proyecto: PropTypes.string,
  filial: PropTypes.string,
};

export default ProjectItem;
