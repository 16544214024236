import { useState, useMemo, useContext } from 'react';

import { ClientContext } from '../providers/ClientProvider';
import { getCalendarProject } from '../services/clientService';

// Services
import {
  getProjectsByUser,
  getMilestonesByProject,
  getDocumentsByProject,
  getCalendar,
  getImagesByProject,
} from '../services/projectsService';

export const HandleMilestones = projectId => {
  const [milestones, setMilestones] = useState({});
  useMemo(() => {
    if (projectId && projectId !== '') {
      getMilestonesByProject(
        projectId,
        ({ data: { data = {} } = {} }) => {
          setMilestones({ ...data[0] });
        },
        error => {
          console.error(error);
        },
      );
    }
  }, [projectId]);
  return { milestones, setMilestones };
};

export const HandleCalendar = (idPropiedad, idProyecto, idCliente) => {
  const [calendarList, setCalendarList] = useState([]);
  const [filterCalendar, setFilterCalendar] = useState(new Date());
  const onChangeFilter = newdate => {
    setFilterCalendar(new Date(newdate));
  };
  useMemo(() => {
    if (idPropiedad && idProyecto && idCliente) {
      getCalendar(
        { idPropiedad, idProyecto, idCliente, filterDate: filterCalendar },
        ({ data: { data = [] } }) => {
          let calendar = [];
          calendar = data;
          getCalendarProject(
            idProyecto,
            ({data}) => {
              calendar.push(...data);
              calendar.sort((a, b) => {
                return a.fecha_desde - b.fecha_desde;
              }).reverse();
              setCalendarList(calendar);
            },
            err => {
              console.error(err);
            }
          );
        },
        err => {
          console.error(err);
        },
      );
    }
  }, [idPropiedad, idProyecto, idCliente, filterCalendar]);
  return { calendarList, setCalendarList, onChangeFilter, filterCalendar };
};

export const HandleProjects = (username = '') => {
  const {
    updateProjectList,
    selectProjectList,
    state: { projectList, activeProject },
  } = useContext(ClientContext);
  const [activeProperty, setActiveProperty] = useState();
  const [propertyList, setPropertyList] = useState([]);
  const [multipleProperty, setMultipleProperty] = useState(false);
  useMemo(() => {
    if (username !== '') {
      getProjectsByUser(
        username,
        data => {
          if (Array.isArray(data)) {
            updateProjectList([...data]);
            selectProjectList(data[0].id_Proyecto);
            setPropertyList([...data[0].propiedades]);
            setActiveProperty(data[0].propiedades[0].id_Propiedad);
            setMultipleProperty(data.length > 1 || data[0].propiedades.length > 1);
          } else {
            updateProjectList([{ ...data }]);
            selectProjectList(data.id_Proyecto);
            setPropertyList([...data.propiedades]);
            setActiveProperty(data.propiedades[0].id_Propiedad);
          }
        },
        err => {
          console.error(err);
        },
      );
    }
  }, [username, selectProjectList, updateProjectList]);
  return { projectList, activeProject, activeProperty, propertyList, multipleProperty };
};

export const HandleDocumentsByProject = (username, projectId) => {
  const [images, setImages] = useState([]);
  useMemo(() => {
    if (username && username !== '' && projectId && projectId !== '') {
      getImagesByProject(
        { username, idProject: projectId },
        ({ data }) => {
          setImages(data);
        },
        err => {
          console.error(err);
        },
      );
    }
  }, [username, projectId]);
  return { images, setImages };
}

export const HandleDocuments = (username, projectId, idPropiedad) => {
  const [documents, setDocuments] = useState([]);
  useMemo(() => {
    if (projectId && projectId !== '' && username !== '' && idPropiedad) {
      getDocumentsByProject(
        { username, idProyecto: projectId, idPropiedad },
        ({ data }) => {
          setDocuments(data);
        },
        error => {
          console.error(error);
        },
      );
    }
  }, [username, projectId, idPropiedad]);

  return { documents, setDocuments };
};
