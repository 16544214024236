import React from 'react';
import IconPropTypes from '../IconPropTypes';

const GalleryIcon = ({ className, color = '#FF6900', secondaryColor = '#FFFFFF', onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width="30"
    height="30"
    viewBox="0 0 30 30"
    onClick={onClick}
  >
    <g fill="none" fillRule="evenodd">
      <rect width="30" height="30" fill={color} rx="2" />
      <g fill={secondaryColor} transform="translate(4.5 4.5)">
        <rect width="9.181" height="9.8" rx="1" />
        <rect width="9.181" height="9.8" x="11.55" rx="1" />
        <rect width="9.181" height="9.8" y="11.2" rx="1" />
        <rect width="9.181" height="9.8" x="11.55" y="11.2" rx="1" />
      </g>
    </g>
  </svg>
);

GalleryIcon.propTypes = IconPropTypes;

export default GalleryIcon;
