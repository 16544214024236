import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '../ButtonTypes';

import './SubsidiaryCard.scss';

const SubsidiaryCard = ({ data, onUpdate }) => {
  const subsidiaryName = Object.keys(data)[0];
  const initialData = Object.values(data)[0];
  const [state, setState] = useState(initialData);
  const [initialState, setInitialState] = useState(initialData);
  const [isModified, setIsModified] = useState(false);

  useEffect(() => {
    setInitialState(initialData);
  }, [initialData]);

  const handleChange = field => {
    setState(prevState => {
      const updatedState = { ...prevState, [field]: !prevState[field] };
      setIsModified(JSON.stringify(updatedState) !== JSON.stringify(initialState));
      return updatedState;
    });
  };

  const handleUpdate = () => {
    onUpdate(subsidiaryName, state);
    setIsModified(false);
  };

  return (
    <div className="SubsidiaryCardItem">
      <h2 className="SubsidiaryCardItem__title">{Object.keys(data)[0].toUpperCase()}</h2>
      <div className="SubsidiaryCardItem__checkbox">
        <FormControlLabel
          control={<Checkbox checked={state.admin} color="primary" onChange={() => handleChange('admin')} />}
          label="Administrador"
        />
        <FormControlLabel
          control={<Checkbox checked={state.messages} color="primary" onChange={() => handleChange('messages')} />}
          label="Mensajes"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={state.additional}
              color="primary"
              onChange={() => handleChange('additional')}
            />
          }
          label="Usuarios Adicionales"
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={state.documentsPV}
              color="primary"
              onChange={() => handleChange('documentsPV')}
            />
          }
          label="Documentos PostVenta Inicio"
        />
      </div>
      <div>
        {isModified && (
        <Button onClick={handleUpdate} className="SubsidiaryCardItem__saveButton">
          Guardar cambios
        </Button>
        )}
      </div>
    </div>
  );
};

SubsidiaryCard.propTypes = {
  data: PropTypes.shape({
    admin: PropTypes.bool,
    additional: PropTypes.bool,
  }).isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default SubsidiaryCard;
