/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState, Fragment, useCallback } from 'react';
import Proptypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '../TextFields';
import SelectInput from '../SelectInput';
import Button from '../ButtonTypes';
import DeleteButton from '../DeleteButton';
import Dialog from '../Dialog';
import MultipleUploadItem from './MultipleUploadItem';
import FileUploadInput from '../FileUploadInput';
import { openDocOnNewTab } from '../../utils/utils';
import { URL_FILE_SOCOVESA_PROD } from '../../constants/urlSocovesa';

import {
  getUnitTypesByProject,
  getActiveClientsByProject,
  getClientsByUnitType,
} from '../../services/projectsService';

import './MultipleUpload.scss';

const uploadTypeOptions = [
  { value: 'POR CLIENTE', label: 'POR CLIENTE', dialog: true },
  { value: 'A TODO EL PROYECTO', label: 'A TODO EL PROYECTO', dialog: false },
  { value: 'POR TIPO DE UNIDAD', label: 'POR TIPO DE UNIDAD', dialog: true },
];

const dialogOption = ['POR TIPO DE UNIDAD', 'POR CLIENTE'];

const useGetUnitTypes = projectId => {
  const [unitTypes, setUnitTypes] = useState([]);
  const [allSelectedUnitTypes, setAllSelectedUnitTypes] = useState(false);

  useEffect(() => {
    getUnitTypesByProject(
      projectId,
      ({ data }) => {
        setUnitTypes(data.data.map(unitType => ({ ...unitType, checked: false, projectId })));
      },
      err => {
        console.error(err);
      },
    );
  }, [projectId]);

  const handleUnitTypeCheck = unitTypeId => {
    setUnitTypes(prevUnitTypes =>
      prevUnitTypes.map(unitType =>
        (unitType.idModelo === unitTypeId ? { ...unitType, checked: !unitType.checked } : unitType),
      ),
    );
  };

  const selectAllUnitTypes = () => {
    const updatedFlag = !allSelectedUnitTypes;
    setAllSelectedUnitTypes(updatedFlag);
    setUnitTypes(prevUnitTypes =>
      prevUnitTypes.map(unitType => ({ ...unitType, checked: updatedFlag })),
    );
  };

  return {
    unitTypes,
    setUnitTypes,
    handleUnitTypeCheck,
    selectAllUnitTypes,
    allSelectedUnitTypes,
  };
};

const useGetClients = projectId => {
  const [clientList, setClientList] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  useEffect(() => {
    getActiveClientsByProject(
      projectId,
      '',
      ({ data: { result } }) => {
        setClientList(
          [...result].map(({ id_Cliente: clientId, ...ele }) => ({ clientId, ...ele })),
        );
      },
      err => {
        console.error(err);
      },
    );
  }, [projectId]);
  const handleCheck = id => {
    const updateClientList = [...clientList].map(({ clientId, checked = false, ...ele }) => {
      return { ...ele, checked: clientId === id ? !checked : checked, clientId };
    });
    setClientList([...updateClientList]);
  };

  const selectAllClients = () => {
    const updatedFlag = !allSelected;
    const updateClientList = [...clientList].map(element => ({ ...element, checked: updatedFlag }));
    setClientList([...updateClientList]);
    setAllSelected(updatedFlag);
  };

  return { clientList, setClientList, handleCheck, selectAllClients, allSelected };
};

const useHandleDialog = fileOptions => {
  const [openDialog, setOpenDialog] = useState(false);
  const { uploadType } = fileOptions;
  const toogleDialog = () => {
    setOpenDialog(!openDialog);
  };
  useEffect(() => {
    if (uploadType !== '' && dialogOption.includes(uploadType)) {
      setOpenDialog(true);
    }
  }, [uploadType]);
  return { openDialog, toogleDialog };
};

const useHandleFiles = () => {
  const [file, setFileValue] = useState();
  const handleFileChange = ({ target: { files } }, name) => {
    setFileValue({ files, name });
  };
  const cleanFile = () => {
    setFileValue(undefined);
  };
  return { file, handleFileChange, cleanFile };
};

const MultipleUpload = ({
  projectName,
  idProject,
  onSubmit,
  documentList = [],
  listDocuments = [],
  deleteCallback,
}) => {
  const [fileOptions, setFileOptions] = useState({ fileType: '', uploadType: '' });
  const { file, handleFileChange, cleanFile } = useHandleFiles();
  const handleSelectUpdate = useCallback(
    ({ target: { value, name } }) => {
      setFileOptions({ ...fileOptions, [name]: value });
    },
    [setFileOptions, fileOptions],
  );
  const { openDialog, toogleDialog } = useHandleDialog(fileOptions);
  const {
    unitTypes,
    handleUnitTypeCheck,
    selectAllUnitTypes,
    allSelectedUnitTypes,
  } = useGetUnitTypes(idProject);
  const { clientList, setClientList, handleCheck, selectAllClients, allSelected } = useGetClients(
    idProject,
  );
  const handleSubmit = async () => {
    const { name, files } = file;
    if (fileOptions.uploadType === 'POR TIPO DE UNIDAD') {
      const promises = unitTypes
        .filter(({ checked }) => checked)
        .map(unitType => {
          return new Promise((resolve, reject) => {
            getClientsByUnitType(
              idProject,
              unitType.modelo,
              ({ data }) => {
                resolve(data.data.map(item => item.cliente));
              },
              reject,
            );
          });
        });

      Promise.all(promises)
        .then(results => {
          const clientIds = results.flat();
          if (onSubmit && clientIds.length > 0) {
            onSubmit(name, files, fileOptions.uploadType, clientIds);
            setFileOptions({ fileType: '', uploadType: '' });
            cleanFile();
            setClientList(clientList.map(client => ({ ...client, checked: false })));
          }
        })
        .catch(error => {
          console.error('Error al obtener clientes por tipo de unidad', error);
        });
    } else {
      const formatClientList = clientList
        .filter(({ checked = false }) => checked)
        .map(({ clientId }) => clientId);
      if (onSubmit) {
        onSubmit(name, files, fileOptions.uploadType, [...formatClientList]);
        setFileOptions({ fileType: '', uploadType: '' });
        cleanFile();
        setClientList(clientList.map(client => ({ ...client, checked: false })));
      }
    }
  };

  return (
    <Fragment>
      <div className="MultipleUpload">
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Grid container spacing={0} alignContent="space-around">
              <Grid item xs={12}>
                <div className="MultipleUpload__title">
                  {!file ? (
                    <Typography variant="subtitle2">ADJUNTAR DOCUMENTO :</Typography>
                  ) : (
                    <MultipleUploadItem
                      name={file.name}
                      to={fileOptions.uploadType}
                      onDelete={cleanFile}
                    />
                  )}
                </div>
              </Grid>
              <Grid item xs={12} sm={12}>
                <div className="MultipleUpload__input">
                  <TextField
                    name="fileType"
                    value={fileOptions.fileType}
                    //label="SELECCIONE TIPO DE DOCUMENTO"
                    label="NOMBRE DEL DOCUMENTO"
                    variant="outlined"
                    onChange={handleSelectUpdate}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12}>
                <div className="MultipleUpload__input">
                  <SelectInput
                    name="uploadType"
                    options={uploadTypeOptions}
                    value={fileOptions.uploadType}
                    disabled={file === undefined}
                    label="TIPO DE ENVIO"
                    variant="outlined"
                    onChange={handleSelectUpdate}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="MultipleUpload__submitButton">
                  {!file ? (
                    <Fragment>
                      <FileUploadInput
                        onChange={event => handleFileChange(event, fileOptions.fileType)}
                        disabled={fileOptions.fileType === ''}
                        name="multipleFileUpload"
                        buttonLabel="CARGAR DOCUMENTO"
                      />
                    </Fragment>
                  ) : (
                    <Button onClick={handleSubmit} style={{ width: '60%' }}>
                      enviar
                    </Button>
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      {/* {documentList && documentList.length ? (
        <div className="MultipleUploadList">
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Typography variant="subtitle2">DOCUMENTOS ENVIADOS:</Typography>
            </Grid>
            <Grid item xs={12}>
              <ul className="MultipleUploadList__list">
                {documentList.map(
                  ({
                    tipo_documento: docName,
                    destinatario: to,
                    _id: id,
                    nombre_archivos: docServerName,
                  }) => (
                    <MultipleUploadItem
                      name={docName}
                      to={to}
                      onDelete={deleteCallback}
                      id={id}
                      key={id}
                      onClick={() => {
                        openDocOnNewTab(`${URL_FILE_SOCOVESA_PROD + docServerName[0]}`);
                      }}
                    />
                  ),
                )}
              </ul>
            </Grid>
          </Grid>
        </div>
      ) : (
        undefined
      )} */}
      {listDocuments && listDocuments.length ? (
        <div className="MultipleUploadList">
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Typography variant="subtitle2">DOCUMENTOS ENVIADOS:</Typography>
            </Grid>
            <Grid item xs={12}>
              <ul className="MultipleUploadList__list">
                {listDocuments.map(
                  ({
                    label: docName,
                    destinatario: to,
                    etag: id,
                    name: name,
                  }) => (
                    <MultipleUploadItem
                      name={docName}
                      to={to}
                      onDelete={deleteCallback}
                      id={id}
                      key={id}
                      onClick={() => {
                        openDocOnNewTab(`${process.env.REACT_APP_URL_BLOBSTORAGE}/${name}`);
                      }}
                    />
                  )
                )}
              </ul>
            </Grid>
          </Grid>
        </div>
      )
        : (undefined)}
      <Dialog
        title={projectName}
        open={openDialog}
        actions={
          <div className="MultipleUpload__dialog__action">
            <Button variant="contained" onClick={toogleDialog}>
              aceptar
            </Button>
          </div>
        }
      >
        <div className="MultipleUpload__dialog">
          <DeleteButton
            label={false}
            style={{ position: 'absolute', top: '20px', right: '20px' }}
            onClick={toogleDialog}
          />
          {clientList && fileOptions.uploadType === 'POR CLIENTE' && clientList.length > 0 ? (
            <FormControl component="fieldset">
              <FormLabel component="legend">SELECCIONE EL/LOS CLIENTES </FormLabel>
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  value=""
                  control={<Checkbox color="primary" checked={allSelected} />}
                  label="Seleccionar todos"
                  onClick={() => selectAllClients(allSelected)}
                  labelPlacement="end"
                />
                {clientList.map(
                  ({
                    clientId,
                    nombres,
                    apellidos,
                    metros_Cuadrados: metrosCuadrados,
                    checked = false,
                  }) => (
                    <FormControlLabel
                      value={clientId}
                      control={<Checkbox color="primary" checked={checked} />}
                      label={`${nombres} ${apellidos} (Dpto. - ${metrosCuadrados}) `}
                      onChange={() => handleCheck(clientId)}
                      labelPlacement="end"
                      key={clientId}
                    />
                  ),
                )}
              </FormGroup>
            </FormControl>
          ) : null}
          {unitTypes && unitTypes.length > 0 && fileOptions.uploadType === 'POR TIPO DE UNIDAD' && (
            <FormControl component="fieldset">
              <FormLabel component="legend">SELECCIONE EL/LOS TIPOS DE UNIDAD</FormLabel>
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  value=""
                  control={
                    <Checkbox
                      color="primary"
                      checked={allSelectedUnitTypes}
                      onChange={() => selectAllUnitTypes()}
                    />
                  }
                  label="Seleccionar todos"
                  labelPlacement="end"
                />
                {unitTypes.map(unitType => (
                  <FormControlLabel
                    key={unitType.idModelo}
                    control={
                      <Checkbox
                        color="primary"
                        checked={unitType.checked}
                        onChange={() => handleUnitTypeCheck(unitType.idModelo)}
                      />
                    }
                    label={`${unitType.modelo} (${unitType.idModelo})`}
                  />
                ))}
              </FormGroup>
            </FormControl>
          )}
        </div>
      </Dialog>
    </Fragment>
  );
};

MultipleUpload.propTypes = {
  projectName: Proptypes.string,
  idProject: Proptypes.string,
  onSubmit: Proptypes.func,
  documentList: Proptypes.array,
  deleteCallback: Proptypes.func,
};

export default MultipleUpload;
