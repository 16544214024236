import { useEffect, useState } from 'react';
import {
  agregarSolicitud,
  getComponentesPorElemento,
  getElementosPorRecinto,
  getFallasPorComponente,
  getImagenesRequerimiento,
  getPropiedadesPostVenta,
  getPropiedadesPostVentaAdittional,
  getRecintosPorUnidad,
  getRequerimientosPostVenta,
  getSolicitudesPostVenta,
  getPropiedadesPostVentaAdmin
} from '../services/postventaService';
import { set } from 'ramda';

export const usePropiedadesPostventa = (cliendId, subsidiary, additional, isOwner, isAdditional, isAdmin, previousProperties = []) => {
  const [propiedadesPV, setpropiedadesPV] = useState({});
  const [propiedadesPVAdicional, setpropiedadesPVAdicional] = useState({});
  const [propiedadesPVAdmin, setpropiedadesPVAdmin] = useState({});
  useEffect(() => {
    if (!!cliendId && cliendId !== '') {
      if (isAdditional) {
        getPropiedadesPostVentaAdittional(
          additional,
          ({ data: { data } }) => {
            setpropiedadesPVAdicional(data);
          },
          err => {
            console.error(err);
          },
        );
      }
      if (isOwner) {
        if (!!previousProperties && previousProperties.length > 0) {
          setpropiedadesPV(previousProperties)
        } else {
          getPropiedadesPostVenta(
            cliendId,
            ({ data: { data } }) => {
              setpropiedadesPV(
                data.filter(propiedad => {
                  const filial = propiedad.filial.split(' ')[0];
                  return filial.toUpperCase() === subsidiary.toUpperCase();
                }),
              );
            },
            err => {
              console.error(err);
            },
          );
        }
      }
      if (isAdmin) {
        if (!!previousProperties && previousProperties.length > 0) {
          setpropiedadesPVAdmin(previousProperties);
        } else {
          getPropiedadesPostVentaAdmin(
            cliendId,
            ({ data: { data } }) => {
              setpropiedadesPVAdmin(
                data.filter(propiedad => {
                  const filial = propiedad.filial.split(' ')[0];
                  return filial.toUpperCase() === subsidiary.toUpperCase();
                }),
              );
            },
            err => {
              console.error(err);
            },
          );
        }
      }
    }
  }, [cliendId, subsidiary, additional]);
  return {
    propiedadesPV,
    setpropiedadesPV,
    propiedadesPVAdicional,
    setpropiedadesPVAdicional,
    propiedadesPVAdmin,
    setpropiedadesPVAdmin,
  };
};

export const usePropiedadesPostventaAdditional = (cliendId, subsidiary) => {
  const [propiedadesPVAdditional, setpropiedadesPVAdditional] = useState({});
  useEffect(() => {
    if (cliendId && cliendId !== '') {
      const mock = getPropiedadesPostVentaAdittional(
        cliendId,
        ({ data: { data } }) => {
          setpropiedadesPVAdditional(
            data.filter(propiedad => {
              const filial = propiedad.filial.split(' ')[0];
              return filial.toUpperCase() === subsidiary.toUpperCase();
            }),
          );
        },
        err => {
          console.error(err);
        },
      );
      setpropiedadesPVAdditional(mock.data);
    }
  }, [cliendId, subsidiary]);
  return { propiedadesPVAdditional, setpropiedadesPVAdditional };
};

export const useImagenesPorRequerimiento = (idRequerimiento, loading = false, setLoading = () => {}) => {
  const [imagenes, setImagenes] = useState([]);
  useEffect(() => {
    if (idRequerimiento && idRequerimiento !== '') {
      setLoading(true);
      getImagenesRequerimiento(
        idRequerimiento,
        ({ data }) => {
          setImagenes(data); 
          setLoading(false); 
        },
        err => {
          console.error(err); 
          setLoading(false); 
        }
      );
    }
  }, [idRequerimiento]);

  return { imagenes, setImagenes };
};

export const useSolicitudPorUnidad = (unidadId, additionalRut, callback = null) => {
  const [solicitudes, setSolicitudes] = useState([]);
  useEffect(() => {
    setSolicitudes([]);
    if (!!callback) callback([]);
    if (unidadId && unidadId !== '') {
      getSolicitudesPostVenta(
        unidadId,
        additionalRut,
        ({ data }) => {
          setSolicitudes(
            data.map(solicitud => ({
              ...solicitud,
              expanded: false,
            })),
          );
          if (!!callback) {
            callback(
              data.map(solicitud => ({
                ...solicitud,
                expanded: false,
              })),
            );
          }
        },
        err => {
          console.error(err);
        },
      );
    }
  }, [unidadId]);
  return { solicitudes, setSolicitudes };
};

export const useRequerimientosPorSolicitud = solicitudId => {
  const [requerimientos, setRequerimientos] = useState([]);
  const [documentos, setDocumentos] = useState([]);
  useEffect(() => {
    if (solicitudId && solicitudId !== '') {
      setRequerimientos([]);
      getRequerimientosPostVenta(
        solicitudId,
        ({ data }) => {
          setRequerimientos(
            data.requerimientos.map(requerimiento => ({
              ...requerimiento,
              solicitudId,
            })),
          );
          setDocumentos(
            data.documentos.map(documento => ({
              ...documento,
              solicitudId,
            })),
          );
        },
        err => {
          console.error(err);
        },
      );
    }
  }, [solicitudId]);
  return { requerimientos, documentos, setRequerimientos };
};

export const useRecintosPorUnidad = unidadId => {
  const [recintosBack, setRecintos] = useState([]);
  useEffect(() => {
    if (unidadId && unidadId !== '') {
      setRecintos([]);
      getRecintosPorUnidad(
        unidadId,
        ({ data }) => {
          setRecintos(data.recintos);
        },
        err => {
          console.error(err);
        },
      );
    }
  }, [unidadId]);
  return { recintosBack };
};

export const useElementosPorRecinto = recintoid => {
  const [elementosBack, setElementosBack] = useState([]);
  useEffect(() => {
    if (recintoid && recintoid !== '') {
      setElementosBack([]);
      getElementosPorRecinto(
        recintoid,
        ({ data }) => {
          setElementosBack(data.elementos);
        },
        err => {
          console.error(err);
        },
      );
    }
  }, [recintoid]);
  return { elementosBack, setElementosBack };
};

export const useComponentesPorElemento = elementoid => {
  const [componentes, setComponentes] = useState([]);
  useEffect(() => {
    if (elementoid && elementoid !== '') {
      setComponentes([]);
      getComponentesPorElemento(
        elementoid,
        ({ data }) => {
          setComponentes(data.componentes);
        },
        err => {
          console.error(err);
        },
      );
    }
  }, [elementoid]);
  return { componentes, setComponentes };
};

export const useFallasPorComponente = componenteid => {
  const [fallas, setFallas] = useState([]);
  useEffect(() => {
    if (componenteid && componenteid !== '') {
      setFallas([]);
      getFallasPorComponente(
        componenteid,
        ({ data }) => {
          setFallas(data.fallas);
        },
        err => {
          console.error(err);
        },
      );
    }
  }, [componenteid]);
  return { fallas, setFallas };
};
