import React from 'react';
import PropTypes from 'prop-types';

import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/styles';

const labelStyles = () => ({
  root: {},
  shrink: {
    transform: 'translate(3px, -15px) scale(0.75)!important',
  },
});

const CustomLabel = ({ children, ...rest }) => <InputLabel {...rest}>{children}</InputLabel>;

CustomLabel.propTypes = {
  children: PropTypes.node,
};

export default withStyles(labelStyles)(CustomLabel);
