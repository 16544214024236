import React, { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';

// Component
import MessageList from '../../components/MessageList';

import {
  getMessagesByProject,
  markMessageAsRead,
  answerByMessage,
  sendMassiveMessage,
} from '../../services/messageService';

import './EnterpriseMessageItem.scss';

const updateMessageList = (
  activeProject,
  username,
  setMessageList,
  setMessagesDetail,
  filterValue,
  updateFlag,
  currentPage = 1,
  limit = 10,
  clientRut,
) => {
  if (updateFlag) {
    getMessagesByProject(
      activeProject,
      !!clientRut ? username.split('-')[0] : undefined,
      filterValue,
      currentPage,
      limit,
      true,
      ({ data: { data, totalRows } }) => {
        setMessageList([...data]);
        setMessagesDetail({ total: totalRows });
      },
      err => {
        console.error(err);
      },
    );
  }
};

const useHandlePagination = ({ total }) => {
  const basePage = 1;
  const limit = 10;
  const [currentPage, setCurrentPagination] = useState(basePage);
  const [totalPages, setTotalPages] = useState(0);
  useEffect(() => {
    if (total && total > 0) {
      setTotalPages(Math.ceil(total / limit));
    }
  }, [total]);
  const forwardPage = () => {
    if (currentPage < totalPages) {
      setCurrentPagination(currentPage + 1);
    }
  };
  const backwardPage = () => {
    if (currentPage > 0) {
      setCurrentPagination(currentPage - 1);
    }
  };
  return { currentPage, forwardPage, backwardPage, totalPages, limit };
};

const useMessageList = (activeProject, username, filterValue, textFilter, updateFlag, clientRut) => {
  const [messageList, setMessageList] = useState([]);
  const [messagesDetail, setMessagesDetail] = useState({
    total: undefined,
  });
  const { currentPage, forwardPage, backwardPage, totalPages, limit } = useHandlePagination(
    messagesDetail,
  );
  useEffect(() => {
    const rut = clientRut || username
    if (rut && rut !== '' && activeProject && updateFlag) {
      if (textFilter === '') {
        updateMessageList(
          activeProject,
          rut,
          setMessageList,
          setMessagesDetail,
          filterValue,
          updateFlag,
          currentPage,
          limit,
          clientRut
        );
      }
    }
  }, [activeProject, username, filterValue, currentPage, updateFlag]);
  return {
    messagesDetail,
    messageList,
    setMessageList,
    setMessagesDetail,
    textFilter,
    currentPage,
    limit,
    forwardPage,
    backwardPage,
    totalPages,
  };
};

const useFilterValue = () => {
  const [filterValue, setFilterValue] = useState('tipo=RECIBIDOS&');
  const onFilterChange = (tabValue = 1) => {
    switch (tabValue) {
      case 1:
        setFilterValue('tipo=RECIBIDOS&');
        break;
      case 2:
        setFilterValue('tipo=ENVIADOS&');
        break;
      default:
        setFilterValue('leido=RECIBIDOS&');
        break;
    }
  };
  return { filterValue, onFilterChange };
};

const EnterpriseMessageItem = ({
  subsidiary,
  projectId,
  projectName,
  className,
  from,
  username,
  fullName,
  textFilter = '',
  openAtStart,
  filteredMessages = [],
  haveNew = false,
  clientRut = null,
  onReadMessages,
}) => {
  const { filterValue, onFilterChange } = useFilterValue();
  const [updateList, setUpdateList] = useState(false);
  const {
    messagesDetail,
    messageList,
    setMessageList,
    setMessagesDetail,
    currentPage,
    limit,
    forwardPage,
    backwardPage,
    totalPages,
  } = useMessageList(projectId, username, filterValue, textFilter, updateList, clientRut);

  const handleOnCollapseList = () => {
    if (messageList.length === 0 && filteredMessages.length === 0) {
      setUpdateList(true);
    }
  };

  const onSubmitAnswerMessage = (messageId, message, onSubmitCallback) => {
    answerByMessage(
      messageId,
      message,
      () => {
        onSubmitCallback();
        updateMessageList(
          projectId,
          username,
          setMessageList,
          setMessagesDetail,
          filterValue,
          updateList,
          currentPage,
          limit,
          clientRut,
        );
      },
      err => {
        console.error(err);
      },
    );
  };

  const markAsRead = messageId => {
    markMessageAsRead(
      messageId,
      () => {
        updateMessageList(
          projectId,
          username,
          setMessageList,
          setMessagesDetail,
          filterValue,
          updateList,
          currentPage,
          limit,
          clientRut,
        );
        onReadMessages()
      },
      err => {
        console.error(err);
      },
    );
    haveNew = false;
  };

  const submitNewMessage = (asunto, message, clients, resetForm, file) => {
    sendMassiveMessage(
      projectId,
      asunto,
      username,
      clients,
      message,
      fullName,
      file,
      () => {
        updateMessageList(
          projectId,
          username,
          setMessageList,
          setMessagesDetail,
          filterValue,
          updateList,
          currentPage,
          limit,
          clientRut
        );
        resetForm();
      },
      err => {
        console.error(err);
      },
    );
  };
  return (
    <MessageList
      className={className}
      projectName={projectName}
      messages={textFilter !== '' ? [...filteredMessages] : [...messageList]}
      subsidiary={subsidiary}
      onSubmitAnswerMessage={onSubmitAnswerMessage}
      onSubmitNewMessage={submitNewMessage}
      messagesDetail={messagesDetail}
      onCollapseItem={markAsRead}
      onCollapseList={handleOnCollapseList}
      onTabChange={onFilterChange}
      from={username}
      to={clientRut}
      projectId={projectId}
      openAtStart={openAtStart}
      currentPage={currentPage}
      forwardPage={forwardPage}
      backwardPage={backwardPage}
      totalPages={totalPages}
      disabledTabs={textFilter !== ''}
      haveNew={haveNew}
    />
  );
};

EnterpriseMessageItem.propTypes = {
  subsidiary: PropTypes.string,
  projectId: PropTypes.string,
  projectName: PropTypes.string,
  className: PropTypes.string,
  username: PropTypes.string,
  fullName: PropTypes.string,
  textFilter: PropTypes.string,
  openAtStart: PropTypes.bool,
  filteredMessages: PropTypes.array,
};

export default memo(EnterpriseMessageItem);
