import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from '@material-ui/core';
import { CheckCircleOutlineOutlined, FileCopyOutlined } from '@material-ui/icons';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import AssignmentIcon from '@material-ui/icons/Assignment';

import DownArrowIconComponent from '../Icons/DownArrow';
import { colorConfig } from '../../constants/colors';
import RequirementIcon from '../Icons/Requirement';
import SolicitudIcon from '../Icons/Solicitud';
import { useRequerimientosPorSolicitud } from '../../customHooks/postventa';
import Download from '../Icons/Download';
import ArrowDownCircle from '../Icons/ArrowDownCircle/ArrowDownCircle';
import './RequestsPostVenta.scss';
import Dialog from '../Dialog';
import './ProjectListPostVenta.scss';
import pluralize from 'pluralize';
import { red } from '@material-ui/core/colors';

const inconColor = {
  not_sent: '#c70000',
  managing: '#e6c200',
  closed: '#158000ff',
}

const RequestsPostVenta = ({
  requests = [],
  subsidiary,
  handleNewReq,
  ConfirmarSolicitud,
  setDialogOpen,
  setSelectedRequirement,
  initialRequestQuantity,
  requerimientos,
  documentos,
  setSelectedRequest,
  setActiveSolicitud,
  setNroSolicitud
}) => {

  const [estadoSolicitud, setEstadoSolicitud] = useState();
  const [finalRequest, setFinalRequest] = useState([]);
  const [requestsShowing, setRequestsShowing] = useState(-1);
  const [openSendRequest, setOpenSendRequest] = useState(false)
  const [requestToSend, setRequestToSend] = useState({})

  const addMoreRows = () => {
    setRequestsShowing(requestsShowing + 5);
  };

  useEffect(() => {
    if (finalRequest.length > 0) {
      finalRequest.map(request => {
        if (request.expanded) {
          setSelectedRequest(request.solicitudId);
          setEstadoSolicitud(!request.SolicitudConfirmada);
          setActiveSolicitud(request);
          setNroSolicitud(request.nroSolicitud);
        }
      });
    }
  }, [finalRequest]);

  useEffect(() => {
    if (initialRequestQuantity) {
      setRequestsShowing(initialRequestQuantity);
    }
  }, [initialRequestQuantity]);

  useEffect(() => {
    if (requestsShowing !== -1) {
      setFinalRequest(
        requests.slice(0, requestsShowing).map((request) => ({
          ...request,
          expanded: !request.SolicitudConfirmada, // Inicialmente expandido si no está confirmada
        }))
      );
    } else {
      setFinalRequest(
        requests.map((request) => ({
          ...request,
          expanded: !request.SolicitudConfirmada, // Inicialmente expandido si no está confirmada
        }))
      );
    }
  }, [requests, requestsShowing]);
  
  const toggleExpanded = (solicitudId) => {
    setFinalRequest((prevRequests) =>
      prevRequests.map((request) =>
        request.solicitudId === solicitudId
          ? { ...request, expanded: !request.expanded }
          : { ...request, expanded: false }
      )
    );
  };

  const clickedRequirement = (requerimiento, confirmada) => {
    setSelectedRequirement(requerimiento);
    setActiveSolicitud(requerimiento)
    setNroSolicitud(requerimiento.nroSolicitud)
    if (confirmada) {
      handleNewReq(true, false);
    } else {
      setDialogOpen(true);
    }
  };

  const getIconColor = request => {
    if (!request.SolicitudConfirmada) return inconColor['not_sent']
    if (request.estadoSolicitud.toUpperCase().indexOf('CERRAD') === -1 &&
      request.estadoSolicitud.toUpperCase().indexOf('NO CORRESPONDE') === -1) return inconColor['managing']

    return inconColor['closed']
  }

  const sendRequest = request => {
    setRequestToSend(request)
    setOpenSendRequest(true)
  }
  return (
    <div>
      <Dialog
        title={<div className='send-request-title'>Enviar solicitud {requestToSend.nroSolicitud} a Postventa</div>}
        open={openSendRequest}
        onEnter={() => setOpenSendRequest(true)}
        onExit={() => setOpenSendRequest(false)}
        disableBackdropClick={false}
        maxWidth={'sm'}
        actions={
          <div className="ProjectDetailPV__newRequest__buttons">
            <Button variant="outlined"
              className='danger'
              onClick={() => setOpenSendRequest(false)}
              title="Volver atrás"
            >
              Cancelar
            </Button>
            <Button variant="outlined"
              className='primary'
              onClick={() => {
                setOpenSendRequest(false)
                handleNewReq(true, true)
              }}
              title={`Agregar un nuevo requerimiento a la solicitud ${requestToSend.nroSolicitud}`}>
              Agregar Requerimiento
            </Button>
            <Button variant="outlined"
              className='primary'
              onClick={() => {
                setOpenSendRequest(false)
                ConfirmarSolicitud(requestToSend.solicitudId)
              }}
              title={`Enviar la solicitud ${requestToSend.nroSolicitud} a Postventa ${subsidiary}`}>
              Enviar
            </Button>
          </div>
        }>
        <div className='ProjectDetailPV__newRequest'>
          <span className='ProjectDetailPV__newRequest__content'>
            {requerimientos.length === 1 ? (
              <>
                <p className="warning">
                  Usted está por enviar una solicitud con un solo requerimiento
                </p>
                <p>¿Seguro ud. no tiene más requerimientos para agregar en esta solicitud?</p>
                <p className="warning">
                  Al enviar ya no podrá agregar ni editar requerimientos para esta solicitud.
                </p>
              </>
            ) : (
              <>
                <p>
                  Usted está por enviar la solicitud {requestToSend.nroSolicitud} con{' '}
                  {requerimientos.length} {pluralize('requerimiento', requerimientos.length)} a
                  Postventa <span className="capitalize">{subsidiary}</span>
                </p>
                <p>
                  También podría editar la solicitud {requestToSend.nroSolicitud} para agregar
                  nuevos requerimientos o editando los existentes.
                </p>
                <p className="warning">
                  Al enviar ya no podrá agregar ni editar requerimientos para esta solicitud.
                </p>
              </>
            )}
          </span>
        </div>
      </Dialog>
      {requests.length > 0 && (
        <>
          {finalRequest.map((solicitud, index) => {
            return (
              <div className="ProjectList__expansionPanelDetails">
                <ExpansionPanel
                  key={index}
                  expanded={solicitud.expanded}
                  onChange={() => {
                    toggleExpanded(solicitud.solicitudId);
                  }}
                  style={{ boxShadow: 'none' }}
                >
                  <ExpansionPanelSummary
                    expandIcon={<DownArrowIconComponent color={colorConfig[subsidiary].icons} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{ border: '1px solid', borderRadius: '5px' }}
                  >
                    <div className="ProjectDetailPV__expansionPanelSummary">
                      <span className="ProjectDetailPV__expansionPanelSummary__UnitName">
                        <div>
                          <SolicitudIcon color={getIconColor(solicitud)} />
                          <div className="ProjectDetailPV__expansionPanelSummary__UnitName__Nro">
                            Número de{' '}
                          </div>{' '}
                          solicitud: {solicitud.nroSolicitud}
                        </div>
                      </span>
                      <div className="ProjectDetailPV__expansionPanelSummary__solicitud-data">
                        <div className="ProjectDetailPV__expansionPanelSummary__solicitud-data__one">
                          {!solicitud.expanded && (
                            <span className="ProjectDetailPV__expansionPanelSummary__addressName">
                              <strong>Fecha de solicitud: </strong>
                              {solicitud.fechaIngreso}
                            </span>
                          )}
                          {!solicitud.expanded &&  <span className="ProjectDetailPV__expansionPanelSummary__estado-solicitud" />}
                          <span className="ProjectDetailPV__expansionPanelSummary__addressName">
                            <strong>Estado: </strong>
                            {!solicitud.SolicitudConfirmada ? (<strong className='warning'>Solicitud Aún no Enviada a Postventa</strong>) : (<>{solicitud.estadoSolicitud}</>)}
                          </span>
                        </div>
                        <div className="ProjectDetailPV__expansionPanelSummary__solicitud-data__two">
                          {!solicitud.expanded && (
                            <>
                              <span className="ProjectDetailPV__expansionPanelSummary__addressName">
                                <strong>Solicitante: </strong>
                                {solicitud.solicitante}
                              </span>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </ExpansionPanelSummary>
                  {solicitud.SolicitudConfirmada || !handleNewReq || !ConfirmarSolicitud ? null : (
                    <div className="ProjectDetailPV__expansionPanelDetails__botones">
                      <Button
                        onClick={() => handleNewReq(true, true)}
                        style={{
                          color: colorConfig[subsidiary].icons,
                          textTransform: 'none',
                        }}
                        variant="outlined"
                      >
                        <FileCopyOutlined color={colorConfig[subsidiary].icons} />
                        Nuevo requerimiento
                      </Button>
                      <Button
                        onClick={() => sendRequest(solicitud)}
                        style={{
                          color: colorConfig[subsidiary].primary,
                          textTransform: 'none',
                        }}
                        variant="outlined"
                      >
                        <CheckCircleOutlineOutlined color={colorConfig[subsidiary].primary} />
                        Enviar solicitud a Postventa
                      </Button>
                    </div>
                  )}
                  <ExpansionPanelDetails>
                    <div className="ProjectDetailPV__expansionPanelDetails__documents">
                      {solicitud.expanded &&
                        documentos.length > 0 &&
                        documentos.reverse().map((documento, secondIndex) => {
                          const right = secondIndex * 25 + 56;
                          return (
                            <div
                              className="ProjectDetailPV__expansionPanelDetails__document"
                              style={{ display: 'flex', flexDirection: 'column' }}
                            >
                              <div 
                                className="ProjectDetailPV__expansionPanelDetails__document__content"
                                style={{ display: 'flex', alignItems: 'center' }}
                              >
                                <a
                                  href={documento.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{ color: colorConfig[subsidiary].primary }}
                                >
                                  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBottom: '2px'}}>
                                    <Download
                                      color={
                                        documento.IdOV ? '#40CFFF' : documento.IdOT ? 'green' : 'red'
                                      }
                                      alt="Descargar"
                                    />
                                    <div
                                      style={{
                                        color: documento.IdOV ? '#40CFFF' : documento.IdOT ? 'green' : 'red'
                                      }}
                                    >
                                      {documento.detalle}
                                    </div>
                                  </div>
                                </a>
                              </div>
                            </div>
                          );
                        })
                      }
                    </div>
                    <div className="ProjectDetailPV__expansionPanelDetails__mobile">
                      {solicitud.expanded &&
                        requerimientos &&
                        requerimientos.length > 0 &&
                        requerimientos.map(requerimiento => {
                          return (
                            <div className="ProjectDetailPV__expansionPanelDetails__mobile__requirement">
                              <div className="ProjectDetailPV__expansionPanelDetails__mobile__details">
                                <div
                                  onClick={() => clickedRequirement(requerimiento, estadoSolicitud)}
                                  className="ProjectDetailPV__expansionPanelDetails__mobile__details__icon"
                                >
                                  {estadoSolicitud ? (
                                    <EditIcon color={colorConfig[subsidiary].icons} />
                                  ) : (
                                    <AssignmentIcon color={colorConfig[subsidiary].icons} />
                                  )}
                                </div>
                                <strong>Nro: </strong>
                                <span>{requerimiento.nroRequerimiento}</span>
                                <strong>Recinto: </strong>
                                <span>{requerimiento.recintoEspecifico}</span>
                                <strong>Elemento: </strong>
                                <span>{requerimiento.elemento}</span>
                                <strong>Componente: </strong>
                                <span>{requerimiento.componente}</span>
                                <strong>Falla: </strong>
                                <span>{requerimiento.falla}</span>
                                <strong>Descripción: </strong>
                                <span>{requerimiento.descripcionRequerimiento}</span>
                                <strong>Estado: </strong>
                                <span>
                                  {requerimiento.estado.toUpperCase().indexOf('CERR') !== -1 ? (
                                    <CheckCircleIcon htmlColor="olivedrab" fontSize="small" />
                                  ) : (
                                    <WatchLaterIcon htmlColor="goldenrod" fontSize="small" />
                                  )}
                                  <span>
                                    {requerimiento.estadoOV ||
                                      requerimiento.estadoOT ||
                                      requerimiento.estado}
                                  </span>
                                </span>
                              </div>
                            </div>
                          );
                        })}
                    </div>

                    <div className="ProjectDetailPV__expansionPanelDetails__desktop">
                      {solicitud.expanded && requerimientos && requerimientos.length > 0 ? (
                        <div className="ProjectDetailPV__expansionPanelDetails" key={index}>
                          <div
                            className="ProjectDetailPV__expansionPanelDetails__table"
                            style={{ color: colorConfig[subsidiary].quaternary }}
                          >
                            <div className="ProjectDetailPV__expansionPanelDetails__table__row__header" />
                            <div className="ProjectDetailPV__expansionPanelDetails__table__row__header">
                              Nro
                            </div>
                            <div className="ProjectDetailPV__expansionPanelDetails__table__row__header">
                              Recinto
                            </div>
                            <div className="ProjectDetailPV__expansionPanelDetails__table__row__header">
                              Elemento
                            </div>
                            <div className="ProjectDetailPV__expansionPanelDetails__table__row__header">
                              Componente
                            </div>
                            <div className="ProjectDetailPV__expansionPanelDetails__table__row__header">
                              Falla
                            </div>
                            <div className="ProjectDetailPV__expansionPanelDetails__table__row__header">
                              Descripción
                            </div>
                            <div className="ProjectDetailPV__expansionPanelDetails__table__row__header">
                              Estado
                            </div>
                            {requerimientos.map(requerimiento => {
                              if (requerimiento.solicitudId === solicitud.solicitudId) {
                                return (
                                  <>
                                    <div
                                      onClick={() =>
                                        clickedRequirement(requerimiento, estadoSolicitud)
                                      }
                                      className="ProjectDetailPV__expansionPanelDetails__table__row__cell ProjectDetailPV__expansionPanelDetails__table__row__cell__icon"
                                    >
                                      {estadoSolicitud ? (
                                        <Tooltip title="Editar requerimiento">
                                          <IconButton>
                                            <EditIcon color={colorConfig[subsidiary].icons} />
                                          </IconButton>
                                        </Tooltip>
                                      ) : (
                                        <Tooltip title="Ver requerimiento">
                                          <IconButton>
                                            <AssignmentIcon color={colorConfig[subsidiary].icons} />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                    </div>
                                    <div className="ProjectDetailPV__expansionPanelDetails__table__row__cell">
                                      {requerimiento.nroRequerimiento}
                                    </div>
                                    <div className="ProjectDetailPV__expansionPanelDetails__table__row__cell">
                                      {requerimiento.recintoEspecifico}
                                    </div>
                                    <div className="ProjectDetailPV__expansionPanelDetails__table__row__cell">
                                      {requerimiento.elemento}
                                    </div>
                                    <div className="ProjectDetailPV__expansionPanelDetails__table__row__cell">
                                      {requerimiento.componente}
                                    </div>
                                    <div className="ProjectDetailPV__expansionPanelDetails__table__row__cell">
                                      {requerimiento.falla}
                                    </div>
                                    <div className="ProjectDetailPV__expansionPanelDetails__table__row__cell">
                                      {requerimiento.descripcionRequerimiento}
                                    </div>
                                    <div className="ProjectDetailPV__expansionPanelDetails__table__row__cell">
                                      <span>
                                        {requerimiento.estado.toUpperCase().indexOf('SOLUCI') !==
                                          -1 ? (
                                          <CheckCircleIcon htmlColor="olivedrab" fontSize="small" />
                                        ) : (
                                          <WatchLaterIcon htmlColor="goldenrod" fontSize="small" />
                                        )}
                                      </span>
                                      <span>
                                        {requerimiento.estadoOV ||
                                          requerimiento.estadoOT ||
                                          requerimiento.estado}
                                      </span>
                                    </div>
                                  </>
                                );
                              }
                            })}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </div>
            );
          })}
          {finalRequest.length >= requestsShowing && requestsShowing > -1 && (
            <div className="add-more-container">
              <Button variant="text" onClick={addMoreRows} padding="0px">
                <span className="EnterpriseMessageList__addMoreRows__label">
                  cargar más Solicitudes
                  <ArrowDownCircle className="EnterpriseMessageList__addMoreRows__icon" />
                </span>
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

RequestsPostVenta.propTypes = {
  requests: PropTypes.array,
  subsidiary: PropTypes.string,
  handleNewReq: PropTypes.func,
  ConfirmarSolicitud: PropTypes.func,
  setDialogOpen: PropTypes.func,
  setSelectedRequirement: PropTypes.func,
  initialRequestQuantity: PropTypes.number,
};

export default RequestsPostVenta;
