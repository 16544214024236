import React, { memo, useContext, useMemo } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import PropTypes from 'prop-types';

// components
import Login from '../../screens/Login';
import ForgotPassword from '../../screens/ForgotPassword';
import RecoverPassword from '../../screens/RecoverPassword';
import LoginContainerComponent from '../../screens/LoginContainer';

// router
import EnterpriseLayout from './EnterpriseLayout';

import { capitalizeEveryWord } from '../../utils/utils';

import ThemeManager from '../../themes/mainTheme';
import { GlobalContext } from '../../providers/GlobalStateProvider';
import { useIslogged } from '../../customHooks/useIslogged';

const EnterpriseRouter = ({
   match: {
    path,
     params: { subsidiary },
  },
  //subsidiary,
}) => {
  const host = window.location.host.split('.')[0];
  document.title = process.env.REACT_APP_TITLE + " - " + capitalizeEveryWord(`${subsidiary} Socovesa`);
  const theme = useMemo(() => ThemeManager(subsidiary), [subsidiary]);
  return (
    <ThemeProvider theme={theme}>
      <Switch>
        <Route
          path={`${path}`}
          exact
          render={props => (
            <LoginContainerComponent subsidiary={subsidiary}>
              <Login {...props} subsidiary={subsidiary} />
            </LoginContainerComponent>
          )}
        />
        <Route
          path={`${path}/ForgotPassword`}
          render={props => (
            <LoginContainerComponent subsidiary={subsidiary}>
              <ForgotPassword {...props} />
            </LoginContainerComponent>
          )}
        />
        <Route path={`${path}/RecoverPassword/:token?`} component={RecoverPassword} />
        <Route path={`${path}/Home`} component={EnterpriseLayout} />
        <Route path={`/Home`} component={EnterpriseLayout} />
        {!useIslogged() && <Redirect to={`/${subsidiary}`} />}
      </Switch>
    </ThemeProvider>
  );
};

EnterpriseRouter.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    params: PropTypes.shape({
      subsidiary: PropTypes.string,
    }),
  }),
};

export default memo(EnterpriseRouter);
