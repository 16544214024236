import React from 'react';
import PropTypes from 'prop-types';
import Button from '../ButtonTypes';

import './PropertyItem.scss';

import RightArrowButton from '../Icons/RightArrowButton';

const PropertyItem = ({ numero, piso, propertyKind, subsidiary, onClick }) => (
  <div className={`PropertyItem PropertyItem--${subsidiary}`}>
    <div className="PropertyItem__propertyNumber">
      <span className="PropertyItem__propertyNumber__label">{propertyKind}</span>
      <span className="PropertyItem__propertyNumber__number">{numero}</span>
    </div>
    <div className="PropertyItem__propertyNumber">
      <span className="PropertyItem__propertyNumber__label">Etapa:</span>
      <span className="PropertyItem__propertyNumber__number">{piso}</span>
      <Button variant="text" onClick={onClick}>
        <RightArrowButton className="PropertyItem__propertyNumber__button" />
      </Button>
    </div>
  </div>
);

PropertyItem.propTypes = {
  numero: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  piso: PropTypes.string,
  subsidiary: PropTypes.string,
  onClick: PropTypes.func,
};

export default PropertyItem;
