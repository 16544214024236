import React, { useState } from 'react';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { es } from 'date-fns/locale';

const DatePicker = ({ onChange, name, firstValue, ...props }) => {
  const { useDate, setDate } = useState(new Date());

  const [ firstValueState, setFirstValueState ] = useState(firstValue ? 'lightgray' : 'black');
  const handleChange = (name, value) => {
    onChange(name, value);
    setFirstValueState('black');
  }

  return (
    <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
      <KeyboardDatePicker
        {...props}
        name={name}
        onChange={value => handleChange(name, value)}
        InputProps={{
          style: {
            color: firstValueState,
          }
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePicker;
