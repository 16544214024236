import React, { Fragment } from 'react';

import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { getBackGrounds } from '../../utils/getLogos';

import './LoginContainer.scss';

const LoginContainer = ({ subsidiary = '', children }) => {
  const matches = useMediaQuery('(max-width: 768px)');
  return (
    <div className={`LoginContainer LoginContainer--${subsidiary}`}>
      <Grid container spacing={0} className="LoginContainer__container ">
        <Fragment>
          {matches || (
            <Grid item xs={12} sm={7}>
              <div className="Login__background">
                <img
                  className="Login__background__image"
                  {...getBackGrounds(subsidiary)}
                  alt="background"
                />
              </div>
            </Grid>
          )}
        </Fragment>
        <Grid item xs={12} sm={5}>
          {children}
        </Grid>
      </Grid>
    </div>
  );
};

LoginContainer.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    params: PropTypes.shape({
      subsidiary: PropTypes.string,
    }),
  }),
  subsidiary: PropTypes.string,
  children: PropTypes.node,
};

export default LoginContainer;
