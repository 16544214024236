import React from 'react';

import IconPropTypes from '../IconPropTypes';

const RightArrowButton = ({ onClick, styles, className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    onClick={onClick}
    style={{ ...{ pointer: onClick ? 'cursor' : 'pointer' }, ...styles }}
    className={className}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M15 30c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15C6.716 0 0 6.716 0 15c0 8.284 6.716 15 15 15zm0-2C7.82 28 2 22.18 2 15S7.82 2 15 2s13 5.82 13 13-5.82 13-13 13z"
      />
      <path d="M4 27V3h24v24z" />
      <path
        fill="currentColor"
        d="M12.884 20.278a.956.956 0 0 1 .007-1.385L16.937 15l-4.046-3.893a.957.957 0 0 1-.007-1.385l.034-.034a1.042 1.042 0 0 1 1.434 0l4.816 4.624a.944.944 0 0 1 0 1.376l-4.816 4.624a1.049 1.049 0 0 1-1.434 0l-.034-.034z"
      />
    </g>
  </svg>
);

RightArrowButton.propTypes = IconPropTypes;

export default RightArrowButton;
