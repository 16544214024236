import React from 'react';
import IconPropTypes from '../IconPropTypes';

const ListIcon = ({ className, color = '#FFD5B9', secondaryColor = '#FFFFFF', onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width="30"
    height="30"
    viewBox="0 0 30 30"
    onClick={onClick}
  >
    <g fill="none" fillRule="evenodd">
      <rect width="30" height="30" fill={color} rx="2" />
      <path
        fill={secondaryColor}
        fillRule="nonzero"
        d="M4.5 22.921c0-1.22.94-2.21 2.1-2.21 1.16 0 2.1.99 2.1 2.21s-.94 2.21-2.1 2.21c-1.16 0-2.1-.99-2.1-2.21zm2.1-14c1.16 0 2.1-.99 2.1-2.21C8.7 5.49 7.76 4.5 6.6 4.5c-1.16 0-2.1.99-2.1 2.21 0 1.221.94 2.211 2.1 2.211zm0 8.105c1.16 0 2.1-.99 2.1-2.21 0-1.221-.94-2.21-2.1-2.21-1.16 0-2.1.989-2.1 2.21 0 1.22.94 2.21 2.1 2.21zm5.747-8.197h11.606c.854 0 1.547-.804 1.547-1.796s-.693-1.796-1.547-1.796H12.347c-.854 0-1.547.804-1.547 1.796s.693 1.796 1.547 1.796zm0 8.105h11.606c.854 0 1.547-.804 1.547-1.796s-.693-1.796-1.547-1.796H12.347c-.854 0-1.547.804-1.547 1.796s.693 1.796 1.547 1.796zm0 8.105h11.606c.854 0 1.547-.804 1.547-1.796s-.693-1.796-1.547-1.796H12.347c-.854 0-1.547.804-1.547 1.796s.693 1.796 1.547 1.796z"
      />
    </g>
  </svg>
);

ListIcon.propTypes = IconPropTypes;

export default ListIcon;
