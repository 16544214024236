/* eslint-disable import/prefer-default-export */
import { useEffect, useState } from 'react';
import { getNotificationList } from '../services/clientService';

export const useNotificationList = (cliendId, read) => {
  const [notificationList, setNotificationList] = useState({});
  useEffect(() => {
    if (cliendId && cliendId !== '') {
      getNotificationList(
        cliendId,
        ({ data: { data } }) => {
          setNotificationList({ ...data[0] });
        },
        err => {
          console.error(err);
        },
      );
    }
  }, [cliendId, read]);
  return { notificationList, setNotificationList };
};
