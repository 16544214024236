import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '@material-ui/core';

// componentes and scss
import './ClientDetail.scss';
import DownArrowIconComponent from '../../components/Icons/DownArrow';
import { colorConfig } from '../../constants/colors';
import DocumentList from '../../components/DocumentList/DocumentList';
import MilestonCalendar from '../../components/MilestonesCalendar/MilestonCalendar';

// services
import { useSignedDocumentsByUnit } from '../../customHooks/fetchSignedDocumentsByProperty ';
import { useMinutesByUnit } from '../../customHooks/fetchMinutessByProperty';
import DocumentListExec from '../../components/DocumentList/DocumentListExec';
import { useRequerimientosPorSolicitud, useSolicitudPorUnidad } from '../../customHooks/postventa';
import RequirementView from '../../components/ProjectListPostVenta/RequirementView';
import RequestsPostVenta from '../../components/ProjectListPostVenta/RequestsPostVenta';
import FilterRequests from '../../components/ProjectListPostVenta/FilterRequests';
import Dialog from '../../components/Dialog';

const ClientProperties = ({ properties = [], projectId, clientId, subsidiary }) => {
  const [finalProperties, setFinalProperties] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState('');
  const [selectedPropertyId, setSelectedPropertyId] = useState('');
  const { signedDocuments, setSignedDocuments } = useSignedDocumentsByUnit(
    clientId,
    selectedProperty,
    projectId,
  );
  const { minutes, setMinutes } = useMinutesByUnit(selectedProperty);
  const { solicitudes, setSolicitudes } = useSolicitudPorUnidad(selectedPropertyId);
  const [filteredRequests, setFilteredRequests] = useState([])
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentRequirement, setCurrentRequirement] = useState({});
  const [selectedRequest, setSelectedRequest] = useState('');
  const { requerimientos, documentos } = useRequerimientosPorSolicitud(
    selectedRequest,
  );
  const [activeSolicitud, setActiveSolicitud] = useState();
  const [nroSolicitud, setNroSolicitud] = useState();

  const selectProperty = property => {
    setSelectedProperty(!!property ? property.id_Propiedad : '')
    setSelectedPropertyId(!!property ? property.id_PropiedadPV : '')
  };

  useEffect(() => {
    setFinalProperties(
      properties.map(property => {
        property.expanded = false;
        return property;
      }),
    );
  }, [properties]);

  useEffect(() => {
    if (finalProperties.length === 1) selectProperty(finalProperties[0]);
  }, [finalProperties]);

  const changeExpandedProperty = propertyId => {
    setFinalProperties(
      finalProperties.map(property => {
        if (property.id_Propiedad === propertyId) {
          property.expanded = !property.expanded;
          if (property.expanded) selectProperty(property);
          else selectProperty('');
        } else property.expanded = false;
        return property;
      }),
    );
  };

  const accordionContent = property => (
    <div className="ClientDocuments">
      <h5>DOCUMENTOS FIRMADOS</h5>
      {signedDocuments && signedDocuments.length > 0 ? (
        <>
          <DocumentList subsidiary={subsidiary} fileList={signedDocuments} />
        </>
      ) : (
        <i>El negocio no tiene documentos firmados.</i>
      )}
      <h5>ACTAS DE ENTREGA</h5>
      {minutes && minutes.length > 0 ? (
        <>
          <DocumentListExec subsidiary={subsidiary} fileList={minutes} onlyList />
        </>
      ) : (
        <i>El cliente no tiene actas de entrega</i>
      )}
      {
        !!solicitudes && solicitudes.length > 0 && (
          <>
            <h5>SOLICITUDES</h5>
            <div className='filter'>
              <FilterRequests requests={solicitudes} setRequests={setFilteredRequests} />
            </div>
            <RequestsPostVenta
              requests={filteredRequests}
              subsidiary={subsidiary}
              setDialogOpen={setDialogOpen}
              setSelectedRequirement={setCurrentRequirement}
              initialRequestQuantity={10}
              requerimientos={requerimientos}
              documentos={documentos}
              setSelectedRequest={setSelectedRequest}
              setActiveSolicitud={setActiveSolicitud}
              setNroSolicitud={setNroSolicitud}
            />
          </>
        )}
      <h5>CALENDARIO DE HITOS</h5>
      <MilestonCalendar
        idProject={projectId}
        clientId={clientId}
        propertyId={property.id_Propiedad}
        propertyNumber={property.numero_Departamento}
      />
    </div>
  );

  return (
    <div className="ClientProperties">
      <Dialog
        open={dialogOpen}
        className="dialog-view"
        onClose={() => setDialogOpen(false)}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        scroll="paper"
        classes={{ paper: 'dialog-view' }}
      >
        <RequirementView
          requirement={currentRequirement}
          handleClose={() => setDialogOpen(false)}
          subsidiary={subsidiary}
        />
      </Dialog>
      {finalProperties.length > 1 ? (
        <>
          {finalProperties.map((property, index) => {
            return (
              <ExpansionPanel
                key={index}
                expanded={property.expanded}
                onChange={() => {
                  changeExpandedProperty(property.id_Propiedad);
                }}
                className="ClientProperty"
              >
                <ExpansionPanelSummary
                  expandIcon={<DownArrowIconComponent color={colorConfig[subsidiary].icons} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={{ border: '1px solid', borderRadius: '5px' }}
                >
                  <h4>{`${property.tipo_Unidad} ${property.numero_Departamento}`}</h4>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ borderRadius: '5px' }}>
                  {selectedProperty === property.id_Propiedad && accordionContent(selectedProperty)}
                </ExpansionPanelDetails>
              </ExpansionPanel>
            );
          })}
        </>
      ) : (
        finalProperties.length === 1 && (
          <>
            <h4>{`${finalProperties[0].tipo_Unidad} ${finalProperties[0].numero_Departamento}`}</h4>
            {accordionContent(selectedProperty)}
          </>
        )
      )}
    </div>
  );
};

ClientProperties.propTypes = {
  properties: PropTypes.array,
  projectId: PropTypes.string,
  clientId: PropTypes.string,
  subsidiary: PropTypes.string,
};

export default ClientProperties;
