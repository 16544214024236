export const almagro = 'almagro';
export const socovesa = 'socovesa';
export const pilares = 'pilares';
export const empresas = 'empresas';

export const almagroCode = '1300';
export const socovesaCode = '1600';
export const pilaresCode = '1050';

export const filialCodeOptions = [almagroCode, socovesaCode, pilaresCode];

export const filialNames = [almagro, pilares, socovesa];

export const subsidiaryOptions = [
  { label: almagro, value: almagroCode },
  { label: pilares, value: pilaresCode },
  { label: socovesa, value: socovesaCode },
];

export const subsidiaryOptionsById = [
  { label: almagro, id: almagroCode },
  { label: pilares, id: pilaresCode },
  { label: socovesa, id: socovesaCode },
];

export const subsidiaryOptionsByText = [
  { label: almagro, value: almagro },
  { label: pilares, value: pilares },
  { label: socovesa, value: socovesa },
];

// Botones del menú en el home
export const gallery = 'gallery';
export const list = 'list';

// Para logo blanco de Socovesa
export const socovesaMenu = 'socovesaMenu';
