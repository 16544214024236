import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '../ButtonTypes';
import Dialog from '../Dialog';
import DeleteButton from '../DeleteButton';
import Searchbar from '../Searchbar/Searchbar';

const usePropClientList = propClientList => {
  const [clientList, setClientList] = useState([]);
  useEffect(() => {
    setClientList([...propClientList]);
  }, [propClientList]);
  return { clientList, setClientList };
};

const ClientSelectionDialog = ({
  open,
  onEnter,
  toogleCallback,
  clientList: propClientList = [],
  projectName,
  setCheckedList,
}) => {
  const { clientList, setClientList } = usePropClientList(propClientList);
  const [allSelected, setAllSelected] = useState(false);
  useMemo(() => {
    if (allSelected) {
      const checkIfAllSelected = clientList.every(({ checked = false }) => checked);
      setAllSelected(checkIfAllSelected);
    }
  }, [clientList]);
  const handleCheck = id => {
    const updatedClientList = [...clientList].map(({ clientId, checked = false, ...ele }) => {
      return { ...ele, checked: clientId === id ? !checked : checked, clientId };
    });
    const updatedListClient = [...listClient].map(({ clientId, checked = false, ...ele }) => {
      return { ...ele, checked: clientId === id ? !checked : checked, clientId };
    });
    setClientList([...updatedClientList]);
    setListClient([...updatedListClient]);
  };

  const updateCheckedList = () => {
    const checkedList = listClient.filter(({ checked }) => checked);
    setCheckedList(checkedList);
  };

  const selectAllClients = () => {
    const updatedValue = !allSelected;
    const updatedClientList = [...clientList].map(element => ({
      ...element,
      checked: updatedValue,
    }));
    const updatedListClient = [...listClient].map(element => ({
      ...element,
      checked: updatedValue,
    }));
    setClientList([...updatedClientList]);
    setListClient([...updatedListClient]);
    setAllSelected(updatedValue);
  };
  const [ searchClient, setSearchClient ] = useState('');
  const [ filteredClientList, setFilteredClientList ] = useState([]);
  useEffect(() => {
    setFilteredClientList([]);
    if (searchClient != '') {
      const filteredList = clientList.filter(client => client.apellidos.toLowerCase().indexOf(searchClient.toLowerCase()) !== -1 || client.nombres.toLowerCase().indexOf(searchClient.toLowerCase()) !== -1);
      setFilteredClientList(filteredList);
    }
  } , [searchClient, clientList]);
  const [ listClient, setListClient ] = useState([]);
  useEffect(() => {
    if (filteredClientList.length === 0) {
      setListClient(clientList);
    } else {
      setListClient(filteredClientList);
    }
  } , [filteredClientList, clientList]);

  return clientList && clientList.length ? (
    <Dialog
      title={projectName}
      open={open}
      onEnter={onEnter}
      onExit={updateCheckedList}
      actions={
        <div className="MultipleUpload__dialog__action">
          <Button variant="contained" onClick={toogleCallback}>
            aceptar
          </Button>
        </div>
      }
    >
      <div className="MultipleUpload__dialog">
        <DeleteButton
          label={false}
          style={{ position: 'absolute', top: '20px', right: '20px' }}
          onClick={toogleCallback}
        />
        {listClient && listClient.length > 0 ? (
          <FormControl component="fieldset">
            <Searchbar 
              onChange={setSearchClient}
              value={searchClient}
            />
            <FormLabel component="legend">SELECCIONE EL/LOS CLIENTES </FormLabel>
            <FormGroup aria-label="position" row>
              {listClient.length > 1 && (
                <FormControlLabel
                  value=""
                  control={<Checkbox color="primary" checked={allSelected} />}
                  label="Seleccionar todos"
                  onClick={() => selectAllClients()}
                  labelPlacement="end"
                />
              )}
              {listClient.map(
                // ({
                //   clientId,
                //   nombres,
                //   apellidos,
                //   //metros_Cuadrados: metrosCuadrados,
                //   checked = false,
                // }) =>  {
                cliente => {
                  return (
                  <FormControlLabel
                    value={cliente.clientId}
                    control={<Checkbox color="primary" checked={cliente.checked} />}
                    // label={`${nombres} ${apellidos} (Dpto. - ${metrosCuadrados}) `}
                    label={`${cliente.nombres} ${cliente.apellidos}`}
                    onChange={() => handleCheck(cliente.clientId)}
                    labelPlacement="end"
                    key={cliente.clientId}
                  />
                )}
              )}
            </FormGroup>
          </FormControl>
        ) : null}
      </div>
    </Dialog>
  ) : null;
};

ClientSelectionDialog.propTypes = {
  open: PropTypes.bool,
  onEnter: PropTypes.func,
  toogleCallback: PropTypes.func,
  clientList: PropTypes.array,
  projectName: PropTypes.string,
  setCheckedList: PropTypes.func,
};

export default ClientSelectionDialog;
