import React from 'react';
import IconPropTypes from '../IconPropTypes';

const BackArrow = ({ className, color = '#4b5763', onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    className={className}
    style={{ cursor: onClick ? 'pointer' : 'auto' }}
    onClick={onClick}
  >
    <path d="M0 0h24v24H0z" fill="none" />
    <path fill={color} d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" />
  </svg>
);

BackArrow.propTypes = IconPropTypes;

export default BackArrow;
