import React, { memo, useMemo } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import PropTypes from 'prop-types';

// components
import Login from '../../screens/Login';
import ForgotPassword from '../../screens/ForgotPassword';
import RecoverPassword from '../../screens/RecoverPassword';
import ClientLayout from './ClientLayout';
import ClientActivation from '../../screens/ClientActivation';
import LoginContainerComponent from '../../screens/LoginContainer';

import { capitalizeEveryWord } from '../../utils/utils';

import ThemeManager from '../../themes/mainTheme';
import { useIslogged } from '../../customHooks/useIslogged';
import { useState } from 'react';

const ClientRouter = ({
  match: {
    path,
    params: { subsidiary },
  },
}) => {
  document.title = process.env.REACT_APP_TITLE + " - " + capitalizeEveryWord(subsidiary);
  const theme = useMemo(() => ThemeManager(subsidiary), [subsidiary]);
  const [isLogged, setIsLogged] = useState(useIslogged());
  return (
    <ThemeProvider theme={theme}>
      <Switch>
        <Route
          path={`${path}`}
          exact
          render={props => (
            <LoginContainerComponent subsidiary={subsidiary}>
              <Login {...props} />
            </LoginContainerComponent>
          )}
        />
        <Route
          path={`${path}/ForgotPassword`}
          render={props => (
            <LoginContainerComponent subsidiary={subsidiary}>
              <ForgotPassword {...props} />
            </LoginContainerComponent>
          )}
        />
        <Route path={`${path}/activacion/:token`} component={ClientActivation} />
        <Route path={`${path}/RecoverPassword/:token?`} component={RecoverPassword} />
        <Route path={`${path}/Home`} component={ClientLayout} />
        {isLogged && <Redirect to={`/${subsidiary}`} />}
      </Switch>
    </ThemeProvider>
  );
};

ClientRouter.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    params: PropTypes.shape({
      subsidiary: PropTypes.string,
    }),
  }),
};

export default memo(ClientRouter);
