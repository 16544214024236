/* eslint-disable no-extra-boolean-cast */
/* eslint-disable import/prefer-default-export */
import { useEffect, useState } from 'react';
import { getOnlyProjectsByUser } from '../services/projectsService';

export const useProjectsByClient = (clientId, projectId = null) => {
  const [projects, setProjects] = useState([]);
  useEffect(() => {
    if (!!clientId) {
      getOnlyProjectsByUser(
        clientId,
        ({ data: { proyectos } }) => {
          proyectos = proyectos.filter(project => !projectId || project.id_Proyecto === projectId)
          setProjects(proyectos);
        },
        err => {
          console.error(err);
        },
      );
    }
  }, [clientId, projectId]);
  return { projects, setProjects };
};
