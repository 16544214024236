import React, { createContext, useReducer, useCallback } from 'react';
import PropTypes from 'prop-types';
import { SELECT_EXECUTIVE, SELECT_CLIENT, REHYDRATE_EXECUTIVE_STATE, SELECT_FILIAL } from '../actions/actionTypes';
import { inicialState, reducer } from '../reducers/enterpriseReducer';

import { HandlePersistence, updateStorage } from '../customHooks/handlePersistence';

const EnterpriseContext = createContext('enterprise');

const EnterpriseProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, inicialState);
  const selectExecutive = useCallback(
    executiveRut => {
      dispatch({ type: SELECT_EXECUTIVE, executiveRut });
      updateStorage({ executiveRut });
    },
    [dispatch],
  );
  const rehydrateState = useCallback(({ executiveRut, client }) => {
    dispatch({ type: REHYDRATE_EXECUTIVE_STATE, executiveRut, client });
  }, []);
  const selectClient = useCallback(
    client => {
      dispatch({ type: SELECT_CLIENT, client });
      updateStorage({ client });
    },
    [dispatch],
  );
  const selectFilial = useCallback(
    filial => {
      dispatch({ type: SELECT_FILIAL, filial });
      updateStorage({ filial });
    },
    [dispatch],
  );
  HandlePersistence(state, rehydrateState);
  return (
    <EnterpriseContext.Provider value={{ state, dispatch, selectExecutive, selectClient, selectFilial }}>
      {children}
    </EnterpriseContext.Provider>
  );
};

EnterpriseProvider.propTypes = {
  children: PropTypes.node,
};

export { EnterpriseProvider, EnterpriseContext };
