import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Button from '../ButtonTypes';
import FileIcon from '../Icons/FileIcon';
import ClearIcon from '../Icons/ClearIcon';
import ArrowDownIcon from '../Icons/ArrowDownIcon';

import { empresas } from '../../constants/filialesNames';
import { URL_FILES_PROD } from '../../constants/urlSocovesa';
import { formatDate, openDocOnNewTab, parseDocumentName, openPromiseOnNewTab } from '../../utils/utils';

import './DocumentItem.scss';
import CheckBox from '../../components/CheckboxTypes';

const DeleteButton = ({ onClick, className }) => (
  <Button onClick={onClick} variant="text" className={className}>
    <span className="DeleteButton">
      <ClearIcon color="currentColor" className="DeleteButton__container" />
      <span className="DeleteButton__label">eliminar</span>
    </span>
  </Button>
);

DeleteButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
};

const DocumentItem = ({ subsidiary, lastModified, name, label, deleteCallback, onCheck, checked = false, link, buttonDel = false, onlyList = false  }) => {

  return (
    <div className={`DocumentItem DocumentItem--${subsidiary}`}>
      {/* {subsidiary === 'empresas' && (
        <CheckBox checked={checked} onCheck={onCheck} />
      )} */}
      <div className={`DocumentItem__simbol DocumentItem__simbol--${subsidiary}`}>
        <FileIcon className="DocumentItem__simbol__icon" color="currentColor" />
      </div>
      <div className={`DocumentItem__container DocumentItem__container--${subsidiary}`}>
        <a
          className="DocumentItem__container__name"
          rel="noopener noreferrer"
          target="_blank"
          href={`${link}`}
        >
          <div className="DocumentItem__nameDateItem">
            <span className="DocumentItem__nameDateItem__date">
              documento publicado {formatDate(lastModified, 'dd/MM/yyyy')}
            </span>
            <span className="DocumentItem__nameDateItem__docname">{label}</span>
            <span className="DocumentItem__nameDateItem__lastModifiedDate">
              {formatDate(lastModified, 'dd/MM/yyyy')}
            </span>
          </div>
        </a>
        <div className="DocumentItem__container__buttons">
          {subsidiary === empresas && (buttonDel  || !onlyList) && (
            <DeleteButton
              className="DocumentItem__container__DeleteButton"
              onClick={deleteCallback}
            />
          )}

          {subsidiary !== empresas && (
            <Fragment>
              <span className="DocumentItem__container__buttons__lastModifiedDate">
                {formatDate(lastModified, 'dd/MM/yyyy')}
              </span>
              <Button
                variant="text"
                onClick={() =>
                  // openDocOnNewTab(`${process.env.REACT_APP_URL_BLOBSTORAGE}/${name}`)
                  window.open(`${link}`, '_blank')
                }
              >
                <ArrowDownIcon
                  className={`DocumentItem__container__icon DocumentItem__container__icon--${subsidiary}`}
                  color="currentColor"
                />
              </Button>

            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

DocumentItem.propTypes = {
  subsidiary: PropTypes.string,
  lastModified: PropTypes.string,
  name: PropTypes.string,
  deleteCallback: PropTypes.func,
};

export default DocumentItem;
