import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from '../ButtonTypes';

import { genCharArray } from '../../utils/utils';

import './AlphabeticalFilter.scss';

const useHandleActive = letterlist => {
  const [active, setActive] = useState(null);
  const onChange = useCallback(
    (activeIndex, onChangeCallback) => {
      const updatedLetterList = [...letterlist];
      if (onChangeCallback && updatedLetterList[activeIndex] !== active) {
        setActive(updatedLetterList[activeIndex]);
        onChangeCallback(updatedLetterList[activeIndex]);
      } else if (onChangeCallback && updatedLetterList[activeIndex] === active) {
        setActive('');
        onChangeCallback('');
      }
    },
    [active],
  );
  return { active, onChange };
};

const useHandleLetterList = () => {
  const [letterList, setLetterList] = useState(['*'].concat(genCharArray('a', 'z')));
  return { letterList, setLetterList };
};

const AlphabeticalFilter = ({ subsidiary, onLetterChangeCallback }) => {
  const { letterList } = useHandleLetterList();
  const { active, onChange } = useHandleActive(letterList, onLetterChangeCallback);
  return (
    <div className="AlphabeticalFilter">
      {letterList.map((letter, index) => (
        <Button variant="text" onClick={() => onChange(index, onLetterChangeCallback)} key={letter}>
          <span
            className={`AlphabeticalFilter__letter AlphabeticalFilter__letter--${subsidiary} ${
              active === letter ? `AlphabeticalFilter__letter--${subsidiary}--active` : ''
            }`}
          >
            {letter}
          </span>
        </Button>
      ))}
    </div>
  );
};

AlphabeticalFilter.propTypes = {
  subsidiary: PropTypes.string,
  onLetterChangeCallback: PropTypes.func,
};

export default AlphabeticalFilter;
