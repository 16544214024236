import React, { useCallback, useState, useEffect, Fragment, useContext } from 'react';
import PropTypes from 'prop-types';

import Collapse from '@material-ui/core/Collapse';
import { Tabs, TabPanel } from '../Tabs';
import Button from '../ButtonTypes';
import DownArrow from '../Icons/DownArrow';
import MessageItem from './MessageItem';
import NewMessageFormEnterprise from './NewMessageForm';
import NewMessageFormClient from './NewMessageFormClient';
import LeftArrowIcon from '../Icons/LeftArrowIcon';
import RightArrowIcon from '../Icons/RightArrowIcon';
import SelectInput from '../SelectInput';

import { empresas } from '../../constants/filialesNames';

import './MessageList.scss';
import { GlobalContext } from '../../providers/GlobalStateProvider';
import { getExecutiveByClient } from '../../services/executive';

// Constants
const TabOptions = [{ label: 'RECIBIDOS', value: 1 }, { label: 'ENVIADOS', value: 2 }];

const dynamicOption = { label: 'REDACTAR MENSAJE', value: 3 };

// Components
const MessagePagination = ({
  currentMessageLength,
  totalMessages,
  currentPage,
  forwardPage,
  backwardPage,
  totalPages,
}) => (
  <span className="MessagePagination">
    <span className="MessagePagination__label">
      1 - {currentMessageLength} de {totalMessages}
    </span>
    <span className="MessagePagination__label">|</span>
    <Button variant="text" disabled={currentPage === 1} onClick={backwardPage}>
      <LeftArrowIcon className="MessagePagination__icon" />
    </Button>
    <Button variant="text" disabled={currentPage === totalPages} onClick={forwardPage}>
      <RightArrowIcon className="MessagePagination__icon" />
    </Button>
  </span>
);

MessagePagination.propTypes = {
  currentMessageLength: PropTypes.number,
  totalMessages: PropTypes.number,
  currentPage: PropTypes.number,
  forwardPage: PropTypes.func,
  backwardPage: PropTypes.func,
  totalPages: PropTypes.number,
  lastPage: PropTypes.number,
};

// Custom Hooks

const useHandleTabs = onTabChange => {
  const [tabValue, setTabValue] = useState(TabOptions[0].value);
  const onChange = useCallback((event, newValue) => {
    if (newValue.props) {
      if (onTabChange) onTabChange(newValue.props.value);
      setTabValue(newValue.props.value);
    } else {
      if (onTabChange) onTabChange(newValue);
      setTabValue(newValue);
    }
  }, []);
  const resetTab = useCallback(() => {
    setTabValue(TabOptions[0].value);
  }, [setTabValue]);
  return { tabValue, onChange, resetTab, setTabValue };
};

const useTabOptions = (messagesDetail, tabValue) => {
  const [tabOptions, setTabOptions] = useState([...TabOptions]);
  useEffect(() => {
    const updatedList = TabOptions.map(elem => ({
      ...elem,
      // key: `(${messagesDetail.total ? messagesDetail.total : 0})`,
    }));
    setTabOptions([...updatedList]);
  }, [TabOptions]);
  useEffect(() => {
    if (tabValue === dynamicOption.value && tabOptions && tabOptions.length) {
      const updatedTabOptions = tabOptions.map(({ value, label, ...rest }) => ({
        ...rest,
        label: value === dynamicOption.value ? dynamicOption.label : label,
        value,
      }));
      setTabOptions(updatedTabOptions);
    } else if (tabValue !== dynamicOption.value && tabOptions && tabOptions.length) {
      const updatedTabOptions = tabOptions.map(({ value, label, ...rest }) => ({
        ...rest,
        label: value === dynamicOption.value ? '' : label,
        value,
      }));
      setTabOptions(updatedTabOptions);
    }
  }, [tabValue]);
  return { tabOptions };
};

const useHandleCollapse = (openAtStart, onCollapseList) => {
  const [handleCollapse, setHandleCollapse] = useState(openAtStart);
  const toogleCollapse = useCallback(() => {
    setHandleCollapse(!handleCollapse);
  }, [handleCollapse]);
  useEffect(() => {
    setHandleCollapse(openAtStart);
  }, [openAtStart]);
  useEffect(() => {
    if (onCollapseList && handleCollapse === true) onCollapseList();
  }, [handleCollapse]);
  return { handleCollapse, toogleCollapse };
};

const MessageList = ({
  projectName,
  subsidiary,
  messages,
  className = '',
  onSubmitAnswerMessage,
  onSubmitNewMessage,
  messagesDetail,
  onCollapseItem,
  onCollapseList,
  onTabChange,
  from,
  to = null,
  projectId,
  openAtStart = true,
  currentPage,
  forwardPage,
  backwardPage,
  totalPages,
  disabledTabs = false,
  haveNew = false,
}) => {

  const [dataExecutive, setDataExecutive] = useState({});
  const getCurrentRut = () => {
    return JSON.parse(sessionStorage.getItem('username'));
  };
  const getDataExecutive = () => {
    const rut = getCurrentRut();
    getExecutiveByClient(
      rut,
      response => {
        setDataExecutive(response.data);
      },
      error => {
        console.error(error);
      },
    );
  };
  useEffect(() => {
    getDataExecutive();
  }, []);

  const { tabValue, onChange, resetTab, setTabValue } = useHandleTabs(onTabChange);
  const { tabOptions } = useTabOptions(messagesDetail, tabValue);
  const { handleCollapse, toogleCollapse } = useHandleCollapse(openAtStart, onCollapseList);
  const writeMessage = () => {
    setTabValue(dynamicOption.value);
  };

  const [onlyMe, setOnlyMe] = useState(true);
  const [messagesFiltered, setMessagesFiltered] = useState(messages);
  useEffect(() => {
    let listaFiltrada = messages;
    const rut = getCurrentRut();
    if (tabValue === 1) {
      if (onlyMe) {
        if (subsidiary === empresas) {
          listaFiltrada = messages.filter(message => parseInt(message.ejecutivo) === rut);
        } else {
          listaFiltrada = messages.filter(message => parseInt(message.receptor) === rut);
        }
      }
    } else if (tabValue === 2) {
      if (onlyMe) {
        if (subsidiary === empresas) {
          listaFiltrada = messages.filter(message => parseInt(message.ejecutivo) === rut);
        } else {
          listaFiltrada = messages.filter(message => parseInt(message.cliente) === rut);
        }
      }
    }
    setMessagesFiltered(listaFiltrada);
  }, [onlyMe, messages, tabValue]);


  return (
    <div className={`MessageList MessageList--${subsidiary} ${className}`}>
      <Button
        variant="text"
        onClick={toogleCollapse}
        className={`MessageList__collapseButton ${
          handleCollapse ? 'MessageList__collapseButton__active' : ''
        }`}
      >
        <DownArrow color="currentColor" />
      </Button>
      <div
        className={`MessageList__header ${handleCollapse ? 'MessageList__header--collapse' : ''}`}
      >
        {haveNew ?
           <h3 className=' MessageList__header__title MessageList__header__title__notRead'>{projectName && projectName}</h3>
        :  <h3 className="MessageList__header__title">{projectName && projectName}</h3>
        }
        <div className="MessageList__header__colapse__mobile">
          {tabValue !== dynamicOption.value && (
            <Fragment>
              <Button
                className="MMessageList__header__colapse__mobile__writeMessageButton"
                onClick={writeMessage}
              >
                REDACTAR MENSAJE
              </Button>
              <SelectInput options={TabOptions} value={tabValue} onChange={onChange} />
            </Fragment>
          )}
        </div>
        <Collapse in={handleCollapse} className="MessageList__header__colapse">
          {handleCollapse && (
            <Fragment>
              <div className="MessageList__header__colapse__tabContainer">
                {!disabledTabs && (
                  <Tabs
                    options={tabOptions}
                    value={tabValue}
                    onChange={onChange}
                    textColor="inherit"
                  />
                )}
                {tabValue !== dynamicOption.value && (
                  <>
                    <Button
                      className="MessageList__header__colapse__writeMessageButton"
                      onClick={writeMessage}
                    >
                      REDACTAR MENSAJE
                    </Button>
                    {subsidiary === 'empresas' ? (
                    <label style={{marginLeft: '90px'}}>
                    <input type="checkbox" checked={onlyMe} onChange={()=>setOnlyMe(!onlyMe)} />
                      Sólo mis mensajes
                    </label>
                    ) : null}
                  </>
                )}
                {/* {messages && messages.length > 0 && messagesDetail.total >= 10 && ( */}
                {messagesFiltered && messagesFiltered.length > 0 && messagesDetail.total >= 10 && (
                  <MessagePagination
                    currentMessageLength={messagesFiltered.length}
                    totalMessages={messagesDetail.total}
                    currentPage={currentPage}
                    forwardPage={forwardPage}
                    backwardPage={backwardPage}
                    totalPages={totalPages}
                  />
                )}
              </div>
            </Fragment>
          )}
        </Collapse>
      </div>
      <Collapse in={handleCollapse}>
        <div className="MessageList__body">
          <TabPanel
            value={tabValue}
            index={tabValue < TabOptions.length ? tabValue : TabOptions.length}
            className="MessageList__body__tabPanel"
          >
            {messagesFiltered && messagesFiltered.length > 0 ? (
              <ul className="MessageList__body__list">
                {messagesFiltered.map(
                  ({
                    asunto,
                    nombre_emisor: nombreEmisor,
                    nombre_receptor: nombreReceptor,
                    mensajes: responses,
                    fecha_ultimo_mensaje: lastMessageDate,
                    leidos: read = false,
                    attached: attached,
                    _id,
                  }) => (
                    <MessageItem
                      subsidiary={subsidiary}
                      read={read}
                      subject={asunto}
                      from={nombreEmisor}
                      at={nombreReceptor}
                      messages={responses}
                      attached={attached}
                      lastMessageDate={lastMessageDate}
                      messageId={_id}
                      onSubmit={onSubmitAnswerMessage}
                      onCollapseItem={onCollapseItem}
                      key={_id}
                    />
                  ),
                )}
              </ul>
            ) : (
              <span>No tiene Mensajes</span>
            )}
          </TabPanel>
          {tabValue === dynamicOption.value && (
            <TabPanel value={tabValue} index={dynamicOption.value}>
              {subsidiary === empresas ? (
                <NewMessageFormEnterprise
                  onSubmit={onSubmitNewMessage}
                  resetTab={resetTab}
                  from={from}
                  to={to}
                  projectId={projectId}
                  projectName={projectName}
                  subsidiary={subsidiary}
                />
              ) : (
                <NewMessageFormClient
                  onSubmit={onSubmitNewMessage}
                  resetTab={resetTab}
                  from={from}
                  projectId={projectId}
                  projectName={projectName}
                  to={dataExecutive.rut}
                  subsidiary={subsidiary}
                />
              )}
            </TabPanel>
          )}
        </div>
      </Collapse>
      {messagesFiltered && messagesFiltered.length > 0 && messagesDetail.total >= 10 && (
        <MessagePagination
          currentMessageLength={messagesFiltered.length}
          totalMessages={messagesDetail.total}
          currentPage={currentPage}
          forwardPage={forwardPage}
          backwardPage={backwardPage}
          totalPages={totalPages}
        />
      )}
    </div>
  );
};

MessageList.propTypes = {
  projectName: PropTypes.string,
  subsidiary: PropTypes.string,
  messages: PropTypes.array,
  className: PropTypes.string,
  onSubmitAnswerMessage: PropTypes.func,
  onSubmitNewMessage: PropTypes.func,
  projectId: PropTypes.string,
  from: PropTypes.string,
  messagesDetail: PropTypes.shape({
    total: PropTypes.number,
  }),
  onCollapseItem: PropTypes.func,
  onCollapseList: PropTypes.func,
  onTabChange: PropTypes.func,
  openAtStart: PropTypes.bool,
  currentPage: PropTypes.number,
  forwardPage: PropTypes.func,
  backwardPage: PropTypes.func,
  totalPages: PropTypes.number,
  disabledTabs: PropTypes.bool,
};

export default MessageList;
