import React, { useState, useCallback, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';

// Formik
import { withFormik, Form } from 'formik';

// Inputs
import TextFields from '../TextFields';
import DeleteButton from '../DeleteButtonSmall';
import Button from '../ButtonTypes';
import SelectInput from '../SelectInput';

// Dialog
import ClientSelectionDialog from '../ClientSelectionDialog';

import { getActiveClientsByProject } from '../../services/projectsService';

// Scss
import './NewMessageForm.scss';
import FileUploadInput from '../FileUploadInput/FileUploadInput';
import { uploadFileMessage } from '../../services/fileService';
import ClipIcon from '../../assets/paper-clip.svg';
import ClearIcon from '../Icons/ClearIcon/ClearIcon';
import { deleteMessageDocument } from '../../services/documents';
import { colorConfig } from '../../constants/colors';

export const uploadTypeOptions = [
  { value: 'POR CLIENTE', label: 'POR CLIENTE', dialog: true },
  { value: 'A TODO EL PROYECTO', label: 'A TODO EL PROYECTO', dialog: false },
];

const useToLabel = (clients = []) => {
  const [toLabel, setToLabel] = useState('');
  useEffect(() => {
    setToLabel(`${clients.length} clientes`);
  }, [clients]);
  return { toLabel };
};

const ToChip = ({ clients, onClick, toogleDialog }) => {
  const { toLabel } = useToLabel(clients);
  return (
    <span className="toChip">
      <Button variant="text" onClick={toogleDialog}>
        {toLabel}
      </Button>
      <Button variant="text" onClick={onClick} style={{ marginLeft: '20px' }}>
        <DeleteButton />
      </Button>
    </span>
  );
};

ToChip.propTypes = {
  clients: PropTypes.array,
  onClick: PropTypes.func,
  toogleDialog: PropTypes.func,
};

const ToInput = ({
  value: inputValue,
  onChange,
  selectedClients,
  name,
  resetToInput,
  toogleDialog,
  label,
  placeholder = label,
}) => {
  const handleSelectUpdate = useCallback(
    e => {
      onChange(e);
    },
    [inputValue],
  );
  const resetValue = () => {
    resetToInput();
  };
  return inputValue && inputValue.length && inputValue !== 'A TODO EL PROYECTO' ? (
    <ToChip clients={selectedClients} onClick={resetValue} toogleDialog={toogleDialog} />
  ) : (
    <SelectInput
      options={uploadTypeOptions}
      value={inputValue}
      name={name}
      onChange={handleSelectUpdate}
      label={label}
      placeholder={placeholder}
    />
  );
};

ToInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string,
  selectedClients: PropTypes.array,
  resetToInput: PropTypes.func,
  toogleDialog: PropTypes.func,
  placeholder: PropTypes.string,
};

const useOpenDialog = to => {
  const [openDialog, setOpenDialog] = useState(false);
  const toogleDialog = useCallback(() => {
    setOpenDialog(!openDialog);
  }, [openDialog, setOpenDialog]);
  useEffect(() => {
    const determineIfOpenDialog = uploadTypeOptions.find(({ value }) => value === to);
    if (determineIfOpenDialog && determineIfOpenDialog.dialog) {
      setOpenDialog(determineIfOpenDialog.dialog);
    }
  }, [to]);
  return { openDialog, toogleDialog };
};

const useClientList = (projectId, openDialog) => {
  const [clientList, setClientList] = useState([]);
  useEffect(() => {
    if (clientList && clientList.length === 0 && openDialog) {
      getActiveClientsByProject(
        projectId,
        '',
        ({ data: { result } }) => {
          setClientList(
            [...result].map(({ id_Cliente: clientId, ...ele }) => ({ clientId, ...ele })),
          );
        },
        err => {
          console.error(err);
        },
      );
    }
  }, [openDialog]);
  return { clientList, setClientList };
};

const NewMessageForm = ({
  handleSubmit,
  values,
  handleBlur,
  handleChange,
  setFieldValue,
  errors,
  touched,
  projectId,
  projectName,
  resetTab,
  to = null,
  from,
  subsidiary,
}) => {
  const [ selectedClients, setSelectedClients ] = useState([]);
  const { openDialog, toogleDialog } = useOpenDialog(values.to);
  const { clientList } = useClientList(projectId, true);
  useEffect(() => {
    setFieldValue('to', [...selectedClients]);
  }, [selectedClients]);
  const resetToInput = () => {
    setFieldValue('to', '');
    setSelectedClients([]);
  };
  useEffect(() => {
    if(!to) return;

    setSelectedClients(clientList.filter(client => client.clientId === to.split('-')[0]));
  }, [to, clientList])

  const [ fileKey, setFileKey ] = useState(Date.now());
  const [ fileLoading, setFileLoading ] = useState(false);
  const [ selectedFile, setSelectedFile ] = useState([]);
  const [_openDialog, _setOpenDialog] = useState(false);
  const [fileName, setFileName] = useState();
  const _toogleDialog = () => {
    _setOpenDialog(!_openDialog);
    setFileKey(Date.now());
  };
  const openModalDocumentName = event => {
    setSelectedFile(event.target.files);
    _toogleDialog();
  };
  useEffect(() => {
    if (selectedFile.length > 0) {
      setFileLoading(true);
      const file = selectedFile[0];
      uploadFileMessage(file, from, projectId, result => {
        setFileLoading(false)
        setFieldValue('file', result.data.link);
        setSelectedFile([]);
        setFileName({
          name: file.name,
          etag: result.data.etag,
        });
      })
    }
  }, [selectedFile, from, projectId]);

  useEffect(()=>{
    if (values.to === 'A TODO EL PROYECTO') {
      setSelectedClients(clientList);
    }
  },[values, clientList]);

  const deleteFile = (nombre) => {
    const label = nombre.split('/').slice(-2).join('/');
    deleteMessageDocument(label, () => {
      setFieldValue('file', '');
      setFileName();
    });
  }

  return (
    <Fragment>
      <Form className="NewMessageForm__form" onSubmit={handleSubmit}>
        <div className="NewMessageForm__form__input" style={!!to ? {display: 'none'} : {}}>
          <ToInput
            name="to"
            value={values.to}
            onChange={handleChange}
            resetToInput={resetToInput}
            onBlur={handleBlur}
            error={errors.to && touched.to}
            valid={touched.to && !errors.to && values.to !== ''}
            helperText={errors.to}
            toogleDialog={toogleDialog}
            selectedClients={selectedClients}
            label="PARA:"
            placeholder="SELECCIONE TIPO DE MENSAJE"
          />
        </div>
        <div className="NewMessageForm__form__input">
          <TextFields
            name="title"
            value={values.title}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.title && touched.title}
            valid={touched.title && !errors.title && values.title !== ''}
            helperText={errors.title}
            label="TITULO:"
          />
        </div>
        <div className="NewMessageForm__form__input">
          <TextFields
            name="message"
            value={values.message}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.message && touched.message}
            valid={touched.message && !errors.message && values.message !== ''}
            helperText={errors.message}
            label=" MENSAJE:"
            multiline
            rows={20}
          />
        </div>
        <div className='NewMessageForm__form__fileUpload'>
          <FileUploadInput
            name="file"
            accept="*"
            buttonLabel={<img src={ClipIcon} width="15px" height="15px" />}
            fileKey={fileKey}
            onChange={e => openModalDocumentName(e)}
            style={{boxSizing: 'content-box'}}
          />
          {fileName && (
            <div className='NewMessageForm__form__fileUpload__file'>
              <a className='NewMessageForm__form__fileUpload__file__link' href={`${values.file}`} target='_blank' style={{color: colorConfig[subsidiary].primary}}>{fileName.name}</a>
              <ClearIcon className='NewMessageForm__form__fileUpload__file__icon' color={colorConfig[subsidiary].primary} onClick={()=>deleteFile(fileName.etag)}/>
            </div>
          )}
        </div>
        <div className="NewMessageForm__form__buttons">
          <Button variant="outlined" onClick={resetTab}>
            Cancelar
          </Button>
          <Button type="submit" diabled={!fileLoading}>Enviar mensaje</Button>
        </div>
      </Form>
      <ClientSelectionDialog
        open={openDialog}
        toogleCallback={toogleDialog}
        clientList={clientList}
        projectName={projectName}
        setCheckedList={setSelectedClients}
      />
    </Fragment>
  );
};

NewMessageForm.propTypes = {
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  onSubmit: PropTypes.func,
  handleSubmit: PropTypes.func,
  values: PropTypes.shape({
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    title: PropTypes.string,
    message: PropTypes.string,
    from: PropTypes.string,
  }),
  errors: PropTypes.object,
  touched: PropTypes.object,
  projectId: PropTypes.string,
  projectName: PropTypes.string,
  resetTab: PropTypes.func,
};

export default withFormik({
  mapPropsToValues: () => {
    return {
      to: '',
      title: '',
      message: '',
      file: '',
    };
  },
  enableReinitialize: true,
  validateOnBlur: false,
  isInitialValid: false,
  handleSubmit: ({ title, message, to, file }, { props: { onSubmit, resetTab } }) => {
    const resetForm = () => {
      resetTab();
    };
    // asunto, emisor, message, clients, resetForm
    let formatTo = [];
    if (Array.isArray(to)) {
      formatTo = to.map(({ clientId, nombres, apellidos }) => ({
        rut: clientId,
        nombre: `${nombres} ${apellidos}`,
      }));
    }
    onSubmit(title, message, formatTo, resetForm, file);
  },
})(NewMessageForm);
