/* eslint-disable import/prefer-default-export */
// Logos
import AlmagroLogo from '../assets/logo-almagro.svg';
import PilaresLogo from '../assets/logo-pilares.svg';
import SocovesaLogo from '../assets/logo-socovesa.svg';
import EmpresasLogo from '../assets/logo-empresas.svg';
import SocovesaMenuLogo from '../assets/logo-socovesa-menu.svg';

// Background
import PilaresBackground from '../assets/Pilares-Desktop-login-background.jpg';
import AlmagroBackground from '../assets/almagro-login.jpg';
import EmpresasBackground from '../assets/img_logempresas.png';
import SocovesaBackground from '../assets/img_logsocovesa.png';

import { socovesa, almagro, pilares, empresas, socovesaMenu } from '../constants/filialesNames';

export function getLogos(subsidiary, mobile, icono = false) {
  const logos = {
    [almagro]: { src: AlmagroLogo, style: { maxWidth: '100px', maxHeight: '100px' } },
    [socovesa]: { src: SocovesaLogo, style: { maxWidth: '170px', maxHeight: '30px' } },
    [pilares]: { src: PilaresLogo, style: { maxWidth: '135px', maxHeight: '50px' } },
    [empresas]: { src: EmpresasLogo, style: { maxWidth: '170px', maxHeight: '38px' } },
    [socovesaMenu]: { src: SocovesaMenuLogo, style: { maxWidth: '170px', maxHeight: '38px' } },
  };

  const logosMobile = {
    [almagro]: { src: AlmagroLogo, style: { maxWidth: '30px', maxHeight: '30px' } },
    [socovesa]: { src: SocovesaLogo, style: { maxWidth: '100px', maxHeight: '35px' } },
    [pilares]: { src: PilaresLogo, style: { maxWidth: '75px', maxHeight: '40px' } },
    [empresas]: { src: EmpresasLogo, style: { maxWidth: '170px', maxHeight: '38px' } },
  };

  const logosIconos = {
    [almagro]: { src: AlmagroLogo, style: { maxWidth: '24px', maxHeight: '24px' } },
    [socovesa]: { src: SocovesaLogo, style: { maxWidth: '36px', maxHeight: '36px' } },
    [pilares]: { src: PilaresLogo, style: { maxWidth: '24px', maxHeight: '24px' } },
    [empresas]: { src: EmpresasLogo, style: { maxWidth: '24px', maxHeight: '24px' } },
  };

  if (mobile) return { ...logosMobile[subsidiary] };

  if (icono) return { ...logosIconos[subsidiary] };

  return { ...logos[subsidiary] };
}

export function getBackGrounds(subsidiary) {
  const backgrounds = {
    [almagro]: { src: AlmagroBackground },
    [socovesa]: { src: SocovesaBackground },
    [pilares]: { src: PilaresBackground },
    [empresas]: { src: EmpresasBackground },
    default: { src: 'https://via.placeholder.com/855x768' },
  };
  return { ...backgrounds[subsidiary] };
}
