import React from 'react';

import IconPropTypes from '../IconPropTypes';

const PassIcon = ({ className, color = '#303446', secondaryColor = '#FFFFFF' }) => (
  <svg width="120px" height="120px" viewBox="0 0 120 120" version="1.1" className={className}>
    <g id="Login-Ui" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group-7" fillRule="nonzero">
        <g id="Group-3-Copy">
          <g id="Group-2">
            <path
              d="M60,0 C93.137085,0 120,26.862915 120,60 C120,70.7849509 117.154485,80.9052962 112.173264,89.6512268 C111.654798,89.2226488 111.11036,88.8280649 110.541681,88.4683403 C115.290838,80.0602464 118,70.3465025 118,60 C118,27.9674845 92.0325155,2 60,2 C27.9674845,2 2,27.9674845 2,60 C2,92.0325155 27.9674845,118 60,118 C70.3465025,118 80.0602464,115.290838 88.4705535,110.543193 C88.8280649,111.11036 89.2226488,111.654798 89.6490889,112.17199 C80.9052962,117.154485 70.7849509,120 60,120 C26.862915,120 0,93.137085 0,60 C0,26.862915 26.862915,0 60,0 Z M111.139095,91.394174 L111.010621,91.6054393 C106.082819,99.5419715 99.3544497,106.243715 91.3962986,111.139884 C90.9573797,110.632656 90.5562438,110.094595 90.1946659,109.528811 C98.0748441,104.715878 104.715878,98.0748441 109.529657,90.1957234 C110.094595,90.5562438 110.632656,90.9573797 111.139095,91.394174 Z"
              id="Combined-Shape"
              fill={color}
            />
            <path
              d="M102,116 C94.2680135,116 88,109.731986 88,102 C88,94.2680135 94.2680135,88 102,88 C109.731986,88 116,94.2680135 116,102 C116,109.731986 109.731986,116 102,116 Z"
              id="Oval"
              fill={color}
            />
            <g id="ui-confirm" transform="translate(94.000000, 96.000000)" fill={secondaryColor}>
              <g id="icons">
                <path
                  d="M6.96187013,9.6720286 L2.22677513,5.80358992 C1.75991133,5.42398812 1.07827451,5.49210816 0.696035098,5.95641027 C0.315258663,6.42470255 0.383828351,7.10954435 0.848942394,7.48989462 L6.45990026,12.0734334 C6.65574256,12.2322883 6.89960548,12.3191791 7.14923413,12.3191791 L7.17414519,12.3187903 L7.209071,12.317682 C7.23475837,12.316097 7.23475837,12.316097 7.26364045,12.3136712 C7.57376682,12.2749988 7.83973081,12.1233942 8.01804634,11.8885396 L15.3313277,2.19494569 C15.6931986,1.71318017 15.5968778,1.03132033 15.1159696,0.670095234 C14.6417913,0.306300071 13.9565185,0.401672551 13.5930038,0.882730979 L6.96187013,9.6720286 Z"
                  id="Shape"
                />
              </g>
            </g>
          </g>
        </g>
        <g
          id="noun_Key_2774743"
          transform="translate(56.526912, 61.526912) rotate(-45.000000) translate(-56.526912, -61.526912) translate(24.526912, 47.526912)"
          fill={color}
        >
          <path
            d="M36.9518211,12.1141027 C37.8844547,5.64204535 43.4514713,0.8 50,0.8 C57.2781722,0.8 63.2,6.7218278 63.2,14 C63.2,21.2781722 57.2781722,27.2 50,27.2 C43.4514713,27.2 37.8844547,22.3579546 36.9518211,15.8858973 L36.8529822,15.2 L15.2,15.2 L15.2,18 C15.2,18.6621722 14.6621722,19.2 14,19.2 C13.3378278,19.2 12.8,18.6621722 12.8,18 L12.8,15.2 L7.2,15.2 L7.2,22 C7.2,22.6621722 6.6621722,23.2 6,23.2 C5.3378278,23.2 4.8,22.6621722 4.8,22 L4.8,15.2 L2,15.2 C1.3378278,15.2 0.8,14.6621722 0.8,14 C0.8,13.3378278 1.3378278,12.8 2,12.8 L36.8529822,12.8 L36.9518211,12.1141027 Z M50,24.8 C55.9549431,24.8 60.8,19.9549431 60.8,14 C60.8,8.04505695 55.9549431,3.2 50,3.2 C44.0450569,3.2 39.2,8.04505695 39.2,14 C39.2,19.9549431 44.0450569,24.8 50,24.8 Z"
            id="Shape"
          />
        </g>
      </g>
    </g>
  </svg>
);

PassIcon.propTypes = IconPropTypes;

export default PassIcon;
