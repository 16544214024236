import React from 'react';
import IconPropType from '../IconPropTypes';

const TurnOffIcon = ({ className, color = '#6B6B6B' }) => (
  <svg width="18px" height="19px" viewBox="0 0 18 19" className={className}>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Icons/24/transversal/grey/ONoFF"
        transform="translate(-3.000000, -3.000000)"
        fill={color}
        fillRule="nonzero"
      >
        <g id="noun_on-off_1738193-(1)" transform="translate(3.000000, 3.000000)">
          <path
            d="M9,0.914285714 C9.56575511,0.914285714 10.0243902,1.37292085 10.0243902,1.93867596 L10.0243902,8.91989547 C10.0243902,9.48565058 9.56575511,9.94428571 9,9.94428571 C8.43424489,9.94428571 7.97560976,9.48565058 7.97560976,8.91989547 L7.97560976,1.93867596 C7.97560976,1.37292085 8.43424489,0.914285714 9,0.914285714 Z"
            id="Rectangle"
          />
          <path
            d="M15.1460795,3.26989726 L13.6872852,4.6817869 L13.9107444,4.89734393 C15.211258,6.15186778 15.9431027,7.86116143 15.9439025,9.64442027 C15.9439024,13.3824029 12.8366078,16.4157143 9,16.4157143 C5.16339215,16.4157143 2.05609756,13.3824029 2.05609756,9.64428571 C2.05689735,7.86116143 2.78874202,6.15186778 4.08925561,4.89734393 L4.31271477,4.6817869 L2.85095951,3.26703152 L2.64226789,3.47427202 C-0.194642689,6.29145631 -0.748427574,10.6165269 1.2915647,14.0298438 C3.32956246,17.4398234 7.45091771,19.094346 11.353177,18.0714637 C15.2575489,17.0480276 17.9785844,13.5968919 17.992682,9.64535596 C17.9934966,7.32457675 17.0432332,5.10144429 15.3547088,3.47131176 L15.1460795,3.26989726 Z"
            id="Path"
          />
        </g>
      </g>
    </g>
  </svg>
);

TurnOffIcon.propTypes = IconPropType;

export default TurnOffIcon;
