import { useEffect } from 'react';

const getItemSessionStorage = item => {
  try {
    return JSON.parse(sessionStorage.getItem(item));
  } catch (error) {
    console.error(error);
  }
};

const validateSessionValue = (state, key) => {
  try {
    if (sessionStorage.getItem(key) !== null && sessionStorage.getItem(key) !== '[object Object]') {
      if (typeof state[key] === 'object') {
        return JSON.parse(sessionStorage.getItem(key));
      }
      return sessionStorage.getItem(key);
    }
  } catch (err) {
    console.error(err);
  }
  return state[key];
};

const rehydrateStateCallback = (state, callbackRehydrateState) => {
  const updatedState = Object.keys(state).reduce((acc, key) => {
    return {
      ...acc,
      [key]: validateSessionValue(state, key),
    };
  }, {});
  callbackRehydrateState({ ...updatedState });
};

const updateStorage = state => {
  Object.keys(state).forEach(key => {
    if(state[key] === undefined) return

    sessionStorage.setItem(
      key,
      typeof state[key] === 'object' ? JSON.stringify(state[key]) : state[key],
    );
  });
};

const clearStorage = () => {
  sessionStorage.clear();
};

const HandlePersistence = (state, callbackRehydrateState, loader) => {
  useEffect(() => {
    if (sessionStorage) {
      if (loader) loader();
      rehydrateStateCallback(state, callbackRehydrateState);
      if (loader) loader();
    }
    return function storeSession() {
      Object.keys(state).forEach(key => {
        sessionStorage.setItem(key, state[key]);
      });
    };
  }, []);
};

export { HandlePersistence, rehydrateStateCallback, updateStorage, clearStorage, validateSessionValue, getItemSessionStorage };
