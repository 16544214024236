import React from 'react';
import PropTypes from 'prop-types';

const FileIcon = ({ color = '#6B6B6B', className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="32"
    viewBox="0 0 24 32"
    className={className}
  >
    <path
      fill={color}
      fillRule="evenodd"
      stroke={color}
      d="M2.67 31h18.607c1.099 0 1.993-.841 1.993-1.875V7.875a.617.617 0 00-.195-.442l-6.644-6.25A.7.7 0 0015.96 1l-13.292.001C1.57 1 .676 1.841.676 2.875v26.25C.676 30.159 1.57 31 2.669 31h0zM16.624 3.134l4.377 4.116h-4.377V3.134h0zM1.739 2.625c0-.345.299-.625.664-.625H15.03v5.625c0 .345.298.625.664.625h5.981v20.625c0 .345-.299.625-.664.625H2.403c-.365 0-.664-.28-.664-.625V2.625h0zM4.929 12H18.75v1H4.93v-1zm0 5H18.75v1H4.93v-1zm0 5H18.75v1H4.93v-1z"
    />
  </svg>
);

FileIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

export default FileIcon;
