import React from 'react';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

import { currentColor } from '../../../constants/colors';

const Requirement = ({ color = currentColor, className }) => (
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className={className}>
    <g transform="translate(0.000000,24.000000) scale(0.004687,-0.004687)" fill={color} stroke="none">
      <path d="M1415 5103 c-41 -21 -74 -52 -91 -85 l-15 -28 -413 0 c-468 0 -481
      -2 -563 -74 -25 -22 -56 -63 -69 -91 l-24 -50 0 -2115 0 -2115 24 -50 c29 -64
      77 -112 141 -141 l50 -24 1190 0 1191 0 54 -55 c65 -66 102 -85 168 -85 66 0
      93 11 145 60 l44 40 41 -17 42 -16 0 -59 c0 -72 25 -125 77 -165 l36 -28 216
      -3 c244 -3 259 0 315 71 25 31 31 49 35 109 l6 71 42 15 42 16 41 -38 c22 -20
      52 -41 66 -46 38 -15 122 -12 158 5 42 20 292 272 305 308 14 36 14 118 0 154
      -5 15 -26 44 -45 64 l-34 35 20 47 c21 47 21 47 71 47 62 0 119 28 156 77 l28
      36 3 201 c2 111 1 215 -3 233 -13 71 -98 133 -183 133 l-48 0 -18 50 -18 50
      32 36 c17 20 36 47 41 61 12 33 11 115 -2 150 -14 38 -266 292 -306 309 -38
      17 -120 18 -160 3 -15 -5 -42 -24 -60 -41 l-32 -31 -50 19 -51 18 0 47 c0 61
      -11 89 -54 137 l-35 39 -3 436 c-3 430 -3 437 -24 458 -29 28 -89 28 -118 0
      -21 -21 -21 -28 -24 -451 l-3 -430 -89 0 -90 0 0 1050 c0 1147 3 1087 -59
      1145 -53 50 -74 53 -373 54 l-278 1 0 90 0 90 390 0 c428 0 438 -1 479 -58 21
      -30 21 -33 21 -610 0 -397 3 -589 11 -611 28 -80 138 -66 154 21 3 18 5 294 3
      613 l-3 580 -24 53 c-31 69 -106 137 -174 157 -42 12 -124 15 -464 15 l-412 0
      -15 28 c-18 34 -51 65 -93 86 -28 14 -101 16 -660 16 -553 -1 -632 -3 -658
      -17z m1263 -155 c9 -9 12 -71 12 -219 0 -203 0 -208 -22 -223 -20 -14 -93 -16
      -593 -16 -500 0 -573 2 -593 16 -22 15 -22 20 -22 223 0 148 3 210 12 219 17
      17 1189 17 1206 0z m-1378 -208 l0 -90 -279 0 c-180 0 -290 -4 -312 -11 -47
      -16 -107 -76 -119 -119 -16 -55 -13 -2091 3 -2111 20 -27 56 -41 88 -34 63 14
      59 -50 59 1070 0 781 3 1024 12 1033 9 9 85 12 280 12 l268 0 20 -39 c24 -48
      79 -94 129 -110 51 -15 1199 -16 1254 0 47 13 109 67 131 114 l17 35 267 0
      c195 0 271 -3 280 -12 9 -9 12 -258 12 -1058 l0 -1047 -21 -19 c-35 -32 -59
      -90 -59 -143 0 -50 0 -50 -47 -71 l-47 -20 -35 34 c-20 19 -49 40 -64 45 -36
      14 -118 14 -154 0 -37 -14 -280 -254 -304 -301 -11 -22 -19 -58 -19 -88 l0
      -50 -281 0 c-160 0 -288 -4 -298 -10 -31 -16 -46 -65 -31 -100 7 -17 21 -35
      31 -40 10 -6 152 -10 338 -10 221 0 321 -3 321 -11 0 -6 -5 -19 -10 -30 -9
      -15 -22 -19 -66 -19 -59 0 -115 -22 -148 -59 -18 -20 -28 -21 -218 -21 l-200
      0 -30 -30 c-25 -26 -29 -36 -24 -63 12 -63 27 -67 236 -67 l189 0 3 -163 c3
      -162 3 -164 31 -200 40 -52 93 -77 166 -77 43 0 60 -4 65 -15 5 -13 -109 -15
      -988 -15 -897 0 -994 2 -1000 16 -3 9 -6 303 -6 654 l0 639 -26 20 c-36 28
      -79 27 -109 -4 l-25 -24 0 -656 c0 -567 2 -661 15 -692 20 -48 41 -71 87 -94
      36 -18 75 -19 1010 -19 l973 0 0 -72 c0 -40 4 -81 9 -91 8 -16 -44 -17 -1099
      -15 l-1107 3 -34 37 -34 38 0 2082 c0 2071 0 2083 20 2116 37 60 44 61 483 62
      l397 0 0 -90z m2550 -2586 c6 -97 18 -115 94 -140 28 -9 72 -27 98 -40 26 -13
      65 -24 86 -24 32 0 45 7 87 50 27 28 56 50 64 50 20 0 241 -220 241 -240 0 -8
      -23 -38 -51 -66 -62 -62 -65 -88 -21 -184 16 -36 37 -86 46 -112 9 -25 25 -50
      34 -54 9 -4 53 -10 97 -13 l80 -6 0 -175 0 -175 -86 -5 c-96 -6 -112 -14 -130
      -68 -7 -20 -27 -70 -46 -111 -18 -40 -33 -85 -33 -98 0 -16 19 -44 55 -82 30
      -32 55 -64 55 -72 0 -19 -221 -239 -240 -239 -9 0 -40 24 -69 54 -30 30 -65
      57 -78 61 -15 3 -50 -6 -101 -28 -42 -19 -91 -37 -108 -41 -55 -12 -68 -37
      -74 -139 l-5 -92 -175 0 -175 0 -3 85 c-1 47 -7 95 -14 107 -7 14 -33 31 -65
      43 -29 11 -76 31 -103 45 -78 39 -102 35 -174 -35 -35 -33 -69 -60 -77 -60
      -18 0 -239 221 -239 239 0 8 27 43 61 78 57 59 61 66 56 101 -6 50 -86 229
      -106 239 -9 4 -56 9 -106 13 l-90 5 0 175 0 175 92 5 c105 6 123 16 142 83 7
      23 26 72 42 109 19 43 27 76 24 91 -4 13 -31 48 -61 78 -30 29 -54 60 -54 69
      0 19 220 240 239 240 8 0 40 -25 72 -55 38 -36 66 -55 82 -55 13 0 58 15 98
      33 41 19 91 40 111 46 55 18 68 43 68 132 0 41 3 79 7 83 4 3 84 5 177 4 l171
      -3 5 -81z"/>
      <path d="M1728 4090 l-72 -90 -279 0 c-271 0 -281 -1 -319 -22 -24 -14 -51
      -41 -66 -68 -27 -45 -27 -46 -30 -285 -4 -286 2 -329 58 -385 56 -56 99 -62
      385 -58 278 4 297 8 347 87 l29 46 -1 288 -1 289 62 76 c75 91 93 124 84 154
      -13 42 -36 58 -81 58 -43 0 -45 -2 -116 -90z m-220 -268 c-7 -10 -40 -51 -72
      -90 l-59 -73 -31 29 c-59 56 -97 65 -138 32 -30 -23 -39 -58 -24 -93 6 -14 46
      -58 89 -96 63 -58 84 -71 110 -71 42 0 47 4 148 129 l84 104 3 -164 c2 -145 1
      -165 -15 -176 -12 -10 -75 -13 -229 -13 -117 0 -219 3 -228 6 -14 5 -16 36
      -16 243 0 131 3 241 7 244 3 4 91 7 195 7 180 0 189 -1 176 -18z"/>
      <path d="M2047 3802 c-30 -33 -32 -75 -4 -108 l25 -29 519 -3 c339 -2 530 1
      554 7 57 17 76 77 38 125 l-20 26 -548 0 c-517 0 -549 -1 -564 -18z"/>
      <path d="M2053 3505 c-44 -31 -37 -111 13 -134 18 -8 182 -11 555 -11 l530 0
      24 25 c16 15 25 36 25 55 0 19 -9 40 -25 55 l-24 25 -538 0 c-459 0 -542 -2
      -560 -15z"/>
      <path d="M1804 3170 c-12 -5 -53 -46 -91 -91 l-69 -83 -244 3 c-270 3 -332 -5
      -382 -47 -63 -53 -68 -78 -68 -367 0 -294 5 -317 76 -371 l37 -29 286 -3 c322
      -3 327 -2 385 71 l31 39 2 298 2 299 71 86 c55 68 70 93 70 120 0 58 -55 97
      -106 75z m-366 -428 l-73 -89 -44 39 c-31 27 -53 38 -75 38 -62 0 -99 -54 -76
      -110 16 -40 166 -170 195 -170 42 0 54 11 151 128 l94 115 -2 -174 -3 -174
      -239 -3 c-185 -2 -241 1 -248 10 -9 15 -11 438 -2 462 5 14 32 16 200 16 l194
      0 -72 -88z"/>
      <path d="M2085 2750 c-26 -29 -25 -85 3 -113 l23 -22 507 -3 c350 -2 515 1
      533 8 49 21 62 80 28 124 l-20 26 -528 0 c-519 0 -528 0 -546 -20z"/>
      <path d="M2097 2458 c-44 -34 -39 -115 9 -137 18 -8 175 -11 535 -11 l510 0
      24 25 c16 15 25 36 25 55 0 19 -9 40 -25 55 l-24 25 -519 0 c-399 0 -522 -3
      -535 -12z"/>
      <path d="M1781 2113 c-11 -10 -47 -51 -79 -91 l-59 -73 -273 1 c-259 1 -275 0
      -317 -21 -27 -13 -55 -37 -71 -62 l-27 -40 -3 -266 c-4 -298 1 -325 63 -380
      56 -49 86 -53 365 -49 290 3 310 8 358 81 l27 41 2 294 2 294 70 88 c59 72 71
      93 71 124 0 47 -28 76 -74 76 -19 0 -43 -8 -55 -17z m-283 -340 c-8 -10 -39
      -47 -68 -83 -29 -36 -56 -69 -61 -74 -5 -5 -27 8 -53 32 -25 23 -54 42 -65 42
      -56 0 -100 -53 -87 -104 3 -13 42 -58 87 -100 71 -67 85 -76 118 -76 42 0 60
      17 172 154 l64 79 3 -163 c2 -111 -1 -167 -9 -176 -16 -19 -458 -21 -477 -2
      -17 17 -17 459 0 476 8 8 67 12 202 12 179 0 188 -1 174 -17z"/>
      <path d="M3530 1621 c-184 -59 -300 -221 -300 -420 0 -130 35 -216 124 -309
      88 -92 185 -132 316 -132 255 0 440 185 440 440 0 197 -113 358 -294 420 -76
      26 -208 26 -286 1z m250 -162 c181 -81 228 -312 92 -452 -62 -65 -104 -82
      -202 -82 -75 0 -91 3 -131 27 -50 30 -98 79 -126 131 -27 49 -25 189 4 242 52
      97 147 155 253 155 44 0 80 -7 110 -21z"/>
    </g>
  </svg>
);

Requirement.propTypes = {
  theme: PropTypes.object,
  color: PropTypes.string,
  className: PropTypes.string,
};

export default withStyles({}, { withTheme: true })(Requirement);
