import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/styles';
import SearchIcon from '../Icons/SearchIcon';
import DoneIcon from '../Icons/DoneIcon';

export const textFieldColors = {
  primary: '#676977',
  secondary: '#ffffff',
};

export const styles = makeStyles(() => ({
  root: ({ color = 'primary' }) => ({
    width: '100%',
    flexBasis: '100%',
    margin: '10px 0',
    color: textFieldColors[color],
    '& label': {
      color: textFieldColors[color],
    },
    '& input': {
      color: textFieldColors[color],
      // borderBottom: `1px solid ${textFieldColors[color]}`,
    },
  }),
}));

function addAdorments(search, color, valid) {
  return search || valid
    ? {
        InputProps: {
          startAdornment: search && (
            <InputAdornment position="start">
              <SearchIcon color={color} />
            </InputAdornment>
          ),
          endAdornment: valid && (
            <InputAdornment position="end">
              <DoneIcon />
            </InputAdornment>
          ),
        },
      }
    : undefined;
}

const TextFieldTypes = props => {
  const {
    search = false,
    valid = false,
    theme: { palette: { primary: { main: primaryColor } = {} } = {} } = {},
    ...restProps
  } = props;
  const updatedProps = { ...restProps, ...addAdorments(search, primaryColor, valid) };
  const classes = styles(restProps);
  return <TextField className={classes.root} {...updatedProps} />;
};

TextFieldTypes.propTypes = {
  search: PropTypes.bool,
  valid: PropTypes.bool,
  color: PropTypes.string,
  theme: PropTypes.object,
};

export default withStyles({}, { withTheme: true })(TextFieldTypes);
