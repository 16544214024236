import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const FilterRequests = ({ requests = [], setRequests }) => {
  const [filter, setFilter] = useState('all');

  const changeFilter = event => {
    setFilter(event.target.value);
  };

  useEffect(() => {
    switch (filter) {
      case 'all':
        setRequests(requests);
        break;
      case 'pending':
        setRequests(
          requests.filter(
            request =>
              request.estadoSolicitud.toUpperCase().indexOf('CERRAD') === -1 &&
              request.estadoSolicitud.toUpperCase().indexOf('NO CORRESPONDE') === -1,
          ),
        );
        break;
      case 'closed':
        setRequests(
          requests.filter(
            request =>
              request.estadoSolicitud.toUpperCase().indexOf('CERRAD') !== -1 ||
              request.estadoSolicitud.toUpperCase().indexOf('NO CORRESPONDE') !== -1,
          ),
        );
        break;
      case 'not_sent':
        setRequests(requests.filter(request => !request.SolicitudConfirmada));
        break;
      default:
        setRequests(requests);
        break;
    }
  }, [filter]);

  return (
    <div onChange={changeFilter.bind(this)}>
      <input type="radio" value="all" name="request" checked={filter === 'all'} id={'request_all'} /> 
      <label for='request_all'>Todas</label><br/>
      <input type="radio" value="pending" name="request" checked={filter === 'pending'} id={'request_pending'} />
      <label for='request_pending'>Pendientes</label><br/>
      <input type="radio" value="closed" name="request" checked={filter === 'closed'} id={'request_closed'} />
      <label for='request_closed'>Cerradas</label><br/>
      <input type="radio" value="not_sent" name="request" checked={filter === 'not_sent'} id={'request_not_sent'} />
      <label for='request_not_sent'>No enviadas</label><br/>
    </div>
  );
};

FilterRequests.propTypes = {
  requests: PropTypes.array,
  setRequests: PropTypes.func,
};

export default FilterRequests;
