import React, { useContext, useEffect, useState } from 'react';
import { Container, Avatar, Typography, Button, Checkbox } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import ExecutiveForm from './ExecutiveForm';

import './EnterpriseExecutiveDetail.scss';

import FileUploadInput from '../FileUploadInput';
import { GlobalContext } from '../../providers/GlobalStateProvider';
import { setAvatar } from '../../services/fileService';
import avatar from '../../assets/avatar.png';
import { getAvatarImage } from '../../constants/endpoints';
import { ThemeProvider } from '@material-ui/styles';
import ThemeManager from '../../themes/mainTheme';
import { saveExecutiveById } from '../../services/executive';

const style = () => ({
  title: {
    color: '#003a63',
    margin: '0 0 25px',
    textTransform: 'uppercase',
    marginTop: '25px',
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
  },
  subtitle: {
    marginBottom: '30px',
  },
  avatar: {
    width: '100px',
    height: '100px',
  },
  container: {
    // padding: 0,
    width: '100%',
    height: '100%',
    padding: '30px',
    borderRadius: '4px',
    boxSizing: 'border-box',
    boxShadow: '0 2px 4px 0 rgba(103, 105, 119, 0.4)',
  },
  button: {
    fontSize: '14px',
    padding: 0,
    color: '#303446',
    margin: '10px 0 30px 0',
  },
  ExecutiveDetail: {
    marginBottom: '100px',
  },
});

const ExecutiveDetail = ({ classes, executive, onSubmit, goBack }) => {
  const [documentFileEvent, setDocumentFileEvent] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [fileKey, setFileKey] = useState(Date.now());
  const {
    state: { token },
  } = useContext(GlobalContext);
  const [avatarImage, setAvatarImage] = useState(
    `${getAvatarImage}/${token}?key=${fileKey}` || avatar,
  );
  const toogleDialog = () => {
    setOpenDialog(!openDialog);
    setFileKey(Date.now());
  };
  const openModalDocumentName = event => {
    setDocumentFileEvent(event.target.files);
    toogleDialog();
  };
  useEffect(() => {
    if (documentFileEvent.length > 0) {
      const file = documentFileEvent[0];
      setAvatar({ token, file }, res => {
        setAvatarImage(`${getAvatarImage}/${token}?key=${fileKey}` || avatar);
      });
    }
  }, [documentFileEvent]);

  const [enabledEmail, setEnabledEmail] = useState(true);
  useEffect(() => {
    if (executive.enabledEmail != undefined) {
      setEnabledEmail(executive.enabledEmail);
    }
    setAvatarImage(`${getAvatarImage}/${token}?key=${fileKey}` || avatar);
  }, [executive]);
  useEffect(() => {
    if (executive.rut) {
      executive.enabledEmail = enabledEmail;
      saveExecutiveById(
        executive,
        res => {},
        err => {
          console.error(err);
        },
      );
    }
  }, [enabledEmail]);

  const { apellidos, nombres } = executive;
  const nombreCompleto = `${nombres} ${apellidos}`;

  return (
    <div className={classes.ExecutiveDetail}>
      <Typography variant="h2" className={classes.title}>
        EDITAR PERFIL EJECUTIVO
      </Typography>
      <Container maxWidth="lg" className={classes.container}>
        <div>
          <Avatar className={classes.avatar} alt={nombreCompleto} src={avatarImage} sizes="100" />
          <FileUploadInput
            onChange={e => openModalDocumentName(e)}
            buttonLabel="CAMBIAR IMAGEN"
            className={classes.button}
            fileKey={fileKey}
            accept=".png, .jpg, .jpeg"
            style={{ backgroundColor: 'transparent', boxShadow: 'none' }}
            name="avatar"
          />
          <label style={{ marginLeft: '500px' }}>
            <input
              type="checkbox"
              checked={enabledEmail}
              onChange={() => setEnabledEmail(!enabledEmail)}
            />
            <span>Recepción de emails {enabledEmail ? 'H' : 'Desh'}abilitada.</span>
          </label>
        </div>
        <Typography variant="subtitle1" className={classes.subtitle}>
          DATOS DEL EJECUTIVO
        </Typography>
        <div>
          <ExecutiveForm executive={executive} onSubmit={onSubmit} goBack={goBack} />
        </div>
      </Container>
    </div>
  );
};

ExecutiveDetail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      idExecutive: PropTypes.string,
    }),
  }),
  onSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  executive: PropTypes.shape({
    nombres: PropTypes.string,
    apellidos: PropTypes.string,
  }),
  classes: PropTypes.shape({
    ExecutiveDetail: PropTypes.string,
    title: PropTypes.string,
    container: PropTypes.string,
    avatar: PropTypes.string,
    button: PropTypes.string,
    subtitle: PropTypes.string,
  }),
  goBack: PropTypes.func,
};

export default withStyles(style)(ExecutiveDetail);
