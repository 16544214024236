import React from 'react';
import PropTypes from 'prop-types';
import IconPropTypes from '../IconPropTypes';

const DropDownArrowIcon = ({ className, arrowColor = '#FFF', color = '#EE3424' }) => (
  <svg width="16" className={className} height="16" viewBox="0 0 16 16">
    <g fill="none" fillRule="evenodd">
      <rect width="12" height="12" fill={color} rx="6" />
      <path
        fill={arrowColor}
        fillRule="nonzero"
        d="M2.649 5.656l2.813 3.376a.536.536 0 0 0 .827-.001L9.1 5.657a.535.535 0 0 0-.069-.758.538.538 0 0 0-.756.068l-2.4 2.881-2.4-2.88a.537.537 0 0 0-.826.688z"
      />
    </g>
  </svg>
);

DropDownArrowIcon.propTypes = {
  arrowColor: PropTypes.string,
  ...IconPropTypes,
};

export default DropDownArrowIcon;
