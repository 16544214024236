import React, { useState, useEffect, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';

// Components
import ProjectTable from '../../components/ProjectTable';

import { GlobalContext } from '../../providers/GlobalStateProvider';
import { EnterpriseContext } from '../../providers/EnterpriseProvider';

import { subsidiaryOptionsByText } from '../../constants/filialesNames';

import './ClientList.scss';
import { useIslogged } from '../../customHooks/useIslogged';
import useClientList, { updateClientList, useHandlePagination } from '../../hooks/clientHooks';
import { saveFilialOnLocalStorage } from '../../services/additionalService';
import { getProjectsByExecutive } from '../../services/projectsService';

const columns = [
  { label: 'nombre', key: 'fullName' },
  { label: 'rut', key: 'rut' },
  { label: 'ACTIVAR / DESACTIVAR', key: 'estado_sistema', defaultValue: 5 },
  { label: 'propiedades', circle: true, key: 'propertyCount', defaultValue: 0 },
  { key: 'id_Cliente', defaultValue: '', id: true },
  { key: 'emailParticular', defaultValue: '' },
];

const ClientList = ({
  match: {
    params: { subsidiary },
  },
  history,
  location: { pathname },
}) => {
  if (!useIslogged()) history.push(`/${subsidiary}`);

  const [filiales, setFiliales] = useState([]);
  const [letterFilter, setLetterFilter] = useState('');
  const [searchFilter, setSearchFilter] = useState('*');
  const [activeSubsidiary, setActiveSubsidiary] = useState(localStorage.getItem('filial') || '');
  const changeSearch = newValue => {
    if (newValue === '') newValue = '*';
    setSearchFilter(newValue);
  };
  const changeSubsidiary = newValue => {
    setActiveSubsidiary(newValue);
  };

  const {
    state: { rut },
  } = useContext(GlobalContext);
  const { pagination, addMoreRows } = useHandlePagination();
  const { clientList, setClientList } = useClientList(
    rut,
    pagination,
    filiales && filiales.length > 0 && filiales[0],
  );
  const { selectClient, selectFilial } = useContext(EnterpriseContext);
  const selectClientCallback = useCallback(
    client => {
      const selectedClient = clientList.find(({ id_Cliente: ClientId }) => ClientId === client);
      selectClient({ ...selectedClient });
      selectFilial(activeSubsidiary);
      saveFilialOnLocalStorage(activeSubsidiary);
      history.push(`${pathname}/${selectedClient.id_Cliente}`);
    },
    [clientList, selectClient],
  );

  useEffect(() => {
    getProjectsByExecutive(
      rut,
      '',
      1,
      10,
      ({ data }) => {
        const { filtroFilial } = data;
        setFiliales(
          filtroFilial
            .filter(filial => {
              return filial.count > 0;
            })
            .map(filial => ({
              label: filial.name.toLowerCase(),
              value: filial.name.toLowerCase(),
            })),
        );
      },
      error => {
        console.log({ error });
      },
    );
  }, []);
  // useEffect(() => {
  //   if (filiales && filiales.length > 0) {
  //     changeSubsidiary(filiales[0].value);
  //   }
  // }, [filiales]);
  useEffect(() => {
    const { page, limit } = pagination;
    updateClientList(rut, setClientList, activeSubsidiary, letterFilter, page, limit, searchFilter);
  }, [activeSubsidiary, rut, letterFilter, searchFilter]);

  const onClientStatusChange = () => {
    const { page, limit } = pagination;
    updateClientList(rut, setClientList, activeSubsidiary, letterFilter, page, limit, searchFilter);
  };
  return (
    <div className="ClientList">
      <h2 className="ClientList__title">Clientes</h2>
      <ProjectTable
        itemList={clientList}
        subsidiary={subsidiary}
        columns={columns}
        subsidiaryOptions={filiales}
        onChangeSubsidiary={changeSubsidiary}
        onChangeSearch={changeSearch}
        onItemClick={selectClientCallback}
        onClientStatusChange={onClientStatusChange}
        onLetterChangeCallback={setLetterFilter}
        addMoreRowsLabel="Cargar más clientes"
        addMoreRowsCallback={addMoreRows}
        messageEmpty="No existen clientes para la filial seleccionada"
      />
    </div>
  );
};

ClientList.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      subsidiary: PropTypes.string,
    }),
  }),
  history: PropTypes.object,
  location: PropTypes.shape({ pathname: PropTypes.string }),
};

export default ClientList;
