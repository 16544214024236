import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Typography, Container, Box } from '@material-ui/core';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';

// Component
import ExecutiveList from '../../components/ExecutiveList';

import './EnterpriseExecutiveList.scss';

const style = () => ({
  form: {
    width: '100%',
    height: '100%',
    padding: '20px',
    borderRadius: '4px',
    boxSizing: 'border-box',
    boxShadow: '0 2px 4px 0 rgba(103, 105, 119, 0.4)',
  },
  title: {
    color: '#003a63',
    margin: '0 0 25px',
    textTransform: 'uppercase',
  },
  message: {
    backgroundColor: '#0093ca',
    fontSize: '17px',
    color: '#FFF',
    padding: '16px 0',
    '& .MuiBox-root': {
      paddingLeft: '60px',
      display: 'flex',
    },
    '& .MuiSvgIcon-root': {
      marginRight: '20px',
    },
  },
});

const EnterpriseExecutiveList = props => {
  const {
    match: {
      params: { updated },
    },
    classes,
  } = props;
  return (
    <div>
      {updated && (
        <Container maxWidth="lg" className={classes.message}>
          <Box>
            <CheckCircleRoundedIcon />
            <Typography>Se ha editado el perfil exitosamente</Typography>
          </Box>
        </Container>
      )}
      <div className="EnterpriseExecutiveList">
        <Typography variant="h2" className={classes.title}>
          PERFIL EJECUTIVOS
        </Typography>
        <ExecutiveList />
      </div>
    </div>
  );
};
EnterpriseExecutiveList.propTypes = {
  classes: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.shape({
      updated: PropTypes.string,
    }),
  }),
};

export default withStyles(style)(EnterpriseExecutiveList);
