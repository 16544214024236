/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import ListIcon from '../Icons/ListIcon/ListIcon';
import { colorConfig, white } from '../../constants/colors';
import GalleryIcon from '../Icons/GalleryIcon/GalleryIcon';
import { almagro, gallery, list } from '../../constants/filialesNames';
import './SwitchButton.scss';

const SwitchButton = ({ subsidiary, select, selectGallery, selectList }) => {
  return (
    <div className="SwitchButton">
      <GalleryIcon
        className={
          select === gallery
            ? 'SwitchButton__icon'
            : 'SwitchButton__icon SwitchButton__icon__no-select'
        }
        color={
          subsidiary === almagro
            ? colorConfig[subsidiary].quaternary
            : colorConfig[subsidiary].primary
        }
        secondaryColor={white}
        onClick={selectGallery}
      />

      <ListIcon
        className={
          select === list
            ? 'SwitchButton__icon'
            : 'SwitchButton__icon SwitchButton__icon__no-select'
        }
        color={
          subsidiary === almagro
            ? colorConfig[subsidiary].quaternary
            : colorConfig[subsidiary].primary
        }
        secondaryColor={white}
        onClick={selectList}
      />
    </div>
  );
};

SwitchButton.propTypes = {
  subsidiary: PropTypes.string,
  select: PropTypes.string,
  selectGallery: PropTypes.func,
  selectList: PropTypes.func,
};

export default SwitchButton;
