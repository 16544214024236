import { useState } from 'react';

const useHandlePagination = () => {
  const numberOfProjectsByPage = 10;
  const [pagination, setPagination] = useState({ page: 1, limit: numberOfProjectsByPage });
  const addMoreRows = () => {
    setPagination({ page: 1, limit: pagination.limit + numberOfProjectsByPage });
  };
  return { pagination, addMoreRows };
};

export default useHandlePagination;
