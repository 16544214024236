import React from 'react';

const EditButton = () => (
  <svg width="22" height="22" viewBox="0 0 22 22">
    <g fill="none" fillRule="evenodd">
      <circle cx="10.816" cy="11.214" r="10.645" fill="#ACACAC" />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M10.688 15.593l-3.073 1.473a.688.688 0 0 1-.984-.663L6.846 13l5.105-7.569a1.541 1.541 0 0 1 2.14-.415l1.287.868c.705.476.891 1.433.415 2.14l-5.105 7.568zm4.205-8.176a.455.455 0 0 0-.123-.632l-1.287-.868a.455.455 0 0 0-.631.123l-4.94 7.324-.154 2.43 2.195-1.053 4.94-7.324z"
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M11.971 7.307l1.902 1.283a.543.543 0 1 1-.608.9l-1.901-1.282a.543.543 0 0 1 .607-.9zm-.405.6a.181.181 0 0 0 .203-.3L13.67 8.89a.181.181 0 0 0-.202.3l-1.902-1.282z"
      />
    </g>
  </svg>
);

export default EditButton;
