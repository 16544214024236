import React from 'react';
import MaterialLink from '@material-ui/core/Link';
import { withStyles } from '@material-ui/styles';

const linkStyles = () => ({
  root: {
    // color: 'white',
  },
});

const Link = props => <MaterialLink {...props} />;

export default withStyles(linkStyles, { withTheme: true })(Link);
