import React from 'react';
import PropTypes from 'prop-types';

import { formatRut } from '../../utils/utils';
import ProjectButton from '../ProjectButton';
import './ExecutiveListItem.scss';

const ExecutiveListItem = ({ nombre, rut, subsidiary }) => {
  const rutFormateado = formatRut(rut);

  return (
    <div className="ExecutiveListItem" key={rut}>
      <div className="item">{nombre}</div>
      <div className="item">{rutFormateado}</div>
      <div className="item">{subsidiary}</div>
      <ProjectButton
        className="ProjectItem__header__button"
        subsidiary={subsidiary}
        onClick={() => {
          window.location.href = `/empresas/Home/executives/detail/${rut}`;
        }}
      />
    </div>
  );
};

ExecutiveListItem.propTypes = {
  subsidiary: PropTypes.string,
  nombre: PropTypes.string,
  rut: PropTypes.string,
};

export default ExecutiveListItem;
