import React from 'react';
import IconPropType from '../IconPropTypes';

const SendIcon = ({ className, color = '#303446', secondaryColor = '#FFFFFF' }) => (
  <svg width="120px" height="120px" viewBox="0 0 120 120" version="1.1" className={className}>
    <g id="Login-Ui" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group-3" fillRule="nonzero">
        <g id="Group-2">
          <path
            d="M60,0 C93.137085,0 120,26.862915 120,60 C120,70.7849509 117.154485,80.9052962 112.173264,89.6512268 C111.654798,89.2226488 111.11036,88.8280649 110.541681,88.4683403 C115.290838,80.0602464 118,70.3465025 118,60 C118,27.9674845 92.0325155,2 60,2 C27.9674845,2 2,27.9674845 2,60 C2,92.0325155 27.9674845,118 60,118 C70.3465025,118 80.0602464,115.290838 88.4705535,110.543193 C88.8280649,111.11036 89.2226488,111.654798 89.6490889,112.17199 C80.9052962,117.154485 70.7849509,120 60,120 C26.862915,120 0,93.137085 0,60 C0,26.862915 26.862915,0 60,0 Z M111.139095,91.394174 L111.010621,91.6054393 C106.082819,99.5419715 99.3544497,106.243715 91.3962986,111.139884 C90.9573797,110.632656 90.5562438,110.094595 90.1946659,109.528811 C98.0748441,104.715878 104.715878,98.0748441 109.529657,90.1957234 C110.094595,90.5562438 110.632656,90.9573797 111.139095,91.394174 Z"
            id="Combined-Shape"
            fill={color}
          />
          <path
            d="M102,116 C94.2680135,116 88,109.731986 88,102 C88,94.2680135 94.2680135,88 102,88 C109.731986,88 116,94.2680135 116,102 C116,109.731986 109.731986,116 102,116 Z"
            id="Oval"
            fill={color}
          />
          <g id="ui-confirm" transform="translate(94.000000, 96.000000)" fill={secondaryColor}>
            <g id="icons">
              <path
                d="M6.96187013,9.6720286 L2.22677513,5.80358992 C1.75991133,5.42398812 1.07827451,5.49210816 0.696035098,5.95641027 C0.315258663,6.42470255 0.383828351,7.10954435 0.848942394,7.48989462 L6.45990026,12.0734334 C6.65574256,12.2322883 6.89960548,12.3191791 7.14923413,12.3191791 L7.17414519,12.3187903 L7.209071,12.317682 C7.23475837,12.316097 7.23475837,12.316097 7.26364045,12.3136712 C7.57376682,12.2749988 7.83973081,12.1233942 8.01804634,11.8885396 L15.3313277,2.19494569 C15.6931986,1.71318017 15.5968778,1.03132033 15.1159696,0.670095234 C14.6417913,0.306300071 13.9565185,0.401672551 13.5930038,0.882730979 L6.96187013,9.6720286 Z"
                id="Shape"
              />
            </g>
          </g>
          <g id="noun_send-email_1498879" transform="translate(31.000000, 38.000000)" fill={color}>
            <g id="Group">
              <g transform="translate(3.128911, 0.000000)">
                <path
                  d="M42.2403004,40.0813517 C42.4252424,40.0813517 42.6008547,40.0342109 42.761378,39.9375604 C43.0502489,39.7695646 43.2393985,39.4687213 43.2684206,39.1329232 L46.3974376,1.58473656 C46.4283411,1.22419592 46.2679706,0.876349935 45.9710877,0.662509856 C45.6769636,0.449739246 45.2941768,0.40607319 44.9623476,0.551485154 L1.15856836,19.3245322 C0.783512799,19.4861584 0.538021098,19.852628 0.531753594,20.2587712 C0.527704,20.6682116 0.762627387,21.0424325 1.13028752,21.2129127 L41.8006039,39.9838662 C41.943982,40.0480653 42.0953814,40.0813517 42.2403004,40.0813517 Z M4.10470186,20.3105485 L44.1986006,3.12697973 L41.3368628,37.4941174 L4.10470186,20.3105485 Z"
                  id="Shape"
                />
                <path
                  d="M20.3379224,43.2102628 C20.6469162,43.2102628 20.9414822,43.072133 21.1359803,42.8310415 L28.9585559,33.2030158 C29.3197303,32.7592873 29.252879,32.1117849 28.8123487,31.7535878 C28.365442,31.3939839 27.7191296,31.4595057 27.3590093,31.8998772 L21.3704631,39.2703956 L21.3704631,29.3401585 L46.1276734,2.19784834 C46.5141702,1.7741487 46.4842802,1.12420081 46.0622393,0.737326912 C45.6430681,0.355323649 44.9899973,0.385357239 44.6044907,0.80590755 L19.5730367,28.2466389 C19.4005821,28.4355178 19.3053817,28.6827141 19.3053817,28.942428 L19.3053817,42.1777222 C19.3053817,42.6156141 19.579497,43.0032579 19.9890752,43.1486786 C20.1082966,43.1898641 20.2249353,43.2102628 20.3379224,43.2102628 Z"
                  id="Path"
                />
              </g>
              <path
                d="M18.4699625,47.6908636 C18.623231,47.6908636 18.774705,47.656795 18.9109795,47.5906622 C20.5493387,46.7978338 21.090565,46.2383767 21.3003678,45.6661967 C21.4981384,45.1326281 21.2243972,44.5398413 20.683607,44.3393708 C20.219252,44.1624737 19.6850718,44.3615548 19.4354119,44.7979349 L19.3305859,44.9282262 C19.0934083,45.1428154 18.6350478,45.4324034 18.0164123,45.729121 C17.5046217,45.9767616 17.2899053,46.5947104 17.5367111,47.1068513 C17.7132737,47.4680942 18.0762838,47.6908636 18.4699625,47.6908636 Z"
                id="Path"
              />
              <path
                d="M12.1214018,49.1770964 C12.6751539,49.1770964 13.097622,48.7337505 13.097622,48.1476846 C13.097622,47.5940286 12.5949254,47.1151439 12.008761,47.1151439 C10.4576197,47.1151439 9.0301391,46.8200038 7.76581992,46.2341783 C7.24873221,45.9963179 6.63257423,46.2230538 6.39375274,46.7398121 C6.15608183,47.256488 6.38221828,47.8697394 6.89946402,48.108786 C8.43932357,48.8174204 10.1562673,49.1770964 12.008761,49.1770964 L12.1214018,49.1770964 Z"
                id="Path"
              />
              <path
                d="M2.60325407,43.7578223 C2.78262932,43.7578223 2.96502753,43.7086122 3.12374981,43.6162092 C3.61615326,43.3244912 3.77908935,42.6899989 3.48811846,42.1988563 C3.13160209,41.5980602 2.81077328,40.9434398 2.52306951,40.2273747 C2.31209049,39.7050479 1.70081871,39.4472282 1.17789406,39.6580515 C0.649176392,39.8707777 0.393280192,40.4719105 0.605125879,41.0005245 C0.93725411,41.8143883 1.30608448,42.562652 1.71106291,43.2499136 C1.90193108,43.568704 2.2409809,43.7578223 2.60325407,43.7578223 Z"
                id="Path"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

SendIcon.propTypes = IconPropType;

export default SendIcon;
