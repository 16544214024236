import React from 'react';
import IconPropTypes from '../IconPropTypes';

const ArrowDownIcon = ({ className, color }) => (
  <svg width="27" height="27" className={className} viewBox="0 0 27 27">
    <g fill="none" fillRule="evenodd" stroke={color} strokeWidth="2" transform="translate(1 1)">
      <circle cx="12.444" cy="12.444" r="12.444" />
      <path
        strokeLinecap="round"
        d="M12.333 5.056v12.833M16.889 14.333l-4.556 4.556M7.833 14.333l4.5 4.556"
      />
    </g>
  </svg>
);

ArrowDownIcon.propTypes = IconPropTypes;

export default ArrowDownIcon;
