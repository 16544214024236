/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import './DocumentList.scss';
import DocumentDetailPV from './DocumentDetailPV';
import Button from '../ButtonTypes';

const DocumentListPostVenta = ({ subsidiary, requirementList }) => {
  const [visibleSolicitudes, setVisibleSolicitudes] = useState(5);

  const loadMore = () => {
    setVisibleSolicitudes(prevVisibleSolicitudes => prevVisibleSolicitudes + 5);
  };
  return (
    <>
      <div className={`DocumentList DocumentList--${subsidiary}`}>
        <div className="DocumentList__list">
          {requirementList.slice(0, visibleSolicitudes).map((solicitud, index) => (
            <DocumentDetailPV key={index} requirement={solicitud} subsidiary={subsidiary} />
          ))}
        </div>
        {visibleSolicitudes < requirementList.length && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button onClick={loadMore} style={{ width: 'max-content', padding: '7px 15px', margin: '5px 0px' }}>
              Cargar más
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

DocumentListPostVenta.propTypes = {
  subsidiary: PropTypes.string,
  requirementList: PropTypes.array,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default memo(DocumentListPostVenta);
