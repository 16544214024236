import { SELECT_EXECUTIVE, SELECT_CLIENT, REHYDRATE_EXECUTIVE_STATE, SELECT_FILIAL } from '../actions/actionTypes';

export const inicialState = {
  executiveRut: '',
  client: {},
  filial: '',
};

export const reducer = (state, action) => {
  switch (action.type) {
    case SELECT_EXECUTIVE:
      return {
        ...state,
        executiveRut: action.executiveRut,
      };
    case SELECT_CLIENT:
      return {
        ...state,
        client: { ...action.client },
      };
    case REHYDRATE_EXECUTIVE_STATE:
      return {
        ...state,
        client: { ...action.client },
        executiveRut: action.executiveRut,
      };
    case SELECT_FILIAL:
      return {
        ...state,
        filial: action.filial,
      };
    default:
      return {
        ...state,
      };
  }
};
