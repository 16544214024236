/* eslint-disable react/no-array-index-key */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';

// import SelectInput from '../SelectInput';
import DownArrowIconComponent from '../Icons/DownArrow';
import Milestones from './Milestones';

import { firstLetterUppercase } from '../../utils/utils';

import './WorkProgress.scss';

// const useProject = projects => {
//   const [selectedProject, setSelectedProject] = useState('');
//   const handleChange = event => {
//     setSelectedProject(event.target.value);
//   };
//   useMemo(() => {
//     setSelectedProject([projects[0].nombre_Proyecto]);
//   }, [projects]);
//   return { selectedProject, setSelectedProject, handleChange };
// };
const WorkProgressState = (state = 'inactivo') => {
  if (state === 'activo') {
    return (
      <Typography className="WorkProgress__panel__sub_title" variant="h5">
        ETAPA <b>{state === 'activo' ? 'ACTIVA' : ''}</b>
      </Typography>
    );
  }
  return false;
};

const WorkProgress = ({ milestones: { fases = [] } = {}, subsidiary }) => {
  // const { selectedProject, handleChange } = useProject(data);
  const [expanded, setExpanded] = React.useState('panel1');
  const handlePanel = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <div className={`WorkProgress WorkProgress--${subsidiary}`}>
      {fases && fases.length > 0 ? (
        fases.map(({ idFase, nombreFase, estado, hitos }, index) => (
          <ExpansionPanel
            key={`panel-${idFase}`}
            square
            className="WorkProgress__panel__parent"
            expanded={expanded === idFase}
            onChange={handlePanel(idFase)}
            // disabled={estado !== 'activo'}
          >
            <ExpansionPanelSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
              expandIcon={<DownArrowIconComponent color="#676977" />}
              className={estado}
            >
              <div className={`WorkProgress__icon WorkProgress__icon--${subsidiary}`} />
              <div className="WorkProgress__header">
                <Typography className="WorkProgress__panel__title" variant="h2">
                  {firstLetterUppercase(nombreFase)}
                </Typography>
                {WorkProgressState(estado)}
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div className="WorkProgress__container" key={index}>
                <div className={`linea linea--${subsidiary}`} />
                <div className="WorkProgress__container__body">
                  {/* eslint-disable-next-line no-shadow */}
                  {hitos.map(({ active, ...hitos }, index) => (
                    <Milestones
                      key={`milestone-${index}`}
                      subsidiary={subsidiary}
                      active={active}
                      {...hitos}
                    />
                  ))}
                </div>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ))
      ) : (
        <span className={`WorkProgress__empty WorkProgress__empty--${subsidiary}`}>
          No hay avances de obra
        </span>
      )}
    </div>
  );
};

WorkProgress.propTypes = {
  milestones: PropTypes.shape({
    fases: PropTypes.array,
  }),
  projects: PropTypes.array,
  subsidiary: PropTypes.string,
};

export default memo(WorkProgress);
