import React from 'react';
import PropTypes from 'prop-types';

import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/styles';

const adormentStyles = makeStyles(() => ({
  root: ({ color }) => ({
    color,
    borderColor: color,
  }),
}));

const CustomInputAdornment = ({ children, ...props }) => {
  const classes = adormentStyles();
  return (
    <InputAdornment className={classes.root} {...props}>
      {children}
    </InputAdornment>
  );
};

CustomInputAdornment.propTypes = {
  children: PropTypes.node,
};

export default CustomInputAdornment;
