import { useState, useContext, useEffect, useMemo } from 'react';
import { subsidiaryOptionsByText } from '../constants/filialesNames';

import { EnterpriseContext } from '../providers/EnterpriseProvider';
import { saveFilialOnLocalStorage } from '../services/additionalService';
import useHandlePagination from './pagination';

import { getProjectsByExecutive } from '../services/projectsService';

const useGetExecutiveProjects = executiveRut => {
  const [projectList, setProjectList] = useState([]);
  const [subsidiaryFilter, setSubsidiaryFilter] = useState([...subsidiaryOptionsByText]);
  const [activeSubsidiary, setActiveSubsidiary] = useState(
    localStorage.getItem('filial') || '',
  );
  const { selectFilial } = useContext(EnterpriseContext);
  const { pagination, addMoreRows } = useHandlePagination();

  useEffect(() => {
    if (activeSubsidiary !== undefined) {
      selectFilial(activeSubsidiary);
      saveFilialOnLocalStorage(activeSubsidiary);
    }
  }, [activeSubsidiary]);

  useMemo(() => {
    if (executiveRut !== '') {
      getProjectsByExecutive(
        executiveRut,
        activeSubsidiary,
        pagination.page,
        pagination.limit,
        res => {
          const filiales = res.data.filtroFilial
            .filter(filial => filial.count > 0)
            .map(filial => ({
              label: filial.name.toLowerCase(),
              value: filial.name.toLowerCase(),
            }));
          setSubsidiaryFilter([...filiales]);
          const thisProjectList = res.data.data.filter(
            project => project.filial.toUpperCase() === activeSubsidiary.toUpperCase(),
          );
          setProjectList([...thisProjectList]);
          if (filiales.length > 0 && activeSubsidiary === '') {
            setActiveSubsidiary(filiales[0].value);
          }
        },
        err => {
          console.error(err);
        },
      );
    }
  }, [executiveRut, activeSubsidiary, pagination]);
  return {
    projectList,
    setProjectList,
    activeSubsidiary,
    setActiveSubsidiary,
    addMoreRows,
    subsidiaryFilter,
  };
};

export default useGetExecutiveProjects;
