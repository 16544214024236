/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import Button from '../ButtonTypes';
import RightArrowButton from '../Icons/RightArrowButton';
import LeftArrowButton from '../Icons/LeftArrowButton';

import './ProjectButton.scss';

const ProjectButton = ({ className, subsidiary, label, onClick, left }) => (
  <div className={`ProjectButton__wrapper ${className}`}>
    <div className="ProjectButton">
      <Button variant="text">
        {left ? <LeftArrowButton
          className={`ProjectButton__container ProjectButton__container--${subsidiary}`}
          onClick={onClick}
          styles={{marginRight: '5'}}
        />:
        <RightArrowButton
          className={`ProjectButton__container ProjectButton__container--${subsidiary}`}
          onClick={onClick}
        />
        }
        {label && (
          <span className={`ProjectButton__label ProjectButton__label--${subsidiary}`}>
            {label}
          </span>
        )}
      </Button>
    </div>
  </div>
);

ProjectButton.propTypes = {
  className: PropTypes.string,
  subsidiary: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
};

export default ProjectButton;
