import React from 'react';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

import { currentColor } from '../../../constants/colors';

const Solicitud = ({ color = currentColor, className }) => (
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className={className} style={{marginRight: '10px'}}>
    <g transform="translate(0.000000,24.000000) scale(0.004687,-0.004687)" fill={color} stroke="none">
      <path d="M1110 5098 c-19 -21 -20 -34 -20 -320 l0 -298 -204 0 c-191 0 -205
      -1 -230 -21 l-26 -20 0 -359 0 -359 22 -15 c33 -23 91 -20 114 5 18 20 19 42
      22 315 l3 294 1544 0 1545 0 0 -1204 0 -1205 26 -20 c43 -34 102 -21 123 26 8
      17 11 375 11 1259 0 1336 2 1283 -52 1298 -13 3 -634 6 -1380 6 l-1358 0 0
      240 0 240 1545 0 1544 0 3 -265 c3 -293 3 -291 69 -301 26 -4 41 0 62 16 l27
      21 0 325 c0 311 -1 325 -20 344 -20 20 -33 20 -1685 20 l-1664 0 -21 -22z"/>
      <path d="M4365 4175 l-25 -24 0 -1676 0 -1675 -150 0 -149 0 -3 420 c-3 464 0
      445 -69 456 -26 4 -41 0 -62 -16 l-27 -21 0 -740 0 -739 -1545 0 -1545 0 -2
      1644 c-3 1601 -4 1644 -22 1665 -26 28 -88 29 -116 1 -20 -20 -20 -33 -20
      -1728 0 -1643 1 -1710 18 -1725 17 -16 148 -17 1685 -17 l1666 0 20 26 c20 26
      21 38 21 320 l0 294 205 0 c205 0 206 0 230 25 l25 24 0 1735 0 1735 -26 20
      c-36 28 -79 27 -109 -4z"/>
      <path d="M1515 4000 c-29 -32 -25 -88 9 -116 l27 -24 775 0 c661 0 779 2 803
      15 46 24 55 74 20 119 l-20 26 -798 0 c-792 0 -798 0 -816 -20z"/>
      <path d="M1722 3700 c-33 -31 -30 -92 4 -119 26 -21 35 -21 609 -21 557 0 584
      1 606 19 33 27 33 95 0 122 -22 18 -49 19 -610 19 -577 0 -588 0 -609 -20z"/>
      <path d="M1771 3375 l-47 -44 -338 -3 -338 -3 -19 -24 c-18 -22 -19 -43 -19
      -358 l0 -334 25 -24 24 -25 333 0 c359 1 357 0 378 54 6 16 10 131 10 284 l0
      258 69 68 c38 38 72 80 76 94 13 51 -30 102 -86 102 -11 0 -41 -20 -68 -45z
      m-286 -285 l-79 -79 -43 39 c-35 33 -50 40 -82 40 -63 0 -98 -57 -70 -113 17
      -33 120 -136 153 -154 44 -23 74 -9 164 80 l82 82 0 -133 0 -132 -220 0 -220
      0 0 225 0 225 197 0 198 0 -80 -80z"/>
      <path d="M2004 3116 c-34 -15 -44 -33 -44 -77 0 -36 29 -66 72 -74 18 -3 377
      -5 796 -3 l764 3 19 24 c24 30 24 82 0 112 l-19 24 -779 2 c-638 2 -784 0
      -809 -11z"/>
      <path d="M1982 2697 c-30 -32 -29 -81 3 -112 l24 -25 586 0 586 0 24 25 c16
      15 25 36 25 55 0 19 -9 40 -25 55 l-24 25 -589 0 -589 0 -21 -23z"/>
      <path d="M1771 2345 l-47 -44 -338 -3 -338 -3 -19 -24 c-18 -22 -19 -43 -19
      -358 l0 -334 25 -24 24 -25 333 0 c359 1 357 0 378 54 6 16 10 131 10 284 l0
      258 69 68 c38 38 72 80 76 94 13 51 -30 102 -86 102 -11 0 -41 -20 -68 -45z
      m-286 -285 l-79 -79 -43 39 c-35 33 -50 40 -82 40 -63 0 -98 -57 -70 -113 17
      -33 120 -136 153 -154 44 -23 74 -9 164 80 l82 82 0 -133 0 -132 -220 0 -220
      0 0 225 0 225 197 0 198 0 -80 -80z"/>
      <path d="M2004 2086 c-34 -15 -44 -33 -44 -77 0 -36 29 -66 72 -74 18 -3 377
      -5 796 -3 l764 3 19 24 c24 30 24 82 0 112 l-19 24 -779 2 c-638 2 -784 0
      -809 -11z"/>
      <path d="M1982 1667 c-30 -32 -29 -81 3 -112 l24 -25 586 0 586 0 24 25 c16
      15 25 36 25 55 0 19 -9 40 -25 55 l-24 25 -589 0 -589 0 -21 -23z"/>
      <path d="M1053 1260 c-12 -5 -26 -18 -32 -29 -7 -13 -11 -136 -11 -351 l0
      -331 25 -24 24 -25 334 0 c319 0 334 1 355 20 11 11 24 35 27 52 3 18 5 172 3
      341 l-3 309 -28 24 -28 24 -322 -1 c-177 0 -332 -4 -344 -9z m557 -375 l0
      -225 -220 0 -220 0 0 225 0 225 220 0 220 0 0 -225z"/>
      <path d="M2004 1056 c-34 -15 -44 -33 -44 -77 0 -36 29 -66 72 -74 18 -3 376
      -5 794 -3 733 3 763 4 783 22 27 25 28 85 2 117 l-19 24 -779 2 c-638 2 -784
      0 -809 -11z"/>
      <path d="M1982 637 c-30 -32 -29 -81 3 -112 l24 -25 586 0 586 0 24 25 c16 15
      25 36 25 55 0 19 -9 40 -25 55 l-24 25 -589 0 -589 0 -21 -23z"/>
    </g>
  </svg>
);

Solicitud.propTypes = {
  theme: PropTypes.object,
  color: PropTypes.string,
  className: PropTypes.string,
};

export default withStyles({}, { withTheme: true })(Solicitud);
