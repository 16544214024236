import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Edit } from '@material-ui/icons';

import { TextField } from '@material-ui/core';

const styles = () => ({
  inputLabel: {
    fontSize: '10px',
  },
  icon: {
    color: '#bebfc5',
  },
  input: {
    fontSize: '16px',
    paddingBottom: '10px',
  },
  contentInput: {
    marginBottom: '45px',
    '& .MuiFormHelperText-root.Mui-error': {
      fontSize: '10px',
      marginTop: '2px',
    },
  },
  formControl: {
    marginTop: '5px !important',
    // marginBottom: '45px',
    '&:after': {
      borderBottom: '2px solid #0093ca',
    },
    '&:hover:not($disabled):not($focused):not($error):before': {
      borderBottom: '2px solid #0093ca !important',
    },
  },
  focused: {
    color: '#0093ca !important',
  },
});

const useProps = props => {
  const propsFields = Object.assign({}, props);
  propsFields.classes = undefined;
  propsFields.icon = undefined;

  propsFields.disabled = propsFields.disabled ? 'disabled' : undefined;
  return propsFields;
};

const ExecutiveFieldForm = props => {
  const { disabled, classes, icon = false } = props;
  const newProps = useProps(props);
  const iconWidget = icon ? <Edit className={classes.icon} /> : '';
  return (
    <TextField
      id="nombre"
      {...newProps}
      className={[classes.contentInput]}
      disabled={disabled}
      fullWidth
      margin="none"
      InputProps={{
        endAdornment: iconWidget,
        classes: {
          root: classes.formControl,
          input: classes.input,
        },
      }}
      InputLabelProps={{
        shrink: true,
        classes: {
          root: classes.inputLabel,
          focused: classes.focused,
        },
        style: {
          fontSize: '20px',
          position: 'relative',
        },
      }}
    />
  );
};

ExecutiveFieldForm.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  classes: PropTypes.shape({
    formControl: PropTypes.string,
    input: PropTypes.string,
    inputLabel: PropTypes.string,
    focused: PropTypes.string,
    icon: PropTypes.string,
    contentInput: PropTypes.string,
  }),
  inputChange: PropTypes.func,
  inputBlur: PropTypes.func,
  icon: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default withStyles(styles)(ExecutiveFieldForm);
