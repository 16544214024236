import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '../../components/ButtonTypes';
import avatar from '../../assets/avatar.png';
import { getAvatarImage } from '../../constants/endpoints';

import LastMessages from '../../components/LastMessages/LastMessages';

// Services
import { getMessagesByProject } from '../../services/messageService';
import { getExecutiveByClient } from '../../services/executive';

import './ClientExecutive.scss';
import { useIslogged } from '../../customHooks/useIslogged';

const ClientExecutive = ({
  match: {
    params: { subsidiary },
  },
  history,
}) => {
  if (!useIslogged()) history.push(`/${subsidiary}`);

  const [dataExecutive, setDataExecutive] = useState({});
  const [messages, setMessages] = useState([]);

  const getCurrentRut = () => {
    return JSON.parse(sessionStorage.getItem('username'));
  };

  const getDataExecutive = () => {
    const rut = getCurrentRut();
    getExecutiveByClient(
      rut,
      response => {
        setDataExecutive(response.data);
      },
      error => {
        console.error(error);
      },
    );
  };

  const getMessages = () => {
    const rut = getCurrentRut();
    getMessagesByProject(
      '',
      rut,
      '',
      1,
      3,
      false,
      response => {
        setMessages(response.data.data);
      },
      error => {
        console.error(error);
      },
    );
  };

  const [fileKey, setFileKey] = useState(Date.now());
  const [avatarImage, setAvatarImage] = useState(avatar);

  useEffect(() => {
    getDataExecutive();
    getMessages();
    setFileKey(Date.now());
  }, []);

  useEffect(() => {
    if (dataExecutive.rut) {
      setAvatarImage(`${getAvatarImage}/null?rut=${dataExecutive.rut}&key=${fileKey}`);
    }
  }, [dataExecutive]);

  const checkMessages = () => {
    history.push(`/${subsidiary}/Home/messages`);
  };
  return (
    <div className={`ClientExecutive__container--${subsidiary}`}>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <div className="ClientExecutive__head">
            <span className="ClientExecutive__head__avatarLabel">
              <img className="ClientExecutive__head__avatar" src={avatarImage} alt="avatar" />
            </span>
            <div className="ClientExecutive__head__name_container">
              <span
                className={`ClientExecutive__head__name_container__name ClientExecutive__head__name_container__name--${subsidiary}`}
              >
                {dataExecutive && dataExecutive.nombres
                  ? `${dataExecutive.nombres.toLowerCase()} ${dataExecutive.apellidos.toLowerCase()}`
                  : 'Sin nombre'}
              </span>
              <span
                className={`ClientExecutive__head__name_container__subname ClientExecutive__head__name_container__subname--${subsidiary}`}
              >
                {dataExecutive && dataExecutive.cargo
                  ? dataExecutive.cargo.toLowerCase()
                  : 'Sin cargo'}
              </span>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <span className={`ClientExecutive__descripcion--${subsidiary}`}>
            {dataExecutive.descripcion || 'Sin descripción'}
          </span>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className="ClientExecutive__data_container">
            {/* <span className="ClientExecutive__data">JEFATURA</span>
            <span className={`ClientExecutive__data__detail--${subsidiary}`}>
              {dataExecutive.jefatura || 'No definido'}
            </span> */}
            <span className="ClientExecutive__data">TELÉFONO</span>
            <span className={`ClientExecutive__data__detail--${subsidiary}`}>
              {dataExecutive.telefono || 'No definido'}
            </span>
            <span className="ClientExecutive__data">EMAIL</span>
            <span className={`ClientExecutive__data__detail--${subsidiary}`}>
              {dataExecutive.mail || 'No definido'}
            </span>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="ClientExecutive__Line" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className="ClientExecutive__contact">
            <span
              className={`ClientExecutive__contact__title ClientExecutive__contact__title--${subsidiary}`}
            >
              CONTÁCTAME
            </span>
            <span
              className={`ClientExecutive__contact__text ClientExecutive__contact__text--${subsidiary}`}
            >
              Puedes contactarme por los siguientes canales:
            </span>
            <Button
              className={`ClientExecutive__contact__button ClientExecutive__contact__button--${subsidiary}`}
              variant="text"
              onClick={() => {
                checkMessages();
              }}
            >
              MENSAJE
            </Button>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className="ClientExecutive__contact">
            <span
              className={`ClientExecutive__contact__title ClientExecutive__contact__title--${subsidiary}`}
            >
              ULTIMOS MENSAJES
            </span>
            <LastMessages messages={messages} onClick={checkMessages} subsidiary={subsidiary} />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

ClientExecutive.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      subsidiary: PropTypes.string,
    }),
  }),
  history: PropTypes.object,
};

export default ClientExecutive;
