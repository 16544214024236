import React from 'react';
import PropTypes from 'prop-types';

import Button from '../ButtonTypes';
import ClearIcon from '../Icons/ClearIcon';

const DeleteButton = ({ onClick, style, label = true }) => (
  <Button onClick={onClick} variant="text" style={{ ...style }}>
    <span className="DeleteButton">
      <ClearIcon color="currentColor" className="DeleteButton__container" />
      {label && <span className="DeleteButton__label">eliminar</span>}
    </span>
  </Button>
);

DeleteButton.propTypes = {
  onClick: PropTypes.func,
  style: PropTypes.object,
  label: PropTypes.bool,
};

export default DeleteButton;
