import React, { Fragment, useMemo, useState, useContext, memo } from 'react';
import axios from 'axios';

import { Route, useHistory, Redirect } from 'react-router-dom';

// Screens
import Toolkit from '../../screens/Toolkit';

import Loader from '../../components/Loader';

import PruebaAPI from '../../components/PruebaAPI/PruebaAPI';

// Router
import ClientRouter from '../ClientRouter';
import EnterpriseRouter from '../EnterpriseRouter';

import { FetchContext } from '../../providers/RequestProvider';
import { GlobalContext, GlobalStateProvider } from '../../providers/GlobalStateProvider';

import { socovesa, pilares, almagro, empresas } from '../../constants/filialesNames';

const Interceptor = (token, subsidiary) => {
  const { fetchInit, fetchEnd, fetchError } = useContext(FetchContext);
  const [interceptor, setInterceptor] = useState(undefined);
  const history = useHistory();
  useMemo(() => {
    if (token) {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      // axios.defaults.headers.common.Authorization = `${token}`;
    }

    setInterceptor(
      axios.interceptors.request.use(
        request => {
          fetchInit(request);
          return request;
        },
        error => {
          fetchEnd();
          return Promise.reject(error);
        },
      ),
    );

    // Add a response interceptor
    setInterceptor(
      axios.interceptors.response.use(
        response => {
          fetchEnd(response.config.url);
          return response;
        },
        res => {
          let message;
          if (res) {
            const { response: { data, status } = {}, config: { url } = {} } = res;
            if (status === 401) {
              sessionStorage.clear();
              if (subsidiary) {
                history.push(`/${subsidiary}`);
              }
            }
            message =
              data && Object.keys(data).length > 0 && typeof data !== 'string'
                ? data.message
                : data;
            if (message && url) {
              fetchError(message, url);
            } else {
              // fallback to network error
              fetchError(res.message, url);
            }
          } else {
            fetchError('No response');
            message = 'No response';
          }
          return Promise.reject(message);
        },
      ),
    );

    return () => {
      axios.interceptors.request.eject(interceptor);
    };
  }, [token, fetchInit, fetchError, fetchEnd]);
  return { token };
};

const Router = () => {
  const {
    state: { token },
  } = useContext(GlobalContext);
  const host = window.location.host.split('.');

  let subsidiary = empresas;
  const pathname = window.location.pathname;

  if (!window.location.host.includes('cloudapp.azure.com')) {
    const filial = host.length > 2 ? host[1] : empresas;
    if ([socovesa, pilares, almagro, empresas].includes(filial)) {
      subsidiary = filial;
    }
  }

  Interceptor(token, subsidiary);
  const favicon = document.getElementById('favicon');
  if (favicon && subsidiary) {
    favicon.href = `/favicon_${subsidiary}.ico`;
  }
  const urls = ['mipv-api', 'apidemopv'];
  return (
    <Fragment>
      <Loader />
      <Route path="/Toolkit" component={Toolkit} />
      <Route path={`/:subsidiary(${socovesa}|${almagro}|${pilares})`} component={ClientRouter} />
      <Route path={`/:subsidiary(${empresas})`} component={EnterpriseRouter} />
      <Route path={`/prueba-api`} exact component={PruebaAPI} />
      {urls.indexOf(host[0]) !== -1 && <Redirect to={`/prueba-api`} />}
      {pathname === '/' && <Redirect to={`/${subsidiary}`} />}
    </Fragment>
  );
};

export default memo(Router);
