import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';

import Typography from '@material-ui/core/Typography';
import SelectInput from '../SelectInput';
import Button from '../ButtonTypes';
import Dialog from '../Dialog';
import FileUploadInput from '../FileUploadInput';
import DeleteButton from '../DeleteButton';

import { ImageSizes } from '../../constants/ImageSizes';
import Lightbox from 'react-awesome-lightbox';
import 'react-awesome-lightbox/build/style.css';
import './ImageUpload.scss';

const useHandleDialog = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [imageFile, setImageFile] = useState();
  const [imagePreview, setImagePreview] = useState({});
  const [imageSize, setImageSize] = useState('');
  const toogleDialog = () => {
    setOpenDialog(!openDialog);
  };
  const handleImagesizeChange = ({ target: { value } }) => {
    if (value && value !== '') {
      setImageSize({
        value,
        dimensions: {
          width: value.match(/\(([^)]+)\)/)[1].match(/\d+/g)[0],
          height: value.match(/\(([^)]+)\)/)[1].match(/\d+/g)[1],
        },
      });
    }
  };
  const handleFileChange = (e, name) => {
    setImageFile({ files: e.target.files, name });
    const fr = new FileReader();
    fr.readAsDataURL(e.target.files[0]);
    fr.onloadend = () => {
      setImagePreview([fr.result]);
    };
    setImagePreview(fr);
    // reset input workaround
    // e.target.value = null;
    toogleDialog();
  };
  return {
    openDialog,
    imageFile,
    toogleDialog,
    handleFileChange,
    handleImagesizeChange,
    imageSize,
    imagePreview,
  };
};

const ImageUpload = ({ onSubmit, imagesList = [], deleteImageCallback }) => {
  const {
    openDialog,
    imageFile,
    toogleDialog,
    handleFileChange,
    handleImagesizeChange,
    imageSize: { value: imageSizeValue = '', dimensions } = {},
    imagePreview,
  } = useHandleDialog();
  const handleSubmit = () => {
    onSubmit(imageFile, imageSizeValue);
    toogleDialog();
  };
  const [selectedImage, setSelectedImage] = useState({});
  const [open, setOpen] = useState(false);

  const onClickImage = image => {
    setSelectedImage(image);
    setOpen(true);
  };

  const [imageList, setImageList] = useState([]);
  useEffect(() => {
    const imagesNames = ImageSizes.map(({ value }) => value);
    const images = imagesList.filter(({ nombre_imagen }) => {
      let existe = 0;
      imagesNames.map(nombre => {
        existe += nombre_imagen.indexOf(nombre);
      });
      return existe > 0;
    });
    setImageList(images);
  }, [imagesList]);

  return (
    <Fragment>
      {open && (
        <Lightbox
          image={selectedImage.url}
          title={selectedImage.title}
          onClose={_e => setOpen(false)}
        />
      )}
      <div className="ImageUpload">
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Grid container spacing={0} alignContent="space-around">
              <Grid item xs={12} sm={6}>
                <SelectInput
                  name="imageSize"
                  options={ImageSizes}
                  value={imageSizeValue}
                  label="SELECCIONE TIPO DE IMAGEN"
                  variant="outlined"
                  onChange={handleImagesizeChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FileUploadInput
                  accept="image/gif, image/jpeg, image/png"
                  onChange={handleFileChange}
                  disabled={imageSizeValue === ''}
                  buttonLabel="ADJUNTAR"
                  className="ImageUpload__uploadButton"
                  name="imageUpload"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      {imageList.length > 0 && (
        <div className="ImageUpload__listContainer">
          <Typography>IMAGEN CARGADA:</Typography>
          <ul className="ImageUpload__listContainer__list">
            {imageList.map(({ nombre_imagen: imageName, url }) => (
              <li
                className="ImageUpload__listContainer__list__item"
                key={imageName}
                onClick={_e => onClickImage({ title: imageName, url })}
              >
                <img
                  src={url}
                  className="ImageUpload__listContainer__list__item__img"
                  alt="preview"
                />
                <Typography style={{ marginLeft: '20px', color: '#0093ca', fontWeight: 600 }}>
                  {imageName.split('/')[1].split('(')[0]}
                </Typography>
                <Typography>
                  {`(${imageName.match(/\(([^)]+)\)/)[1].match(/\d+/g)[0]}px x ${
                    imageName.match(/\(([^)]+)\)/)[1].match(/\d+/g)[1]
                  }px)`}
                </Typography>
                {/* <DeleteButton
                  label
                  style={{ marginLeft: 'auto', marginRight: '20px' }}
                  onClick={() => deleteImageCallback(imageName)}
                /> */}
              </li>
            ))}
          </ul>
        </div>
      )}
      <Dialog
        title="Previsualización de imagen"
        open={openDialog}
        maxWidth={false}
        actions={
          <div className="ImageUpload__action">
            <Button variant="contained" onClick={toogleDialog} color="secondary">
              cancelar
            </Button>
            <Button variant="contained" onClick={handleSubmit}>
              aceptar
            </Button>
          </div>
        }
      >
        <div className="ImageUpload__preview">
          <img
            className="ImageUpload__preview__img"
            src={imagePreview}
            alt="preview"
            style={
              dimensions && { width: `${dimensions.width}px`, height: `${dimensions.height}px` }
            }
          />
          <span className="ImageUpload__preview__description">{imageSizeValue}</span>
        </div>
      </Dialog>
    </Fragment>
  );
};

ImageUpload.propTypes = {
  onSubmit: PropTypes.func,
  imageList: PropTypes.array,
  deleteImageCallback: PropTypes.func,
};

export default ImageUpload;
