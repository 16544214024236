import React, { createContext, useReducer, useCallback } from 'react';
import PropTypes from 'prop-types';

import { reducer, inicialState } from '../reducers/clientReducer';
import { UPDATE_PROJECTLIST, SELECT_PROJECTLIST } from '../actions/actionTypes';

const ClientContext = createContext('client');

const ClientProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, inicialState);
  const updateProjectList = useCallback(projectList => {
    dispatch({ type: UPDATE_PROJECTLIST, projectList });
  }, []);
  const selectProjectList = useCallback(activeProject => {
    dispatch({ type: SELECT_PROJECTLIST, activeProject });
  }, []);
  return (
    <ClientContext.Provider value={{ state, dispatch, updateProjectList, selectProjectList }}>
      {children}
    </ClientContext.Provider>
  );
};

ClientProvider.propTypes = {
  children: PropTypes.node,
};

export { ClientProvider, ClientContext };
