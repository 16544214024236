import React, { useEffect, useState, useContext, useCallback, memo } from 'react';
import PropTypes from 'prop-types';

// Components
import EnterpriseMessageItem from './EnterpriseMessageItem';
import SubsidiaryTabs from '../../components/SubsidiaryTabs';
import Searchbar from '../../components/Searchbar';
import ArrowDownCircle from '../../components/Icons/ArrowDownCircle';
import Button from '../../components/ButtonTypes';

import { filterMessage } from '../../services/messageService';

import { GlobalContext } from '../../providers/GlobalStateProvider';
import useDebounce from '../../customHooks/debounce';

import './EnterpriseMessageList.scss';
import { useIslogged } from '../../customHooks/useIslogged';
import useGetExecutiveProjects from '../../customHooks/executive';

const baseRows = 10;

const searchMessage = (text, setFilteredMessages) => {
  filterMessage(
    text,
    ({ data: { data } }) => {
      setFilteredMessages([...data]);
    },
    err => {
      console.error(err);
    },
  );
};

const useHandleTextFilter = () => {
  const [textFilter, setFilterText] = useState('');
  const filterOnChange = newValue => {
    setFilterText(newValue);
  };
  return { textFilter, setFilterText, filterOnChange };
};
const EnterpriseMessageList = ({
  match: {
    params: { subsidiary },
  },
  history,
  onReadMessages,
}) => {
  if (!useIslogged()) history.push(`/${subsidiary}`);

  const {
    state: { fullName, rut },
  } = useContext(GlobalContext);

  const {
    projectList,
    activeSubsidiary,
    setActiveSubsidiary,
    addMoreRows,
    subsidiaryFilter,
  } = useGetExecutiveProjects(rut);
  const { textFilter, filterOnChange } = useHandleTextFilter();
  const { debouncedValue } = useDebounce(textFilter, 1000);
  const [filteredMessages, setFilteredMessages] = useState([]);
  useEffect(() => {
    if (debouncedValue && debouncedValue !== '') {
      searchMessage(debouncedValue, setFilteredMessages);
    }
  }, [debouncedValue]);

  useEffect(() => {
    setActiveSubsidiary(activeSubsidiary);
  }, []);

  const handleTabChange = useCallback(
    (event, newValue) => {
      setActiveSubsidiary(newValue);
    },
    [setActiveSubsidiary],
  );

  return (
    <div className="EnterpriseMessageList">
      <h2 className="EnterpriseMessageList__title">MIS MENSAJES</h2>
      <SubsidiaryTabs
        className="EnterpriseMessageList__tabs"
        value={activeSubsidiary}
        options={subsidiaryFilter}
        onChange={handleTabChange}
      />
      <div className="EnterpriseMessageList__searchbar">
        <Searchbar
          helperMessage="¿Estás buscando un mensajes en particular?"
          onChange={filterOnChange}
          value={textFilter}
        />
      </div>
      {projectList.map(
        ({ id_Proyecto: projectId, nombre_Proyecto: projectName, haveNew }, index) => (
          <EnterpriseMessageItem
            className="EnterpriseMessageList__item"
            projectName={projectName}
            subsidiary={subsidiary}
            fullName={fullName}
            from={rut}
            username={rut}
            textFilter={textFilter}
            projectId={projectId}
            openAtStart={index === 0}
            key={projectId}
            filteredMessages={filteredMessages.filter(message => message.id_proyecto === projectId)}
            haveNew={haveNew}
            onReadMessages={onReadMessages}
          />
        ),
      )}
      <div className="EnterpriseMessageList__addMoreRows">
        {projectList.length >= baseRows && (
          <Button variant="text" onClick={addMoreRows} padding="0px">
            <span className="EnterpriseMessageList__addMoreRows__label">
              cargar más Proyectos
              <ArrowDownCircle className="EnterpriseMessageList__addMoreRows__icon" />
            </span>
          </Button>
        )}
      </div>
    </div>
  );
};

EnterpriseMessageList.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      subsidiary: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  onReadMessages: PropTypes.func.isRequired,
};

export default memo(EnterpriseMessageList);
