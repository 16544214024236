import React from 'react';
import PropTypes from 'prop-types';
import ClientProjects from './ClientProjects';
import EnterpriseMessageItem from '../EnterpriseMessages/EnterpriseMessageItem';

const ProjectDetails = ({
  subsidiaryProjects,
  subsidiary,
  nombres,
  apellidos,
  executiveRut,
  rutCliente,
  onReadMessages,
  clientId,
}) => (
  <div className="ProjectList__others">
    <h5>MENSAJES</h5>
    {subsidiaryProjects.map(({ id_Proyecto, nombre_Proyecto, haveNew }, index) => (
      <EnterpriseMessageItem
        className="EnterpriseMessageList__item"
        projectName={nombre_Proyecto}
        subsidiary={subsidiary}
        fullName={`${nombres} ${apellidos}`}
        from={executiveRut}
        username={executiveRut}
        projectId={id_Proyecto}
        openAtStart={index === 0}
        key={index}
        haveNew={haveNew}
        clientRut={rutCliente}
        onReadMessages={onReadMessages}
      />
    ))}
    <ClientProjects
      projects={subsidiaryProjects}
      clientId={clientId.slice(0, -1)}
      subsidiary={subsidiary}
    />
  </div>
);

ProjectDetails.propTypes = {
  subsidiaryProjects: PropTypes.array,
  subsidiary: PropTypes.string,
  nombres: PropTypes.string,
  apellidos: PropTypes.string,
  executiveRut: PropTypes.string,
  rutCliente: PropTypes.string,
  onReadMessages: PropTypes.func,
  clientId: PropTypes.string,
};

export default ProjectDetails;
