import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Components
import ClientMessageList from './ClientMessageList';
import { GlobalContext } from '../../providers/GlobalStateProvider';
import Searchbar from '../../components/Searchbar';

import { getProjectsByUser } from '../../services/projectsService';
import useDebounce from '../../customHooks/debounce';

import { filterMessage } from '../../services/messageService';

import './ClientMessages.scss';
import { useIslogged } from '../../customHooks/useIslogged';

const useProjectList = (username = '') => {
  const [projectList, setProjectList] = useState([]);
  useEffect(() => {
    if (username !== '') {
      getProjectsByUser(
        username,
        data => {
          if (Array.isArray(data)) {
            setProjectList([...data]);
          } else {
            setProjectList([{ ...data }]);
          }
        },
        err => {
          console.error(err);
        },
      );
    }
  }, [username]);
  return { projectList };
};

const useHandleTextFilter = () => {
  const [textFilter, setFilterText] = useState('');
  const filterOnChange = newValue => {
    setFilterText(newValue);
  };
  return { textFilter, setFilterText, filterOnChange };
};

const searchMessage = (text, setFilteredMessages) => {
  filterMessage(
    text,
    ({ data: { data } }) => {
      setFilteredMessages([...data]);
    },
    err => {
      console.error(err);
    },
  );
};

const useDebounceSearch = debouncedValue => {
  const [filteredMessages, setFilteredMessages] = useState([]);
  useEffect(() => {
    if (debouncedValue && debouncedValue !== '') {
      searchMessage(debouncedValue, setFilteredMessages);
    }
  }, [debouncedValue]);
  return { filteredMessages };
};

const ClientMessages = ({
  match: {
    params: { subsidiary },
  },
  history,
  onReadMessages
}) => {

  if (!useIslogged()) history.push(`/${subsidiary}`)

  const { state: { username = '' } = {} } = useContext(GlobalContext);
  const { filterOnChange, textFilter } = useHandleTextFilter();
  const { debouncedValue } = useDebounce(textFilter, 1000);
  const { projectList } = useProjectList(username);
  const { filteredMessages } = useDebounceSearch(debouncedValue);
  return (
    <div className={`ClientMessages ClientMessages--${subsidiary}`}>
      <h1 className={`ClientMessages__title ClientMessages__title--${subsidiary}`}>Mis mensajes</h1>
      <Searchbar
        helperMessage="¿Estás buscando un mensajes en particular?"
        onChange={filterOnChange}
        value={textFilter}
        subsidiary={subsidiary}
        className="ClientMessages__searchBar"
      />
      {projectList && projectList.length > 0
        ? projectList.map(({ id_Proyecto: projectId, nombre_Proyecto: projectName }, index) => (
            <ClientMessageList
              subsidiary={subsidiary}
              projectId={projectId}
              projectName={projectName}
              openAtStart={index === 0}
              key={projectId}
              filteredMessages={filteredMessages}
              textFilter={textFilter}
              onReadMessages={onReadMessages}
            />
          ))
        : null}
    </div>
  );
};

ClientMessages.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      subsidiary: PropTypes.string,
    }),
  }),
};

export default ClientMessages;
