import { useEffect, useState } from "react";
import { getAdicionales, getClientData } from "../../../services/postventaService";


export const useAdditionals = (rutCliente) => {
  const [additionals, setAdditionals] = useState([]);
  useEffect(() => {
    getAdicionales(
      rutCliente,
      ({ data }) => {
      setAdditionals(data);
    }, err => {
      console.error(err);
    });
  }, [rutCliente]);
  return { additionals };
};

export const useCliente = rut => {
  const [cliente, setCliente] = useState(null);
  useEffect(() => {
    getClientData(
      rut,
      ({ data }) => {
        const cli = data.data[0];
        setCliente({ ...cli, dv: dgv(cli.id_Cliente) });
      },
      err => {
        console.error(err);
      },
    );
  }, [rut]);
  return { cliente };
};

export const dgv = (T) => {
  //digito verificador
  var M = 0,
    S = 1;
  for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
  return S ? S - 1 : 'k';
}
