import React from 'react';

import PropTypes from 'prop-types';

// Formik
import { withFormik, Form } from 'formik';
import * as Yup from 'yup';

import TextFields from '../../components/TextFields';
import Buttons from '../../components/ButtonTypes';

function goToLogin(history, subsidiary) {
  history.push(`/${subsidiary}/Home`);
}


export const RecoverPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(4, 'La contraseña debe tener almenos 4 caracteres de largo')
    .max(20, 'La contraseña debe tener maximo 20 caracteres de largo')
    .required('Este campo es requerido'),
  verifyPassword: Yup.string()
    .required('Este campo es requerido')
    .oneOf([Yup.ref('password'), null], 'Las Contraseñas deben coincidir'),
});

const RecoverPasswordInnerForm = ({
  errors,
  touched,
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  onCancel,
  // isValid,
}) => {
  return (
    <Form className="RecoverPassword__container__form" onSubmit={handleSubmit} onCancel={onCancel}>
      <TextFields
        name="password"
        type="password"
        value={values.password}
        color="primary"
        onChange={handleChange}
        onBlur={handleBlur}
        error={errors.password && touched.password}
        label="Nueva clave de acceso"
        helperText={errors.password && touched.password ? errors.password : '(4-20 caracteres)'}
      />
      <TextFields
        name="verifyPassword"
        type="password"
        value={values.verifyPassword}
        color="primary"
        onChange={handleChange}
        onBlur={handleBlur}
        error={errors.verifyPassword && touched.verifyPassword}
        label="Repetir clave de acceso"
        helperText={errors.verifyPassword && touched.verifyPassword && errors.verifyPassword}
      />
      <div className="RecoverPassword__container__form__buttons">
        <Buttons type="submit">Guardar</Buttons>
        <Buttons color="secondary" onClick={onCancel}>Cancelar</Buttons>
      </div>
    </Form>
  );
};

RecoverPasswordInnerForm.propTypes = {
  errors: PropTypes.shape({
    password: PropTypes.string,
    verifyPassword: PropTypes.string,
  }),
  touched: PropTypes.shape({
    password: PropTypes.bool,
    verifyPassword: PropTypes.bool,
  }),
  values: PropTypes.shape({
    password: PropTypes.string,
    verifyPassword: PropTypes.string,
  }),
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  handleSubmit: PropTypes.func,
  isValid: PropTypes.bool,
};

export default withFormik({
  mapPropsToValues: () => {
    return {
      password: '',
      verifyPassword: '',
    };
  },
  enableReinitialize: true,
  validateOnBlur: false,
  isInitialValid: false,
  validationSchema: RecoverPasswordSchema,
  handleSubmit: (values, { props: { onSubmit } }) => {
    onSubmit(values);
  },
})(RecoverPasswordInnerForm);
