import React from 'react';
import PropTypes from 'prop-types';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { withStyles } from '@material-ui/core/styles';

const styles = ({
  palette: {
    primary: { main },
  },
}) => ({
  tabs: {
    backgroundColor: main,
    borderBottom: `2px solid ${main}!important`,
  },
});

const TabsContainer = ({ options = [], classes, tabProps, ...props }) => (
  <Tabs {...props} classes={{ indicator: classes.tabs }}>
    {options.map(({ label, value, key }) => (
      <Tab label={`${label} ${key ? `(${key})` : ''}`} value={value} key={value} {...tabProps} />
    ))}
  </Tabs>
);

TabsContainer.propTypes = {
  options: PropTypes.array,
  tabProps: PropTypes.object,
  classes: PropTypes.object,
};

export default withStyles(styles)(TabsContainer);
